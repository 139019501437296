import * as React from 'react'
import { Typography } from '@applift/factor'
import {
  DevicesImage,
  UserAudio,
  UserVideo,
  WebsiteImage,
  WebsiteResponsive,
} from '@applift/illustrations'
import { AddCreativeDialogEmptySection, EmptyCard } from '../../../components'

interface CreateEmptySectionProps {
  creativeType: 'image' | 'html' | 'video' | 'audio' | 'native'
}

export const CreateEmptySection = (props: CreateEmptySectionProps) => {
  const { creativeType } = props
  const title = React.useMemo(() => {
    switch (creativeType) {
      case 'image':
        return 'Image Creative'
      case 'html':
        return 'HTML Creative'
      case 'video':
        return 'Video Creative'
      case 'audio':
        return 'Audio Creative'
      case 'native':
        return 'Native Creative'
    }
  }, [creativeType])

  const subText = React.useMemo(() => {
    switch (creativeType) {
      case 'image':
        return 'Captivate your audience with images that leave a lasting impression.'
      case 'html':
        return 'Engage users with dynamic, interactive content that adapts seamlessly across devices.'
      case 'video':
        return 'Encourage brand recall with rich storytelling content that leaves a lasting impression.'
      case 'audio':
        return 'Engage users through immersive sound experiences that bring your brand to life.'
      case 'native':
        return 'Deliver unobtrusive ads that compliment the content that surrounds them.'
    }
  }, [creativeType])

  const illustration = React.useMemo(() => {
    switch (creativeType) {
      case 'image':
        return <WebsiteImage sx={{ width: 100, height: 100 }} />
      case 'html':
        return <DevicesImage sx={{ width: 100, height: 100 }} />
      case 'video':
        return <UserVideo sx={{ width: 100, height: 100 }} />
      case 'audio':
        return <UserAudio sx={{ width: 100, height: 100 }} />
      case 'native':
        return <WebsiteResponsive sx={{ width: 100, height: 100 }} />
    }
  }, [creativeType])

  return (
    <AddCreativeDialogEmptySection>
      <EmptyCard
        illustration={illustration}
        title={
          <Typography
            component="h2"
            variant="bodyLarge"
            gutterBottom={false}
            weight="demi"
            lineHeight="single-line"
          >
            {title}
          </Typography>
        }
        subText={
          <Typography
            component="span"
            variant="bodyMedium"
            gutterBottom={false}
          >
            {subText}
          </Typography>
        }
      />
    </AddCreativeDialogEmptySection>
  )
}
