import { Box, Typography } from '@applift/factor'

export const SupportedFilesNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Typography
        variant="label"
        sx={{ textColor: 'neutral-500' }}
        lineHeight="single-line"
      >
        Supported Type & Max Size Limit
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 4,
          }}
        >
          <Typography variant="label" lineHeight="single-line">
            MP3, WAV, OGG
          </Typography>
          <Typography
            variant="label"
            sx={{ textColor: 'neutral-300' }}
            lineHeight="single-line"
          >
            &bull;
          </Typography>
          <Typography variant="label" lineHeight="single-line">
            100MB
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
