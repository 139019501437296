import { Row, Col, Box } from '@applift/factor'
import { UserAudio } from '@applift/illustrations'
import { FileUpload, PickUsers, TargetAudio } from '@applift/icons'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'

export const GroupedEmptyTab = () => {
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={<UserAudio sx={{ width: 100, height: 100 }} />}
              title="Grouped Audio Creatives"
              subText="Save time on asset management by grouping multiple variations of the same creative format. Apply these groups across campaigns, and test and optimize your creatives’ performance."
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<TargetAudio />}
                title="Hands-Free Engagement"
                subText="Reach consumers while they are engaged in offline activities."
              />
              <EmptyFeatureCard
                icon={<PickUsers />}
                title="Reach"
                subText="Tap into large, highly engaged audiences across audio categories and genres."
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple audio files at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
