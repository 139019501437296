import { WithResponse } from '@applift/platform'
import { AxiosResponse, AxiosError } from 'axios'

import { getInstance } from './instance'
import { AddAssetPayload, MediaData } from '../models/asset'

export const addAsset = async (queryParameters: AddAssetPayload) => {
  try {
    const formData = new FormData()

    formData.append(
      'filesMetadata',
      JSON.stringify(queryParameters.filesMetadata)
    )
    for (let i = 0; i < queryParameters.files.length; i++) {
      const file = queryParameters.files[i]
      formData.append(
        'files',
        file as File,
        queryParameters.filesMetadata[i]?.attachedFileName
      )
    }

    const response: AxiosResponse<WithResponse<MediaData>> =
      await getInstance().post('v3/asset', formData, {
        headers: { 'Content-Type': 'multipart/form-data; charset=utf-8' },
      })
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
