import * as React from 'react'

import { Row, Col, Box, Typography } from '@applift/factor'

interface EmptyHeroSectionType {
  /**
   * Need to pass empty state's illustration
   */
  illustration: React.ReactNode
  /**
   * Title of the empty state
   */
  title?: string
  /**
   * Sub-text of the empty state
   */
  subText?: string
  /**
   * Action of the empty state
   */
  action?: React.ReactNode
}

export const EmptyHeroSection = (props: EmptyHeroSectionType) => {
  const { illustration, title, subText, action } = props
  return (
    <Row sx={{ alignItems: 'center' }}>
      <Col xs={12} md={7} lg={5}>
        <Box sx={{ textColor: 'primary-500', width: 100 }}>{illustration}</Box>
      </Col>
      <Col xs={12} md={5} lg={7}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'center', md: 'start' },
            alignItems: { xs: 'center', md: 'start' },
            gap: 16,
          }}
        >
          {title && (
            <Typography
              component={'h2'}
              weight="demi"
              variant="h5"
              lineHeight="single-line"
              gutterBottom={false}
            >
              {title}
            </Typography>
          )}
          {subText && (
            <Typography
              variant="bodyLarge"
              gutterBottom={false}
              weight="normal"
              lineHeight="multi-line"
              sx={{ textAlign: { xs: 'center', md: 'left' } }}
            >
              {subText}
            </Typography>
          )}
          {action && action}
        </Box>
      </Col>
    </Row>
  )
}
