import React from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  enqueueSnackbar,
  LoadingButton,
  TextField,
  Typography,
} from '@applift/factor'

import { CreativeGroup } from '../../../api'
import { CREATIVE_NAME_LIMIT } from '../../../constants'
import { useRenameCreativeGroup } from '../../../hooks'

interface ConfirmationDialogProps {
  data: CreativeGroup
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const RenameCreativeGroupDialog = (props: ConfirmationDialogProps) => {
  const {
    data,
    onClose,
    onSuccess: _onSuccess,
    onError: _onError,
    onSettle,
  } = props

  const [groupName, setGroupName] = React.useState(data.creativeGroupName ?? '')

  const isError = groupName.length > CREATIVE_NAME_LIMIT
  const errorMessage = isError ? `Character limit exceeded` : ''

  const isDisabled =
    groupName.length <= 0 || groupName === data.creativeGroupName || isError

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length <= 0) {
      setGroupName('')
    } else {
      setGroupName(e.target.value)
    }
  }

  const onSuccess = () => {
    const message = (
      <Typography>
        <Typography weight="demi">{data.creativeGroupName}</Typography> group
        renamed successfully
      </Typography>
    )
    enqueueSnackbar(message, { variant: 'success' })
    _onSuccess?.()
    onSettle?.()
    onClose()
  }

  const onError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
    _onError?.()
    onSettle?.()
    onClose()
  }

  const renameMutation = useRenameCreativeGroup({ onSuccess, onError })

  const handleSubmit = () => {
    renameMutation.mutate({
      creativeGroupName: groupName.trim(),
      groupId: data.creativeGroupId,
    })
  }

  return (
    <Dialog open maxWidth={'sm'} fullWidth>
      <DialogTitle>Rename Creative Group</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ pb: 32 }}>
          <TextField
            required
            value={groupName}
            onChange={handleChange}
            label="Group Name"
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            InputLabelProps={{
              renderSuffix: (
                <Typography
                  sx={{ textColor: isError ? 'danger-400' : 'neutral-600' }}
                >
                  {groupName.length}/{CREATIVE_NAME_LIMIT}
                </Typography>
              ),
            }}
            style={{ width: 400 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={renameMutation.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          disabled={isDisabled}
          loading={renameMutation.isLoading}
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
