import {
  SelectAdvanceComponents,
  Typography,
  Box,
  TypoTooltip,
} from '@applift/factor'
import { Row } from '@tanstack/react-table'

import { CreativeGroup } from '../../api/getCreativeGroupsList'

export const ListItemRenderer =
  SelectAdvanceComponents.DefaultListOptionItemWrapper<CreativeGroup>({
    disableCheckbox: true,
    disableRowExpansion: true,
    selectionStyle: 'all',
  })

export const renderOption = (row: Row<CreativeGroup>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: 100,
        overflow: 'hidden',
      }}
    >
      <TypoTooltip
        arrow
        TypgraphyProps={{ weight: 'demi', lineHeight: 'single-line' }}
      >
        {row.original.creativeGroupName}
      </TypoTooltip>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 100,
          overflow: 'hidden',
          gap: 8,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            flexShrink: 0,
          }}
        >
          <Typography
            variant="label"
            weight="demi"
            lineHeight="single-line"
            sx={{ flexShrink: 0, textColor: 'neutral-400' }}
          >
            ID
          </Typography>
          <Typography
            component="span"
            variant="label"
            lineHeight="single-line"
            sx={{ flexShrink: 0 }}
          >
            {row.original.creativeGroupId}
          </Typography>
        </Box>
        <Typography
          component="span"
          variant="label"
          weight="demi"
          lineHeight="single-line"
          sx={{ flexShrink: 0, textColor: 'neutral-400' }}
        >
          &bull;
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Typography
            variant="label"
            weight="demi"
            lineHeight="single-line"
            sx={{ flexShrink: 0, textColor: 'neutral-400' }}
          >
            Total Creatives
          </Typography>
          <Typography
            component="span"
            variant="label"
            lineHeight="single-line"
            sx={{ flexShrink: 0 }}
          >
            {row.original.creativesCount}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
