import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { attachInstance } from '../api/instance'
import { queryClient } from '../cache'
import { CssBaseline, SnackbarProvider } from '@applift/factor'
import { AppCreatives } from '@applift/icons'
import {
  AppProvider,
  AppRootProvider,
  DataDogProvider,
  AppRootProps,
  PlatformDevTools,
} from '@applift/platform'
import { attachInstance as attachPlatformCreativesInstance } from '@applift/platform-creatives'
import { AppRoute } from './AppRoute'

export const REACT_APP_ORIGIN_URL =
  process.env.REACT_APP_ORIGIN_URL ?? 'https://iqm.stage.applift.com'

const REACT_APP_GOOGLE_API_URL =
  process.env.REACT_APP_GOOGLE_API_URL ?? 'https://oauth.stage.iqm.com'

const devAuthCredentials = {
  email: process.env.REACT_APP_EMAIL,
  password: process.env.REACT_APP_PASSWORD,
} as any

/* refer to package.json these envs are configured there */
export const REACT_APP_ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ??
  'stage') as AppRootProps['deploymentEnvironment']
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION ?? '-'
export const REACT_APP_DD_CLIENT_TOKEN =
  process.env.REACT_APP_DD_CLIENT_TOKEN ?? ''
export const REACT_APP_DD_APPLICATION_ID =
  process.env.REACT_APP_DD_APPLICATION_ID ?? ''

// We are setting here for quick change that would eleminate restarting the app when ORIGIN_URL changes
const REACT_APP_ORIGIN_URL_LOCALSTORAGE =
  window.localStorage.getItem('ORIGIN_URL')

export const App = () => {
  return (
    <AppRootProvider
      appLogo={<AppCreatives />}
      appName="Creatives"
      appVersion={REACT_APP_VERSION}
      deploymentEnvironment={REACT_APP_ENVIRONMENT}
      originURLFallback={
        REACT_APP_ORIGIN_URL_LOCALSTORAGE || REACT_APP_ORIGIN_URL
      }
      isAppBeta={false}
      legacyMode={true}
      // @ts-ignore
      attachInterceptorsToInstance={(instance, token, owid) => {
        attachInstance(instance, token, owid, REACT_APP_GOOGLE_API_URL)
        attachPlatformCreativesInstance(instance)
      }}
      dataDogCredentials={{
        clientToken: REACT_APP_DD_CLIENT_TOKEN,
        applicationId: REACT_APP_DD_APPLICATION_ID,
      }}
    >
      <DataDogProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider domRoot={document.body}>
            <CssBaseline />
            <AppProvider>
              <AppRoute />
            </AppProvider>
          </SnackbarProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            panelPosition="right"
            position="top-right"
          />
          <PlatformDevTools
            position="bottom-left"
            panelPosition="left"
            slotProps={{
              authProps: {
                initData: devAuthCredentials,
              },
            }}
          />
        </QueryClientProvider>
      </DataDogProvider>
    </AppRootProvider>
  )
}
