import { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface UpdateCreativeStatusRequest {
  creativeIds: number[]
  creativeStatusId: number
}

interface UpdateCreativeStatusResponse
  extends WithResponse<{ message: string }> {}

export const updateCreativeStatus = async (
  params: UpdateCreativeStatusRequest
) => {
  try {
    const res: AxiosResponse<UpdateCreativeStatusResponse> =
      await getInstance().patch('/v3/crt/creatives/update-status', params)

    return res.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
