import { Box, Typography } from '@applift/factor'

export const SpecificationsNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Typography
        variant="label"
        sx={{
          textColor: 'neutral-500',
        }}
        lineHeight="single-line"
      >
        Recommended Audio Length
      </Typography>
      <Typography variant="label" lineHeight="single-line">
        15 <Typography sx={{ textColor: 'neutral-500' }}>or</Typography> 30
        Seconds
      </Typography>
    </Box>
  )
}
