import * as React from 'react'
import { Box, enqueueSnackbar } from '@applift/factor'
import { DropZone } from './DropZone'
import { LoadingState, ErrorState } from './DropZoneStates'
import { SpecificationsNote } from './SpecificationsNote'
import { SupportedFilesNote } from './SupportedFilesNote'
import { CreateProps } from '../Create'
import { useImageUpload } from '../../../../../hooks'
import { DropZoneStates } from '../../../../../models'

interface UploadProps {
  onAddCreatives: CreateProps['onChange']
}

export const Upload = (props: UploadProps) => {
  const { onAddCreatives } = props

  const [dropZoneState, setDropZoneState] =
    React.useState<DropZoneStates>('normal')

  const addImages = useImageUpload({
    onSuccess: data => {
      onAddCreatives?.(data!)
      setDropZoneState('normal')
    },
    onError: e => {
      enqueueSnackbar({ message: `Error-${e}`, variant: 'error' })
      setDropZoneState('error')
    },
  })

  const onFileAdded = (files: File[]) => {
    setDropZoneState('loading')
    addImages.mutate({ files: files })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 16,
        pr: 24,
        gap: 24,
      }}
    >
      {dropZoneState === 'loading' ? (
        <LoadingState />
      ) : dropZoneState === 'error' ? (
        <ErrorState />
      ) : (
        <DropZone onAdd={onFileAdded} setDropZoneState={setDropZoneState} />
      )}
      <SupportedFilesNote />
      <SpecificationsNote />
    </Box>
  )
}
