import { utils } from 'xlsx'
import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
  NATIVE_BRAND_NAME_CHAR_LIMIT,
  NATIVE_DESCRIPTION__CHAR_LIMIT,
  NATIVE_TITLE_CHAR_LIMIT,
} from '../constants'
import { ErrorCSVType } from '../models'
import { isValidURL } from './helper'
import { Box, enqueueSnackbar, Link, Typography } from '@applift/factor'

export const creativeNameValidationFn = (value: string) => {
  if (!value || value.length > CREATIVE_NAME_LIMIT) {
    return !value ? 'Name is required' : 'Character limit exceeded'
  }
  return undefined
}

export const creativeThirdPartyIDValidationFn = (value: string) => {
  if (value.length > CREATIVE_THIRD_PARTY_ID_LIMIT) {
    return 'Character limit exceeded'
  }
  return undefined
}

export const htmlWidthValidationFn = (value?: number) => {
  if (value! < 120 || value! > 2000) {
    return 'Width must be between 120-2000'
  }
  return undefined
}

export const htmlHeightValidationFn = (value?: number) => {
  if (value! < 50 || value! > 1800) {
    return 'Height must be between 50-1800'
  }
  return undefined
}

export const clickUrlValidationFn = (
  value: string,
  isClickUrlMandatory: boolean
) => {
  if (isClickUrlMandatory === false) {
    if (value?.length && value !== '–') {
      return isValidURL(value) ? undefined : 'Enter a valid URL'
    }
  } else if (!value || !isValidURL(value)) {
    return !value || value === '–'
      ? 'Click URL is required'
      : 'Enter a valid URL'
  }
  return undefined
}

export const pixelUrlValidationFn = (value: string) =>
  isValidURL(value) === false ? 'Enter a valid URL' : undefined

export const trackingUrlValidationFn = (value: string) => {
  if (value.trim() && !isValidURL(value)) {
    return 'Enter a valid URL'
  }
  return undefined
}

export const nativeTitlesValidationFn = (title: string) => {
  if (Boolean(title) === false) return 'Title is required'
  if (title.length > NATIVE_TITLE_CHAR_LIMIT) return 'Character limit exceeded'
  return undefined
}

export const nativeDescriptionValidationFn = (description: string) => {
  if (Boolean(description) === false) return 'Description is required'
  if (description.length > NATIVE_DESCRIPTION__CHAR_LIMIT)
    return 'Character limit exceeded'
  return undefined
}

export const nativeBrandNameValidationFn = (value: string) => {
  if (Boolean(value) === false) return 'Brand Name is required'
  if (value.length > NATIVE_BRAND_NAME_CHAR_LIMIT)
    return 'Character limit exceeded'
  return undefined
}

export const errorFileToast = (
  errorType: ErrorCSVType[],
  closeSnackbar: (key?: any | undefined) => void,
  fileName: string
) => {
  const sheet = utils.json_to_sheet(errorType)
  const csvData = utils.sheet_to_csv(sheet)
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const snackId = enqueueSnackbar(
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Typography>
        {errorType.length} creative{errorType.length > 1 ? 's' : ''} failed to
        upload. Check the failure reason in the file and re-upload.
      </Typography>
      <Link
        href={url}
        // @ts-ignore
        download={fileName}
        onClick={() => {
          setTimeout(() => {
            URL.revokeObjectURL(url)
          }, 1000)
          closeSnackbar(snackId)
        }}
      >
        Download Failed Creatives
      </Link>
    </Box>,
    {
      variant: 'error',
      persist: true,
    }
  )
}
