import { NativeCreativeGenerationData } from './Creative'

export interface NativeVideo {
  src: string
  creativeSource: string
  creativeSourceURL: string
  name: string
  duration: number
  height?: number
  width?: number
  temporaryMediaId: string
  parentId: string // id of native item
}

export type NativeImage = {
  src: string
  width: number
  name: string
  height: number
  temporaryMediaId: string
  parentId: string // id of native item
}

export type NativeBrandIcon = {
  src: string
  width: number
  name: string
  height: number
  parentId: string // id of native item
}

export interface CTAOption {
  id: number | string
  ctaText: string
  defaultCta: boolean
}

export interface CTAresponseType {
  ctaDetailsList: CTAOption[]
}

// ------------ Below types are only for native file upload validation

export enum NativeCSVColKey {
  CREATIVE_AD_NAME = 'creative ad name',
  CREATIVE_AD_TYPE = 'creative ad type',
  TITLE = 'title',
  DESCRIPTION = 'description',
  CREATIVE_AD_IMAGE_URL = 'creative ad image',
  CTA_TEXT = 'cta text',
  CTA_URL = 'cta url',
  BRAND_NAME = 'brand name',
  BRAND_ICON_URL = 'brand icon url',
  PIXEL_URL = 'pixel url',
  VAST_URL = 'creative ad video vast url',
}
export interface CreativeRow {
  [NativeCSVColKey.CREATIVE_AD_NAME]: string
  [NativeCSVColKey.CREATIVE_AD_TYPE]: 'Display' | 'Video'
  [NativeCSVColKey.CREATIVE_AD_IMAGE_URL]: string
  [NativeCSVColKey.TITLE]: string
  [NativeCSVColKey.DESCRIPTION]: string
  [NativeCSVColKey.CTA_TEXT]?: string
  [NativeCSVColKey.CTA_URL]: string
  [NativeCSVColKey.BRAND_NAME]?: string
  [NativeCSVColKey.BRAND_ICON_URL]?: string
  [NativeCSVColKey.VAST_URL]?: string
  [NativeCSVColKey.PIXEL_URL]?: string
}

export interface ValidationResult {
  error: string | null
  validCreatives: NativeCreativeGenerationData[]
  invalidRows: {
    rowData: CreativeRow
    errors: string[]
  }[]
}
