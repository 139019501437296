import axios, { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '@applift/platform'
import { QueryFunctionContext } from '@tanstack/react-query'

import { UseCreativeGroupsListParams } from '../hooks/useCreativeGroupsList'
import { getInstance } from './instance'

export interface CreativeGroup {
  creativeGroupId: number
  creativeGroupName: string
  creativeTypeId: number
  createdAt: number
  creativesCount: number
}

export interface GetCreativeGroupsListResponse {
  totalRecords: number
  filteredRecords: number
  creativeGroupList: CreativeGroup[]
}

export const getCreativeGroupsList = async (
  params: QueryFunctionContext<UseCreativeGroupsListParams[]>
) => {
  const { pageParam = 1, queryKey, signal } = params
  // @ts-ignore
  const { scope: _, isSelectAll, ...requestBody } = queryKey[0] || {}

  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('/v3/crt/creatives/groups/list - Request Cancelled')
  })

  try {
    const response: AxiosResponse<WithResponse<GetCreativeGroupsListResponse>> =
      await getInstance().post(
        `/v3/crt/creatives/groups/list`,
        {
          ...requestBody,
          pageNo: isSelectAll ? -1 : pageParam,
        },
        {
          cancelToken: source.token,
        }
      )

    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
