import { ColumnDef } from '@applift/datagrid'
import { Box, Tooltip } from '@applift/factor'
import {
  CampaignCountCell,
  CreativeIdCell,
  CreativeNameCell,
  CreativePreviewCell,
  CreativeSizeCell,
  CreativeStatusCell,
  DateCell,
  RawDataCell,
} from '../../../components'
import { Creative } from '../../../models'
import { isImageCreative, isNativeImageCreatve } from '../../../utils'

export const ExistingNativeColDef: ColumnDef<Creative>[] = [
  {
    accessorKey: 'creativeId',
    id: 'creativeId',
    header: 'ID',
    enableResizing: false,
    meta: {
      description: 'Unique system-generated number assigned to each Creative',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <CreativeIdCell rowData={props.row.original} action={props.onAction} />
    ),
    size: 70,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Creative Preview Thumbnail',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => <CreativePreviewCell creative={props.row.original} />,
  },
  {
    accessorKey: 'creativeName',
    id: 'creativeName',
    header: 'Creative Name',
    enableResizing: false,

    cell: props => (
      <>
        <CreativeNameCell
          creativeTypeId={props.row.original.platformCreativeTypeId}
          label={props.row.original.creativeName}
        />
      </>
    ),
    size: 330,
  },
  {
    accessorKey: 'creativeStatusId',
    id: 'creativeStatusId',
    header: 'Status',
    size: 120,
    enableResizing: false,
    enableSorting: false,
    meta: {
      description: 'Creative Status',
    },
    cell: props => {
      return (
        <CreativeStatusCell statusId={props.row.original.creativeStatusId} />
      )
    },
  },
  {
    accessorKey: 'externalCreativeId',
    id: 'externalCreativeId',
    header: '3rd Party ID',

    size: 180,
    maxSize: 500,
    minSize: 100,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },

  {
    accessorKey: 'clickUrl',
    id: 'clickUrl',
    header: 'Click URL',
    enableSorting: false,

    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 180,
    maxSize: 500,
    minSize: 100,
  },
  {
    accessorKey: 'pixelUrl',
    id: 'pixelUrl',
    header: 'Pixel URL',
    enableSorting: false,

    cell: props => {
      const row = props.row.original
      const isImage = isImageCreative(row)
      const isNativeImage = isNativeImageCreatve(row)
      let tooltip = ''

      if (isImage === false && isNativeImage === false) {
        tooltip = 'Only editable for Image and Native Image'
      }

      return (
        <Tooltip
          enterDelay={500}
          enterNextDelay={500}
          title={tooltip}
          placement="top"
        >
          <Box sx={{ width: 100 }}>
            <RawDataCell value={props.row.original?.pixelUrl ?? ''} />
          </Box>
        </Tooltip>
      )
    },
    size: 180,
    maxSize: 500,
    minSize: 100,
  },
  {
    accessorKey: 'createdAt',
    id: 'createdAt',
    header: 'Created',
    meta: {
      align: 'right',
    },
    cell: props => <DateCell date={props.row.original.createdAt} />,

    size: 200,
    maxSize: 250,
    minSize: 100,
  },
  {
    accessorKey: 'duration',
    id: 'duration',
    header: 'Size',
    enableSorting: false,
    meta: {
      description:
        'Creative dimensions in pixels or the duration of the video or audio ad',
      align: 'right',
    },
    cell: props => <CreativeSizeCell creative={props.row.original} />,
    size: 120,
    maxSize: 250,
    minSize: 80,
  },
  {
    accessorKey: 'campaignCount',
    id: 'campaignCount',
    header: 'Campaigns',
    enableSorting: false,
    meta: {
      align: 'right',
    },
    cell: props => (
      <CampaignCountCell action={props.onAction} rowData={props.row.original} />
    ),
    size: 120,
    maxSize: 250,
    minSize: 80,
  },
]
