import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'
import { Box, Layout, LayoutContainer, Container } from '@applift/factor'
import {
  InfoBar,
  AppBar,
  useAppContext,
  useAppRootContext,
} from '@applift/platform'
import { useDatadogTrackViewsManually } from '@applift/app-utils'
import { QueryClient } from '@tanstack/react-query'
import { Sidebar } from '../modules/sidebar'
import { DialogManager, DialogManagerProvider } from '../modules/dialogManager'
import styles from './index.module.scss'

type RootContext = {
  appRootContext: ReturnType<typeof useAppRootContext>
  appContext: ReturnType<typeof useAppContext>
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RootContext>()({
  component: RootComponent,
})

function RootComponent() {
  // This hook will track datadog views manually on route change, required for SPA
  useDatadogTrackViewsManually()
  return (
    <DialogManagerProvider>
      <LayoutContainer
        header={
          <>
            <InfoBar />
            <AppBar />
          </>
        }
      >
        <Layout
          sidebar={<Sidebar />}
          sidebarWidth={320}
          maxWidth={320}
          minWidth={320}
          sidebarVariant="persistent"
          hideResizerHandle
        >
          <Box Component={Container} className={styles.root}>
            <Outlet />
          </Box>
        </Layout>
        <DialogManager />
      </LayoutContainer>
    </DialogManagerProvider>
  )
}
