import { MutateOptions, useMutation } from '@tanstack/react-query'

import { updateCreative } from '../api'

type useUpdateCreativeMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof updateCreative>>,
  any,
  Parameters<typeof updateCreative>[0],
  unknown
>

export const useUpdateCreative = (
  params?: useUpdateCreativeMutationOptions
) => {
  const mutationData = useMutation({
    mutationKey: [{ scope: 'updateCreative' }],
    mutationFn: updateCreative,
    onSuccess: params?.onSuccess,
    onError: params?.onError,
    onSettled: params?.onSettled,
  })

  return mutationData
}
