import { useQuery } from '@tanstack/react-query'

import { getCreativeStatuses } from '../api'

interface UseCreativeStatuses {
  displayAllStatusOption?: boolean
}

export const useCreativeStatuses = (params: UseCreativeStatuses = {}) => {
  const { displayAllStatusOption } = params
  const queryData = useQuery({
    queryKey: [{ scope: 'getCreativeStatuses', displayAllStatusOption }],
    queryFn: getCreativeStatuses,
    staleTime: Infinity,
    cacheTime: Infinity,
  })
  return queryData
}
