import * as React from 'react'

import { ReactChildrenType } from '../../../models/utils'

export type ActionType =
  | 'deleteCreative'
  | 'editPixelUrl'
  | 'editClickUrl'
  | 'addToGroup'
  | 'assignToCampaign'
  | 'assignedCampaign'
  | 'createCampaign'
  | 'deleteCreativeGroup'
  | 'duplicateCreativeGroup'
  | 'renameCreativeGroup'
  | 'showPreview'
  | 'editClickUrlConfirmation'
  | undefined

interface DialogManagerOptions {
  onSuccess?: (response: any) => void
  onError?: (error: any) => void
  onSettle?: () => void
}

interface AllCreativeDialogManagerContextType {
  setAction: (
    actionName: ActionType,
    payload?: any,
    options?: DialogManagerOptions
  ) => void
  actionData?: {
    actionName: ActionType
    payload: any
    options?: DialogManagerOptions
  }
}

const AllCreativeDialogManagerContext =
  React.createContext<AllCreativeDialogManagerContextType>(undefined!)

interface AllCreativeDialogManagerProviderProps {
  children: ReactChildrenType
}

export const AllCreativeDialogManagerProvider = (
  props: AllCreativeDialogManagerProviderProps
) => {
  const { children } = props

  const [actionData, setActionData] =
    React.useState<AllCreativeDialogManagerContextType['actionData']>()

  const setAction = React.useCallback(
    (action: ActionType, payload: any, options?: DialogManagerOptions) => {
      setActionData({
        actionName: action,
        payload: payload,
        options: options,
      })
    },
    [setActionData]
  )

  return (
    <AllCreativeDialogManagerContext.Provider
      value={{
        setAction: setAction,
        actionData: actionData,
      }}
    >
      {children}
    </AllCreativeDialogManagerContext.Provider>
  )
}

export const useAllCreativeDialogManager = () => {
  const { setAction, actionData } = React.useContext(
    AllCreativeDialogManagerContext
  )
  return { setAction, actionData }
}
