import { Paper, PaperProps } from '@applift/factor'

export const CreativeSection = (props: PaperProps) => {
  const { children, ...other } = props
  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        width: 100,
        height: 100,
        ...other?.sx,
      }}
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        ...other?.style,
      }}
      {...other}
    >
      {children}
    </Paper>
  )
}
