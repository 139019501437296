import {
  Box,
  TextField,
  Tooltip,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { InfoCircle } from '@applift/icons'
import { useStore } from '@tanstack/react-store'
import { NativeCreationFormType } from '../../CreateWrapper'
import { pixelUrlValidationFn } from '../../../../../../utils'
import { NativeCreativeGenerationData } from '../../../../../../models'

interface Props {
  form: NativeCreationFormType
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  creativesList: NativeCreativeGenerationData[]
}

export const PixelURL = (props: Props) => {
  const { form, onChange, creativesList } = props

  const isMultipleCreativesPresent = creativesList.length > 1

  const updateinAllCreatives = () => {
    const value = form.getFieldValue('pixelURL')

    onChange?.(
      creativesList
        .filter(item => item.nativeType === 'image')
        .map(creative => ({ ...creative, pixelURL: value }))
    )
    enqueueSnackbar(
      <>
        <Typography sx={{ textWeight: 'demi' }}>Pixel URL</Typography> updated
        succesfully for all creatives
      </>,
      { variant: 'success' }
    )
  }

  const { nativeType } = useStore(form.store, state => state.values)

  return (
    <Box sx={{ display: nativeType === 'image' ? 'block' : 'none' }}>
      <form.Field
        name="pixelURL"
        validators={{
          onChange: ({ value }) => pixelUrlValidationFn(value),
        }}
      >
        {field => {
          const hasError = Boolean(field.state.meta.errors[0])
          const enableUpdateBtn =
            hasError === false &&
            isMultipleCreativesPresent === true &&
            Boolean(field.state.value)
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <TextField
                label={<Label />}
                variant="outlinedDash"
                fullWidth
                placeholder="Enter Pixel URL"
                value={field.state.value}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value?.trim()?.length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
              <Box
                onClick={updateinAllCreatives}
                style={{
                  cursor: enableUpdateBtn ? 'pointer' : 'auto',
                  pointerEvents: enableUpdateBtn ? 'auto' : 'none',
                  opacity: enableUpdateBtn ? 1 : 0.4,
                }}
                sx={{ pl: 12 }}
              >
                <Typography
                  sx={{
                    textWeight: 'demi',
                    textColor: 'primary-600',
                    display: hasError ? 'none' : 'block',
                  }}
                >
                  Update in all creatives
                </Typography>
              </Box>
            </Box>
          )
        }}
      </form.Field>
    </Box>
  )
}

const Label = () => {
  return (
    <Tooltip
      title="To verify the ad serving using a third-party system, you can copy and paste Tracking Pixel URL from that system into this field"
      placement="top"
      arrow
    >
      <Box
        Component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="label">Pixel URL</Typography>
        <InfoCircle fontSize={14} sx={{ textColor: 'neutral-400' }} />
      </Box>
    </Tooltip>
  )
}
