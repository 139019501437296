import { WithResponse } from '@applift/platform'
import { RowSelectionState } from '@applift/datagrid'
import {
  Box,
  Button,
  Chip,
  enqueueSnackbar,
  LoadingButton,
  Tooltip,
} from '@applift/factor'

import { Actions } from './Actions'
import { CreativeGroup } from '../../../../../api'
import { CampaignActions } from './CampaignActions'
import { useCreativeStatuses, useModifyGroup } from '../../../../../hooks'
import { useAllCreativeDialogManager } from '../../..'
import { Creative, ModifyroupResponse } from '../../../../../models'
import React from 'react'

interface ActionPanelProps extends CreativeGroup {
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  filteredRecords: number
  selectionData: Creative[]
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    rowSelection,
    setRowSelection,
    filteredRecords,
    selectionData,
    creativeGroupId,
  } = props

  const selectionCount = Object.values(rowSelection).filter(Boolean).length ?? 0

  const { setAction } = useAllCreativeDialogManager()
  const { data: creativeStatusType } = useCreativeStatuses()
  const updateCreativeGroupMuation = useModifyGroup({
    onSuccess: res => {
      enqueueSnackbar(res?.data.message, { variant: 'success' })
      setRowSelection({})
    },
    onError: (res: WithResponse<ModifyroupResponse>) => {
      enqueueSnackbar(res.data?.message, { variant: 'error' })
      setRowSelection({})
    },
  })

  const isDeletedCreativeSelected = React.useMemo(() => {
    return selectionData.some(
      creative =>
        creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['deleted']?.id
    )
  }, [selectionData, creativeStatusType])

  const isDifferentCreativeSelected = React.useMemo(
    () =>
      selectionData.reduce((hasDifferent, curr, _, selectedCreatives) => {
        if (hasDifferent) return true
        return (
          curr.platformCreativeTypeId !==
          selectedCreatives[0]?.platformCreativeTypeId
        )
      }, false),
    [selectionData]
  )
  const isRejectedCreativeSelected = React.useMemo(() => {
    return selectionData.some(
      creative =>
        creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['rejected']?.id
    )
  }, [selectionData, creativeStatusType])

  const actionErrorTooltip = React.useMemo(() => {
    if (isDeletedCreativeSelected) {
      return 'Deleted creatives can’t be edited, duplicated, or removed.'
    }
  }, [isDeletedCreativeSelected])

  const campaignActionTooltip = React.useMemo(() => {
    if (isDifferentCreativeSelected) {
      return 'Choose creatives with the same Creative Type to add them to a campaign.'
    }
    if (isDeletedCreativeSelected || isRejectedCreativeSelected) {
      return 'Pending, Running, or Paused creative can be added to a campaign'
    }
  }, [
    isDifferentCreativeSelected,
    isDeletedCreativeSelected,
    isRejectedCreativeSelected,
  ])

  return (
    <Box
      sx={{
        p: 16,
        gap: 16,
        width: 100,
        flexShrink: 0,
        display: 'flex',
        overflow: 'hidden',
        flexWrap: 'wrap-reverse',
      }}
    >
      {selectionCount > 0 ? (
        <>
          <Box
            sx={{
              gap: 8,
              mr: 'auto',
              display: 'flex',
              alignItems: 'center',
              py: 4,
            }}
          >
            <Chip
              size="small"
              color="secondary"
              label={`${selectionCount} of ${filteredRecords} selected `}
              onDelete={() => setRowSelection({})}
            />
            <Tooltip title={actionErrorTooltip} arrow placement="top">
              <Box>
                <Actions
                  selectionData={selectionData}
                  setRowSelection={setRowSelection}
                />
              </Box>
            </Tooltip>
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                setAction('addToGroup', selectionData, {
                  onSettle: () => {
                    setRowSelection({})
                  },
                })
              }
            >
              Save To Group
            </Button>
            <LoadingButton
              size="small"
              variant="outlined"
              loading={updateCreativeGroupMuation.isLoading}
              onClick={() => {
                updateCreativeGroupMuation.mutate({
                  actionType: 'REMOVE',
                  creativeIds: selectionData.map(
                    creative => creative.creativeId
                  ),
                  creativeGroupIds: [creativeGroupId],
                })
              }}
            >
              Remove from Group
            </LoadingButton>
            <Tooltip title={campaignActionTooltip} arrow placement="top">
              <Box>
                <CampaignActions
                  selectionData={selectionData}
                  setRowSelection={setRowSelection}
                />
              </Box>
            </Tooltip>
          </Box>
        </>
      ) : (
        <Box style={{ height: 34 }} />
      )}
    </Box>
  )
}
