import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  sx,
  Typography,
  TypoTooltip,
} from '@applift/factor'

import { CreativeGroup } from '../../../../api'
import { CreativeTypeIcon } from '../..'
import { useCreativeTypes } from '../../../../hooks/useCreativeTypes'
import { GroupActions } from './GroupAction'

export interface GroupAccordionProps
  extends CreativeGroup,
    Omit<AccordionProps, 'id'> {}

export const GroupAccordion = (props: GroupAccordionProps) => {
  const {
    createdAt,
    creativeGroupName,
    creativesCount,
    creativeGroupId,
    children,
    creativeTypeId,
    ...other
  } = props

  const creativeTypes = useCreativeTypes()
  const creativeTypeMappingById = creativeTypes.data?.creativeTypeMappingById

  return (
    <Accordion
      elevation={1}
      sx={{
        mb: 16,
        width: 100,
        height: 100,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      {...other}
      classes={{ region: sx({ height: 100 }) }}
    >
      <AccordionSummary
        classes={{ content: sx({ overflow: 'hidden', py: 4 }) }}
      >
        <Box
          sx={{
            gap: 12,
            width: 100,
            display: 'flex',
            overflow: 'hidden',
            alignItems: 'center',
          }}
        >
          <Typography
            weight="demi"
            variant="bodySmall"
            gutterBottom={false}
            sx={{ textColor: 'neutral-500', flexShrink: 0 }}
          >
            {creativeGroupId}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: 100,
              overflow: 'hidden',
            }}
          >
            <CreativeTypeIcon
              name={creativeTypeMappingById?.[creativeTypeId]?.name}
              sx={{
                fontSize: 18,
                mr: 4,
                alignItems: 'center',
                textColor: 'primary-600',
                flexShrink: 0,
              }}
            />
            <TypoTooltip
              TypgraphyProps={{
                variant: 'bodySmall',
                weight: 'demi',
                gutterBottom: false,
              }}
              title={creativeGroupName}
              arrow
            >
              {creativeGroupName}
            </TypoTooltip>
          </Box>
        </Box>
        <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
          <Typography
            gutterBottom={false}
            variant="bodySmall"
            component={'p'}
            weight="demi"
            sx={{ textColor: 'neutral-400' }}
          >
            Created On:&nbsp;
          </Typography>
          <Typography
            gutterBottom={false}
            variant="bodySmall"
            weight="demi"
            sx={{ mr: 16 }}
          >
            {new Date(createdAt).toLocaleDateString()}
          </Typography>
          <Typography
            variant="bodySmall"
            weight="demi"
            gutterBottom={false}
            sx={{ textColor: 'neutral-400' }}
          >
            Creatives:&nbsp;
          </Typography>
          <Typography
            gutterBottom={false}
            variant="bodySmall"
            weight="demi"
            sx={{ mr: 16 }}
          >
            {creativesCount}
          </Typography>
          <GroupActions
            createdAt={createdAt}
            creativeGroupName={creativeGroupName}
            creativesCount={creativesCount}
            creativeTypeId={creativeTypeId}
            creativeGroupId={creativeGroupId}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, borderTop: 1, height: 100 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
