import { enqueueSnackbar } from '@applift/factor'

import {
  AssetsResponse,
  ErrorCSVType,
  SingleAsset,
} from '../../../../../models'
import { ERROR_MESSAGES } from '../../../../../constants'
import { formatFileSize } from '../../../../../utils'
import { DropItem } from '@applift/drag-drop'

export const getAudioDuration = async (url: string): Promise<number | null> => {
  return new Promise((resolve, reject) => {
    const audio = new Audio()

    audio.src = url

    // Event listener when the audio metadata is loaded
    audio.onloadedmetadata = () => {
      resolve(Math.round(audio.duration)) // duration in seconds
    }

    // Error handling
    audio.onerror = () => {
      enqueueSnackbar('Error loading audio file', { variant: 'error' })
      reject(null)
    }
  })
}

export const isFileLessThan100MB = (file: File): boolean =>
  file.size <= 104857600

export const isAudio = (fileName: string) =>
  /^.*\.(mp3|wav|ogg|mpeg)$/i.test(fileName)

export const filterAllowedAudioFiles = (fileList: File[]) => {
  const validFiles: File[] = []
  const errorFiles: ErrorCSVType[] = []

  fileList.forEach(file => {
    if (isFileLessThan100MB(file) === false) {
      errorFiles.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection': ERROR_MESSAGES.SIZE_EXCEED,
      })
    } else if (isAudio(file.name) === false) {
      errorFiles.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
      })
    } else {
      validFiles.push(file)
    }
  })

  return { validFiles, errorFiles }
}

// Function to get durations for an array of audio asset URLs and add durations to each asset
export async function getDuration(audioAssets: AssetsResponse) {
  // Process each asset and add its duration to a custom object
  const result = await Promise.all(
    Object.keys(audioAssets).map(async key => {
      const asset = audioAssets[key] as SingleAsset
      const duration = await getAudioDuration(asset.assetCDNURL as string)
      const fileName = key.match(/~([^~]+)\.[a-zA-Z0-9]+$/)?.[1] ?? ''
      return {
        asset,
        duration,
        name: `${fileName} ${Math.round(duration ?? 0)}s`,
      } // Return an object with the asset and its duration
    })
  )
  return result
}

export const getAudioItems = async (items: DropItem[]) => {
  const resultFiles: Promise<File>[] = []

  for (const item of items) {
    if (item.kind === 'file') {
      resultFiles.push(item.getFile())
    }
  }
  const finalResult = await Promise.all(resultFiles)
  const audioFiles: File[] = []
  for (const file of finalResult) {
    audioFiles.push(file)
  }
  return { audioFiles }
}
