import { WorkBook, WorkSheet, utils } from 'xlsx'
import { CreativeRow, NativeCSVColKey } from '../models'
import { toLowerCase } from './helper'
import { ERROR_MESSAGES, MANDATORY_NATIVE_COL } from '../constants'
import { enqueueSnackbar } from '@applift/factor'

interface InvalidRow {
  errors: string[]
  rowData: Record<string, any>
}

export const generateCSV = (invalidRows: InvalidRow[]) => {
  if (invalidRows.length === 0) return ''

  // Create headers with capitalized first letters
  const headers = [
    // @ts-ignore
    ...Object.keys(invalidRows[0].rowData).map(header =>
      header
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    ),
    'Errors',
  ]

  // Generate rows with data and errors
  const rows = invalidRows.map(item =>
    headers
      .slice(0, -1)
      .map(header => {
        const originalKey = header
          .split(' ')
          .map(word => word.charAt(0).toLowerCase() + word.slice(1))
          .join(' ')
        return item.rowData[originalKey] || ''
      })
      .concat(item.errors.join('; '))
  )

  // Prepare CSV content with headers and rows
  const csvContent = [
    headers.join(','),
    ...rows.map(row =>
      row.map(cell => `"${String(cell).replace(/"/g, '""')}"`).join(',')
    ),
  ].join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  return URL.createObjectURL(blob)
}

export const fileToFileList = (file: File) => {
  const dataTransfer = new DataTransfer()
  dataTransfer.items.add(file) // Adds the file to the DataTransfer object
  return dataTransfer.files // Returns a FileList-like object
}

// Workbook Row Extraction
export const extractRowsFromWorkbook = (workbook: WorkBook): CreativeRow[] => {
  const allRow = Object.values(workbook.Sheets).reduce(
    (acc: CreativeRow[], sheet: WorkSheet) => [
      ...acc,
      ...utils.sheet_to_json<CreativeRow>(sheet).map(row => {
        // Normalize column headers to lowercase
        const normalizedRow: { [key in NativeCSVColKey]?: string } = {}
        for (const key in row) {
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            const normalizedKey = toLowerCase(key)
            const matchingEnumKey = Object.values(NativeCSVColKey).find(
              enumKey => toLowerCase(enumKey) === normalizedKey
            )

            if (matchingEnumKey) {
              // Use the matching enum key as the index
              // @ts-ignore
              normalizedRow[matchingEnumKey] = row[key]
            }
          }
        }
        // Assert the normalizedRow is of type `CreativeRow`
        return normalizedRow as CreativeRow
      }),
    ],
    []
  )

  let isValid = true
  const row = allRow?.[0]
  if (row) {
    const exisiting = Object.keys(row)
    isValid = MANDATORY_NATIVE_COL.every(item => exisiting.includes(item))
    if (
      !exisiting.includes('creative ad image') &&
      !exisiting.includes('creative ad video vast url')
    ) {
      isValid = false
    }
  }

  if (isValid) {
    return allRow
  } else {
    enqueueSnackbar(ERROR_MESSAGES.INVALID_FORMAT, {
      variant: 'error',
    })
    return []
  }
}

export const readFileAsArrayBuffer = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
