import { TypographyProps, TypoTooltip } from '@applift/factor'

import {
  AUDIO_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../constants'
import { Creative } from '../../models/Creative'

interface CreativeSizeCellProps {
  creative: Creative
  isFooterCell?: boolean
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

function getImageSizeString(width: number, height: number) {
  if (width && height) {
    return `${width}px x ${height}px`
  }
  return '—'
}

function getVideoSizeString(duration?: number) {
  if (duration) {
    return `${duration}s`
  }
  return '—'
}

export const CreativeSizeCell = (props: CreativeSizeCellProps) => {
  const { creative, textTypographyProps, isFooterCell } = props
  const {
    platformCreativeTypeId,
    duration,
    creativeWidth,
    creativeHeight,
    subMediaType,
  } = creative

  let value = ''

  switch (platformCreativeTypeId) {
    case VIDEO_CREATIVE_ID:
    case AUDIO_CREATIVE_ID:
      value = getVideoSizeString(duration as number)
      break
    case NATIVE_CREATIVE_ID:
      if (!subMediaType) {
        value = '—'
      }
      value =
        subMediaType?.toLowerCase() === 'video'
          ? getVideoSizeString(duration as number)
          : getImageSizeString(
              creativeWidth as number,
              creativeHeight as number
            )
      break
    default:
      value = getImageSizeString(
        creativeWidth as number,
        creativeHeight as number
      )
  }
  return (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        ...textTypographyProps,
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {value}
    </TypoTooltip>
  )
}
