import { Col, Paper, Row, Typography } from '@applift/factor'
import { useStore } from '@tanstack/react-store'
import { NativeCreationFormType } from '../../CreateWrapper'
import { ClickURL } from './ClickURL'
import { NativeCreativeGenerationData } from '../../../../../../models'
import { PixelURL } from './PixelURL'
import { CustomTracking } from './CustomTracking'
import { CTA } from './CTA'

interface Props {
  form: NativeCreationFormType
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  creativesList: NativeCreativeGenerationData[]
}

export const TargetingCard = (props: Props) => {
  const { form, onChange, creativesList } = props

  const { nativeType } = useStore(form.store, state => state.values)

  const isImageNative = nativeType === 'image'

  return (
    <Paper
      elevation={2}
      sx={{ p: 16, display: 'flex', flexDirection: 'column', gap: 16 }}
    >
      <Typography
        sx={{
          textWeight: 'demi',
          textColor: 'neutral-600',
        }}
      >
        Targeting
      </Typography>

      <Row>
        <Col xs={6}>
          <ClickURL
            form={form}
            onChange={onChange}
            creativesList={creativesList}
          />
        </Col>
        <Col xs={6}>
          <CTA form={form} onChange={onChange} creativesList={creativesList} />
        </Col>
      </Row>
      <Row sx={{ display: isImageNative ? 'block' : 'none' }}>
        <Col xs={6}>
          <PixelURL
            form={form}
            onChange={onChange}
            creativesList={creativesList}
          />
        </Col>
      </Row>
      <Row sx={{ display: isImageNative ? 'none' : 'block' }}>
        <Col xs={12}>
          <CustomTracking form={form} />
        </Col>
      </Row>
    </Paper>
  )
}
