import { DropItem } from '@applift/drag-drop'
import { verifySize, allowedMimeTypes } from '../../../../../utils/image'
import { ErrorCSVType } from '../../../../../models'
import { ERROR_MESSAGES } from '../../../../../constants'
import { errorFileToast, formatFileSize } from '../../../../../utils'

export const getValidImagePickerItems = async (
  files: File[],
  closeSnackbar: (key?: any | undefined) => void
) => {
  const errorType: ErrorCSVType[] = []
  const validFiles: File[] = []
  const promises: Promise<void>[] = []
  for (const file of files) {
    if (verifySize(file)) {
      const promise = new Promise<void>(resolve => {
        const img = new Image()
        const url = URL.createObjectURL(file)
        img.onload = () => {
          validFiles.push(file)
          URL.revokeObjectURL(url) // Clean up the object URL
          resolve()
        }
        img.onerror = () => {
          errorType?.push({
            'File Name': file.name,
            'File Size': formatFileSize(file.size),
            'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
          })
          URL.revokeObjectURL(url) // Clean up on error
          resolve()
        }
        img.src = url
      })
      promises.push(promise)
    } else {
      errorType?.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection':
          allowedMimeTypes.indexOf(file.type) >= 0
            ? ERROR_MESSAGES.SIZE_EXCEED
            : ERROR_MESSAGES.UNSUPPORTED,
      })
    }
  }
  await Promise.all(promises)
  if (errorType.length > 0) {
    errorFileToast(errorType, closeSnackbar, 'image_error_files.csv')
  }
  return validFiles
}

export const getValidImageDropItems = async (
  items: DropItem[],
  closeSnackbar: (key?: any | undefined) => void
) => {
  const resultFiles: Promise<File>[] = []
  const invalidFiles: Promise<File>[] = []
  for (const item of items) {
    if (item.kind === 'file') {
      if (allowedMimeTypes.indexOf(item.type) >= 0) {
        resultFiles.push(item.getFile())
      } else {
        invalidFiles.push(item.getFile())
      }
    }
  }
  const finalResult = await Promise.all(resultFiles)
  const errorFiles = await Promise.all(invalidFiles)

  const validFiles: File[] = []
  const promises: Promise<void>[] = []
  for (const file of finalResult) {
    if (verifySize(file)) {
      const promise = new Promise<void>(resolve => {
        const img = new Image()
        const url = URL.createObjectURL(file)
        img.onload = () => {
          validFiles.push(file)
          URL.revokeObjectURL(url) // Clean up the object URL
          resolve()
        }
        img.onerror = () => {
          errorFiles.push(file)
          URL.revokeObjectURL(url) // Clean up on error
          resolve()
        }
        img.src = url
      })
      promises.push(promise)
    } else {
      errorFiles.push(file)
    }
  }

  await Promise.all(promises)
  const errorType = errorFiles.map<ErrorCSVType>(one => ({
    'File Name': one.name,
    'File Size': formatFileSize(one.size),
    'Reason for Rejection':
      allowedMimeTypes.indexOf(one.type) >= 0
        ? ERROR_MESSAGES.SIZE_EXCEED
        : ERROR_MESSAGES.UNSUPPORTED,
  }))

  if (errorType.length > 0) {
    errorFileToast(errorType, closeSnackbar, 'image_error_files.csv')
  }

  return validFiles
}
