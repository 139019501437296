import { MEGA_BYTE, kILO_BYTE } from '../../../../../constants'

const allowedMimeTypesSize = {
  'image/png': MEGA_BYTE * 2,
  'image/jpeg': MEGA_BYTE * 2,
  'image/jpg': MEGA_BYTE * 2,
  'image/gif': kILO_BYTE * 750,
} as const

export const verifyThumbnailSize = (file: File) => {
  const allowedSize =
    allowedMimeTypesSize[file.type as keyof typeof allowedMimeTypesSize]
  return allowedSize && file.size <= allowedSize ? true : false
}
