import { Box, Typography, Link } from '@applift/factor'
import { Close } from '@applift/icons'
import { Loading, Warning } from '@applift/illustrations'
import { EmptyCard } from '../../../../../components'

export const LoadingState = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 100,
      height: 100,
      overflow: 'hidden',
      py: 24,
      px: 16,
      gap: 24,
      border: 1,
      borderRadius: 4,
    }}
  >
    <EmptyCard
      illustration={<Loading sx={{ width: 100, height: 100 }} />}
      subText={
        <Typography component="span" variant="bodySmall" gutterBottom={false}>
          Please wait...
        </Typography>
      }
      isLoading
    />
  </Box>
)

export const ErrorState = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 100,
      height: 100,
      overflow: 'hidden',
      py: 24,
      px: 16,
      gap: 24,
      border: 1,
      borderRadius: 4,
    }}
  >
    <EmptyCard
      illustration={<Warning sx={{ width: 100, height: 100 }} />}
      subText={
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          Oops, something went wrong. Please try again after some time.
        </Typography>
      }
      action={
        <Link>
          <Typography
            gutterBottom={false}
            sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
          >
            <Close fontSize={18} sx={{ display: 'flex' }} />
            Close
          </Typography>
        </Link>
      }
    />
  </Box>
)
