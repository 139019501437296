import { Box, Col, Layout, Row, sx, Typography } from '@applift/factor'
import * as React from 'react'

import { useMediaQueue } from '../../../../../hooks'
import { MediaData } from '../../../../../models/asset'
import { NativeCreativeGenerationData } from '../../../../../models/Creative'
import { NativeCreationFormType } from '../CreateWrapper'
import { BrandInfoCard } from './BrandInfoCard'
import { NativeContentHeader } from './ContentHeader'
import { CreativeInfoCard } from './CreativeInfoCard'
import { DisplayInfoCard } from './DisplayInfoCard'
import {
  onBrandIconProcessed,
  onImageProcessed,
  onVideoProcessed,
  onVideoThumbmailProcessed,
} from './helper'
import { TargetingCard } from './TargetingCard'
import { NativePreview } from '@applift/platform-creatives'
import { useStore } from '@tanstack/react-form'

interface Props {
  data: Map<string, NativeCreativeGenerationData>
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  onRemove?: (id: string) => void
  onError?: (hasError: boolean) => void
  form: NativeCreationFormType
  idGeneratorRef: React.MutableRefObject<number>
  onSelect: (creative: NativeCreativeGenerationData) => void
  isCreateClicked?: boolean
}

export const CreateNativeCreativeSection = (props: Props) => {
  const {
    data,
    onChange,
    onError,
    form,
    idGeneratorRef,
    onSelect,
    isCreateClicked,
  } = props
  // eslint-disable-next-line
  const [actionPanelError, setActionPanelError] = React.useState<boolean>(false)

  const hasErrorInGrid = React.useMemo(() => {
    return Array.from(data.values()).some(creative => creative.hasError)
  }, [data])

  const creativeList = React.useMemo(() => Array.from(data.values()), [data])

  React.useEffect(() => {
    onError?.(actionPanelError || hasErrorInGrid)
  }, [actionPanelError, hasErrorInGrid, onError])

  const updateMasterList = React.useCallback(() => {
    const formData = {
      ...form.state.values,
      hasError: form.state.canSubmit === false,
    }
    onChange?.([formData])
  }, [form, onChange])

  const onNativeImageProcessed = React.useCallback(
    (files: MediaData) => onImageProcessed(files, creativeList, form, onChange),
    [creativeList, form, onChange]
  )
  const onNativeVideoThumbmailProcessed = React.useCallback(
    (files: MediaData) =>
      onVideoThumbmailProcessed(files, creativeList, form, onChange),
    [creativeList, form, onChange]
  )

  const onNativeVideoProcessed = React.useCallback(
    async (files: MediaData) =>
      await onVideoProcessed(files, creativeList, form, onChange),
    [creativeList, form, onChange]
  )
  const onNativeBrandIconProcessed = React.useCallback(
    (files: MediaData) =>
      onBrandIconProcessed(files, creativeList, form, onChange),
    [creativeList, form, onChange]
  )

  const imageQueue = useMediaQueue(onNativeImageProcessed)
  const videoThumbmailQueue = useMediaQueue(onNativeVideoThumbmailProcessed)
  const brandIconQueue = useMediaQueue(onNativeBrandIconProcessed)
  const videoQueue = useMediaQueue(onNativeVideoProcessed)

  const {
    brandIcon,
    brandName,
    cta,
    videoObjects,
    imageObjects,
    nativeType,
    titles,
    descriptions,
  } = useStore(form.store, state => state.values)

  const previewType = React.useMemo(() => {
    if (nativeType === 'image' && imageObjects?.[0]?.src?.length) {
      return 'image'
    } else if (
      videoObjects?.[0]?.creativeSource?.length ||
      videoObjects?.[0]?.creativeSourceURL?.length
    ) {
      return 'videoIMA'
    } else if (videoObjects?.[0]?.src.length) {
      return 'videoJS'
    }
    return undefined
  }, [nativeType, imageObjects, videoObjects])

  return (
    <Layout
      sidebarWidth={400}
      hideResizerHandle
      classes={{
        sidebarContent: sx({ overflowY: 'hidden' }),
      }}
      sidebar={
        <Box sx={{ p: 16 }}>
          <Box sx={{ mb: 16 }}>
            <Typography
              sx={{ textColor: 'neutral-600' }}
              variant="bodyMedium"
              weight="demi"
            >
              Preview
            </Typography>
          </Box>
          <NativePreview
            brandIcon={brandIcon?.src ?? ''}
            brandName={brandName?.length ? brandName : 'Your brand name'}
            description={
              descriptions?.[0]?.length
                ? descriptions?.[0]
                : 'This is a native description'
            }
            title={titles?.[0]?.length ? titles?.[0] : 'This is a native title'}
            //@ts-ignore
            variant={previewType}
            CTA={cta?.label.length ? cta.label : 'Call to action'}
            {...(previewType === 'image'
              ? { src: imageObjects?.[0]?.src ?? '' }
              : previewType === 'videoIMA'
                ? {
                    adTagXML: videoObjects[0]?.creativeSource,
                    adTagUrl: videoObjects[0]?.creativeSourceURL,
                  }
                : {
                    sources: [
                      {
                        src: videoObjects?.[0]?.src,
                        type: 'video/mp4',
                      },
                    ],
                  })}
          />
        </Box>
      }
      sidebarAnchor="right"
      sidebarVariant="permanent"
    >
      <Row sx={{ width: 100 }}>
        <Col
          xs={12}
          sx={{
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <NativeContentHeader
            form={form}
            idGeneratorRef={idGeneratorRef}
            onChange={onChange}
            onSelect={onSelect}
            currentCreativesCount={creativeList.length}
            isCreateClicked={isCreateClicked ?? false}
          />

          <form style={{ overflowY: 'auto' }} onChange={updateMasterList}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                width: 100,
                px: 24,
                py: 4,
                height: 100,
              }}
            >
              <CreativeInfoCard
                form={form}
                imageQueue={imageQueue}
                updateMasterList={updateMasterList}
                videoThumbmailQueue={videoThumbmailQueue}
                videoQueue={videoQueue}
                isCreateClicked={isCreateClicked ?? false}
              />
              <DisplayInfoCard form={form} />
              <TargetingCard
                form={form}
                creativesList={creativeList}
                onChange={onChange}
              />
              <BrandInfoCard
                form={form}
                updateMasterList={updateMasterList}
                queue={brandIconQueue}
                creativesList={creativeList}
                onChange={onChange}
                isCreateClicked={isCreateClicked ?? false}
              />
            </Box>
          </form>
        </Col>
      </Row>
    </Layout>
  )
}
