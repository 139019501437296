import { MutateOptions, useMutation } from '@tanstack/react-query'
import { createCreative } from '../api'

type useCreateCreativeMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof createCreative>>,
  unknown,
  Parameters<typeof createCreative>[0],
  unknown
>

export const useCreateCreative = (
  options: useCreateCreativeMutationOptions
) => {
  return useMutation(createCreative, {
    ...options,
    mutationKey: ['createCreative'],
  })
}
