import { Box, Skeleton } from '@applift/factor'

const SidebarSkeleton = () => {
  return (
    <Box sx={{ px: 16, py: 12, display: 'flex', gap: 12 }}>
      <Skeleton variant="rounded" height={60} width={80} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Skeleton variant="rounded" height={11} width={49} />
          <Skeleton variant="rounded" height={11} width={11} />
        </Box>
        <Skeleton variant="rounded" height={11} width={121} />
      </Box>
    </Box>
  )
}

export default SidebarSkeleton
