import * as React from 'react'
import { getLocalTimeZone } from '@internationalized/date'

import { Container, Divider } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import { ColumnDef, constructListFromColumnDef } from '@applift/datagrid'

import { Creative, DateRangeType, ViewType } from '../../../models'
import { ActionPanel } from './ActionPanel'
import { CreativeGroup } from '../../../api'
import { GroupsList } from './GroupsList/GroupsList'
import { CreativeSection } from '../../../components'
import { AllCreativeDialogManager } from '../../common'
import {
  AUDIO_CREATIVE_ID,
  DEFAULT_CREATIVE_LIST_SORTING,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../constants'
import { useCreativeGroupsList, useTimezoneList } from '../../../hooks'
import { GroupedEmptyTab as AllGroupedEmptyTab } from '../../all'
import { GroupedEmptyTab as HtmlGroupedEmptyTab } from '../../html'
import { GroupedEmptyTab as NativeGroupedEmptyTab } from '../../native'
import { GroupedEmptyTab as AudioGroupedEmptyTab } from '../../audio'
import { GroupedEmptyTab as VideoGroupedEmptyTab } from '../../video'
import { GroupedEmptyTab as ImageGroupedEmptyTab } from '../../image'

interface GroupedTabProps {
  creativeType: 'all' | 'image' | 'native' | 'video' | 'audio' | 'html'
  colDef: ColumnDef<Creative>[]
}

const creativeTypeIdMapper = {
  all: [
    HTML_CREATIVE_ID,
    NATIVE_CREATIVE_ID,
    IMAGE_CREATIVE_ID,
    VIDEO_CREATIVE_ID,
    AUDIO_CREATIVE_ID,
  ],
  image: [IMAGE_CREATIVE_ID],
  audio: [AUDIO_CREATIVE_ID],
  native: [NATIVE_CREATIVE_ID],
  video: [VIDEO_CREATIVE_ID],
  html: [HTML_CREATIVE_ID],
}

export const GroupedTab = (props: GroupedTabProps) => {
  const { creativeType, colDef } = props

  const ctx = useAppContext()
  const organizationTimezoneId = ctx.appMeta.organizationTimezone

  const { data: timezoneList } = useTimezoneList()
  const organizationTimezoneName =
    timezoneList?.[Number(organizationTimezoneId)]?.name ?? getLocalTimeZone()

  // Filter out the default column visibility in case needed custom default column visibility
  const [_, defaultColumnVisibility] = constructListFromColumnDef(colDef)

  const [columnVisibility, setColumnVisibility] = React.useState(
    defaultColumnVisibility
  )

  const [viewType, setViewType] = React.useState<ViewType>('table')
  const [sorting, setSorting] = React.useState(DEFAULT_CREATIVE_LIST_SORTING)
  const [search, setSearch] = React.useState('')
  const [statusIds, setStatusIds] = React.useState<number[]>([])
  const [dateRange, setDateRange] = React.useState<DateRangeType>({
    startDate: null,
    endDate: null,
  })

  const {
    data: allCreativeData,
    fetchNextPage,
    isError,
    isFetching,
    isLoading,
  } = useCreativeGroupsList({
    searchField: search,
    creativeStatusIds: statusIds,
    platformCreativeTypeIds: creativeTypeIdMapper[creativeType],
    startTime: dateRange?.startDate,
    endTime: dateRange?.endDate,
  })

  const data = React.useMemo(
    () =>
      allCreativeData?.pages?.reduce<CreativeGroup[]>(
        (prev, curr) => [...prev, ...(curr?.data?.creativeGroupList ?? [])],
        []
      ) ?? [],
    [allCreativeData]
  )

  const filteredRecords =
    allCreativeData?.pages?.[0]?.data?.filteredRecords || 0

  if (
    isLoading === false &&
    isFetching === false &&
    allCreativeData?.pages?.[0]?.data?.filteredRecords === 0 &&
    // If user haven't applied any filter and still the filteredRecords are empty means there are no creatives for that particular type
    Boolean(search) === false &&
    Boolean(Array.isArray(statusIds) && statusIds.length > 0) === false &&
    !dateRange?.endDate &&
    !dateRange?.startDate
  ) {
    switch (creativeType) {
      case 'all':
        return <AllGroupedEmptyTab />
      case 'image':
        return <ImageGroupedEmptyTab />
      case 'video':
        return <VideoGroupedEmptyTab />
      case 'audio':
        return <AudioGroupedEmptyTab />
      case 'native':
        return <NativeGroupedEmptyTab />
      case 'html':
        return <HtmlGroupedEmptyTab />
      default:
        return <AllGroupedEmptyTab />
    }
  }

  return (
    <CreativeSection>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          width: 100,
          height: 100,
          p: 0,
        }}
      >
        <ActionPanel
          search={search}
          setSearch={setSearch}
          statusIds={statusIds}
          setStatusIds={setStatusIds}
          viewType={viewType}
          setViewType={setViewType}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          sorting={sorting}
          setSorting={setSorting}
          filteredRecords={filteredRecords}
          creativeTypeIds={creativeTypeIdMapper[creativeType]}
          dateRange={dateRange}
          setDateRange={setDateRange}
          organizationTimezoneName={organizationTimezoneName}
        />
        <Divider sx={{ borderColor: 'neutral-100' }} />
        <GroupsList
          colDef={colDef}
          data={data}
          search={search}
          fetchNextPage={fetchNextPage}
          statusIds={statusIds}
          isError={isError}
          isFetching={isFetching}
          isLoading={isLoading}
          viewType={viewType}
          setViewType={setViewType}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          sorting={sorting}
          setSorting={setSorting}
          filteredRecords={filteredRecords}
          dateRange={dateRange}
          organizationTimezoneName={organizationTimezoneName}
        />
      </Container>
      <AllCreativeDialogManager />
    </CreativeSection>
  )
}
