import * as React from 'react'
import { Box, enqueueSnackbar } from '@applift/factor'
import { SupportedFilesNote } from './SupportedFilesNote'
import { SpecificationsNote } from './SpecificationsNote'
import { DropZone } from './DropZone'
import { CreateProps } from '../Create'
import { ErrorState, LoadingState } from './DropZoneStates'
import { useVideoUpload } from '../../../../../hooks/useVideo'
import { allowedVideoMIMETypes } from './utiles'
import { DropZoneStates } from '../../../../../models'

interface UploadProps {
  onAddCreatives: CreateProps['onChange']
}

export const Upload = (props: UploadProps) => {
  const { onAddCreatives } = props

  const [dropZoneState, setDropZoneState] =
    React.useState<DropZoneStates>('normal')

  // TODO: fix loading state validation of vast tasks time currently it is not concidered in the loading state
  const addVideos = useVideoUpload({
    onSuccess: data => {
      onAddCreatives?.(data!)
      setDropZoneState('normal')
    },
    onError: e => {
      enqueueSnackbar({ message: `Error-${e}`, variant: 'error' })
      setDropZoneState('error')
    },
  })

  const onFileAdded = (files: File[]) => {
    setDropZoneState('loading')
    const videoFile: File[] = []
    for (const file of files) {
      if (allowedVideoMIMETypes.includes(file.type)) {
        videoFile.push(file)
      }
    }
    addVideos.mutate({ files: files })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 16,
        pr: 24,
        gap: 24,
      }}
    >
      {dropZoneState === 'loading' ? (
        <LoadingState />
      ) : dropZoneState === 'error' ? (
        <ErrorState onClose={() => setDropZoneState('normal')} />
      ) : (
        <DropZone
          onFileAdded={onFileAdded}
          onAddCreatives={onAddCreatives}
          setDropZoneState={setDropZoneState}
        />
      )}
      <SupportedFilesNote />
      <SpecificationsNote />
    </Box>
  )
}
