import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface RenameCreativeGroupParams {
  groupId: number
  creativeGroupName: string
}

interface RenameCreativeGroupResponse
  extends WithResponse<{ id: number; message: string }> {}

export const renameCreativeGroup = async (
  params: RenameCreativeGroupParams
) => {
  try {
    const res: AxiosResponse<RenameCreativeGroupResponse> =
      await getInstance().patch(`/v3/crt/creatives/groups/${params.groupId}`, {
        creativeGroupName: params.creativeGroupName,
      })
    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
