import { useQuery } from '@tanstack/react-query'
import { getTimezoneListKey } from '../api/QueryKeys'
import { getTimezoneList } from '../api'
import { WithResponse } from '../models'
import { enqueueSnackbar } from '@applift/factor'

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    }
  )

export const useTimezoneList = () => {
  const data = useQuery(
    getTimezoneListKey.keys({ scope: 'getTimezones' }),
    getTimezoneList,
    {
      onError,
      keepPreviousData: true,
      cacheTime: Infinity,
    }
  )
  return data
}
