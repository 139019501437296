import { Box, Row, Col } from '@applift/factor'
import { ReactChildrenType } from '../../models/utils'

export interface AddCreativeDialogEmptySectionType {
  children: ReactChildrenType
}

export const AddCreativeDialogEmptySection = (
  props: AddCreativeDialogEmptySectionType
) => {
  const { children } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 16,
        px: 32,
      }}
    >
      <Row
        sx={{
          width: 100,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={4}>
          {children}
        </Col>
      </Row>
    </Box>
  )
}
