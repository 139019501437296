import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  enqueueSnackbar,
  LoadingButton,
  Typography,
} from '@applift/factor'

import { CreativeGroup } from '../../../api'
import { useDeleteCreativeGroup } from '../../../hooks'

interface ConfirmationDialogProps {
  data: CreativeGroup
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const DeleteCreativeGroupDialog = (props: ConfirmationDialogProps) => {
  const {
    data,
    onClose,
    onSuccess: _onSuccess,
    onSettle,
    onError: _onError,
  } = props

  const onSuccess = () => {
    const message = (
      <Typography>
        <Typography weight="demi">{data.creativeGroupName}</Typography> group
        deleted successfully
      </Typography>
    )
    enqueueSnackbar(message, { variant: 'success' })
    _onSuccess?.()
    onSettle?.()
    onClose()
  }

  const onError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
    onSettle?.()
    _onError?.(message)
    onClose()
  }

  const deleteMutation = useDeleteCreativeGroup({ onSuccess, onError })

  const handleSubmit = () => {
    deleteMutation.mutate({ creativeGroupIds: [data.creativeGroupId] })
  }

  return (
    <Dialog open maxWidth={'sm'} fullWidth>
      <DialogTitle>Delete Creative Group?</DialogTitle>
      <DialogContent>
        <Typography sx={{ flexShrink: 0 }} variant="bodySmall" component="span">
          Are you sure you want to delete the creative group{' '}
          <Typography weight="demi">{data.creativeGroupName}</Typography>?
          Deleting the group won't delete the nested creatives.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={deleteMutation.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          onClick={handleSubmit}
          loading={deleteMutation.isLoading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
