import { MutateOptions, useMutation } from '@tanstack/react-query'
import { uploadImageCreativesToAssets } from '../api/image'

type useImageUploadMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof uploadImageCreativesToAssets>>,
  unknown,
  Parameters<typeof uploadImageCreativesToAssets>[0],
  unknown
>

export const useImageUpload = (options: useImageUploadMutationOptions) => {
  return useMutation(uploadImageCreativesToAssets, {
    ...options,
    mutationKey: ['uploadImageCreativesToAssets'],
  })
}
