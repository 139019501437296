import { SortingState } from '@applift/datagrid'
import { AllCreativeListProps } from '../hooks/useCreative'
import { scopeTypes } from './ScopeNames'
import {
  CreativeCampaignRequest,
  CreativeTypesApiRequest,
  GetCreativeStatusCountRequest,
  GetCreativeTypeCountRequest,
} from '../models'

interface getAllCreativeListKeyArgs extends AllCreativeListProps {
  scope: scopeTypes
}

export const getAllCreativeListKey = {
  keys: (args: getAllCreativeListKeyArgs) => [args],
}

export const getCreativeTypesKey = {
  keys: (
    args: {
      scope: scopeTypes
    } & CreativeTypesApiRequest
  ) => [args],
}

export const getCreativeCampaignDetailKey = {
  keys: (
    args: {
      scope: scopeTypes
    } & CreativeCampaignRequest
  ) => [args],
}

export const getBasicIoCampaignListKey = {
  keys: (args: {
    scope: scopeTypes
    ioIdList?: number[]
    ids?: number[]
    owIdList?: string[]
    ioBudgetTypeIdsList?: number[]
    ioStatusIdsList?: number[]
    campaignIds?: number[]
    noOfEntries?: number
    sortBy?: SortingState
    offset?: number
    isAllOWIds?: boolean
    creativeTypeIds?: number[]
    pageNo?: number
    searchField: string
    campaignStatus?: string[]
  }) => [args],
}

export const getCreativeStatusCountKey = {
  keys: (
    args: GetCreativeStatusCountRequest & {
      scope: scopeTypes
    }
  ) => [args],
}

export const getCreativeTypeCountKey = {
  keys: (
    args: GetCreativeTypeCountRequest & {
      scope: scopeTypes
    }
  ) => [args],
}

export const getNativeCTAOptionsKey = {
  keys: (args: { scope: scopeTypes; showOnlyDefault: boolean }) => [args],
}

export const getTimezoneListKey = {
  keys: (args: { scope: string }) => [args],
}
