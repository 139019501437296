import { MutateOptions, useMutation } from '@tanstack/react-query'
import { uploadVideoCreativesToAssets } from '../api/video'

type useVideoUploadMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof uploadVideoCreativesToAssets>>,
  unknown,
  Parameters<typeof uploadVideoCreativesToAssets>[0],
  unknown
>

export const useVideoUpload = (options: useVideoUploadMutationOptions) => {
  return useMutation(uploadVideoCreativesToAssets, {
    ...options,
    mutationKey: ['uploadVideoCreativesToAssets'],
  })
}
