import { Typography } from '@applift/factor'
import { fromAbsolute, getLocalTimeZone } from '@internationalized/date'
import { dateFormat } from '../../../../../utils'

export interface CreatedDateValueTypeProps {
  createdAt: number
  timezone?: string
}

export const CreatedDateValueType = (props: CreatedDateValueTypeProps) => {
  const { timezone, createdAt } = props

  const zonedTime = timezone?.length
    ? dateFormat(fromAbsolute(createdAt, timezone).toDate(), {
        timeZone: timezone,
      })
    : dateFormat(fromAbsolute(createdAt, getLocalTimeZone()).toDate(), {
        timeZone: getLocalTimeZone(),
      })

  return (
    <Typography variant="bodySmall" sx={{ pl: 12 }}>
      {zonedTime}
    </Typography>
  )
}
