import { Box, Button, enqueueSnackbar } from '@applift/factor'
import {
  DevicesImage,
  UserAudio,
  UserVideo,
  WebsiteImage,
  WebsiteResponsive,
} from '@applift/illustrations'
import { Add } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'

import { EmptyCreativeCard } from '../../components/EmptyCreativeCard'
import { EmptyCreativeSection } from '../../components/EmptyCreativeSection'
import { useDialogManager } from '../dialogManager'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'
import {
  AUDIO_CREATIVE_ID,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../constants'

export const AllEmptyTab = () => {
  const { setAction } = useDialogManager()
  const queryClient = useQueryClient()
  return (
    <Box sx={{ paddingTop: 16 }}>
      {/* This is empty page - show when there is no creative */}
      <EmptyCreativeSection>
        <EmptyCreativeCard
          illustration={<WebsiteImage sx={{ width: 100, height: 100 }} />}
          title="Image Creative"
          subText="Capture consumers’ attention with bold, static visuals."
          action={
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() =>
                setAction('image', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          IMAGE_CREATIVE_ID
                        ),
                    })

                    if (createdCreativesId.length) {
                      const succeededCreative = Object.keys(
                        response.successData ?? {}
                      ).map(assetId => {
                        return {
                          creativeId: Number(
                            response.successData[`${assetId}`]
                          ),
                          platformCreativeTypeId: IMAGE_CREATIVE_ID,
                          creativeName: request[`${assetId}`]?.creativeName,
                        }
                      })
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add Image Creative
            </Button>
          }
        />
        <EmptyCreativeCard
          illustration={<DevicesImage sx={{ width: 100, height: 100 }} />}
          title="HTML Creative"
          subText="Engage users with dynamic, interactive content that adapts seamlessly across devices."
          action={
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() =>
                setAction('html', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          HTML_CREATIVE_ID
                        ),
                    })

                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: HTML_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add HTML Creative
            </Button>
          }
        />
        <EmptyCreativeCard
          illustration={<UserVideo sx={{ width: 100, height: 100 }} />}
          title="Video Creative"
          subText="Deliver compelling stories with moving images and sound."
          action={
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() =>
                setAction('video', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          VIDEO_CREATIVE_ID
                        ),
                    })
                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: VIDEO_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add Video Creative
            </Button>
          }
        />
        <EmptyCreativeCard
          illustration={<UserAudio sx={{ width: 100, height: 100 }} />}
          title="Audio Creative"
          subText="Reach audiences wherever they are with personalized sounds that resonate."
          action={
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() =>
                setAction('audio', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) => {
                        return (
                          query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                          query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                            AUDIO_CREATIVE_ID
                          )
                        )
                      },
                    })
                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: AUDIO_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add Audio Creative
            </Button>
          }
        />
        <EmptyCreativeCard
          illustration={<WebsiteResponsive sx={{ width: 100, height: 100 }} />}
          title="Native Creative"
          subText="Blend in naturally with surrounding content to drive cohesive, authentic interactions."
          action={
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() =>
                setAction('native', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          NATIVE_CREATIVE_ID
                        ),
                    })
                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: NATIVE_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add Native Creative
            </Button>
          }
        />
      </EmptyCreativeSection>
    </Box>
  )
}
