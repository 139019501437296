import * as React from 'react'
import {
  sx,
  Col,
  Row,
  Box,
  Radio,
  Button,
  Dialog,
  FormLabel,
  TextField,
  Typography,
  RadioGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  LoadingButton,
  FormControlLabel,
} from '@applift/factor'
import {
  IOSelect,
  useAppContext,
  IOBasicDetailsListType,
  IOSelectApiRefType,
} from '@applift/platform'
import { useForm } from '@tanstack/react-form'
import { CampaignAdvanced, CampaignPG } from '@applift/icons'
import { DataGrid, GridActionCellParams } from '@applift/datagrid'

import { Creative } from '../../../models/Creative'
import { CAMPAIGN_TYPE_BY_NAME, IO_STATUS_ID } from '../../../constants'
import { getPathNumber } from '../../../utils'
import { colDef } from './colDef'
import styles from './index.module.scss'

interface CreateCampaignDialogProps {
  data: Creative[]
  onClose: () => void
  onSuccess?: (request?: any) => void
}

export const CreateCampaignDialog = (props: CreateCampaignDialogProps) => {
  const { onClose, onSuccess, data } = props

  const [action, setAction] =
    React.useState<GridActionCellParams<string, any>>()

  const ctx = useAppContext()
  const owId = ctx.appMeta.loggedInOwId
  const ioSelectRef = React.useRef<IOSelectApiRefType>(null)

  const selectedCreativeIds = data?.map(creative => creative.creativeId) ?? []

  const form = useForm({
    defaultValues: {
      insertionOrder: [] as IOBasicDetailsListType[],
      campaignName: '',
      campaignType: CAMPAIGN_TYPE_BY_NAME.ADVANCED as number,
    },
    onSubmit: async ({ value }) => {
      window.open(
        encodeURI(
          `/campaigns/u/${getPathNumber()}/#/${
            value.campaignType === CAMPAIGN_TYPE_BY_NAME.ADVANCED
              ? 'campaign-create'
              : 'campaign-create-pg'
          }?ioId=${
            value.insertionOrder?.[0]?.ioId
          }&campaignName=${value.campaignName.trim()}&creativeIds=${selectedCreativeIds.join(',')}`
        ),
        '_blank'
      )
      onSuccess?.()
      onClose?.()
    },
  })

  React.useEffect(() => {
    switch (action?.actionName) {
      case 'REMOVE': {
        const actionRowId = action?.metaData?.row.ioId
        if (actionRowId) {
          ioSelectRef.current?.removeSelection(String(actionRowId))
        }
        break
      }
    }
  }, [action])

  return (
    <Dialog fullWidth maxWidth="lg" open>
      <form
        style={{ height: '100%' }}
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Typography weight="demi" sx={{ ml: 8 }}>
            Create Campaign
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ flexDirection: 'column', display: 'flex', gap: 24 }}
        >
          <Row>
            <Col xs={12}>
              <Typography sx={{ mb: 16 }}>
                Enter the campaign details which you would like to create using
                the selected creative(s)
              </Typography>
            </Col>
          </Row>
          <Row sx={{ mb: 16 }}>
            <Col xs={4}>
              <form.Field name="insertionOrder">
                {field => (
                  <IOSelect
                    ref={ioSelectRef}
                    slotProps={{
                      PaperProps: {
                        style: {
                          maxWidth: 500,
                        },
                      },
                    }}
                    multiple={false}
                    onChange={(value, action, isSelectAll) => {
                      console.log('ON_CHANGE', { value, action, isSelectAll })
                      field.handleChange(Object.values(value))
                    }}
                    params={{
                      sortBy: '-ioId',
                      owIdList: [owId],
                      ioStatusIdsList: [
                        IO_STATUS_ID.ACTIVE,
                        IO_STATUS_ID.EXPIRED,
                      ],
                    }}
                    TextFieldProps={{
                      label: 'Insertion Order',
                      required: true,
                      fullWidth: true,
                      variant: 'outlinedDash',
                    }}
                  />
                )}
              </form.Field>
            </Col>
            <Col xs={4}>
              <form.Field name="campaignName">
                {field => (
                  <TextField
                    sx={{ width: 100 }}
                    label="Campaign Name"
                    variant="outlinedDash"
                    placeholder="Enter Campaign Name"
                    value={field.state.value}
                    required
                    onChange={e => {
                      const value = e.target.value
                      if (value.trim().length === 0) {
                        field.handleChange(value.trim())
                        return
                      }
                      field.handleChange(e.target.value)
                    }}
                    error={Boolean(field.state.meta.errors?.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
            <Col xs={4}>
              <form.Field name="campaignType">
                {field => (
                  <Box>
                    <FormLabel
                      key="campaignType"
                      id="radio-buttons-campaign-type"
                      sx={{ mx: 12, display: 'flex', alignItems: 'center' }}
                    >
                      <Typography
                        variant="label"
                        component="span"
                        sx={{ textColor: 'neutral-600' }}
                      >
                        Campaign Type
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ textColor: 'danger-400' }}
                      >
                        *
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      defaultValue={CAMPAIGN_TYPE_BY_NAME.ADVANCED}
                      value={field.state.value}
                      title="DealStatus"
                      name="radio-buttons-group"
                      onChange={value => {
                        const campaignType = parseInt(value.target.value, 10)
                        field.handleChange(campaignType)
                      }}
                      sx={{ display: 'flex', flexDirection: 'row', mx: 8 }}
                    >
                      <FormControlLabel
                        value={CAMPAIGN_TYPE_BY_NAME.ADVANCED}
                        control={<Radio />}
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 4,
                            }}
                          >
                            <CampaignAdvanced color="primary" fontSize={20} />
                            <Typography gutterBottom={false}>
                              Advance
                            </Typography>
                          </Box>
                        }
                      />
                      <FormControlLabel
                        value={CAMPAIGN_TYPE_BY_NAME.PG}
                        control={<Radio />}
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 4,
                            }}
                          >
                            <CampaignPG color="primary" fontSize={20} />
                            <Typography gutterBottom={false}>PG</Typography>
                          </Box>
                        }
                      />
                    </RadioGroup>
                  </Box>
                )}
              </form.Field>
            </Col>
          </Row>
          <form.Subscribe selector={state => [state.values]}>
            {([values]) => {
              const selectedIO = values?.insertionOrder
              return selectedIO?.length ? (
                <Row sx={{ height: 100 }}>
                  <Col sx={{ height: 100 }}>
                    <DataGrid
                      data={selectedIO}
                      columns={colDef}
                      onAction={setAction}
                      rowCount={selectedIO?.length}
                      hideHeader
                      hideFooter
                      rowHeight={40}
                      checkboxSelection={false}
                      overscrollBehaviorX="contain"
                      showColumnRightBorder
                      showCellRightBorder
                      disableSelectAll
                      disableRowSelectionOnClick
                      classes={{
                        root: `${styles.table} ${sx({
                          borderRadius: 0,
                          border: 1,
                          borderBottom: 0,
                        })}`,
                      }}
                    />
                  </Col>
                </Row>
              ) : null
            }}
          </form.Subscribe>
        </DialogContent>
        <DialogActions>
          <form.Subscribe selector={state => [state.values]}>
            {([values]) => {
              const isEmpty =
                Boolean(values?.insertionOrder.length) === false ||
                Boolean(values?.campaignName) === false ||
                Boolean(values?.campaignType) === false
              const disableSubmit = isEmpty
              return (
                <>
                  <Button color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    disabled={disableSubmit}
                  >
                    Create
                  </LoadingButton>
                </>
              )
            }}
          </form.Subscribe>
        </DialogActions>
      </form>
    </Dialog>
  )
}
