import { Box } from '@applift/factor'
import { Creative } from '../../../models'
import { ImageCell } from '../ImageCell'
import { getPlaceholderIcon } from './helper'
import * as React from 'react'

interface ImageCellWrapperProps {
  creative: Creative
}

export const ImageCellWrapper = (props: ImageCellWrapperProps) => {
  const { creative } = props
  const {
    creativeThumbnailSource,
    platformCreativeTypeId,
    creativePreviewFlag,
  } = creative

  const [localThumbmailSrc, setLocalThumbmailSrc] = React.useState<string>('')
  const [hideImage, setHideImage] = React.useState<boolean>(false)
  const [retryCount, setRetryCount] = React.useState<number>(0)

  const checkIfImageExists = (
    url: string,
    callback: (exists: boolean) => void
  ) => {
    const img = new Image()
    img.src = url
    img.onload = () => callback(true)
    img.onerror = () => callback(false)
  }

  const handleImageCheck = React.useCallback(() => {
    checkIfImageExists(creativeThumbnailSource, exists => {
      if (exists) {
        setLocalThumbmailSrc(creativeThumbnailSource)
        setHideImage(false)
      } else {
        setHideImage(true)
      }
    })
  }, [creativeThumbnailSource])

  React.useEffect(() => {
    if (creativePreviewFlag === 1) {
      handleImageCheck()
    } else if (retryCount < 3) {
      const timer = setTimeout(() => {
        handleImageCheck()
        setRetryCount(retryCount + 1)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [creativePreviewFlag, handleImageCheck, retryCount])

  React.useEffect(() => {
    handleImageCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeThumbnailSource])

  return localThumbmailSrc?.length &&
    !hideImage &&
    creativePreviewFlag !== undefined ? (
    <ImageCell src={localThumbmailSrc} />
  ) : (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {getPlaceholderIcon(platformCreativeTypeId)}
    </Box>
  )
}
