import { Creative } from '../../../models'

export const ACTION = {
  EDIT_CREATIVE_NAME: 'EDIT_CREATIVE_NAME',
  EDIT_THIRD_PARTY_ID: 'EDIT_THIRD_PARTY_ID',
  EDIT_CLICK_URL: 'EDIT_CLICK_URL',
  EDIT_PIXEL_URL: 'EDIT_PIXEL_URL',
  SORT: 'SORT',
  OPEN_ASSIGN_TO_CAMPAIGN: 'OPEN_ASSIGN_TO_CAMPAIGN',
  OPEN_CREATIVE_DETAIL: 'OPEN_CREATIVE_DETAIL',
} as const

export type ActionNameType = keyof typeof ACTION

export type ActionMetaData = { data?: Creative; id?: number; params?: any }
