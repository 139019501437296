import {
  UseInfiniteQueryResult,
  type UseQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query'

import { getAllCreativeListKey } from '../api/QueryKeys'
import { getAllCreativeList } from '../api/creative'
import { DEFAULT_PAGE_SIZE } from '../constants'
import { CreativeTableResponse } from '../models/Creative'

export interface AllCreativeListProps {
  searchField?: string
  sortBy?: string
  noOfEntries?: number
  creativeStatusIds?: number[]
  platformCreativeTypeIds?: number[]
  creativeIds?: number[]
  campaignIds?: number[]
  owIds?: number[]
  activeCreatives?: boolean
  createdAfter?: number
  allOwIds?: boolean
  includeOrganizationDetails?: boolean
  includeUserDetails?: boolean
  creativeGroupId?: number
  startTime?: number | null
  endTime?: number | null
  includeCreativeSourceDetails?: boolean
}

export const useCreativesList = (
  props: AllCreativeListProps,
  options?: Omit<
    UseQueryOptions<any>,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  >
): UseInfiniteQueryResult<CreativeTableResponse> => {
  const {
    owIds,
    sortBy,
    searchField,
    creativeIds,
    noOfEntries = DEFAULT_PAGE_SIZE,
    campaignIds,
    createdAfter,
    allOwIds,
    activeCreatives,
    includeOrganizationDetails,
    includeUserDetails,
    creativeStatusIds,
    platformCreativeTypeIds,
    creativeGroupId,
    startTime,
    endTime,
    includeCreativeSourceDetails = false,
  } = props

  // @ts-ignore
  const queryData = useInfiniteQuery({
    queryKey: getAllCreativeListKey.keys({
      scope: 'getAllCreativeList',
      owIds,
      sortBy,
      creativeIds,
      searchField,
      campaignIds,
      noOfEntries,
      createdAfter,
      allOwIds,
      includeOrganizationDetails,
      includeUserDetails,
      activeCreatives,
      creativeStatusIds,
      platformCreativeTypeIds,
      creativeGroupId,
      startTime,
      endTime,
      includeCreativeSourceDetails,
    }),
    queryFn: getAllCreativeList,
    getNextPageParam: (lastPage, pages) => {
      const totalRecordsFetched = pages.reduce((prev, one) => {
        const datalength = one?.filteredList?.length
        if (datalength) {
          return prev + datalength
        }
        return prev
      }, 0)
      if (
        lastPage?.filteredRecords !== undefined &&
        totalRecordsFetched < lastPage?.filteredRecords
      ) {
        return pages.length + 1
      }
      return
    },
    ...(options ?? {}),
  })
  return queryData
}
