import * as React from 'react'
import {
  Box,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  sx,
  AccordionDetails,
  enqueueSnackbar,
} from '@applift/factor'
import { ChevronRight, Delete } from '@applift/icons'

import { AddAudioThumbnailDropZone } from '../../Upload'
import { verifyThumbnailSize } from '../helper'
import { AssetsResponse } from '../../../../../../models'
import { AudioCreationFormType } from '../Create'
import { useAssetUpload } from '../../../../../../hooks'

interface Props {
  form: AudioCreationFormType
  updateMasterList: () => void
}

export const AudioThumbnail = (props: Props) => {
  const { updateMasterList, form } = props

  const [expandedUploadAudio, setExpandedUploadAudio] = React.useState<
    string | false
  >(false)

  const handleChangeUploadAudio =
    (panel: string) => (_: any, isExpanded: boolean) => {
      setExpandedUploadAudio(isExpanded ? panel : false)
    }

  const onSuccess = (res: AssetsResponse) => {
    const assetKey = Object.keys(res)[0]
    const assetURL = res[assetKey as keyof typeof res]?.assetCDNURL
    if (assetURL) {
      form.setFieldValue('logoSource', assetURL)
      setDropzoneState('thumbnail')
      updateMasterList()
    }
  }

  const onError = () => {
    enqueueSnackbar('Something went wrong. Please try after some time', {
      variant: 'error',
    })
    setDropzoneState('normal')
  }

  const assetUpload = useAssetUpload({ onError, onSuccess })

  const onFileUpload = async (files: File[] | null) => {
    const file = files?.[0]
    const isValidImage = verifyThumbnailSize(file as File)

    if (isValidImage === false) {
      return enqueueSnackbar(
        'Uploaded image should match the mentioned specifications',
        { variant: 'error' }
      )
    }
    if (file) {
      setDropzoneState('loading')
      assetUpload.mutate([file])
    }
  }

  const [dropzoneState, setDropzoneState] = React.useState<
    'normal' | 'loading' | 'timeout' | 'error' | 'thumbnail'
  >('normal')

  return (
    <form.Field name="logoSource">
      {field => (
        <Accordion
          expanded={expandedUploadAudio === 'panelUploadAudio'}
          onChange={handleChangeUploadAudio('panelUploadAudio')}
          sx={{
            width: 100,
            boxShadow: 0,
            border: 1,
            borderRadius: 4,
            borderColor: 'neutral-100',
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ChevronRight fontSize={18} />}
            classes={{
              content: sx({
                alignItems: 'center',
                justifyContent: 'between',
                my: 4,
              }),
            }}
            sx={{
              flexDirection: 'row-reverse',
              gap: 12,
              textColor: 'neutral-500',
            }}
          >
            <Typography
              lineHeight="single-line"
              sx={{ textColor: 'neutral-1000' }}
            >
              Audio Thumbnail
            </Typography>
            {Boolean(field.state.value) && (
              <IconButton
                color="secondary"
                size="small"
                onClick={e => {
                  e.stopPropagation()
                  field.handleChange('')
                  updateMasterList()
                }}
              >
                <Delete />
              </IconButton>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 100,
                gap: 16,
              }}
            >
              <AddAudioThumbnailDropZone
                image={field.state.value}
                onUpload={onFileUpload}
                state={dropzoneState}
                setDropzoneState={setDropzoneState}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </form.Field>
  )
}
