import { Box, sx } from '@applift/factor'

import { ImageWrapper } from './ImageWrapper'
import { VideoWrapper } from './VideoWrapper'
import { Creative } from '../../../../../../models/Creative'
import { VIDEO_CREATIVE_ID } from '../../../../../../constants'

export interface CreativePreviewImageProps {
  creative: Creative
}

export const CreativePreviewImage = (props: CreativePreviewImageProps) => {
  const { creative } = props
  const { platformCreativeTypeId } = creative

  return (
    <Box
      style={{ height: 200, objectFit: 'contain' }}
      className={sx({ width: 100, flexShrink: 0 })}
    >
      <Box sx={{ width: 100, height: 100, position: 'relative' }}>
        {platformCreativeTypeId === VIDEO_CREATIVE_ID ? (
          <VideoWrapper creative={creative} />
        ) : (
          <ImageWrapper creative={creative} />
        )}
      </Box>
    </Box>
  )
}
