import { useClipboard, useDrop } from '@applift/drag-drop'
import {
  Box,
  Button,
  Divider,
  enqueueSnackbar,
  FileTrigger,
  Skeleton,
  TextField,
  Typography,
} from '@applift/factor'
import { useStore } from '@tanstack/react-store'
import { SampleFile } from '@applift/app-utils'
import { Add, Delete, Upload } from '@applift/icons'
import clsx from 'clsx'
import * as React from 'react'

import {
  ERROR_MESSAGES,
  MAX_NATIVE_MEDIA_LIMIT,
  NATIVE_VIDEO_DURATION_MAX,
  NATIVE_VIDEO_DURATION_MIN,
  VIDEO_ALLOWED_TYPES,
} from '../../../../../../constants'
import { NativeCreationFormType } from '../../CreateWrapper'
import { getVideoItems, validateXmlUrl } from './utils'

import { getVideoMetaData, isValidURL } from '../../../../../../utils'
import styles from './index.module.scss'

interface Props {
  form: NativeCreationFormType
  onVideoUpload: (files: File[]) => void
  processingFileCount: number
  isCreateClicked: boolean
  updateMasterList: () => void
}

export const VideoDropzone = (props: Props) => {
  const {
    form,
    onVideoUpload,
    processingFileCount,
    isCreateClicked,
    updateMasterList,
  } = props

  const { videoObjects, uniqueId: parentId } = useStore(
    form.store,
    state => state.values
  )

  const ref = React.useRef<HTMLDivElement>(null)

  const [displayTextArea, toggleTextAreaVisibility] = React.useState(false)
  const [videoXmlUrl, setVideoXmlUrl] = React.useState('')

  const addVideoFromURL = async () => {
    if (videoXmlUrl.length > 0) {
      const exceedsLimit = videoObjects.length >= MAX_NATIVE_MEDIA_LIMIT
      if (exceedsLimit) {
        return enqueueSnackbar(
          `Maximum of ${MAX_NATIVE_MEDIA_LIMIT} files can be uploaded`,
          { variant: 'error' }
        )
      }
      const isVASTurl = isValidURL(videoXmlUrl)
      const validResultCreative = await validateXmlUrl(videoXmlUrl)
      const src = validResultCreative?.mediaFiles[0].fileURL
      const metaData = await getVideoMetaData({ URL: src })

      if (validResultCreative) {
        if (
          validResultCreative?.duration >= NATIVE_VIDEO_DURATION_MIN &&
          validResultCreative?.duration <= NATIVE_VIDEO_DURATION_MAX
        ) {
          form.setFieldValue('videoObjects', [
            ...form.state.values.videoObjects,
            {
              src: validResultCreative?.mediaFiles[0].fileURL,
              creativeSource: isVASTurl ? '' : videoXmlUrl,
              creativeSourceURL: isVASTurl ? videoXmlUrl : '',
              name: '',
              parentId: parentId,
              temporaryMediaId: crypto.randomUUID(),
              duration: validResultCreative?.duration ?? 0,
              height: metaData?.height ?? 0,
              width: metaData?.width ?? 0,
            },
          ])
          setVideoXmlUrl('')
          toggleTextAreaVisibility(false)
        } else if (validResultCreative) {
          enqueueSnackbar(ERROR_MESSAGES.INVALID_DURATION, { variant: 'error' })
        }
        updateMasterList()
      } else {
        enqueueSnackbar('Invalid XML/URL', { variant: 'error' })
      }
    }
  }

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      const { videoFiles } = await getVideoItems(e.items)
      if (videoFiles.length > 0) {
        onVideoUpload(videoFiles)
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      const { videoFiles } = await getVideoItems(items)
      if (videoFiles.length > 0) {
        onVideoUpload(videoFiles)
      }
    },
  })
  const hideVideoError =
    Boolean(videoObjects?.length) || Boolean(processingFileCount)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 100 }}>
      <Box
        {...dropProps}
        {...clipboardProps}
        role="button"
        ref={ref}
        tabIndex={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 100,
          height: 100,
          overflow: 'hidden',
          p: 24,
          gap: 24,
          borderRadius: 4,
        }}
        className={clsx(styles.dropZone, isDropTarget && styles.active)}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 8,
            alignItems: 'center',
            flexDirection: 'column',
            flexShrink: 0,
          }}
        >
          <FileTrigger
            allowsMultiple
            acceptedFileTypes={VIDEO_ALLOWED_TYPES}
            onSelect={files => {
              if (files) {
                onVideoUpload(Array.from(files))
              }
            }}
          >
            {({ onClick }) => {
              return (
                <Button
                  startIcon={<Upload />}
                  variant="outlined"
                  onClick={onClick}
                  size="small"
                >
                  Upload File
                </Button>
              )
            }}
          </FileTrigger>

          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            onClick={() => toggleTextAreaVisibility(true)}
          >
            Add VAST/XML URL
          </Button>

          <Typography
            sx={{
              display: 'flex',
              textColor: 'neutral-600',
            }}
            variant="label"
          >
            Drag or Paste files here
          </Typography>
        </Box>
        <Divider orientation="vertical" color="secondary" flexItem />
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 12, width: 100 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              flexWrap: 'wrap',
              width: 100,
            }}
          >
            <Box sx={{ display: displayTextArea ? 'flex' : 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 100,
                }}
              >
                <TextField
                  label="VAST XML/URL"
                  variant="outlinedDash"
                  value={videoXmlUrl}
                  InputProps={{
                    endAdornment: (
                      <Button
                        size="small"
                        variant="text"
                        disabled={Boolean(videoXmlUrl?.length) === false}
                        onClick={addVideoFromURL}
                      >
                        Add
                      </Button>
                    ),
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      addVideoFromURL()
                    }
                  }}
                  fullWidth
                  placeholder="Enter VAST XML/URL"
                  onChange={e => {
                    setVideoXmlUrl(e.target.value)
                  }}
                />
              </Box>
            </Box>
            <SupportedVideoInfo />
          </Box>
        </Box>
      </Box>
      {hideVideoError === false && isCreateClicked ? (
        <Typography
          component="span"
          variant="label"
          lineHeight="single-line"
          sx={{
            marginTop: 4,
            marginX: 12,
            mb: 24,
            textColor: 'danger-500',
          }}
        >
          Creative is required
        </Typography>
      ) : null}
      {hideVideoError && (
        <VideosAndSkeleton
          skeletonCount={processingFileCount}
          form={form}
          updateMasterList={updateMasterList}
        />
      )}
    </Box>
  )
}

const SupportedVideoInfo = () => (
  <>
    <Box>
      <Typography sx={{ mr: 2, textColor: 'neutral-500' }} variant="label">
        Supported Type:{' '}
      </Typography>
      <Typography variant="label">MOV, MP4</Typography>

      <Typography
        variant="label"
        sx={{ textColor: 'neutral-300', mx: 4 }}
        lineHeight="single-line"
      >
        &bull;
      </Typography>
      <Typography variant="label">
        XLS, CSV, XLSX (
        <Typography sx={{ display: 'inline-flex' }}>
          <SampleFile
            sampleFileText="Sample File"
            sampleFileURL={`${window.location.origin}/creatives/example-files/sample_video_native_creatives.xlsx`}
            sampleFileName="sample_video_native_creatives.xlsx"
            buttonProps={{
              sx: { textWeight: 'normal', px: 0, py: 0 },
              startIcon: undefined,
            }}
          />
        </Typography>
        )
      </Typography>
    </Box>
    <Box>
      <Typography sx={{ mr: 2, textColor: 'neutral-500' }} variant="label">
        Duration:
      </Typography>
      <Typography variant="label">Min 15s</Typography>
      <Typography
        variant="label"
        sx={{ textColor: 'neutral-300', mx: 4 }}
        lineHeight="single-line"
      >
        &bull;
      </Typography>
      <Typography variant="label">Max 120s</Typography>
      <Typography
        variant="label"
        sx={{ textColor: 'neutral-300', mx: 4 }}
        lineHeight="single-line"
      >
        &bull;
      </Typography>
      <Typography variant="label">Upto 30s recommended</Typography>
    </Box>
  </>
)

interface VideosAndSkeletonProps {
  skeletonCount: number
  form: NativeCreationFormType
  updateMasterList: () => void
}

const VideosAndSkeleton = (props: VideosAndSkeletonProps) => {
  const { skeletonCount, form, updateMasterList } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        mt: 8,
        gap: 8,
      }}
    >
      <form.Field name="videoObjects" mode="array">
        {field => {
          return (
            <>
              {field.state.value.map((videoItem, i) => {
                return (
                  <form.Field key={i} name={`videoObjects[${i}].src`}>
                    {() => {
                      return (
                        <Box
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            gap: 4,
                            alignItems: 'start',
                          }}
                          style={{
                            width: '80px',
                            height: '60px',
                            cursor: 'pointer',
                          }}
                          className={styles.imageWrapper}
                          key={`${videoItem.temporaryMediaId}_${i}`}
                          onClick={() => {
                            field.removeValue(i)
                            updateMasterList()
                          }}
                        >
                          <video className={styles.video}>
                            <source src={videoItem.src} type="video/mp4" />
                            <track
                              src="captions.vtt"
                              kind="captions"
                              label="English"
                            />
                          </video>
                          <Box
                            sx={{ position: 'absolute' }}
                            className={styles.actionIcon}
                          >
                            <Delete
                              fontSize={14}
                              sx={{
                                fontSize: 16,
                                textColor: 'neutral-0',
                              }}
                            />
                          </Box>
                        </Box>
                      )
                    }}
                  </form.Field>
                )
              })}
            </>
          )
        }}
      </form.Field>

      {new Array(skeletonCount).fill(null).map((_, index) => (
        <Skeleton
          variant="rectangular"
          height={60}
          width={80}
          key={`skeleton_${index}`}
        />
      ))}
    </Box>
  )
}
