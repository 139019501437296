export const getPathNumber = () => {
  const href = window.location.href

  if (href.includes('localhost')) {
    return 0
  }

  try {
    //@ts-ignore
    const num = Number(href?.match(/\/u\/[0-9]+/)[0].substring(3))
    return isNaN(num) ? 0 : num
  } catch (e) {
    return 0
  }
}
