import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@applift/factor'
import { Delete, InfoCircle, Add } from '@applift/icons'
import { useStore } from '@tanstack/react-store'

import { NativeCreationFormType } from '../../CreateWrapper'
import { MAX_TITLE_DESCRIPTION_VARIATION } from '../../../../../../constants'
import {
  NATIVE_DESCRIPTION__CHAR_LIMIT,
  TWITTER_NATIVE_DESCRIPTION_LIMIT,
} from '../../../../../../constants'
import { nativeDescriptionValidationFn } from '../../../../../../utils'

export interface DescriptionProps {
  form: NativeCreationFormType
}

export const Description = (props: DescriptionProps) => {
  const { form } = props

  const { descriptions } = useStore(form.store, state => state.values)

  const showAddDescriptionVariation =
    descriptions.length < MAX_TITLE_DESCRIPTION_VARIATION
  const disableAddDescriptionVariation =
    Boolean(descriptions[descriptions.length - 1]) === false

  return (
    <form.Field name="descriptions" mode="array">
      {descriptionValues => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          {descriptionValues.state.value.map((_, idx) => (
            <form.Field
              name={`descriptions[${idx}]`}
              key={`descriptions[${idx}]`}
              validators={{
                onChange: ({ value }) => nativeDescriptionValidationFn(value),
              }}
            >
              {field => {
                const descriptionValue = field.state.value.trim()
                const showTwitterWarning =
                  descriptionValue.length > TWITTER_NATIVE_DESCRIPTION_LIMIT &&
                  Boolean(field.state.meta.errors.length) == false

                let helperText: React.ReactNode | string = ''

                if (showTwitterWarning) {
                  helperText = (
                    <Typography
                      sx={{
                        textColor: 'orange-300',
                        mt: 2,
                        ml: 4,
                        display: showTwitterWarning ? 'block' : 'none',
                      }}
                    >
                      {`X (Twitter) supports ${TWITTER_NATIVE_DESCRIPTION_LIMIT} characters only`}
                    </Typography>
                  )
                }
                if (field.state.meta.errors[0]) {
                  helperText = field.state.meta.errors[0]
                }

                return (
                  <Box key={`description_${idx}`} sx={{ display: 'flex' }}>
                    <TextField
                      label={<Label fieldIndex={idx + 1} />}
                      variant="outlinedDash"
                      fullWidth
                      placeholder="Enter Description"
                      value={field.state.value}
                      required
                      InputLabelProps={{
                        renderSuffix: (
                          <>
                            {field.state.value.length}/
                            {NATIVE_DESCRIPTION__CHAR_LIMIT}
                          </>
                        ),
                      }}
                      onChange={(e: any) => {
                        const value = e.target.value
                        if (value?.trim()?.length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={helperText}
                    />

                    <IconButton
                      onClick={() => descriptionValues.removeValue(idx)}
                      size="small"
                      color="secondary"
                      sx={{
                        display:
                          descriptionValues.state.value.length > 1
                            ? 'block'
                            : 'none',
                        alignSelf: helperText ? undefined : 'end',
                      }}
                    >
                      <Delete fontSize={16} />
                    </IconButton>
                  </Box>
                )
              }}
            </form.Field>
          ))}
          <Box
            sx={{
              gap: 4,
              display: showAddDescriptionVariation ? 'flex' : 'none',
              alignItems: 'center',
              ml: 12,
            }}
            onClick={() => form.pushFieldValue('descriptions', '')}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              style={{
                opacity: disableAddDescriptionVariation ? 0.4 : 1,
                cursor: 'pointer',
                pointerEvents: disableAddDescriptionVariation ? 'none' : 'auto',
              }}
            >
              <Add sx={{ textColor: 'primary-600', mr: 2 }} fontSize={14} />
              <Typography
                sx={{
                  textColor: 'primary-600',
                  textWeight: 'demi',
                }}
              >
                Add Variation
              </Typography>
            </Box>
            <Typography sx={{ textColor: 'neutral-500' }}>
              ({descriptions.length}/{MAX_TITLE_DESCRIPTION_VARIATION})
            </Typography>
          </Box>
        </Box>
      )}
    </form.Field>
  )
}

interface LabelProps {
  fieldIndex: number
}
const Label = (props: LabelProps) => {
  const { fieldIndex } = props

  return (
    <Tooltip
      title="Recommended character limits: 25, 90, or 140, with X(Twitter) supporting up to 90 characters"
      placement="top"
      arrow
    >
      <Box
        Component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="label">Description {fieldIndex}</Typography>
        <InfoCircle fontSize={14} sx={{ textColor: 'neutral-400' }} />
      </Box>
    </Tooltip>
  )
}
