import { MutateOptions, useMutation } from '@tanstack/react-query'

import { editClickUrl } from '../api'

type useEditClickUrlMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof editClickUrl>>,
  any,
  Parameters<typeof editClickUrl>[0],
  unknown
>

export const useEditClickUrl = (options: useEditClickUrlMutationOptions) => {
  return useMutation(editClickUrl, {
    ...options,
    mutationKey: ['editClickUrl'],
  })
}
