import { AxiosError, AxiosResponse } from 'axios'
import { ModifyGroupRequest, ModifyroupResponse, WithResponse } from '../models'
import { getInstance } from './instance'

export const modifyGroup = async (request: ModifyGroupRequest) => {
  try {
    const response: AxiosResponse<WithResponse<ModifyroupResponse>> =
      await getInstance().post(
        '/v3/crt/creatives/groups/modify-creatives',
        request
      )
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}
