import * as React from 'react'

import { RowSelectionState } from '@applift/datagrid'
import {
  CaretDown,
  Copy,
  Delete,
  Edit,
  PlayCircle,
  PauseCircle,
} from '@applift/icons'
import {
  bindMenu,
  bindTrigger,
  Button,
  enqueueSnackbar,
  Menu,
  MenuItem,
  usePopupState,
} from '@applift/factor'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'

import { Creative } from '../../../../models'
import { useAllCreativeDialogManager } from '../../DialogManager'
import {
  arraysAreEqual,
  getCreativeTypeIdFromRoute,
  isImageCreative,
  isNativeImageCreatve,
} from '../../../../utils'
import {
  useCreativeStatuses,
  useDuplicateCreatives,
  useUpdateCreativeStatus,
} from '../../../../hooks'

interface ActionsProps {
  selectionData: Creative[]
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const Actions = (props: ActionsProps) => {
  const { selectionData, setRowSelection } = props
  const actionPopupState = usePopupState({
    variant: 'popover',
    popupId: 'actionPopup',
  })
  const queryClient = useQueryClient()
  const router = useRouter()

  const { setAction } = useAllCreativeDialogManager()
  const { data: creativeStatusType } = useCreativeStatuses()
  const creativeStatusNameMapping =
    creativeStatusType?.creativeStatusMappingByName

  const isAllImageOrNativeImageCreatives = selectionData.every(
    creative => isImageCreative(creative) || isNativeImageCreatve(creative)
  )

  const isAnyRejectedOrDeletedCreative = selectionData.some(
    creative =>
      creative.creativeStatusId ===
        creativeStatusNameMapping?.['deleted']?.id ||
      creative.creativeStatusId === creativeStatusNameMapping?.['rejected']?.id
  )

  const isAllRunningCreatives = selectionData.every(
    creative =>
      creative.creativeStatusId === creativeStatusNameMapping?.['running']?.id
  )

  const isAllPausedCreatives = selectionData.every(
    creative =>
      creative.creativeStatusId === creativeStatusNameMapping?.['paused']?.id
  )

  const isAllDeletedCreatives = selectionData.some(
    creative =>
      creative.creativeStatusId === creativeStatusNameMapping?.['deleted']?.id
  )

  const doesBothApprovedAndNonApprovedSelected =
    selectionData.some(
      creative => creative.approvalRequiredPostUrlUpdate === false
    ) && selectionData.some(creative => creative.approvalRequiredPostUrlUpdate)

  const isPixelUrlDisabled =
    isAllImageOrNativeImageCreatives === false || isAnyRejectedOrDeletedCreative
  const isClickUrlDisabled =
    isAnyRejectedOrDeletedCreative || doesBothApprovedAndNonApprovedSelected
  const isDuplicateDisabled = isAnyRejectedOrDeletedCreative
  const isDeleteDisabled = isAllDeletedCreatives
  const isPauseActionVisible = isAllRunningCreatives
  const isRunActionVisible = isAllPausedCreatives
  const isAllActionsDisabled = isAllDeletedCreatives

  const onSuccess = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' })
    setRowSelection({})
    actionPopupState.close()
  }
  const onError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
    setRowSelection({})
    actionPopupState.close()
  }

  const duplicateCreativeMutation = useDuplicateCreatives({
    onSuccess,
    onError,
  })

  const updateStatusMutation = useUpdateCreativeStatus({
    onSuccess: response => {
      enqueueSnackbar(response?.message, {
        variant: 'success',
      })
      const creativeTypeIds = getCreativeTypeIdFromRoute(
        router.history.location.href
      )
      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            (query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
              arraysAreEqual(
                query.queryKey?.[0]?.platformCreativeTypeIds,
                creativeTypeIds ?? []
              )) ||
            query.queryKey?.[0]?.scope === 'getCreativeStatusCount'
          )
        },
      })
    },
    onError: error => {
      enqueueSnackbar(
        error?.data?.errorObjects?.[0].error ||
          error.data.detail ||
          'Something went wrong.',
        {
          variant: 'error',
        }
      )
    },
    onSettled: () => {
      setRowSelection({})
    },
  })

  return (
    <>
      {/* @ts-ignore */}
      <Button
        variant="outlined"
        size="small"
        disabled={isAllActionsDisabled}
        endIcon={<CaretDown />}
        {...bindTrigger(actionPopupState)}
        selected={actionPopupState.isOpen}
      >
        Actions
      </Button>
      {/* @ts-ignore */}
      <Menu
        {...bindMenu(actionPopupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            border: 1,
            borderColor: 'primary-600',
            marginTop: 2,
          },
          style: { width: '160px' },
        }}
      >
        <MenuItem
          disabled={isDuplicateDisabled}
          onClick={() => {
            duplicateCreativeMutation.mutate({
              creativeIds: selectionData.map(creative => creative.creativeId),
            })
          }}
          startIcon={<Copy sx={{ textColor: 'neutral-400' }} />}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAction('deleteCreative', selectionData, {
              onSettle: () => {
                setRowSelection({})
              },
            })
            actionPopupState.close()
          }}
          disabled={isDeleteDisabled}
          startIcon={<Delete sx={{ textColor: 'neutral-400' }} />}
        >
          Delete
        </MenuItem>
        <MenuItem
          disabled={isClickUrlDisabled}
          onClick={() => {
            setAction('editClickUrl', selectionData, {
              onSettle: () => {
                setRowSelection({})
              },
            })
            actionPopupState.close()
          }}
          startIcon={<Edit sx={{ textColor: 'neutral-400' }} />}
        >
          Edit Click URL
        </MenuItem>
        <MenuItem
          disabled={isPixelUrlDisabled}
          onClick={() => {
            setAction('editPixelUrl', selectionData, {
              onSettle: () => {
                setRowSelection({})
              },
            })
            actionPopupState.close()
          }}
          startIcon={<Edit sx={{ textColor: 'neutral-400' }} />}
        >
          Edit Pixel URL
        </MenuItem>
        {isPauseActionVisible ? (
          <MenuItem
            onClick={() => {
              updateStatusMutation.mutate({
                creativeIds: selectionData.map(creative => creative.creativeId),
                creativeStatusId: creativeStatusNameMapping?.['paused']
                  ?.id as number,
              })
              actionPopupState.close()
            }}
            startIcon={<PauseCircle sx={{ textColor: 'neutral-400' }} />}
          >
            Pause
          </MenuItem>
        ) : null}
        {isRunActionVisible ? (
          <MenuItem
            onClick={() => {
              updateStatusMutation.mutate({
                creativeIds: selectionData.map(creative => creative.creativeId),
                creativeStatusId: creativeStatusNameMapping?.['running']
                  ?.id as number,
              })
              actionPopupState.close()
            }}
            startIcon={<PlayCircle sx={{ textColor: 'neutral-400' }} />}
          >
            Run
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}
