import {
  StatusFailed,
  StatusProcessing,
  StatusRunning,
  StatusPaused,
  StatusPending,
  StatusReady,
  StatusRejected,
  StatusDeleted,
  StatusDraft,
  StatusExpired,
  StatusError,
  CreativeImage,
  CreativeHTML,
  CreativeAudio,
  CreativeVideo,
  CreativeGoTV,
  CreativeNative,
  DeviceDesktop,
  DeviceMobile,
  DeviceTablet,
  DeviceTV,
  ChannelApp,
  ChannelWeb,
  ChannelCTV,
  ChannelOTT,
  ChannelUnknown,
  StatusApproved,
  AudienceMatched,
  AudienceSegmented,
  AudienceRetargeted,
  AudienceLookalike,
  AudienceContextual,
  AudienceGeo,
  AudienceCampaign,
  AudiencePreBid,
  EventPixel,
  EventPostbackMacro,
  FolderConnect,
  Key,
  LinkHorizontal,
  BrandAndroid,
  BrandApple,
  Globe,
  EllipsisHorizontalCircle,
  InventoryContextual,
  InventoryOpenExchange,
  InventoryPmpDeals,
  LayoutCard,
} from '@applift/icons'
import { SvgIconProps, capitalize } from '@applift/factor'

interface IconType {
  element: any
  props?: Partial<SvgIconProps>
}

const _IconNames = {
  Processing: 'StatusFailed',
  Failed: 'StatusFailed',
  Active: 'StatusActive',
  Running: 'StatusRunning',
  Paused: 'StatusPaused',
  Suspended: 'StatusPaused',
  Pending: 'StatusPending',
  Ready: 'StatusReady',
  Rejected: 'StatusRejected',
  Deleted: 'StatusDeleted',
  Draft: 'StatusDraft',
  Expired: 'StatusExpired',
  Image: 'CreativeImage',
  HTML: 'CreativeHTML',
  Audio: 'CreativeAudio',
  Video: 'CreativeVideo',
  Native: 'CreativeNative',
  GOTV: 'CreativeGoTV',
  Error: 'StatusError',
  Desktop: 'DeviceDesktop',
  Mobile: 'DeviceMobile',
  Tablet: 'DeviceTablet',
  ConnectedTV: 'DeviceTV',
  Web: 'ChannelWeb',
  App: 'ChannelApp',
  OTT: 'ChannelOTT',
  CTV: 'ChannelCTV',
  Unknown: 'ChannelUnknown',
  AudienceMatched: 'AudienceMatched',
  AudienceCampaign: 'AudienceCampaign',
  AudienceContextual: 'AudienceContextual',
  AudienceGeo: 'AudienceGeo',
  AudienceLookalike: 'AudienceLookalike',
  AudiencePreBid: 'AudiencePreBid',
  AudienceSegmented: 'AudienceSegmented',
  AudienceRetargeted: 'AudienceRetargeted',
  EventPixel: 'EventPixel',
  EventPostbackMacro: 'EventPostbackMacro',
  FolderConnect: 'FolderConnect',
  Key: 'Key',
  LinkHorizontal: 'LinkHorizontal',
  BrandAndroid: 'BrandAndroid',
  BrandApple: 'BrandApple',
  Globe: 'Globe',
  Layout: 'LayoutCard',
  EllipsisHorizontalCircle: 'EllipsisHorizontalCircle',
  InventoryOpenExchange: 'InventoryOpenExchange',
  InventoryPmpDeals: 'InventoryPmpDeals',
  InventoryContextual: 'InventoryContextual',
} as const

export type IconNamesTypes = keyof typeof _IconNames

export type IconRegistry = {
  [key in IconNamesTypes]: IconType
}

export const IconNames: IconRegistry = {
  Active: {
    element: StatusApproved,
    props: { sx: { textColor: 'success-400' } },
  },
  Processing: {
    element: StatusProcessing,
    props: { sx: { textColor: 'warning-400' } },
  },
  Failed: {
    element: StatusFailed,
    props: { sx: { textColor: 'danger-400' } },
  },
  Running: {
    element: StatusRunning,
    props: { sx: { textColor: 'success-400' } },
  },
  Paused: {
    element: StatusPaused,
    props: { sx: { textColor: 'warning-400' } },
  },
  Suspended: {
    element: StatusPaused,
    props: { sx: { textColor: 'warning-400' } },
  },
  Pending: {
    element: StatusPending,
    props: { sx: { textColor: 'warning-400' } },
  },
  Ready: {
    element: StatusReady,
    props: { sx: { textColor: 'info-400' } },
  },
  Rejected: {
    element: StatusRejected,
    props: { sx: { textColor: 'danger-400' } },
  },
  Deleted: {
    element: StatusDeleted,
    props: { sx: { textColor: 'danger-400' } },
  },
  Draft: {
    element: StatusDraft,
    props: { sx: { textColor: 'info-400' } },
  },
  Expired: {
    element: StatusExpired,
    props: { sx: { textColor: 'neutral-600' } },
  },
  Image: {
    element: CreativeImage,
    props: { sx: { textColor: 'primary-600' } },
  },
  HTML: {
    element: CreativeHTML,
    props: { sx: { textColor: 'primary-600' } },
  },
  Audio: {
    element: CreativeAudio,
    props: { sx: { textColor: 'primary-600' } },
  },
  Video: {
    element: CreativeVideo,
    props: { sx: { textColor: 'primary-600' } },
  },
  Native: {
    element: CreativeNative,
    props: { sx: { textColor: 'primary-600' } },
  },
  GOTV: {
    element: CreativeGoTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  Desktop: {
    element: DeviceDesktop,
    props: { sx: { textColor: 'primary-600' } },
  },
  Mobile: {
    element: DeviceMobile,
    props: { sx: { textColor: 'primary-600' } },
  },
  Tablet: {
    element: DeviceTablet,
    props: { sx: { textColor: 'primary-600' } },
  },
  ConnectedTV: {
    element: DeviceTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  App: {
    element: ChannelApp,
    props: { sx: { textColor: 'primary-600' } },
  },
  Web: {
    element: ChannelWeb,
    props: { sx: { textColor: 'primary-600' } },
  },
  OTT: {
    element: ChannelOTT,
    props: { sx: { textColor: 'primary-600' } },
  },
  CTV: {
    element: ChannelCTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  Unknown: {
    element: ChannelUnknown,
    props: { sx: { textColor: 'primary-600' } },
  },
  Error: {
    element: StatusError,
    props: { sx: { textColor: 'danger-400' } },
  },
  AudienceMatched: {
    element: AudienceMatched,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudienceSegmented: {
    element: AudienceSegmented,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudienceRetargeted: {
    element: AudienceRetargeted,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudienceLookalike: {
    element: AudienceLookalike,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudienceContextual: {
    element: AudienceContextual,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudienceGeo: {
    element: AudienceGeo,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudienceCampaign: {
    element: AudienceCampaign,
    props: { sx: { textColor: 'primary-600' } },
  },
  AudiencePreBid: {
    element: AudiencePreBid,
    props: { sx: { textColor: 'primary-600' } },
  },
  EventPixel: {
    element: EventPixel,
    props: { sx: { textColor: 'primary-600' } },
  },
  EventPostbackMacro: {
    element: EventPostbackMacro,
    props: { sx: { textColor: 'primary-600' } },
  },
  InventoryOpenExchange: {
    element: InventoryOpenExchange,
    props: { sx: { textColor: 'primary-600' } },
  },
  InventoryPmpDeals: {
    element: InventoryPmpDeals,
    props: { sx: { textColor: 'primary-600' } },
  },
  InventoryContextual: {
    element: InventoryContextual,
    props: { sx: { textColor: 'primary-600' } },
  },
  FolderConnect: {
    element: FolderConnect,
    props: { sx: { textColor: 'neutral-400' } },
  },
  Key: {
    element: Key,
    props: { sx: { textColor: 'neutral-400' } },
  },
  LinkHorizontal: {
    element: LinkHorizontal,
    props: { sx: { textColor: 'neutral-400' } },
  },
  BrandAndroid: {
    element: BrandAndroid,
    props: { sx: { textColor: 'neutral-400' } },
  },
  BrandApple: {
    element: BrandApple,
    props: { sx: { textColor: 'neutral-400' } },
  },
  Globe: {
    element: Globe,
    props: { sx: { textColor: 'neutral-400' } },
  },
  Layout: {
    element: LayoutCard,
    props: { sx: { textColor: 'neutral-400' } },
  },
  EllipsisHorizontalCircle: {
    element: EllipsisHorizontalCircle,
    props: { sx: { textColor: 'neutral-400' } },
  },
}

export const getIcon = (
  iconName: IconNamesTypes,
  props?: Partial<SvgIconProps>
) => {
  const result =
    IconNames[
      iconName
        .trim()
        .replace(/\s\s+/g, '')
        .split(' ')
        .map(capitalize)
        .join('') as IconNamesTypes
    ]
  const Component = (result?.element || StatusError) as (
    props: any
  ) => JSX.Element
  const finalProps = result?.props || {}

  return <Component {...finalProps} {...props} />
}
