export const STANDARD_IMAGE_DIMENSIONS_LANDSCAPE = [
  '300x50',
  '300x250',
  '320x50',
  '320x100',
  '728x90',
  '970x250',
]

export const STANDARD_IMAGE_DIMENSIONS_PORTRAIT = [
  '160x600',
  '300x600',
  '320x480',
]

export const STANDARD_IMAGE_DIMENSIONS_SQUARE = ['250x250']

export const STANDARD_IMAGE_DIMENSIONS = [
  ...STANDARD_IMAGE_DIMENSIONS_LANDSCAPE,
  ...STANDARD_IMAGE_DIMENSIONS_PORTRAIT,
  ...STANDARD_IMAGE_DIMENSIONS_SQUARE,
]
