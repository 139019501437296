import { AUDIO_CREATIVE_ID } from '../../../constants'
import { CreateCreativeRequest } from '../../../api'
import { AudioCreativeGenerationData } from '../../../models'

export function transformAudioStateToApiRequest(
  state: Map<string, AudioCreativeGenerationData>
) {
  const request = {} as CreateCreativeRequest

  for (const [key, value] of state.entries()) {
    if (value.audioCreativeType === 'URL') {
      request[key] = {
        creativeName: value.name?.trim() as string,
        ...(value.thirdPartyID?.trim()
          ? { externalCreativeId: value.thirdPartyID?.trim() }
          : {}),
        platformCreativeTypeId: AUDIO_CREATIVE_ID,
        creativeSourceType: 'URL',
        creativeSource: value.creativeSource as string,
        ...(value.clickURL?.trim()
          ? { clickUrl: value.clickURL?.trim() as string }
          : {}),
        audioDetails: {
          duration: Math.round(value.duration ?? 0),
          customTracking: {
            ...(value.startURL?.trim()
              ? { startUrl: value.startURL?.trim() }
              : {}),
            ...(value.firstQuartileURL?.trim()
              ? { firstQuartileURL: value.firstQuartileURL?.trim() }
              : {}),
            ...(value.midPointURL?.trim()
              ? { midPointURL: value.midPointURL?.trim() }
              : {}),
            ...(value.thirdQuartileURL?.trim()
              ? { thirdQuartileURL: value.thirdQuartileURL?.trim() }
              : {}),
            ...(value.completeURL?.trim()
              ? { completeURL: value.completeURL?.trim() }
              : {}),
            ...(value.impressionURL?.trim()
              ? { impressionURL: value.impressionURL?.trim() }
              : {}),
          },
          logoSource: value?.logoSource?.trim().length
            ? value?.logoSource
            : undefined,
        },
      }
    }
    if (
      ['DAAST_XML', 'DAAST_URL', 'VAST_XML', 'VAST_URL'].includes(
        value.audioCreativeType
      ) &&
      value.audioCreativeType !== 'INVALID'
    ) {
      request[key] = {
        creativeName: value.name?.trim(),
        ...(value.thirdPartyID?.trim()
          ? { externalCreativeId: value.thirdPartyID?.trim() }
          : {}),
        platformCreativeTypeId: AUDIO_CREATIVE_ID,
        creativeSourceType: value.audioCreativeType,
        creativeSource: value.creativeSource,
      }
    }
  }

  return request
}
