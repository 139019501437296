import { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface EditPixelUrlRequest {
  creativeIds?: number[]
  campaignIds?: number[]
  pixelUrl: string | null
}

interface EditPixelUrlResponse extends WithResponse<{ message: string }> {}

export const editPixelUrl = async (params: EditPixelUrlRequest) => {
  try {
    const res: AxiosResponse<EditPixelUrlResponse> = await getInstance().patch(
      '/v3/crt/creatives/pixel-url',
      params
    )

    return res.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
