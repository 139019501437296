import { Box, Col, Divider, Row, Typography } from '@applift/factor'

export const SupportedFiles = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Box>
        <Typography weight="demi" variant="bodySmall" sx={{ mb: 12 }}>
          Image Specifications
        </Typography>
        <Row sx={{ width: 100 }} xs={{ gutterSize: 8, gutterDirection: 'x' }}>
          <Col xs={5}>
            <Typography sx={{ textColor: 'neutral-500' }} variant="label">
              Supported Type:
            </Typography>
          </Col>
          <Col xs={7}>
            <Typography variant="label">JPG, JPEG, PNG, GIF</Typography>
          </Col>
        </Row>
        <Row sx={{ width: 100 }} xs={{ gutterSize: 8, gutterDirection: 'x' }}>
          <Col xs={5}>
            <Typography sx={{ textColor: 'neutral-500' }} variant="label">
              Minimum Size:
            </Typography>
          </Col>
          <Col xs={7}>
            <Typography variant="label">
              200x200, 267x200, 382x200, 1200x628
            </Typography>
          </Col>
        </Row>
        <Row sx={{ width: 100 }} xs={{ gutterSize: 8, gutterDirection: 'x' }}>
          <Col xs={5}>
            <Typography sx={{ textColor: 'neutral-500' }} variant="label">
              Aspect Ratio:
            </Typography>
          </Col>
          <Col xs={7}>
            <Typography variant="label">1:1, 4:3, 1.91:1, 16:9</Typography>
          </Col>
        </Row>
      </Box>
      <Divider sx={{ width: 100 }} color="secondary" />
      <Box>
        <Typography weight="demi" variant="bodySmall" sx={{ mb: 12 }}>
          Video Specifications
        </Typography>
        <Row sx={{ width: 100 }} xs={{ gutterSize: 8 }}>
          <Col xs={5}>
            <Typography sx={{ textColor: 'neutral-500' }} variant="label">
              Supported Type:
            </Typography>
          </Col>
          <Col xs={7}>
            <Typography variant="label">MOV, MP4, XLS, CSV, XLSX</Typography>
          </Col>
        </Row>
        <Row sx={{ width: 100 }} xs={{ gutterSize: 8 }}>
          <Col xs={5}>
            <Typography sx={{ textColor: 'neutral-500' }} variant="label">
              Duration:
            </Typography>
          </Col>
          <Col xs={7}>
            <Typography variant="label">Between 15s to 120s</Typography>
          </Col>
        </Row>
      </Box>
    </Box>
  )
}
