import { MutateOptions, useMutation } from '@tanstack/react-query'

import { editPixelUrl } from '../api'

type useEditPixelUrlMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof editPixelUrl>>,
  any,
  Parameters<typeof editPixelUrl>[0],
  unknown
>

export const useEditPixelUrl = (options: useEditPixelUrlMutationOptions) => {
  return useMutation(editPixelUrl, {
    ...options,
    mutationKey: ['editPixelUrl'],
  })
}
