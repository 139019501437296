import { ColumnDef } from '@applift/datagrid'
import { IOBasicDetailsListType } from '@applift/platform'
import { IoNameCell, IoStatusCell, RawDataCell } from '../../CellTypes'
import { ActionCell } from './ActionCell'

export const colDef: ColumnDef<IOBasicDetailsListType>[] = [
  {
    accessorKey: 'ioId',
    id: 'ioId',
    header: 'ID',
    size: 150,
    maxSize: 200,
    minSize: 100,
    cell: props => {
      return (
        <RawDataCell
          textTypographyProps={{ variant: 'bodySmall' }}
          value={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'ioName',
    id: 'ioName',
    header: 'Insertion Order Name',
    size: 480,
    maxSize: 500,
    minSize: 120,
    sortingFn: (rowA, rowB) => {
      return rowA.original.ioName.toLowerCase() <
        rowB.original.ioName.toLowerCase()
        ? -1
        : 1
    },
    cell: props => {
      return (
        <IoNameCell
          textTypographyProps={{ variant: 'bodySmall' }}
          ioName={props.renderValue() as string}
          ioTypeId={props.row.original?.ioBudgetTypeId}
        />
      )
    },
  },
  {
    accessorKey: 'ioStatusId',
    header: 'Status',
    enableSorting: false,
    meta: {
      description: 'Insertion Order Status',
    },
    cell: props => <IoStatusCell statusId={props.renderValue() as number} />,
    size: 150,
  },
  {
    header: 'Action',
    id: 'action',
    // @ts-ignore
    cell: props => (
      <ActionCell
        onAction={props.onAction}
        row={props.row.original}
        meta={props?.table?.options?.meta}
      />
    ),
    enableSorting: false,
    meta: {
      align: 'center',
    },
    size: 80,
  },
]
