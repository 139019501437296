import { CreateCreativeRequest } from '../../../api'
import { CreateImageCreativeType } from '../../../models'

export function transformImageStateToApiRequest(
  state: Map<string, CreateImageCreativeType>
): CreateCreativeRequest {
  const request = {} as CreateCreativeRequest

  for (const [key, value] of state.entries()) {
    // @ts-ignore
    request[key] = {
      creativeName: value.name?.trim() as string,
      platformCreativeTypeId: 11,
      clickUrl: value.clickURL?.trim() as string,
      creativeSource: value.image,
      creativeSourceType: 'URL',
      ...(value.thirdPartyID?.trim()
        ? { externalCreativeId: value.thirdPartyID?.trim() }
        : {}),
      imageDetails: {
        creativeWidth: value.width as number,
        creativeHeight: value.height as number,
        ...(value.pixelURL ? { pixelUrl: value.pixelURL.trim() } : {}),
      },
    }
  }

  return request
}
