import * as React from 'react'
import { TextField } from '@applift/factor'
import { useStore } from '@tanstack/react-store'
import {
  validateDAASTURL,
  validateDAASTXML,
  validateVASTURL,
  validateVASTXML,
} from '@applift/platform-creatives'

import { AudioCreativeUploadType } from '../../../../../../models'
import { isValidURL, isValidXML } from '../../../../../../utils'
import { AudioCreationFormType } from '../Create'

interface Props {
  form: AudioCreationFormType
  updateMasterList: () => void
}

export const DaastVastInput = (props: Props) => {
  const { form, updateMasterList } = props

  const { creativeSource, audioCreativeType } = useStore(
    form.store,
    state => state.values
  )

  const daastXmlOrUrlValidationFn = (value: string) => {
    if (
      (isValidURL(value) || isValidXML(value)) &&
      audioCreativeType !== 'INVALID'
    ) {
      return undefined
    }
    return 'DAAST XML/URL should be valid'
  }

  React.useEffect(() => {
    const getCreativeSourceType = async (value: string) => {
      const [isValidDaastXML, isValidDaastURL, isValidVASTXML, isValidVASTURL] =
        await Promise.all([
          validateDAASTXML(value),
          validateDAASTURL(value),
          validateVASTXML(value),
          validateVASTURL(value),
        ])

      let type: AudioCreativeUploadType = 'INVALID'
      if (isValidDaastURL.data?.isValid) {
        type = 'DAAST_URL'
      } else if (isValidDaastXML.data?.isValid) {
        type = 'DAAST_XML'
      } else if (isValidVASTXML.data?.isValid) {
        type = 'VAST_XML'
      } else if (isValidVASTURL.data?.isValid) {
        type = 'VAST_URL'
      }
      form.setFieldValue('audioCreativeType', type)
      updateMasterList()
    }
    getCreativeSourceType(creativeSource)
  }, [creativeSource, updateMasterList, form])

  return (
    <form.Field
      name="creativeSource"
      validators={{
        onChange: ({ value }) => {
          if (!value) {
            return 'DAAST XML/URL is required!'
          }
          return undefined
        },
        onChangeAsyncDebounceMs: 500,
        onChangeAsync: async ({ value }) => daastXmlOrUrlValidationFn(value),
      }}
    >
      {field => (
        <TextField
          label="DAAST XML/URL"
          variant="outlinedDash"
          placeholder="Enter DAAST XML/URL"
          value={field.state.value}
          required
          onChange={e => field.handleChange(e.target.value)}
          multiline
          rows={4}
          error={Boolean(field.state.meta.errors.length)}
          helperText={field.state.meta.errors[0] ?? ''}
        />
      )}
    </form.Field>
  )
}
