import React from 'react'

import { InlineEdit, TextField, Typography } from '@applift/factor'
import { ACTION, InlineEditAction } from '../../../CreativeTableActions'

import styles from '../index.module.scss'
import { pixelUrlValidationFn } from '../../../../../utils'

export interface PixelUrlValueTypeProps {
  pixelUrl: string
  creativeId: number
  readonly?: boolean
  setAction: React.Dispatch<React.SetStateAction<InlineEditAction>>
}

export const PixelUrlValueType = (props: PixelUrlValueTypeProps) => {
  const { pixelUrl, creativeId, setAction, readonly } = props

  const [updatedPixelUrl, setUpdatedPixelUrl] = React.useState(pixelUrl || '')

  const handleValueChange = (val: string = '') => {
    if (val.trim().length > 0) {
      setUpdatedPixelUrl(val)
    } else {
      setUpdatedPixelUrl('')
    }
  }

  const handleSubmit = () => {
    setUpdatedPixelUrl(pixelUrl)
    setAction({
      actionName: ACTION.EDIT_PIXEL_URL,
      metaData: {
        id: creativeId,
        params: { pixelUrl: updatedPixelUrl },
      },
    })
  }

  const [focused, setFocused] = React.useState(false)

  return readonly ? (
    <Typography sx={{ ml: 12 }}>{updatedPixelUrl}</Typography>
  ) : (
    <InlineEdit
      value={updatedPixelUrl ? updatedPixelUrl : focused ? '' : '–'}
      onChange={handleValueChange}
      onCancel={() => setUpdatedPixelUrl(pixelUrl)}
      onConfirm={handleSubmit}
      validate={val => {
        return pixelUrlValidationFn(val === '–' ? '' : val)
      }}
    >
      {({ inputProps, inputRef }) => (
        <TextField
          fullWidth
          variant="outlinedDash"
          inputRef={node => {
            inputRef.current = node?.parentElement ?? node
          }}
          className={styles.inlineEditTextField}
          onBlur={() => setFocused(false)}
          {...inputProps}
          onFocus={e => {
            inputProps?.onFocus(e)
            setFocused(true)
          }}
        />
      )}
    </InlineEdit>
  )
}
