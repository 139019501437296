import { AxiosResponse } from 'axios'
import { WithResponse } from '../models'
import { FileMetaDataType, AssetsResponse } from '../models'
import { getInstance } from './instance'
import { ASSET_SEQUENCE_SPLIT_CHARACTER } from '../constants'

export const uploadAssets = async (files: File[]) => {
  const filesMetaData: FileMetaDataType[] = []
  const formData = new FormData()

  for (const file of files) {
    const uniqueName = `${crypto.randomUUID()}${ASSET_SEQUENCE_SPLIT_CHARACTER}${file.name}`
    const metaData: FileMetaDataType = {
      attachedFileName: uniqueName,
      originalFileName: file.name,
    }
    filesMetaData.push(metaData)
    formData.append('files', file, uniqueName)
  }
  formData.append('filesMetadata', JSON.stringify(filesMetaData))

  try {
    const response: AxiosResponse<WithResponse<AssetsResponse>> =
      await getInstance().post('/v3/asset', formData)
    return response.data.data
  } catch (error) {
    throw Promise.reject(error)
  }
}
