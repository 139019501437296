import * as React from 'react'
import { Box, Paper, Typography, PaperProps } from '@applift/factor'

export interface EmptyCreativeCardType extends PaperProps {
  /**
   * Need to pass empty state's illustration
   */
  illustration: React.ReactNode
  /**
   * Title of the empty state
   */
  title?: string
  /**
   * Sub-text of the empty state
   */
  subText?: string
  /**
   * Action of the empty state
   */
  action?: React.ReactNode
}

export const EmptyCreativeCard = (props: EmptyCreativeCardType) => {
  const { illustration, title, subText, action, ...other } = props
  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        p: 24,
        gap: 16,
        alignItems: 'center',
        justifyContent: 'between',
        ...other.sx,
      }}
      {...other}
    >
      <Box sx={{ textColor: 'primary-500', width: 100, px: 32 }}>
        {illustration}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {title && (
          <Typography
            component={'h2'}
            weight="demi"
            variant="bodyLarge"
            sx={{ textAlign: 'center' }}
          >
            {title}
          </Typography>
        )}
        {subText && (
          <Typography
            variant="bodySmall"
            gutterBottom={false}
            weight="normal"
            align="center"
            lineHeight="multi-line"
          >
            {subText}
          </Typography>
        )}
      </Box>
      {action && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {action}
        </Box>
      )}
    </Paper>
  )
}
