import { Typography } from '@applift/factor'
import { Loading } from '@applift/illustrations'
import { AddCreativeDialogEmptySection } from '../../../components/AddCreativeDialogEmptySection'
import { EmptyCard } from '../../../components/EmptyCard'

export const UploadingCreatives = () => {
  return (
    <AddCreativeDialogEmptySection>
      <EmptyCard
        illustration={<Loading sx={{ width: 100, height: 100 }} />}
        isLoading
        subText={
          <Typography
            component="span"
            variant="bodyMedium"
            gutterBottom={false}
          >
            Creative(s) being uploaded, Please wait...
          </Typography>
        }
      />
    </AddCreativeDialogEmptySection>
  )
}
