import {
  Box,
  Col,
  Divider,
  Link,
  Paper,
  Row,
  SvgIcon,
  Typography,
} from '@applift/factor'
import { ExternalLink } from '@applift/icons'

import { NativeCreationFormType } from '../../CreateWrapper'
import { Title } from './Title'
import { Description } from './Description'

interface Props {
  form: NativeCreationFormType
}

export const DisplayInfoCard = (props: Props) => {
  const { form } = props

  return (
    <Paper
      elevation={2}
      sx={{ p: 16, display: 'flex', flexDirection: 'column', gap: 16 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'between' }}>
        <Typography
          sx={{
            textWeight: 'demi',
            textColor: 'neutral-600',
          }}
        >
          Ad Details
        </Typography>
        <Link
          /* @ts-ignore */
          target="_blank"
          href="https://help.iqm.com/en/articles/8706031-how-to-set-up-native-ads"
          color="primary"
        >
          <SvgIcon fontSize={16} sx={{ mr: 8 }}>
            <ExternalLink />
          </SvgIcon>
          Learn about variations
        </Link>
      </Box>
      <Row>
        <Col xs={12}>
          <Title form={form} />
        </Col>
      </Row>
      <Divider orientation="horizontal" color="secondary" />
      <Row>
        <Col xs={12}>
          <Description form={form} />
        </Col>
      </Row>
    </Paper>
  )
}
