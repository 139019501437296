import { Row, Col, Box, Button, enqueueSnackbar } from '@applift/factor'
import { DevicesImage } from '@applift/illustrations'
import { Add, Devices, WebsiteClick, FileUpload } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'
import { HTML_CREATIVE_ID } from '../../constants'
import { useDialogManager } from '../dialogManager'

export const HTMLEmptyTab = () => {
  const { setAction } = useDialogManager()
  const queryClient = useQueryClient()
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={<DevicesImage sx={{ width: 100, height: 100 }} />}
              title="HTML Creative"
              subText="Engage users with dynamic, interactive content that adapts seamlessly across devices."
              action={
                <Button
                  startIcon={<Add />}
                  variant="outlined"
                  onClick={() =>
                    setAction('html', undefined, {
                      onSuccess: (
                        response: CreateCreativeResponse,
                        request: CreateCreativeRequest
                      ) => {
                        const createdCreativesId = Object.values(
                          response.successData
                        )
                        const failedCreatives = Object.keys(response.failedData)
                        if (createdCreativesId.length) {
                          enqueueSnackbar({
                            message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                            variant: 'success',
                          })
                        }
                        if (failedCreatives.length) {
                          enqueueSnackbar({
                            message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                            variant: 'error',
                          })
                        }

                        queryClient.resetQueries({
                          predicate: (query: any) =>
                            query.queryKey?.[0]?.scope ===
                              'getAllCreativeList' &&
                            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                              HTML_CREATIVE_ID
                            ),
                        })

                        const succeededCreative = Object.keys(
                          response.successData ?? {}
                        ).map(assetId => {
                          return {
                            creativeId: Number(
                              response.successData[`${assetId}`]
                            ),
                            platformCreativeTypeId: HTML_CREATIVE_ID,
                            creativeName: request[`${assetId}`]?.creativeName,
                          }
                        })
                        if (succeededCreative.length) {
                          setAction('group', succeededCreative)
                        }
                      },
                    })
                  }
                >
                  Add HTML Creative
                </Button>
              }
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<Devices />}
                title="Adaptive Experience"
                subText="HTML creatives rely on an iFrame, allowing you to create a consistent ad experience regardless of the consumer’s device."
              />
              <EmptyFeatureCard
                icon={<WebsiteClick />}
                title="Drive Engagement"
                subText="Introduce interactive multimedia elements to encourage users to interact with your ad in more ways and learn about the product or service."
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple HTML codes at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
