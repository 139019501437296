import { useQuery } from '@tanstack/react-query'
import { GetCreativeTypeCountRequest } from '../models'
import { getCreativeTypeCount } from '../api/creative'

export const useCreativeTypeCount = (
  params?: GetCreativeTypeCountRequest,
  options?: {
    enabled?: boolean
  }
) => {
  const queryData = useQuery({
    queryKey: [{ scope: 'getCreativeTypeCount', ...params }],
    queryFn: getCreativeTypeCount,
    enabled: options?.enabled ?? true,
  })
  return queryData
}
