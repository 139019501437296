import {
  Alert,
  Box,
  Col,
  IconButton,
  Link,
  Paper,
  Row,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  sx,
} from '@applift/factor'
import { Close, Image, InfoCircle, PlayCircle } from '@applift/icons'
import * as React from 'react'
import { useStore } from '@tanstack/react-store'
import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
  MAX_NATIVE_VARIATION_LIMIT,
} from '../../../../../../constants'
import { MediaQueue } from '../../../../../../hooks/useMediaQueue'
import {
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
} from '../../../../../../utils'
import { NativeCreationFormType } from '../../CreateWrapper'
import { Dropzone } from '../../Upload/Dropzone'

interface Props {
  form: NativeCreationFormType
  imageQueue: MediaQueue
  videoThumbmailQueue: MediaQueue
  videoQueue: MediaQueue
  updateMasterList: () => void
  isCreateClicked: boolean
}

export const CreativeInfoCard = (props: Props) => {
  const {
    form,
    imageQueue,
    updateMasterList,
    videoThumbmailQueue,
    videoQueue,
    isCreateClicked,
  } = props

  const { imageObjects, videoObjects, nativeType } = useStore(
    form.store,
    state => state.values
  )

  const [displayWarning, toggleWarningVisibility] = React.useState(false)

  const addedCreativeCount =
    nativeType === 'image' ? imageObjects?.length : videoObjects?.length

  const switchMediaType = (value: 'image' | 'video') => {
    if (value) {
      form.store.setState(prev => ({
        ...prev,
        values: {
          ...form.state.values,
          imageObjects: [],
          videoObjects: [],
          pixelURL: '',
          nativeType: value,
          videoThumbnail: null,
          customTracking: {
            impressionTrackingUrl: '',
            thirdQuartileTrackingUrl: '',
            midpointTrackingTrackingUrl: '',
            completeTrackingUrl: '',
            firstQuartileTrackingUrl: '',
            startTrackingUrl: '',
          },
        },
      }))
      updateMasterList()
    }
  }

  return (
    <Paper
      elevation={2}
      sx={{ p: 16, display: 'flex', flexDirection: 'column', gap: 16 }}
    >
      <Typography sx={{ textWeight: 'demi', textColor: 'neutral-600' }}>
        Creative Info
      </Typography>
      <Row>
        <Col xs={6}>
          <form.Field
            name="creativeName"
            validators={{
              onChange: ({ value }) => creativeNameValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label="Creative Name"
                variant="outlinedDash"
                fullWidth
                placeholder="Enter Creative Name"
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                    </>
                  ),
                }}
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
        </Col>
        <Col xs={6}>
          <form.Field
            name="thirdPartyId"
            validators={{
              onChange: ({ value }) => creativeThirdPartyIDValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label={
                  <Tooltip
                    title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                    placement="top"
                    arrow
                  >
                    <Box
                      Component={'span'}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                      style={{ pointerEvents: 'all' }}
                    >
                      <Typography variant="label">3rd Party ID</Typography>
                      <InfoCircle
                        fontSize={14}
                        sx={{ textColor: 'neutral-400' }}
                      />
                    </Box>
                  </Tooltip>
                }
                variant="outlinedDash"
                fullWidth
                placeholder="Enter 3rd Party ID"
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value.length}/{CREATIVE_THIRD_PARTY_ID_LIMIT}
                    </>
                  ),
                }}
                value={field.state.value}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="label"
              sx={{ textColor: 'neutral-500', ml: 12 }}
            >
              Creative
              {addedCreativeCount > 0 &&
                ` (${addedCreativeCount}/${MAX_NATIVE_VARIATION_LIMIT})`}
            </Typography>
            <Typography sx={{ textColor: 'danger-400', fontSize: 12, mx: 2 }}>
              *
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 12,
            }}
          >
            <form.Field name="nativeType">
              {field => (
                <ToggleButtonGroup
                  style={{
                    pointerEvents:
                      [...(videoObjects ?? []), ...(imageObjects ?? [])]
                        .length > 0
                        ? 'none'
                        : 'auto',
                  }}
                  color="primary"
                  value={field.state.value}
                  exclusive
                  orientation="horizontal"
                  size="medium"
                  variant="outlined"
                  onChange={(_, val) => switchMediaType(val)}
                >
                  <ToggleButton
                    value="image"
                    disabled={videoObjects?.length > 0}
                  >
                    <Image sx={{ textColor: 'neutral-500', mr: 4 }} />
                    <Typography
                      {...{
                        sx:
                          field.state.value === 'image'
                            ? { textWeight: 'demi', textColor: 'neutral-600' }
                            : {
                                textColor: 'neutral-600',
                                textWeight: 'normal',
                              },
                      }}
                    >
                      Image
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value="video"
                    disabled={imageObjects?.length > 0}
                  >
                    <PlayCircle sx={{ textColor: 'neutral-500', mr: 4 }} />
                    <Typography
                      {...{
                        sx:
                          field.state.value === 'video'
                            ? { textWeight: 'demi', textColor: 'neutral-600' }
                            : {
                                textColor: 'neutral-600',
                                textWeight: 'normal',
                              },
                      }}
                    >
                      Video
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </form.Field>
            {addedCreativeCount > 0 && (
              <Link
                Component="button"
                color="primary"
                underline="none"
                sx={{
                  textWeight: 'demi',
                  display: addedCreativeCount > 0 ? 'block' : 'none',
                }}
                onClick={e => {
                  e.preventDefault()
                  toggleWarningVisibility(true)
                }}
              >
                Change
              </Link>
            )}
          </Box>
          {displayWarning && (
            <Alert
              severity="warning"
              sx={{ mt: 8, display: 'flex' }}
              classes={{
                root: sx({ alignItems: 'center' }),
                message: sx({ width: 100, pt: 0 }),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'between',
                  alignItems: 'center',
                  width: 100,
                }}
              >
                <Typography>
                  Are you sure you want to switch to{' '}
                  {nativeType === 'image' ? 'Video' : 'Image'}? All your{' '}
                  {nativeType} files will be discarded.
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    Component="button"
                    color="primary"
                    underline="none"
                    onClick={e => {
                      e.preventDefault()
                      switchMediaType(
                        form.getFieldValue('nativeType') === 'image'
                          ? 'video'
                          : 'image'
                      )
                      toggleWarningVisibility(false)
                    }}
                    sx={{ textWeight: 'demi', fontSize: 12 }}
                  >
                    Confirm
                  </Link>
                  <IconButton
                    color="secondary"
                    onClick={() => toggleWarningVisibility(false)}
                    sx={{ py: 0 }}
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
            </Alert>
          )}
        </Col>
        <Row>
          <Col xs={12} sx={{ my: 8 }}>
            <Box
              style={
                displayWarning
                  ? { opacity: 0.4, pointerEvents: 'none' }
                  : undefined
              }
            >
              <Dropzone
                form={form}
                imageQueue={imageQueue}
                videoThumbmailQueue={videoThumbmailQueue}
                updateMasterList={updateMasterList}
                videoQueue={videoQueue}
                isCreateClicked={isCreateClicked}
              />
            </Box>
          </Col>
        </Row>
      </Row>
    </Paper>
  )
}
