import { Box } from '@applift/factor'
import { VirtualItem as VirtualItemType } from '@tanstack/react-virtual'

export interface VirtualItemProps extends VirtualItemType {
  children: React.ReactNode
  totalLanes: number
}

export const VirtualItem = (props: VirtualItemProps) => {
  const { index, start, lane, totalLanes, size, children } = props

  return (
    <Box
      key={index}
      sx={{ position: 'absolute', px: 12 }}
      style={{
        top: start,
        left: `${lane * (100 / totalLanes)}%`,
        height: size,
        width: `${100 / totalLanes}%`,
      }}
    >
      {children}
    </Box>
  )
}
