import * as React from 'react'
import clsx from 'clsx'
import {
  Box,
  Divider,
  Typography,
  Button,
  FileTrigger,
  useSnackbar,
} from '@applift/factor'
import { Upload } from '@applift/icons'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'
import { useDrop, useClipboard } from '@applift/drag-drop'

import { getValidImageDropItems, getValidImagePickerItems } from './utils'
import { DropZoneStates } from '../../../../../models'

import styles from './index.module.scss'

export interface DropZoneProps {
  onAdd: (file: File[]) => void
  setDropZoneState: React.Dispatch<React.SetStateAction<DropZoneStates>>
}

export const DropZone = (props: DropZoneProps) => {
  const { onAdd, setDropZoneState } = props
  const ref = React.useRef(null)
  const { closeSnackbar } = useSnackbar()

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      const validfiles = await getValidImageDropItems(e.items, closeSnackbar)
      if (validfiles.length > 0) {
        onAdd(validfiles)
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      const validfiles = await getValidImageDropItems(items, closeSnackbar)
      if (validfiles.length > 0) {
        onAdd(validfiles)
      }
    },
  })

  return (
    <Box
      {...dropProps}
      {...clipboardProps}
      role="button"
      tabIndex={0}
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 24,
        px: 16,
        gap: 24,
        borderRadius: 4,
      }}
      className={clsx(styles.dropZone, isDropTarget && styles.active)}
    >
      <FileTrigger
        allowsMultiple
        acceptedFileTypes={['image/jpeg', 'image/png', 'image/gif']}
        onSelect={async items => {
          if (items) {
            const validfiles = await getValidImagePickerItems(
              Array.from(items),
              closeSnackbar
            )
            if (validfiles.length > 0) {
              onAdd(validfiles)
            }
          }
        }}
      >
        {({ onClick }) => {
          return (
            <Button variant="outlined" startIcon={<Upload />} onClick={onClick}>
              Upload Image
            </Button>
          )
        }}
      </FileTrigger>
      <IntegrationUpload onAdd={onAdd} setDropZoneState={setDropZoneState} />
      <Divider sx={{ width: 100 }} color="secondary" />
      <Typography sx={{ textColor: 'neutral-600' }} lineHeight="single-line">
        or Drag & Drop
      </Typography>
    </Box>
  )
}

const IntegrationUpload = ({
  onAdd,
  setDropZoneState,
}: {
  onAdd: (file: File[]) => void
  setDropZoneState: React.Dispatch<React.SetStateAction<DropZoneStates>>
}) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 4,
      }}
    >
      <Typography sx={{ textColor: 'neutral-500' }} lineHeight="single-line">
        import from
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexWrap: 'wrap',
          gap: 8,
        }}
      >
        <GoogleFilePicker
          acceptedFileTypes="png,jpg,jpeg,gif"
          onSelect={async files => {
            const vaidFiles = await getValidImagePickerItems(
              files,
              closeSnackbar
            )
            onAdd(vaidFiles)
          }}
          onFilesDownloadingStart={() => {
            setDropZoneState('loading')
          }}
        />
        <DropBoxFilePicker
          extensions={['.png', '.jpg', '.jpeg', '.gif']}
          onSelect={async files => {
            const vaidFiles = await getValidImagePickerItems(
              files,
              closeSnackbar
            )
            onAdd(vaidFiles)
          }}
          onFilesDownloadingStart={() => {
            setDropZoneState('loading')
          }}
        />
      </Box>
    </Box>
  )
}
