import * as React from 'react'
import { Box, Paper, Row, Col } from '@applift/factor'
import { FileVideoCard } from './FileVideoCard'
import { VastVideoCard } from './VastVideoCard'
import { VideoCreativeGenerationData } from '../../../../../models'
import { ActionPanel } from './ActionPanel'

export interface CreateProps {
  data: Map<string, VideoCreativeGenerationData>
  onChange?: (creative: VideoCreativeGenerationData[]) => void
  onRemove?: (id: string) => void
  onError?: (hasError: boolean) => void
}

export const Create = (props: CreateProps) => {
  const { data, onChange, onRemove, onError } = props
  const [actionPanelError, setActionPanelError] = React.useState<boolean>(false)

  const hasErrorInGrid = React.useMemo(() => {
    return Array.from(data.values()).some(creative => creative.hasError)
  }, [data])

  const creativeList = React.useMemo(() => Array.from(data.values()), [data])

  React.useEffect(() => {
    onError?.(actionPanelError || hasErrorInGrid)
  }, [actionPanelError, hasErrorInGrid, onError])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          width: 100,
          overflow: 'hidden',
          alignItems: 'center',
          px: 24,
          py: 12,
          gap: 16,
        }}
      >
        <ActionPanel
          data={data}
          setError={setActionPanelError}
          onChange={onChange}
        />
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          overflow: 'auto',
          p: 24,
        }}
      >
        <Row
          xs={{ gutterSize: 16, col: 1 }}
          md={{ gutterSize: 24, col: 2 }}
          lg={3}
        >
          {creativeList.map(creative => {
            if (creative.videoCreativeType === 'file') {
              return (
                <Col key={creative.id}>
                  <FileVideoCard
                    id={creative.id}
                    name={creative?.name}
                    videoUrl={creative.videoUrl}
                    thirdPartyID={creative?.thirdPartyID}
                    clickURL={creative?.clickURL}
                    // TDOD: warning is currenlty removed
                    // warning={creative?.warning}
                    impressionURL={creative?.impressionURL}
                    startURL={creative?.startURL}
                    firstQuartileURL={creative?.firstQuartileURL}
                    midPointURL={creative?.midPointURL}
                    thirdQuartileURL={creative?.thirdQuartileURL}
                    completeURL={creative?.completeURL}
                    onChange={onChange}
                    onRemove={onRemove}
                  />
                </Col>
              )
            }
            return (
              <Col key={creative.id}>
                <VastVideoCard
                  id={creative.id}
                  name={creative?.name}
                  thirdPartyID={creative?.thirdPartyID}
                  // TDOD: warning is currenlty removed
                  // warning={creative?.warning}
                  vastXml={creative?.vastXml}
                  onChange={onChange}
                  onRemove={onRemove}
                />
              </Col>
            )
          })}
        </Row>
      </Box>
    </Box>
  )
}
