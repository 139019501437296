import { Box, Tooltip, Typography } from '@applift/factor'
import { InfoCircle } from '@applift/icons'

const SpecificationsTooltip = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography lineHeight="single-line" weight="demi">
          Third-Party Ad-Tags
        </Typography>
        <Typography lineHeight="single-line">
          We support HTML, MRaid & 3rd party tags like DCM.
        </Typography>
      </Box>
      <Typography sx={{ textColor: 'neutral-600' }}>
        Note: if you are not using our ad server, then [IAA_TRACKING_CLICK_URL]
        macro is required for HTML tags
      </Typography>
    </Box>
  )
}

export const SpecificationsNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Tooltip arrow title={<SpecificationsTooltip />}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textColor: 'neutral-600',
            gap: 4,
          }}
          lineHeight="single-line"
        >
          <Box
            Component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            <InfoCircle sx={{ textColor: 'neutral-400' }} />
          </Box>
          Third-Party-Ad-Tags
        </Typography>
      </Tooltip>
    </Box>
  )
}
