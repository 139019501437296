import * as React from 'react'
import clsx from 'clsx'
import { Box } from '@applift/factor'

import styles from './index.module.scss'

export const ImageCell = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { className, src, alt, ...other } = props
  return (
    <Box
      sx={{
        p: 8,
        mx: 'auto',
        height: 100,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* eslint-disable-next-line */}
      <img
        src={src}
        alt={alt}
        className={clsx(styles.previewImage, className)}
        {...other}
      />
    </Box>
  )
}
