import * as React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LoadingButton,
  DialogActions,
  DialogProps,
  enqueueSnackbar,
} from '@applift/factor'
import { CreativeImage } from '@applift/icons'

import { CreateImageCreativeType } from '../../../models'
import { generateCreativeMapData } from '../helper'
import { CreateWrapper } from './CreateWrapper'
import { UploadingCreatives } from '../../common'
import { noop } from '../../../utils'
import { transformImageStateToApiRequest } from './helper'
import { useCreateCreative } from '../../../hooks'
import { useQueryClient } from '@tanstack/react-query'
import { scopeTypes } from '../../../api'

interface AddImageCreativeDialogProps extends DialogProps {
  onSuccess?: (response?: any, request?: any) => void
  onError?: (error?: any, request?: any) => void
  onSettle?: () => void
  actionText?: string
  dialogTitle?: string
}

export const AddImageCreativeDialog = (props: AddImageCreativeDialogProps) => {
  const {
    open,
    onClose,
    onSuccess,
    onError,
    onSettle,
    actionText = 'Add Creative',
    dialogTitle = 'Image Creative',
    ...others
  } = props
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [creativeState, setCreativeState] = React.useState(
    new Map<string, CreateImageCreativeType>(generateCreativeMapData([]))
  )

  const updateCreativesBulk = React.useCallback(
    (creatives: CreateImageCreativeType[]) => {
      setCreativeState(prevMap => {
        const newMap = new Map(prevMap)
        creatives?.forEach(one => {
          newMap.set(one.id, one)
        })
        return newMap
      })
    },
    [setCreativeState]
  )

  const removeCreative = React.useCallback(
    (id: string) => {
      setCreativeState(prevMap => {
        const newMap = new Map(prevMap)
        newMap.delete(id)
        return newMap
      })
    },
    [setCreativeState]
  )

  const queryClient = useQueryClient()

  const createImageCreative = useCreateCreative({
    onSuccess: (response, request) => {
      onClose?.({}, 'escapeKeyDown')
      onSuccess?.(response, request)
      queryClient.refetchQueries({
        predicate: query =>
          (query.queryKey?.[0] as { scope?: scopeTypes })?.scope ===
          'getCreativeTypeCount',
      })
    },
    onError: (error, request) => {
      onClose?.({}, 'escapeKeyDown')
      enqueueSnackbar({
        // @ts-ignore
        message: error?.errorObjects?.[0]?.error || 'Something went wrong',
        variant: 'error',
      })
      onError?.(error, request)
    },
    onSettled: onSettle,
  })

  const loading: boolean = createImageCreative.isLoading
  const isAddCreativeCTADisable = React.useMemo(() => {
    return hasError || creativeState.size === 0 || loading
  }, [hasError, creativeState, loading])

  return (
    <>
      <Dialog
        fullScreen="bleed"
        open={open}
        onClose={loading ? noop : onClose}
        {...others}
      >
        <DialogTitle
          onClose={loading ? noop : e => onClose?.(e, 'escapeKeyDown')}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Box
              sx={{
                display: 'flex',
                fontSize: 32,
                textColor: 'primary-600',
              }}
            >
              <CreativeImage />
            </Box>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ py: 0, pr: 0 }}>
          {createImageCreative.isLoading && <UploadingCreatives />}
          {createImageCreative.isLoading === false && (
            <CreateWrapper
              data={creativeState}
              onChange={updateCreativesBulk}
              onRemove={removeCreative}
              onError={setHasError}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            color="secondary"
            onClick={e => onClose?.(e, 'escapeKeyDown')}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={isAddCreativeCTADisable}
            loading={createImageCreative.isLoading}
            onClick={() => {
              const param = transformImageStateToApiRequest(creativeState)
              createImageCreative.mutate(param)
            }}
          >
            {actionText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
