import { Row, Col, Box } from '@applift/factor'
import { WebsiteResponsive } from '@applift/illustrations'
import { Id, Group, FileUpload } from '@applift/icons'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'

export const GroupedEmptyTab = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={
                <WebsiteResponsive sx={{ width: 100, height: 100 }} />
              }
              title="Grouped Native Creatives"
              subText="Save time on asset management by grouping multiple variations of the same creative format. Apply these groups across campaigns, and test and optimize your creatives’ performance."
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<Id />}
                title="Define Text Variations"
                subText="Add up to five combinations of titles and descriptions to a single creative."
              />
              <EmptyFeatureCard
                icon={<Group />}
                title="Apply Visual Variation"
                subText="Generate multiple combinations of the titles, descriptions, images, and videos that you add to create variants of the same ad.  "
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple files at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
