import { WithResponse } from '@applift/platform'
import { AxiosError, AxiosResponse } from 'axios'

import { CTAresponseType } from '../models'
import { getInstance } from './instance'
import { QueryFunctionContext } from '@tanstack/react-query'
import { getNativeCTAOptionsKey } from './QueryKeys'

export const getNativeCTAOptions = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getNativeCTAOptionsKey)['keys']>
>) => {
  const { showOnlyDefault } = queryKey[0] || {}
  try {
    const response: AxiosResponse<WithResponse<CTAresponseType>> =
      await getInstance().get('/v3/crt/master/cta/list', {
        params: {
          showOnlyDefault: showOnlyDefault,
        },
      })

    return response?.data?.data?.ctaDetailsList.map(item => ({
      label: item.ctaText,
      value: item.id.toString(),
      isDefault: item.defaultCta,
    }))
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}
