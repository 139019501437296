import { DropItem } from '@applift/drag-drop'
import { read, utils } from 'xlsx'

import { enqueueSnackbar } from '@applift/factor'
import { validateVASTURL, validateVASTXML } from '@applift/platform-creatives'
import {
  errorFileToast,
  formatFileSize,
  getVideoMetaData,
  isValidURL,
  readFileAsArrayBuffer,
} from '../../../.../../../../../utils'
import {
  ALLOWED_EXCEL_FORMATS,
  ERROR_MESSAGES,
  kILO_BYTE,
  MEGA_BYTE,
  NATIVE_VIDEO_DURATION_MAX,
  NATIVE_VIDEO_DURATION_MIN,
  VIDEO_FILE_TYPE,
} from '../../../../../../constants'
import { ErrorCSVType } from '../../../../../../models'

export const removeFileExtension = (fileName: string) => {
  const fileExtensionRegex = new RegExp(/\.[^/.]+$/)
  return fileName.replace(fileExtensionRegex, '')
}

type TVASTCreativeRow = {
  Name: string
  VAST: string
  Placement: string
  tempCreativeId: number
  [key: string]: any
}

const allowedMimeTypesSize = {
  'image/png': MEGA_BYTE * 2,
  'image/jpeg': MEGA_BYTE * 2,
  'image/jpg': MEGA_BYTE * 2,
  'image/gif': kILO_BYTE * 750,
} as const

const verifySize = (file: File) => {
  const allowedSize =
    allowedMimeTypesSize[file.type as keyof typeof allowedMimeTypesSize]

  return allowedSize && file.size <= allowedSize ? true : false
}

const allowedMimeTypes = Object.keys(allowedMimeTypesSize)

export const getValidImagePickerItems = (
  files: File[],
  closeSnackbar: (key?: any | undefined) => void
) => {
  const errorType: ErrorCSVType[] = []
  const validFiles: File[] = []
  for (const file of files) {
    if (verifySize(file)) {
      validFiles.push(file)
    }
    errorType.push({
      'File Name': file.name,
      'File Size': formatFileSize(file.size),
      'Reason for Rejection':
        allowedMimeTypes.indexOf(file.type) >= 0
          ? ERROR_MESSAGES.INVALID_ASPECT_RATIO
          : ERROR_MESSAGES.UNSUPPORTED,
    })
  }

  if (errorType.length > 0) {
    errorFileToast(errorType, closeSnackbar, 'native_error_file.csv')
  }

  return validFiles
}

export const getValidNativeImageItems = async (items: DropItem[]) => {
  const resultFiles: Promise<File>[] = []

  for (const item of items) {
    if (item.kind === 'file') {
      resultFiles.push(item.getFile())
    }
  }
  const finalResult = await Promise.all(resultFiles)

  const validFiles: File[] = []

  for (const file of finalResult) {
    validFiles.push(file)
  }
  return validFiles
}
export const getVideoItems = async (items: DropItem[]) => {
  const resultFiles: Promise<File>[] = []

  for (const item of items) {
    if (item.kind === 'file') {
      resultFiles.push(item.getFile())
    }
  }
  const finalResult = await Promise.all(resultFiles)
  const videoFiles: File[] = []
  for (const file of finalResult) {
    videoFiles.push(file)
  }
  return { videoFiles }
}

export const getValidURL = (value: string) => {
  let url = ''
  try {
    const urlObj = new URL(value)
    url = urlObj.href
  } catch (err) {
    console.error(err)
  }
  return url
}

export const validateImageDimensions = async (
  imageUrl: string,
  allowedDimensions: { height: number; width: number; aspectRatio: number }[]
): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      if (validateAspectRatio(allowedDimensions, image)) {
        return resolve(image)
      }
      return reject(image)
    }
    image.onerror = () => {
      return reject(image)
    }
    image.src = getValidURL(imageUrl)
  })
}

export const validateAspectRatio = (
  allowedDimensions: { height: number; width: number; aspectRatio: number }[],
  image: HTMLImageElement | { naturalWidth: number; naturalHeight: number }
) => {
  const filteredDimension = allowedDimensions.filter(dimension => {
    return (
      Number((image.naturalWidth / image.naturalHeight).toFixed(2)) ===
      Number(dimension.aspectRatio.toFixed(2))
    )
  })[0]
  if (filteredDimension) {
    return (
      image.naturalHeight >= filteredDimension.height &&
      image.naturalWidth >= filteredDimension.width
    )
  }
  return false
}

export const validateImageFileDimensions = async (
  files: File[],
  validDimensions: {
    width: number
    height: number
    aspectRatio: number
  }[],
  errorLogs?: ErrorCSVType[]
) => {
  const validFiles: { file: File; width: number; height: number }[] = []
  const promises: Promise<void>[] = []
  for (let i = 0; i < files.length; i++) {
    const file = files[i] as File
    if (allowedMimeTypes.indexOf(file.type) < 0) {
      errorLogs?.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
      })
    } else if (verifySize(file) === false) {
      errorLogs?.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection': ERROR_MESSAGES.SIZE_EXCEED,
      })
    } else {
      const reader = new FileReader()

      const promise = new Promise<void>(resolve => {
        reader.onload = e => {
          const img = new Image()

          img.onload = () => {
            let isImageValid = false
            const matchingAspectRatio = validDimensions.find(
              dimension =>
                Number((img.naturalWidth / img.naturalHeight).toFixed(2)) ===
                Number(dimension.aspectRatio.toFixed(2))
            )
            if (
              matchingAspectRatio &&
              img.naturalHeight >= matchingAspectRatio.height &&
              img.naturalWidth >= matchingAspectRatio.width
            ) {
              isImageValid = true
            }

            if (isImageValid) {
              validFiles.push({
                file: file,
                width: img.width,
                height: img.height,
              })
            } else {
              errorLogs?.push({
                'File Name': file.name,
                'File Size': formatFileSize(file.size),
                'Reason for Rejection': ERROR_MESSAGES.INVALID_ASPECT_RATIO,
              })
            }
            resolve() // Resolve the promise after image load handling
          }

          img.onerror = () => {
            errorLogs?.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
            })
            resolve() // Resolve the promise in case of image load error
          }

          img.src = e.target?.result as string
        }
      })

      reader.readAsDataURL(file)
      promises.push(promise)
    }
  }

  await Promise.all(promises) // Wait for all image loading promises to resolve
  return validFiles
}

export const getVideoDuration = async (param: {
  file?: File
  URL?: string
}) => {
  let videoMetadata: { duration: number } | null = null
  const video = document.createElement('video')

  video.src = param.file
    ? URL.createObjectURL(param.file)
    : (param.URL as string)

  const loadedMetadata = new Promise<{ duration: number }>(
    (resolve, reject) => {
      video.onloadedmetadata = () => resolve({ duration: video.duration })
      video.onerror = e => reject(e)
    }
  )

  video.load()

  try {
    videoMetadata = await loadedMetadata
  } catch (error) {
    enqueueSnackbar('Error while loading video metadata', {
      variant: 'error',
    })
  }

  return videoMetadata
}

export const validateXmlUrl = async (xmlUrl: string) => {
  let validateResult = null
  let validResultCreative = null

  const isVASTurl = isValidURL(xmlUrl)
  if (isVASTurl) {
    validateResult = await validateVASTURL(xmlUrl)
  } else {
    validateResult = await validateVASTXML(xmlUrl)
  }
  if (validateResult.data?.isValid === false) {
    return null
  } else if (validateResult) {
    const creatives = validateResult?.data?.result?.ads.map(
      item => item.creatives
    )[0]

    //todo: geting only linear creative replace when available from lib
    const lineraCreatives = creatives?.filter(item => {
      return item.type === 'linear'
    })

    validResultCreative = lineraCreatives?.[0]
  }
  return validResultCreative
}
const MB400 = MEGA_BYTE * 400

export const validateVideoFileUpload = async (files: File[]) => {
  const validFiles: { file: File; duration: number }[] = []
  const validUrls: {
    videoURL: string
    xmlURL: string
    duration: number
    height: number
    width: number
  }[] = []
  const validXmls: {
    videoURL: string
    xmlCode: string
    duration: number
    height: number
    width: number
  }[] = []
  const errorLogs: ErrorCSVType[] = []

  await Promise.all(
    Array.from(files).map(async file => {
      if (VIDEO_FILE_TYPE?.includes(file.type)) {
        const isCorrectSize = file.size > MB400 ? false : true
        const durationObject = await getVideoDuration({ file })

        const isValiduration = durationObject?.duration
          ? durationObject?.duration >= NATIVE_VIDEO_DURATION_MIN &&
            durationObject?.duration <= NATIVE_VIDEO_DURATION_MAX
          : false

        if (durationObject?.duration && isCorrectSize && isValiduration) {
          validFiles.push({
            file,
            duration: durationObject.duration,
          })
        } else {
          if (isCorrectSize === false) {
            errorLogs.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.SIZE_EXCEED,
            })
          } else if (isValiduration === false) {
            errorLogs.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_DURATION,
            })
          }
        }
      } else if (ALLOWED_EXCEL_FORMATS.includes(file.type)) {
        const buffer = await readFileAsArrayBuffer(file)
        const workbook = read(buffer, { type: 'array' })
        const keys = ['Creative Name', 'VAST/VAST URL', 'Placement']
        for (const sheetName of workbook.SheetNames) {
          const sheet = workbook.Sheets[sheetName]
          let rows: TVASTCreativeRow[] = []
          if (sheet) {
            rows = utils.sheet_to_json(sheet)?.map((r: any) => ({
              ...r,
              tempCreativeId: crypto.randomUUID(),
            }))
          }

          if (!rows?.length && workbook.SheetNames.length === 1) {
            errorLogs.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
            })
            return
          }
          const headerKeys = rows[1]
          const headers = headerKeys && Object.keys(headerKeys)

          if (!headerKeys || !headerKeys['VAST/VAST URL']) {
            errorLogs.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
            })
            return
          }
          const headerRowKeys =
            headers &&
            headers.reduce(
              (
                headerRowKeys: { rawKey: string; key: string }[],
                rawKey: string
              ) => {
                const formattedKey = rawKey.trim().toLowerCase()
                const key = keys.find(key => key.toLowerCase() === formattedKey)
                if (key) {
                  headerRowKeys.push({
                    rawKey,
                    key,
                  })
                }
                return headerRowKeys
              },
              []
            )

          if (!headerRowKeys || !headerRowKeys.length) {
            errorLogs.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
            })
            return
          } else {
            for (const row of rows) {
              let formattedRow: TVASTCreativeRow | null = null
              headerRowKeys.forEach(headerRowKey => {
                const rowValue: any = row[headerRowKey.rawKey]
                if (rowValue) {
                  if (!formattedRow) {
                    formattedRow = {} as TVASTCreativeRow
                  }
                  formattedRow[headerRowKey.key] = rowValue
                }
              })
              if (formattedRow) {
                const xmlURL = formattedRow['VAST/VAST URL']
                const isURL = isValidURL(xmlURL)
                const validResultCreative = await validateXmlUrl(xmlURL)
                const videoSrc = validResultCreative?.mediaFiles[0]?.fileURL
                const metaData = await getVideoMetaData({ URL: videoSrc })

                if (validResultCreative) {
                  if (
                    validResultCreative?.duration >=
                      NATIVE_VIDEO_DURATION_MIN &&
                    validResultCreative?.duration <= NATIVE_VIDEO_DURATION_MAX
                  ) {
                    if (isURL) {
                      validUrls.push({
                        videoURL: validResultCreative?.mediaFiles[0].fileURL,
                        xmlURL: formattedRow['VAST/VAST URL'],
                        duration: validResultCreative?.duration,
                        height: metaData?.height ?? 0,
                        width: metaData?.width ?? 0,
                      })
                    } else {
                      validXmls.push({
                        videoURL: validResultCreative?.mediaFiles[0].fileURL,
                        xmlCode: formattedRow['VAST/VAST URL'],
                        duration: validResultCreative?.duration,
                        height: metaData?.height ?? 0,
                        width: metaData?.width ?? 0,
                      })
                    }
                  } else {
                    errorLogs.push({
                      'File Name': file.name,
                      'File Size': formatFileSize(file.size),
                      'Reason for Rejection': ERROR_MESSAGES.INVALID_DURATION,
                    })
                  }
                } else {
                  errorLogs.push({
                    'File Name': file.name,
                    'File Size': formatFileSize(file.size),
                    'Reason for Rejection': 'Invalid XML/URL',
                  })
                }
              }
            }
          }
        }
      } else {
        errorLogs.push({
          'File Name': file.name,
          'File Size': formatFileSize(file.size),
          'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
        })
      }
    })
  )

  return {
    validFiles,
    validUrls,
    validXmls,
    errorLogs,
  }
}
