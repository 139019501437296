import { Box, Paper, Skeleton, Typography } from '@applift/factor'

export const GroupAccordionSkeleton = () => {
  return (
    <Paper
      sx={{
        height: 100,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'between',
        px: 16,
      }}
    >
      <Box sx={{ display: 'flex', gap: 24, width: 100, overflow: 'hidden' }}>
        <Box sx={{ flexShrink: 0 }}>
          <Skeleton>
            <Typography variant="bodyMedium">creaetive</Typography>
          </Skeleton>
        </Box>
        <Box sx={{ flexShrink: 0, overflow: 'hidden', width: 100 }}>
          <Skeleton>
            <Typography variant="bodyMedium" noWrap>
              Cretive Name : Creative Lorem ipsum dolor sit, amet consectetur
              adipisicing elit. Iusto itaque qui
            </Typography>
          </Skeleton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 24, flexShrink: 0 }}>
        <Skeleton>
          <Typography variant="bodyMedium">Created On: DD/MM/YYYY</Typography>
        </Skeleton>
        <Skeleton>
          <Typography variant="bodyMedium">Creatives : Count</Typography>
        </Skeleton>
        <Skeleton>
          <Typography variant="bodyMedium">Action</Typography>
        </Skeleton>
      </Box>
    </Paper>
  )
}
