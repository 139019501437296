import { CampaignInfoType } from '../../../models'

export const NEGLECTED_CAMPAIGN_STATUS = ['deleted', 'expired', 'rejected']

export function compareWithoutOrder(
  intialData: CampaignInfoType[],
  currentData: CampaignInfoType[]
) {
  // Sort the arrays of objects
  const sortFunction = (a: { id: number }, b: { id: number }) => {
    return a.id - b.id
  }

  intialData.sort(sortFunction)
  currentData.sort(sortFunction)

  // Convert the sorted arrays back to JSON strings
  const sortedJson1 = JSON.stringify(intialData)
  const sortedJson2 = JSON.stringify(currentData)

  // Compare the resulting strings
  return sortedJson1 === sortedJson2
}

export function removeDeletedEntries(data: any) {
  const result = {}

  for (const key in data) {
    if (!NEGLECTED_CAMPAIGN_STATUS.includes(data[key].status)) {
      // @ts-ignore
      result[key] = data[key]
    }
  }

  return result
}
