import { useMutation, useQueryClient } from '@tanstack/react-query'

import { WithResponse } from '../models'
import { deleteCreativeGroup, scopeTypes } from '../api'

interface UseDeleteCreativeGroupParams {
  onSuccess: (message: string) => void
  onError: (message: string) => void
}

export const useDeleteCreativeGroup = (
  params: UseDeleteCreativeGroupParams
) => {
  const { onSuccess, onError } = params
  const queryClient = useQueryClient()

  const mutationData = useMutation({
    mutationKey: [{ scope: 'deleteCreativeGroup' }],
    mutationFn: deleteCreativeGroup,
    onSuccess: ({ success, data, errorObjects }) => {
      if (success) {
        onSuccess(data?.message ?? 'Creative Deleted Successfully')
        queryClient.invalidateQueries({
          predicate: query =>
            (query.queryKey[0] as { scope: scopeTypes })?.scope ===
            'getCreativeGroupsList',
        })
      } else {
        const error = errorObjects?.[0]?.error
        if (error && typeof error === 'string') {
          onError(error)
        } else {
          onError('Something went wrong!')
        }
      }
    },
    onError: (e: WithResponse<any>) => {
      const error = e.errorObjects?.[0]?.error
      if (error && typeof error === 'string') {
        onError(error)
      } else {
        onError('Something went wrong!')
      }
    },
  })
  return mutationData
}
