import * as React from 'react'

import { useAllCreativeDialogManager } from './context'
import { ReactChildrenType } from '../../../models/utils'
import {
  AddCreativeToGroupDialog,
  AssignToCampaignDialog,
  CreateCampaignDialog,
  DeleteCreativeDialog,
  DeleteCreativeGroupDialog,
  DuplicateCreativeGroupDialog,
  EditClickUrlConfirmationDialog,
  EditClickUrlDialog,
  EditPixelUrlDialog,
  RenameCreativeGroupDialog,
} from '../../../components/Dialog'
import { CreativePreview } from '@applift/platform-creatives'
import { useQueryClient } from '@tanstack/react-query'
import { scopeTypes } from '../../../api'

export const AllCreativeDialogManager = () => {
  const { actionData, setAction } = useAllCreativeDialogManager()

  const onClose = React.useCallback(() => {
    setAction(undefined, undefined)
  }, [setAction])

  const queryClient = useQueryClient()

  return (
    <>
      <ShowDialog condition={actionData?.actionName === 'deleteCreative'}>
        <DeleteCreativeDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'editPixelUrl'}>
        <EditPixelUrlDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'editClickUrl'}>
        <EditClickUrlDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog
        condition={actionData?.actionName === 'editClickUrlConfirmation'}
      >
        <EditClickUrlConfirmationDialog
          url={actionData?.payload?.url}
          data={actionData?.payload?.creatives}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'addToGroup'}>
        <AddCreativeToGroupDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'assignToCampaign'}>
        <AssignToCampaignDialog
          selectedCreativeData={actionData?.payload}
          showAddedCampaign
          title="Assign To Campaigns"
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'assignedCampaign'}>
        <AssignToCampaignDialog
          selectedCreativeData={actionData?.payload}
          showAddedCampaign
          title="Assigned Campaigns"
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'createCampaign'}>
        <CreateCampaignDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'deleteCreativeGroup'}>
        <DeleteCreativeGroupDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
        />
      </ShowDialog>
      <ShowDialog
        condition={actionData?.actionName === 'duplicateCreativeGroup'}
      >
        <DuplicateCreativeGroupDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'renameCreativeGroup'}>
        <RenameCreativeGroupDialog
          data={actionData?.payload}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'showPreview'}>
        <CreativePreview
          open
          creativeId={actionData?.payload?.id}
          onClose={(_event, _reason, hasChanged) => {
            if (hasChanged === true) {
              queryClient.resetQueries({
                predicate: ({ queryKey }) =>
                  (queryKey?.[0] as { scope: scopeTypes })?.scope ===
                  'getAllCreativeList',
              })
            }
            onClose()
          }}
        />
      </ShowDialog>
    </>
  )
}

interface ShowDialogType {
  condition: boolean
  children: ReactChildrenType
}

const ShowDialog = (props: ShowDialogType) => {
  const { condition, children } = props
  return condition ? children : null
}
