import {
  bindMenu,
  bindTrigger,
  Button,
  Menu,
  MenuItem,
  sx,
  usePopupState,
} from '@applift/factor'
import { AngleDown, AngleUp, ListCheck, Upload } from '@applift/icons'

export interface AddNewButtonProps {
  onNewClick: () => void
  onExisitingClick: () => void
  isOutlined?: boolean
}

// eslint-disable-next-line
export const AddNewButton = (props: AddNewButtonProps) => {
  const filtersPopupState = usePopupState({
    variant: 'popover',
    popupId: 'filtersPopup',
  })
  const { onExisitingClick, onNewClick, isOutlined } = props
  return (
    <>
      {/* @ts-ignore */}
      <Button
        {...bindTrigger(filtersPopupState)}
        endIcon={filtersPopupState.isOpen ? <AngleUp /> : <AngleDown />}
        variant={isOutlined ? 'outlined' : 'contained'}
      >
        Add New
      </Button>
      {/* @ts-ignore */}
      <Menu
        {...bindMenu(filtersPopupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            border: 1,
            borderColor: 'primary-600',
            marginTop: 2,
          },
        }}
        classes={{ list: sx({ overflow: 'auto', height: 'auto' }) }}
      >
        <MenuItem
          startIcon={<Upload sx={{ textColor: 'neutral-400' }} />}
          onClick={() => {
            onNewClick(), filtersPopupState.close()
          }}
        >
          Upload New Images or Videos
        </MenuItem>
        <MenuItem
          startIcon={<ListCheck sx={{ textColor: 'neutral-400' }} />}
          onClick={() => {
            onExisitingClick(), filtersPopupState.close()
          }}
        >
          Select Existing Images or Videos
        </MenuItem>
      </Menu>
    </>
  )
}
