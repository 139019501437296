import React from 'react'
import { InlineEdit, TextField, Typography } from '@applift/factor'

import { InlineEditAction } from '../../../CreativeTableActions'
import { Creative } from '../../../../../models'
import styles from '../index.module.scss'
import { clickUrlValidationFn } from '../../../../../utils'
import { MANDATORY_CLICK_URL_SOURCE_TYPE } from '../../../../../constants'

export interface ClickUrlValueTypeProps {
  clickUrl: string
  creativeId: number
  readonly?: boolean
  creative: Creative
  setAction: React.Dispatch<React.SetStateAction<InlineEditAction>>
}

export const ClickUrlValueType = (props: ClickUrlValueTypeProps) => {
  const { clickUrl, creativeId, readonly, setAction, creative } = props

  const [updatedClickUrl, setUpdatedClickUrl] = React.useState(clickUrl)

  const handleValueChange = (val: string = '') => {
    if (val.trim().length > 0) {
      setUpdatedClickUrl(val)
    } else {
      setUpdatedClickUrl('')
    }
  }
  const isClickUrlMandatory = MANDATORY_CLICK_URL_SOURCE_TYPE.includes(
    creative?.creativeSourceType
  )
  const handleSubmit = () => {
    setUpdatedClickUrl(clickUrl)
    setAction({
      actionName: 'EDIT_CLICK_URL',
      metaData: {
        id: creativeId,
        data: {
          ...creative,
          clickUrl: updatedClickUrl,
        },
        params: { clickUrl: updatedClickUrl },
      },
    })
  }

  const [focused, setFocused] = React.useState(false)

  return readonly ? (
    <Typography sx={{ ml: 12 }}>{clickUrl}</Typography>
  ) : (
    <InlineEdit
      value={updatedClickUrl ? updatedClickUrl : focused ? '' : '–'}
      onChange={handleValueChange}
      onCancel={() => setUpdatedClickUrl(clickUrl)}
      onConfirm={handleSubmit}
      validate={val => {
        return clickUrlValidationFn(val, isClickUrlMandatory)
      }}
    >
      {({ inputProps, inputRef }) => (
        <TextField
          fullWidth
          variant="outlinedDash"
          inputRef={node => {
            inputRef.current = node?.parentElement ?? node
          }}
          className={styles.inlineEditTextField}
          onBlur={() => setFocused(false)}
          {...inputProps}
          onFocus={e => {
            inputProps?.onFocus(e)
            setFocused(true)
          }}
        />
      )}
    </InlineEdit>
  )
}
