import { Row, Col, Box } from '@applift/factor'
import { UserVideo } from '@applift/illustrations'
import { FileUpload, PlaySlider, PlaySquareAlt } from '@applift/icons'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'

export const GroupedEmptyTab = () => {
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={<UserVideo sx={{ width: 100, height: 100 }} />}
              title="Grouped Video Creatives"
              subText="Save time on asset management by grouping multiple variations of the same creative format. Apply these groups across campaigns, and test and optimize your creatives’ performance."
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<PlaySquareAlt />}
                title="High Engagement"
                subText="Keep users engaged longer with attention-capturing content."
              />
              <EmptyFeatureCard
                icon={<PlaySlider />}
                title="Tailored Experiences"
                subText="Choose whether consumers will see your ad before, during, or after the video."
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple videos at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
