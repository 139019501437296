import { CREATIVE_SECTION, routeToCreativeTypeIdMapper } from '../constants'

export function getSelectedCreativeSection(pathname: string) {
  if (pathname.split('/').includes('ungrouped')) {
    return CREATIVE_SECTION.UNGROUPED
  }
  if (pathname.split('/').includes('grouped')) {
    return CREATIVE_SECTION.GROUPED
  }
  return CREATIVE_SECTION.ALL
}

export function getCreativeTypeIdFromRoute(route: string) {
  const validKeys = ['audio', 'video', 'html', 'native', 'image', 'all']
  const key = validKeys.find(key => route.includes(key)) // Find the first matching key

  return key ? routeToCreativeTypeIdMapper[key] : [] // If key is found, return its value; else, return []
}
