import { TypographyProps, TypoTooltip } from '@applift/factor'

interface RawDataCellProps {
  value: string | number
  isFooterCell?: boolean
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const RawDataCell = ({
  value,
  isFooterCell,
  textTypographyProps,
}: RawDataCellProps) => {
  return value ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        ...textTypographyProps,
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {value}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
