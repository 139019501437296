import { useDrop, useClipboard } from '@applift/drag-drop'
import { SampleFile } from '@applift/app-utils'
import {
  Box,
  Button,
  Divider,
  enqueueSnackbar,
  FileTrigger,
  Typography,
} from '@applift/factor'
import { Upload } from '@applift/icons'
import clsx from 'clsx'
import React from 'react'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'

import { fileToFileList } from '../../../../../utils'
import { CSV_UPLOAD_ERROR_MESSAGES } from '../../../../../constants'

import styles from './index.module.scss'

interface DropzoneProps {
  onUpload: (fileList: FileList | null | File[]) => Promise<void>
  setIsCsvLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const Dropzone = (props: DropzoneProps) => {
  const { onUpload, setIsCsvLoading } = props

  const ref = React.useRef<HTMLDivElement>(null)

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      if (e?.items?.length > 1) {
        enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.TOO_MANY_FILES, {
          variant: 'error',
        })
        return
      }
      const item = e.items?.[0]
      if (item?.kind === 'file') {
        const file = await item.getFile()
        onUpload(fileToFileList(file))
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      if (items?.length > 1) {
        enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.TOO_MANY_FILES, {
          variant: 'error',
        })
        return
      }
      if (items.length && items[0]?.kind === 'file') {
        const file = await items[0].getFile()
        onUpload(fileToFileList(file))
      }
    },
  })

  return (
    <Box
      className={clsx(styles.dropZone, isDropTarget && styles.active)}
      {...dropProps}
      {...clipboardProps}
      role="button"
      tabIndex={0}
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 12,
        justifyContent: 'center',
        p: 16,
        height: 100,
        borderRadius: 4,
      }}
    >
      <FileTrigger acceptedFileTypes={['text/csv']} onSelect={onUpload}>
        {({ onClick }) => {
          return (
            <Button
              startIcon={<Upload />}
              variant="outlined"
              onClick={onClick}
              color="primary"
              size="medium"
            >
              Upload CSV
            </Button>
          )
        }}
      </FileTrigger>
      <SampleFile
        sampleFileText="Sample File"
        sampleFileURL={`${window.location.origin}/creatives/example-files/sample_native_creatives.csv`}
        sampleFileName="sample_native_creatives.csv"
        buttonProps={{
          sx: { textWeight: 'normal', px: 0, py: 0 },
          startIcon: undefined,
        }}
      />

      <Typography sx={{ display: 'block', mt: 12, textColor: 'neutral-500' }}>
        import from
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <GoogleFilePicker
          acceptedFileTypes="csv"
          onSelect={async files => {
            onUpload(files)
          }}
          onFilesDownloadingStart={() => {
            setIsCsvLoading(true)
          }}
        />
        <DropBoxFilePicker
          extensions={['.csv']}
          onSelect={async files => {
            onUpload(files)
          }}
          onFilesDownloadingStart={() => {
            setIsCsvLoading(true)
          }}
        />
      </Box>
      <Divider
        orientation="horizontal"
        sx={{ borderColor: 'neutral-200', width: 100 }}
      />
      <Typography
        sx={{ textColor: 'neutral-500', display: 'block', mx: 'auto' }}
      >
        Drag or Paste files here
      </Typography>
    </Box>
  )
}
