import {
  bindMenu,
  bindTrigger,
  Box,
  IconButton,
  Menu,
  MenuItem,
  sx,
  Typography,
  usePopupState,
} from '@applift/factor'
import { ArrowsSort } from '@applift/icons'
import { ColumnSort, SortingState } from '@tanstack/react-table'
import { SetStateAction } from 'react'

export interface SortMenuProps {
  sorting: SortingState
  setSorting: React.Dispatch<SetStateAction<SortingState>>
}

const sortingOptions = [
  {
    label: 'Creative ID',
    value: [{ id: 'creativeId', desc: true }],
  },
  {
    label: 'Created',
    value: [{ id: 'createdAt', desc: true }],
  },
  {
    label: 'Last Modified',
    value: [{ id: 'modifiedAt', desc: true }],
  },
  {
    label: 'Creative Name A-Z',
    value: [{ id: 'creativeName', desc: true }],
  },
  {
    label: 'Creative Name Z-A',
    value: [{ id: 'creativeName', desc: false }],
  },
]

function isSelected(
  sorting: SortingState,
  value: ColumnSort = { id: '', desc: false }
) {
  return sorting.some(item => item.id === value.id && item.desc === value.desc)
}

export const SortMenu = (props: SortMenuProps) => {
  const { sorting, setSorting } = props
  const filtersPopupState = usePopupState({
    variant: 'popover',
    popupId: 'filtersPopup',
  })
  return (
    <>
      {/* @ts-ignore */}
      <IconButton
        color="secondary"
        {...bindTrigger(filtersPopupState)}
        selected={filtersPopupState.isOpen}
      >
        <ArrowsSort />
      </IconButton>
      {/* @ts-ignore */}
      <Menu
        {...bindMenu(filtersPopupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        renderContentTop={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'between',
              p: 8,
              pt: 12,
              gap: 8,
            }}
          >
            <Typography weight="demi">Sort</Typography>
          </Box>
        }
        PaperProps={{
          sx: {
            border: 1,
            borderColor: 'primary-600',
            marginTop: 2,
          },
          style: { width: '200px' },
        }}
        classes={{
          root: sx({ height: 'auto' }),
          list: sx({ overflow: 'auto', height: 100 }),
        }}
      >
        {sortingOptions.map(option => (
          <MenuItem
            key={option.label}
            selected={isSelected(sorting, option.value[0])}
            onClick={() => {
              setSorting(option.value)
              filtersPopupState.close()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
