import { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface EditClickUrlRequest {
  creativeIds?: number[]
  campaignIds?: number[]
  clickUrl: string | null
  confirmStatusChange: boolean
}

interface EditClickUrlResponse extends WithResponse<{ message: string }> {}

export const editClickUrl = async (params: EditClickUrlRequest) => {
  try {
    const res: AxiosResponse<EditClickUrlResponse> = await getInstance().patch(
      '/v3/crt/creatives/click-url',
      params
    )

    return res.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
