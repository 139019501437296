import { IoCurrency, IoImpression } from '@applift/icons'
import { Box, TypographyProps, TruncateMiddle } from '@applift/factor'
import { BUDGET_TYPE_ID } from '../../constants'

interface IoNameCellProp {
  ioTypeId: number
  ioName: string
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const IoNameCell = ({
  ioName,
  ioTypeId,
  textTypographyProps,
}: IoNameCellProp) => {
  const icon = () => {
    if (ioTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
      return <IoCurrency color="primary" fontSize={24} />
    }
    if (ioTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return <IoImpression color="primary" fontSize={24} />
    }
    return null
  }

  return (
    <Box
      sx={{
        width: 100,
        display: 'flex',
        alignItems: 'center',
        pr: 24,
      }}
    >
      {icon()}
      <TruncateMiddle
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
          sx: {
            width: 100,
            ml: 8,
            ...textTypographyProps?.sx,
          },
        }}
      >
        {ioName}
      </TruncateMiddle>
    </Box>
  )
}
