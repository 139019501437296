import { Box, Typography, TextField, Tooltip } from '@applift/factor'
import { InfoCircle } from '@applift/icons'

import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
} from '../../../../../../constants'
import {
  clickUrlValidationFn,
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
} from '../../../../../../utils'
import { AudioCreationFormType } from '../Create'

interface Props {
  form: AudioCreationFormType
}

export const AudioBasicInfo = (props: Props) => {
  const { form } = props

  return (
    <>
      <form.Field
        name="name"
        validators={{
          onChange: ({ value }) => creativeNameValidationFn(value),
        }}
      >
        {field => (
          <TextField
            label="Name"
            variant="outlinedDash"
            placeholder="Enter Name"
            value={field.state.value}
            required
            InputLabelProps={{
              renderSuffix: (
                <>
                  {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                </>
              ),
            }}
            onChange={(e: any) => {
              const value = e.target.value
              if (value.trim().length === 0) {
                field.handleChange(value.trim())
                return
              }
              field.handleChange(e.target.value)
            }}
            error={Boolean(field.state.meta.errors.length)}
            helperText={field.state.meta.errors[0] ?? ''}
          />
        )}
      </form.Field>
      <form.Field
        name="thirdPartyID"
        validators={{
          onChange: ({ value }) =>
            creativeThirdPartyIDValidationFn(value as string),
        }}
      >
        {field => (
          <TextField
            label={
              <Tooltip
                title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                placement="top"
                arrow
              >
                <Box
                  Component={'span'}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                  style={{ pointerEvents: 'all' }}
                >
                  <Typography variant="label">3rd Party ID</Typography>
                  <InfoCircle fontSize={14} sx={{ textColor: 'neutral-400' }} />
                </Box>
              </Tooltip>
            }
            variant="outlinedDash"
            placeholder="Enter 3rd Party ID"
            value={field.state.value}
            InputLabelProps={{
              renderSuffix: (
                <>
                  {field.state.value?.length}/{CREATIVE_THIRD_PARTY_ID_LIMIT}
                </>
              ),
            }}
            onChange={(e: any) => {
              const value = e.target.value
              if (value.trim().length === 0) {
                field.handleChange(value.trim())
                return
              }
              field.handleChange(e.target.value)
            }}
            error={Boolean(field.state.meta.errors.length)}
            helperText={field.state.meta.errors[0] ?? ''}
          />
        )}
      </form.Field>
      <form.Field
        name="clickURL"
        validators={{
          onChange: ({ value }) => clickUrlValidationFn(value as string, true),
        }}
      >
        {field => (
          <TextField
            label="Click URL"
            variant="outlinedDash"
            placeholder="Enter Click URL"
            value={field.state.value}
            onChange={e => {
              const value = e.target.value
              if (value.trim().length === 0) {
                field.handleChange(value.trim())
                return
              }
              field.handleChange(e.target.value)
            }}
            error={Boolean(field.state.meta.errors.length)}
            helperText={field.state.meta.errors[0] ?? ''}
            required
          />
        )}
      </form.Field>
    </>
  )
}
