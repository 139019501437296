import { SingleAsset, CreateImageCreativeType } from '../models'
import { removeFileExtension, removeSequenceFromAssets } from './helper'
import { MEGA_BYTE, kILO_BYTE, STANDARD_IMAGE_DIMENSIONS } from '../constants'

const allowedMimeTypesSize = {
  'image/png': MEGA_BYTE * 2,
  'image/jpeg': MEGA_BYTE * 2,
  'image/gif': kILO_BYTE * 750,
} as const

export const allowedMimeTypes = Object.keys(allowedMimeTypesSize)

export const isRecommendedImageDimensionSize = ({
  height,
  width,
}: {
  height: number
  width: number
}) => {
  const result = `${width}x${height}`
  return STANDARD_IMAGE_DIMENSIONS.indexOf(result) >= 0
}

export const verifySize = (file: File) => {
  const allowedSize =
    allowedMimeTypesSize[file.type as keyof typeof allowedMimeTypesSize]
  return allowedSize && file.size <= allowedSize ? true : false
}

export const computeImageDimensions = (
  fileName: string,
  asset: SingleAsset
) => {
  return new Promise<CreateImageCreativeType>((res, rej) => {
    const image = new Image()
    image.src = asset.assetCDNURL
    image.onload = () => {
      const obj: CreateImageCreativeType = {
        name: removeFileExtension(removeSequenceFromAssets(fileName)),
        id: crypto.randomUUID(),
        image: asset.assetCDNURL,
        height: image.naturalHeight,
        width: image.naturalWidth,
        isRecommendedDimensionSize: isRecommendedImageDimensionSize({
          height: image.naturalHeight,
          width: image.naturalWidth,
        }),
      }

      res(obj)
    }
    image.onerror = e => {
      rej(e)
    }
  })
}
