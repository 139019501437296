import { Box, Typography } from '@applift/factor'
import { CreativeStatusIcon } from '../../../Mappings/CreativeStatusIcon'
import { useCreativeStatuses } from '../../../../../hooks'

interface CreativeStatusValueProps {
  creativeStatusId: number
}

export const CreativeStatusValueType = (props: CreativeStatusValueProps) => {
  const creativeStatusObj =
    useCreativeStatuses().data?.creativeStatusMappingById?.[
      props.creativeStatusId
    ]
  const creativeStatusName = creativeStatusObj?.name
  const creativeStatusDisplayName = creativeStatusObj?.displayName

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pl: 12 }}>
      <CreativeStatusIcon
        name={creativeStatusName}
        sx={{ fontSize: 20, mr: 4 }}
      />
      <Typography variant="bodySmall" gutterBottom={false}>
        {creativeStatusDisplayName}
      </Typography>
    </Box>
  )
}
