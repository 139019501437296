import * as React from 'react'
import clsx from 'clsx'
import {
  Box,
  Divider,
  Typography,
  Button,
  Link,
  FileTrigger,
  useSnackbar,
} from '@applift/factor'
import { Redo, Upload } from '@applift/icons'
import { Loading, Maintenance, Warning } from '@applift/illustrations'
import { useDrop, useClipboard } from '@applift/drag-drop'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'

import { EmptyCard } from '../../../../../components'
import { getValidAudioThumbnailDropItems } from './utils'

import styles from './index.module.scss'

const SupportedFilesNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Typography
        variant="label"
        sx={{ textColor: 'neutral-500' }}
        lineHeight="single-line"
      >
        Supported Type & Max Size Limit
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 4,
          }}
        >
          <Typography variant="label" lineHeight="single-line">
            JPG, JPEG, PNG
          </Typography>
          <Typography
            variant="label"
            sx={{ textColor: 'neutral-300' }}
            lineHeight="single-line"
          >
            &bull;
          </Typography>
          <Typography variant="label" lineHeight="single-line">
            2MB
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 4,
          }}
        >
          <Typography variant="label" lineHeight="single-line">
            GIF
          </Typography>
          <Typography
            variant="label"
            sx={{ textColor: 'neutral-300' }}
            lineHeight="single-line"
          >
            &bull;
          </Typography>
          <Typography variant="label" lineHeight="single-line">
            750KB
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export interface AddAudioThumbnailDropZoneType {
  /**
   * Need to pass thumbnail image's URL
   */
  image?: string
  /**
   * On thumbnail upload
   */
  onUpload: (files: File[]) => void
  /**
   * Dropzone state
   */
  state: 'normal' | 'loading' | 'timeout' | 'error' | 'thumbnail'
  setDropzoneState: React.Dispatch<
    React.SetStateAction<
      'normal' | 'loading' | 'timeout' | 'error' | 'thumbnail'
    >
  >
}

export const AddAudioThumbnailDropZone = (
  props: AddAudioThumbnailDropZoneType
) => {
  const { image = '', onUpload, state, setDropzoneState } = props

  const { closeSnackbar } = useSnackbar()

  const ref = React.useRef(null)

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      const validFiles = await getValidAudioThumbnailDropItems(
        e.items,
        closeSnackbar
      )
      if (validFiles?.length) {
        onUpload(validFiles)
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      const validFiles = await getValidAudioThumbnailDropItems(
        items,
        closeSnackbar
      )
      if (validFiles?.length) {
        onUpload(validFiles)
      }
    },
  })

  return (
    <>
      {state === 'normal' ? (
        <Box
          {...dropProps}
          {...clipboardProps}
          role="button"
          tabIndex={0}
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
            height: 100,
            overflow: 'hidden',
            py: 24,
            px: 16,
            gap: 24,
            borderRadius: 4,
          }}
          className={clsx(styles.dropZone, isDropTarget && styles.active)}
        >
          <FileTrigger
            allowsMultiple={false}
            acceptedFileTypes={[
              'image/jpeg',
              'image/png',
              'image/gif',
              'image/jpg',
            ]}
            onSelect={fileList => onUpload(fileList?.[0] ? [fileList[0]] : [])}
          >
            {({ onClick }) => {
              return (
                <Button
                  startIcon={<Upload />}
                  variant="outlined"
                  onClick={onClick}
                  size="medium"
                >
                  Upload Image
                </Button>
              )
            }}
          </FileTrigger>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 4,
            }}
          >
            <Typography
              sx={{ textColor: 'neutral-500' }}
              lineHeight="single-line"
            >
              import from
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexWrap: 'wrap',
                gap: 8,
              }}
            >
              <GoogleFilePicker
                acceptedFileTypes="png,jpg,jpeg,gif"
                allowsMultiple={false}
                onSelect={async files => {
                  onUpload(files)
                }}
                onFilesDownloadingStart={() => {
                  setDropzoneState('loading')
                }}
              />
              <DropBoxFilePicker
                key="upload-thumbnail"
                extensions={['.png', '.jpg', '.jpeg', '.gif']}
                allowsMultiple={false}
                onFilesDownloadingStart={() => {
                  setDropzoneState('loading')
                }}
                onSelect={async files => {
                  onUpload(files)
                }}
              />
            </Box>
          </Box>
          <Divider sx={{ width: 100 }} color="secondary" />
          <Typography
            sx={{ textColor: 'neutral-600' }}
            lineHeight="single-line"
          >
            or Drag & Drop
          </Typography>
          <SupportedFilesNote />
        </Box>
      ) : state === 'thumbnail' ? (
        <Box
          Component={'img'}
          // @ts-ignore
          src={image}
          alt="ImageCreative"
          sx={{ display: 'flex', width: 100, height: 'auto' }}
          style={{ aspectRatio: '4 / 3', objectFit: 'contain' }}
          width={4}
          height={3}
        />
      ) : state === 'loading' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
            height: 100,
            overflow: 'hidden',
            py: 24,
            px: 16,
            gap: 24,
            border: 1,
            borderRadius: 4,
          }}
        >
          <EmptyCard
            illustration={<Loading sx={{ width: 100, height: 100 }} />}
            subText={
              <Typography
                component="span"
                variant="bodySmall"
                gutterBottom={false}
              >
                Compressing the images, <br />
                Please wait...
              </Typography>
            }
            isLoading
          />
        </Box>
      ) : state === 'timeout' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
            height: 100,
            overflow: 'hidden',
            py: 24,
            px: 16,
            gap: 24,
            border: 1,
            borderRadius: 4,
          }}
        >
          <EmptyCard
            illustration={<Maintenance sx={{ width: 100, height: 100 }} />}
            subText={
              <Typography
                component="span"
                variant="bodyMedium"
                gutterBottom={false}
              >
                It's taking more time than usual.
              </Typography>
            }
            action={
              <Link>
                <Typography
                  gutterBottom={false}
                  sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  <Redo fontSize={18} sx={{ display: 'flex' }} />
                  Refresh
                </Typography>
              </Link>
            }
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
            height: 100,
            overflow: 'hidden',
            py: 24,
            px: 16,
            gap: 24,
            border: 1,
            borderRadius: 4,
          }}
        >
          <EmptyCard
            illustration={<Warning sx={{ width: 100, height: 100 }} />}
            subText={
              <Typography
                component="span"
                variant="bodyMedium"
                gutterBottom={false}
              >
                Oops, something went wrong. Please try again after some time.
              </Typography>
            }
          />
        </Box>
      )}
    </>
  )
}
