import { Link, Typography, TypoTooltip } from '@applift/factor'
import { ACTION, InlineEditAction } from '../../../CreativeTableActions'

export interface CreativeIdValueTypeProps {
  creativeId: number
  setAction: React.Dispatch<React.SetStateAction<InlineEditAction>>
  readonly?: boolean
}

export const CreativeIDValueType = (props: CreativeIdValueTypeProps) => {
  const { creativeId, readonly, setAction } = props
  return readonly ? (
    <Typography sx={{ ml: 12 }}>{creativeId}</Typography>
  ) : (
    <Typography variant="bodySmall" sx={{ pl: 12 }}>
      <Link
        Component="button"
        color="primary"
        sx={{ textWeight: 'demi' }}
        onClick={() =>
          setAction?.({
            actionName: ACTION.OPEN_CREATIVE_DETAIL,
            // @ts-ignore
            metaData: { data: { creativeId: creativeId } },
          })
        }
      >
        <TypoTooltip
          arrow
          placement="top"
          color="primary"
          TypgraphyProps={{
            gutterBottom: false,
            weight: 'normal',
          }}
        >
          {creativeId}
        </TypoTooltip>
      </Link>
    </Typography>
  )
}
