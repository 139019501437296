import { Box, Typography } from '@applift/factor'
import { SampleFile } from '@applift/app-utils'

export const SupportedFilesNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Typography
        variant="label"
        sx={{ textColor: 'neutral-500' }}
        lineHeight="single-line"
      >
        Supported Type
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 4,
          }}
        >
          <Typography variant="label" lineHeight="single-line">
            XLSX, CSV
          </Typography>
          <Typography
            variant="label"
            sx={{ textColor: 'neutral-300' }}
            lineHeight="single-line"
          >
            &bull;
          </Typography>
          <Typography
            sx={{ display: 'flex' }}
            variant="label"
            lineHeight="single-line"
          >
            <SampleFile
              sampleFileText="Sample File"
              sampleFileURL={`${window.location.origin}/creatives/example-files/sample_html_creatives.csv`}
              sampleFileName="sample_html_creatives.csv"
              buttonProps={{
                sx: { textWeight: 'normal', fontSize: 12, px: 0, py: 0 },
                startIcon: undefined,
              }}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
