import { useQuery } from '@tanstack/react-query'
import { getCreativeTypes } from '../api/creative'
import { enqueueSnackbar } from '@applift/factor'
import { getCreativeTypesKey } from '../api/QueryKeys'
import { CreativeTypesApiRequest } from '../models'

export const useCreativeTypes = (props: CreativeTypesApiRequest = {}) => {
  const data = useQuery(
    getCreativeTypesKey.keys({
      scope: 'getCreativeTypes',
      ...props,
    }),
    getCreativeTypes,
    {
      keepPreviousData: true,
      retry: false,
    }
  )
  if (data.isError) {
    const error: any = data.error
    enqueueSnackbar(
      error?.errorObjects
        ? (error?.errorObjects[0]?.error as string)
        : 'Something went wrong!',
      {
        variant: 'error',
      }
    )
  }
  return data
}
