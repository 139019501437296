import { Row, Col, Box } from '@applift/factor'
import { MediaGroup } from '@applift/illustrations'
import { Clock, Group, ClipboardCheck } from '@applift/icons'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'

export const GroupedEmptyTab = () => {
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={<MediaGroup sx={{ width: 100, height: 100 }} />}
              title="Grouped Creatives"
              subText="Join two or more creatives and reuse them together across multiple campaigns."
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<Clock />}
                title="Refine Operations"
                subText="Save time on asset management, and reduce the need for repetitive creative uploads across multiple campaigns."
              />
              <EmptyFeatureCard
                icon={<Group />}
                title="Categorize Effectively"
                subText="Organize your ads based on their objectives for a plug-and-play approach."
              />
              <EmptyFeatureCard
                icon={<ClipboardCheck />}
                title="Test and Learn"
                subText="Group multiple variations of the same creative format to test each creative and simplify performance analysis."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
