import { ASSET_SEQUENCE_SPLIT_CHARACTER } from '../constants'
import { IMAGE_CREATIVE_ID, NATIVE_CREATIVE_ID } from '../constants'
import { Creative } from '../models'

const hasValidProtocol = (url: string) => {
  const validProtocols = ['http://', 'https://']
  return validProtocols.some(protocol => url.toLowerCase().startsWith(protocol))
}

export const urlRegEx =
  /^(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const isValidURL = (url: string) => {
  if (url?.length <= 0) {
    return true
  }
  if (typeof url !== 'string') {
    return false
  }
  try {
    // eslint-disable-next-line no-new
    new URL(url)

    if (hasValidProtocol(url.trim()) === false) {
      return false
    }
    return urlRegEx.test(url.trim())
  } catch (error) {
    return false
  }
}

// TODO: remove the below function
export const isValidHTML = (htmlCode: string) => {
  const a = document.createElement('div')
  a.innerHTML = htmlCode
  for (let c = a.childNodes, i = c.length; i--; ) {
    // @ts-ignore
    if (c[i].nodeType === 1) return true
  }
  return false
}

// TODO: remove the below function
export const isValidXML = (value: string) => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(value, 'text/xml')
  return !xmlDoc.getElementsByTagName('parsererror').length
}

export const noop = () => {}

export const removeFileExtension = (fileName: string) => {
  const fileExtensionRegex = new RegExp(/\.[^/.]+$/)
  return fileName.replace(fileExtensionRegex, '')
}

export const removeSequenceFromAssets = (fileName: string = '') => {
  return fileName.split(ASSET_SEQUENCE_SPLIT_CHARACTER)?.[1] ?? fileName
}
export const isImageCreative = (creative: Creative) =>
  creative.platformCreativeTypeId === IMAGE_CREATIVE_ID

export const isNativeImageCreatve = (creative: Creative) =>
  creative.platformCreativeTypeId === NATIVE_CREATIVE_ID &&
  creative.subMediaType?.toLowerCase() === 'image'

export const toLowerCase = (value: string): string => value.trim().toLowerCase()

export function ensureHttpUrl(url: string) {
  // Check if the string starts with "http://" or "https://"
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    // Add "https://" to the beginning of the string
    return 'https://' + url
  }
  // Return the original string if it already contains http/https
  return url
}
