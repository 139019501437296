import { RowSelectionState } from '@tanstack/react-table'
import { CreativeGroup } from '../../api/getCreativeGroupsList'

export const transformCreativeGroupInitDataToRowSelection = (
  initialData?: Record<string, CreativeGroup>
) => {
  const result: RowSelectionState = {}
  for (const [key] of Object.entries(initialData ?? {})) {
    result[key] = true
  }
  return result
}
