import * as React from 'react'
import clsx from 'clsx'
import { Box, Divider, Typography, Button, FileTrigger } from '@applift/factor'
import { Add, Upload } from '@applift/icons'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'
import { Warning } from '@applift/illustrations'
import { useClipboard, useDrop } from '@applift/drag-drop'

import { EmptyCard } from '../../../../../components'
import styles from './index.module.scss'
import { getAudioItems } from './helper'
import { DropZoneStates } from '../../../../../models'

export interface DropZoneProps {
  /**
   * Sets the state of the drop zone
   * @default 'normal'
   */
  state?: 'normal' | 'error'
  onAdd: () => void
  onUpload: (file: File[]) => void
  setDropZoneState: React.Dispatch<React.SetStateAction<DropZoneStates>>
}

export const DropZone = (props: DropZoneProps) => {
  const { state = 'normal', onAdd, onUpload, setDropZoneState } = props

  const ref = React.useRef(null)

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      const { audioFiles } = await getAudioItems(e.items)
      if (audioFiles) {
        onUpload(Array.from(audioFiles))
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      const { audioFiles } = await getAudioItems(items)
      if (audioFiles) {
        onUpload(audioFiles)
      }
    },
  })
  return (
    <>
      {state === 'normal' ? (
        <Box
          {...dropProps}
          {...clipboardProps}
          ref={ref}
          tabIndex={0}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
            height: 100,
            overflow: 'hidden',
            py: 24,
            px: 16,
            gap: 24,
            borderRadius: 4,
          }}
          className={clsx(styles.dropZone, isDropTarget && styles.active)}
        >
          <Button variant="outlined" startIcon={<Add />} onClick={onAdd}>
            Add DAAST XML / URL
          </Button>

          <FileTrigger
            allowsMultiple
            acceptedFileTypes={['.mp3', '.wav', '.ogg']}
            onSelect={files => {
              if (files) {
                onUpload(Array.from(files))
              }
            }}
          >
            {({ onClick }) => {
              return (
                <Button
                  startIcon={<Upload />}
                  variant="outlined"
                  onClick={onClick}
                  size="medium"
                >
                  Upload Audio
                </Button>
              )
            }}
          </FileTrigger>
          <Integrations
            onUpload={onUpload}
            setDropZoneState={setDropZoneState}
          />
          <Divider sx={{ width: 100 }} color="secondary" />
          <Typography
            sx={{ textColor: 'neutral-600' }}
            lineHeight="single-line"
          >
            or Drag & Drop
          </Typography>
        </Box>
      ) : (
        <ErrorState />
      )}
    </>
  )
}

const Integrations = ({
  onUpload,
  setDropZoneState,
}: {
  onUpload: (file: File[]) => void
  setDropZoneState: React.Dispatch<React.SetStateAction<DropZoneStates>>
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      gap: 4,
    }}
  >
    <Typography sx={{ textColor: 'neutral-500' }} lineHeight="single-line">
      import from
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexWrap: 'wrap',
        gap: 8,
      }}
    >
      <GoogleFilePicker
        acceptedFileTypes="mp3,wav,ogg"
        onSelect={async files => {
          onUpload(files)
        }}
        onFilesDownloadingStart={() => {
          setDropZoneState('loading')
        }}
      />
      <DropBoxFilePicker
        key="upload-main-file"
        extensions={['.mp3', '.wav', '.ogg']}
        onFilesDownloadingStart={() => {
          setDropZoneState('loading')
        }}
        onSelect={async files => {
          onUpload(files)
        }}
      />
    </Box>
  </Box>
)

const ErrorState = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 100,
      height: 100,
      overflow: 'hidden',
      py: 24,
      px: 16,
      gap: 24,
      border: 1,
      borderRadius: 4,
    }}
  >
    <EmptyCard
      illustration={<Warning sx={{ width: 100, height: 100 }} />}
      subText={
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          Oops, something went wrong. Please try again after some time.
        </Typography>
      }
    />
  </Box>
)
