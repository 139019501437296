import * as React from 'react'
import { GridActionCellParams } from '@applift/datagrid'
import { IconButton, Tooltip, Box, Link, Typography } from '@applift/factor'
import { Delete, AppBidModel, SemanticError } from '@applift/icons'
import { CampaignInfoType } from '../../../../models'
import { getPathNumber } from '../../../../utils'

export interface DeleteCellProps {
  onAction?: (params: GridActionCellParams<string, any>) => void
  row?: CampaignInfoType
  meta?: any
}

const DeleteIconTooltipObject = {
  0: {
    tooltip: '',
    type: undefined,
  },
  1: {
    tooltip: 'Creatives with Advanced Modeling applied cannot be removed.',
    type: undefined,
  },
  2: {
    tooltip: 'Creatives with Deleted or Rejected status cannot be removed.',
    type: undefined,
  },
  3: {
    tooltip: 'Creatives can’t be removed from expired or deleted campaign.',
    type: undefined,
  },
  4: {
    tooltip:
      'Selected creative can’t be removed as it is the only one in the campaign',
    type: undefined,
  },
  5: {
    tooltip: (
      <Box sx={{ display: 'flex', gap: 8 }}>
        <SemanticError color="warning" fontSize={24} />
        <Typography>
          Removing a creative from running or paused campaign may impact its
          performance.
        </Typography>
      </Box>
    ),
    type: 'warning',
  },
}

export const DeleteCell = (props: DeleteCellProps) => {
  const { onAction, row, meta } = props

  const handleRemoveSelection = () => {
    onAction?.({
      actionName: 'removeRow',
      metaData: { rowId: row?.id },
    })
  }
  const expiredCamapign = row?.status === 'expired'
  const deletedCampaign = row?.status === 'deleted'

  const campaignWithOnlyOneCreative = meta.campaignWithOnlyOneCreative ?? []
  const advanceModeledCampaign = meta.advanceModeledCampaign ?? []
  const isSingleCreativeSelected = meta.isSingleCreativeSelected ?? false

  const isInitSelected = meta?.initCampaignId.includes(Number(row?.id)) ?? false

  const isAdvanceModelingApplied =
    advanceModeledCampaign.filter((cmpDetail: any) => cmpDetail.id === row?.id)
      .length > 0
  const isOnlyAttacedCreative =
    campaignWithOnlyOneCreative.filter(
      (cmpDetail: any) => cmpDetail.id === row?.id
    ).length > 0
  const isDeletedOrRejectedCreative =
    meta?.isDeletedCreativeSelected || meta?.isRejectedCreativeSelected

  const isDeleteDisabled =
    isSingleCreativeSelected &&
    (isDeletedOrRejectedCreative ||
      isAdvanceModelingApplied ||
      expiredCamapign ||
      deletedCampaign ||
      (isOnlyAttacedCreative && row?.status !== 'draft'))

  const [deleteBtnTooltipId, setDeleteBtnTooltiId] =
    React.useState<keyof typeof DeleteIconTooltipObject>(0)

  const deleteIconTooltipMessage = React.useCallback(() => {
    // Doesn't have any condition to show initial selected campaign for the multi select creative dialog
    if (isSingleCreativeSelected === false) {
      setDeleteBtnTooltiId(0)
      return
    }

    if (isAdvanceModelingApplied) {
      setDeleteBtnTooltiId(1)
      return
    }
    if (isDeletedOrRejectedCreative) {
      setDeleteBtnTooltiId(2)
      return
    }
    if (expiredCamapign || deletedCampaign) {
      setDeleteBtnTooltiId(3)
      return
    }
    if (isOnlyAttacedCreative && row?.status !== 'draft') {
      setDeleteBtnTooltiId(4)
      return
    }

    // For this condition button is enable message in just a warning
    if (
      isInitSelected &&
      (row?.status.toLowerCase() === 'running' ||
        row?.status.toLowerCase() === 'paused')
    ) {
      setDeleteBtnTooltiId(5)
      return
    }
    setDeleteBtnTooltiId(0)
  }, [
    isSingleCreativeSelected,
    isInitSelected,
    deletedCampaign,
    expiredCamapign,
    isAdvanceModelingApplied,
    isDeletedOrRejectedCreative,
    isOnlyAttacedCreative,
    row,
  ])

  React.useEffect(() => {
    deleteIconTooltipMessage()
  }, [deleteIconTooltipMessage])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        title={DeleteIconTooltipObject[deleteBtnTooltipId].tooltip}
        severity={DeleteIconTooltipObject[deleteBtnTooltipId].type as any}
        placement="top"
        arrow
      >
        <Box>
          <IconButton
            onClick={handleRemoveSelection}
            sx={{ fontSize: 24, textColor: 'neutral-400' }}
            borderRadius="circle"
            disabled={isDeleteDisabled}
          >
            <Delete />
          </IconButton>
        </Box>
      </Tooltip>
      {isAdvanceModelingApplied && (
        <Tooltip
          title={'View modelling in Bid Model App'}
          placement="top"
          arrow
        >
          <Link
            /* @ts-ignore */
            target="_blank"
            href={`/bidmodel/u/${getPathNumber()}/#/creative/${row?.id}`}
          >
            <AppBidModel fontSize={24} />
          </Link>
        </Tooltip>
      )}
    </Box>
  )
}
