import { read } from 'xlsx'
import { Box, enqueueSnackbar, Link, Typography } from '@applift/factor'

import {
  ERROR_MESSAGES,
  MAX_NATIVES_COUNT_PER_CREATION,
} from '../../../../../constants'
import {
  CreativeRow,
  NativeBrandIcon,
  NativeCreativeGenerationData,
  NativeCSVColKey,
  NativeImage,
  NativeVideo,
  ValidationResult,
} from '../../../../../models'
import { getDefaultrackingURLs } from '../../../helper'
import {
  extractRowsFromWorkbook,
  generateCSV,
  readFileAsArrayBuffer,
  toLowerCase,
} from '../../../../../utils'
import { validateFilePreProcessing, validateRow } from '../Upload/validation'

const CSV_UPLOAD_ERROR_MESSAGES = {
  PARSE_FAILED: 'Unable to read the file. Please upload a valid CSV',
  TOO_MANY_RECORDS: 'File should contain an maximum of 200 records',
}

// Main CSV Processing Function
export const processCSVFile = async (
  fileList: FileList | File[],
  currentNativeCount: number,
  generateId: () => number,
  closeSnackbar: (key?: any) => void
) => {
  // Validate file initial checks
  const fileValidationError = validateFilePreProcessing(fileList)
  if (fileValidationError) return []

  try {
    const file = fileList[0] as File
    const buffer = await readFileAsArrayBuffer(file)
    const workbook = read(buffer, { type: 'array', raw: true })
    const allRows = extractRowsFromWorkbook(workbook)
    const res = await validateAndProcessRows(
      allRows,
      currentNativeCount,
      generateId
    )

    if (res.invalidRows?.length) {
      const url = generateCSV(res.invalidRows)
      const snackId = enqueueSnackbar(
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Typography>
            {res?.invalidRows?.length} creative
            {res?.invalidRows?.length > 1 ? 's' : ''} failed to upload. Check
            the failure reason in the file and re-upload.
          </Typography>
          <Link
            href={url}
            // @ts-ignore
            download="native_error_file.csv"
            onClick={() => {
              setTimeout(() => {
                URL.revokeObjectURL(url)
              }, 1000)
              closeSnackbar(snackId)
            }}
          >
            Download Failed Creatives
          </Link>
        </Box>,
        {
          variant: 'error',
          persist: true,
        }
      )
    }
    return res.validCreatives
  } catch (error) {
    enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.PARSE_FAILED, {
      variant: 'error',
    })
    return []
  }
}

// Detailed Validation Logic
const validateAndProcessRows = async (
  rows: CreativeRow[],
  currentNativeCount: number,
  generateId: () => number
): Promise<ValidationResult> => {
  const validCreatives: NativeCreativeGenerationData[] = []
  const invalidRows: { rowData: CreativeRow; errors: string[] }[] = []
  if (rows?.length + currentNativeCount > MAX_NATIVES_COUNT_PER_CREATION) {
    enqueueSnackbar(ERROR_MESSAGES.MAXIMUM_200_LIMTI, {
      variant: 'error',
    })
    return {
      error: ERROR_MESSAGES.MAXIMUM_200_LIMTI,
      validCreatives: [],
      invalidRows: [],
    }
  }

  for (const row of rows) {
    const uniqueCreativeId = crypto.randomUUID()
    const temporaryDisplayId = generateId()
    const {
      errors: validationErrors,
      imageObject,
      brandIcon,
      videoObject,
    } = await validateRow(row, uniqueCreativeId)

    if (validationErrors.length === 0) {
      validCreatives.push(
        transformRowToCreative(
          row,
          uniqueCreativeId,
          imageObject,
          brandIcon,
          videoObject,
          temporaryDisplayId
        )
      )
    } else {
      invalidRows.push({ rowData: row, errors: validationErrors })
    }
  }

  return {
    error: null,
    validCreatives,
    invalidRows,
  }
}

// Transform Row to Creative Object
const transformRowToCreative = (
  row: CreativeRow,
  creativeUniqueId: string,
  imageObject: NativeImage[],
  brandIconObject: NativeBrandIcon[],
  videoObject: NativeVideo[],
  id: number
): NativeCreativeGenerationData => {
  const nativeType =
    toLowerCase(row[NativeCSVColKey.CREATIVE_AD_TYPE]) === 'display'
      ? 'image'
      : 'video'
  return {
    temporaryId: id,
    creativeName: row[NativeCSVColKey.CREATIVE_AD_NAME],
    nativeType,
    titles: [row[NativeCSVColKey.TITLE] ?? ''],
    descriptions: [row[NativeCSVColKey.DESCRIPTION] ?? ''],
    imageObjects: imageObject,
    videoObjects: videoObject,
    brandIcon: brandIconObject[0] ?? null,
    brandName: row[NativeCSVColKey.BRAND_NAME] ?? '',
    uniqueId: creativeUniqueId,
    clickURL: row[NativeCSVColKey.CTA_URL] ?? '',
    pixelURL: row[NativeCSVColKey.PIXEL_URL] ?? '',
    thirdPartyId: '',
    videoThumbnail:
      nativeType === 'video' && imageObject?.[0] ? imageObject[0] : null,
    cta: row[NativeCSVColKey.CTA_TEXT]
      ? {
          value: crypto.randomUUID(),
          label: row[NativeCSVColKey.CTA_TEXT],
          isDefault: false,
        }
      : null,
    customTracking: getDefaultrackingURLs(),
  }
}
