export const dateFormat = (
  value: Date | number,
  formatProps?: Intl.DateTimeFormatOptions
) => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    ...formatProps,
  })

  return formatter.format(value)
}

export function formatFileSize(sizeInBytes: number) {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0

  while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
    sizeInBytes /= 1024
    unitIndex++
  }

  return `${sizeInBytes.toFixed(1)}${units[unitIndex]}`
}
