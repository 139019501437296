import {
  bindMenu,
  bindTrigger,
  Box,
  Button,
  Menu,
  MenuItem,
  usePopupState,
} from '@applift/factor'

import { CaretDown, Copy } from '@applift/icons'
import { Creative } from '../../../../models'
import { useAllCreativeDialogManager } from '../../DialogManager'
import { RowSelectionState } from '@tanstack/react-table'
import { useCreativeStatuses } from '../../../../hooks'

interface CampaignActionsProps {
  selectionData: Creative[]
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const CampaignActions = (props: CampaignActionsProps) => {
  const { selectionData, setRowSelection } = props

  const CampaignActionsPopupState = usePopupState({
    variant: 'popover',
    popupId: 'CampaignActionsPopup',
  })
  const { setAction } = useAllCreativeDialogManager()

  const { data: creativeStatusType } = useCreativeStatuses()

  const isSameTypeOfCreativeSelected = selectionData.every(
    (creative, _, arr) =>
      creative.platformCreativeTypeId === arr[0]?.platformCreativeTypeId
  )

  const isAnyDeletedOrRejectedCreativeSelected = selectionData.some(
    creative =>
      creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['deleted']?.id ||
      creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['rejected']?.id
  )

  const isCampaignActionsDiasabled =
    isSameTypeOfCreativeSelected === false ||
    isAnyDeletedOrRejectedCreativeSelected

  return (
    <>
      <Box>
        {/* @ts-ignore */}
        <Button
          variant="outlined"
          size="small"
          disabled={isCampaignActionsDiasabled}
          endIcon={<CaretDown />}
          {...bindTrigger(CampaignActionsPopupState)}
          selected={CampaignActionsPopupState.isOpen}
        >
          Campaign Actions
        </Button>
      </Box>
      {/* @ts-ignore */}
      <Menu
        {...bindMenu(CampaignActionsPopupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            border: 1,
            borderColor: 'primary-600',
            marginTop: 2,
          },
          style: { width: '210px' },
        }}
      >
        <MenuItem
          onClick={() => {
            setAction('assignToCampaign', selectionData, {
              onSettle: () => {
                setRowSelection({})
              },
            })
            CampaignActionsPopupState.close()
          }}
          startIcon={<Copy sx={{ textColor: 'neutral-400' }} />}
        >
          Assign To Campaign
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAction('createCampaign', selectionData, {
              onSettle: () => {
                setRowSelection({})
              },
            })
            CampaignActionsPopupState.close()
          }}
          startIcon={<Copy sx={{ textColor: 'neutral-400' }} />}
        >
          Create Campaign
        </MenuItem>
      </Menu>
    </>
  )
}
