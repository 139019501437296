import { Typography } from '@applift/factor'
import {
  AUDIO_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../../../constants'

export interface CreativeSizeValueTypeProps {
  platformCreativeTypeId: number
  creativeHeight: number
  creativeWidth: number
  duration: number
  nativeMediaType?: string
}

function getImageSizeString(width: number, height: number) {
  if (width && height) {
    return `${width}px x ${height}px`
  }
  return '—'
}

function getVideoSizeString(duration?: number) {
  if (duration) {
    return `${duration}s`
  }
  return '—'
}

export const CreativeSizeValueType = (props: CreativeSizeValueTypeProps) => {
  const {
    creativeHeight,
    creativeWidth,
    platformCreativeTypeId,
    duration,
    nativeMediaType,
  } = props

  let value = ''

  switch (platformCreativeTypeId) {
    case VIDEO_CREATIVE_ID:
    case AUDIO_CREATIVE_ID:
      value = getVideoSizeString(duration as number)
      break
    case NATIVE_CREATIVE_ID:
      if (!nativeMediaType) {
        value = '—'
      }
      value =
        nativeMediaType?.toLowerCase() === 'video'
          ? getVideoSizeString(duration as number)
          : getImageSizeString(
              creativeWidth as number,
              creativeHeight as number
            )
      break
    default:
      value = getImageSizeString(
        creativeWidth as number,
        creativeHeight as number
      )
  }

  return (
    <Typography variant="bodySmall" sx={{ pl: 12 }}>
      {value}
    </Typography>
  )
}
