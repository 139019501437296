import { enqueueSnackbar } from '@applift/factor'
import { CSV_FORMAT, EXCEL_FORMATS } from '@applift/app-utils'
import { DropItem } from '@applift/drag-drop'
import { ErrorCSVType } from '../../../../../models'
import { ERROR_MESSAGES } from '../../../../../constants'
import { formatFileSize } from '../../../../../utils'

const CSV_UPLOAD_ERROR_MESSAGES = {
  UPLOAD_FAILED: 'File failed to upload. Please try again',
}

export const validateFile = (files: FileList | null) => {
  const errorFiles: ErrorCSVType[] = []
  const validFiles: File[] = []

  if (!files?.length || files === null) {
    enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.UPLOAD_FAILED, {
      variant: 'error',
    })
    return {}
  }
  for (const file of files!) {
    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    if (fileExtension !== 'csv' && fileExtension !== 'xlsx') {
      errorFiles.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
      })
      continue
    }
    if (!file.size) {
      errorFiles.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
      })
      continue
    }
    validFiles.push(file)
  }
  return { validFiles, errorFiles }
}

export const getValidDropItems = async (items: DropItem[]) => {
  const resultFiles: Promise<File>[] = []
  const invalidFiles: Promise<File>[] = []

  const allowedMimeTypes = [...EXCEL_FORMATS, CSV_FORMAT]
  for (const item of items) {
    if (item.kind === 'file') {
      if (allowedMimeTypes.indexOf(item.type) >= 0) {
        resultFiles.push(item.getFile())
      } else {
        invalidFiles.push(item.getFile())
      }
    }
  }
  const finalResult = await Promise.all(resultFiles)
  const errorFiles = await Promise.all(invalidFiles)

  const validFiles: File[] = []

  for (const file of finalResult) {
    if (file.size) {
      validFiles.push(file)
    } else {
      errorFiles.push(file)
    }
  }

  const errorType = errorFiles.map<ErrorCSVType>(one => ({
    'File Name': one.name,
    'File Size': formatFileSize(one.size),
    'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
  }))

  return { validFiles, errorType }
}
