import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface DuplicateCreativeGroupParams {
  groupId: number
}

interface DuplicateCreativeGroupResponse
  extends WithResponse<{ id: number; message: string }> {}

export const duplicateCreativeGroup = async (
  params: DuplicateCreativeGroupParams
) => {
  try {
    const res: AxiosResponse<DuplicateCreativeGroupResponse> =
      await getInstance().post(
        `/v3/crt/creatives/groups/duplicate/${params.groupId}`
      )

    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
