import { uploadAssets } from './assets'
import { CreateImageCreativeType } from '../models'
import { computeImageDimensions } from '../utils'

interface UploadImageCreativesToAssetsParamsType {
  files: File[]
}

export const uploadImageCreativesToAssets = async (
  params: UploadImageCreativesToAssetsParamsType
) => {
  try {
    const assets = await uploadAssets(params.files)
    const dimensionsPromises = []
    for (const [fileName, assetDetails] of Object.entries(assets)) {
      dimensionsPromises.push(computeImageDimensions(fileName, assetDetails))
    }
    const allResolvedImages = await Promise.allSettled(dimensionsPromises)
    const result = allResolvedImages
      .map(one => (one.status === 'fulfilled' ? one.value : false))
      .filter(Boolean) as CreateImageCreativeType[]

    return result
  } catch (error) {
    Promise.reject(error)
  }
}
