import React from 'react'
import { InlineEdit, TextField, Typography } from '@applift/factor'

import { CREATIVE_THIRD_PARTY_ID_LIMIT } from '../../../../../constants'
import { InlineEditAction } from '../../../CreativeTableActions'

export interface ThirdPartyIDValueTypeProps {
  creativeId: number
  thirdPartyId: string
  readonly?: boolean
  setAction: React.Dispatch<React.SetStateAction<InlineEditAction>>
}

export const ThirdPartyIDValueType = (props: ThirdPartyIDValueTypeProps) => {
  const { thirdPartyId, creativeId, setAction, readonly } = props

  const [updated3rdPartyId, setUpdated3rdPartyId] = React.useState(thirdPartyId)

  const handleValueChange = (val: string = '') => {
    if (val.trim().length > 0) {
      setUpdated3rdPartyId(val)
    } else {
      setUpdated3rdPartyId('')
    }
  }

  const handleSubmit = () => {
    setUpdated3rdPartyId(thirdPartyId)
    setAction({
      actionName: 'EDIT_THIRD_PARTY_ID',
      metaData: {
        id: creativeId,
        params: { externalCreativeId: updated3rdPartyId },
      },
    })
  }

  const [focused, setFocused] = React.useState(false)

  return readonly ? (
    <Typography sx={{ ml: 12 }}>{updated3rdPartyId}</Typography>
  ) : (
    <InlineEdit
      value={updated3rdPartyId ? updated3rdPartyId : focused ? '' : '–'}
      onChange={handleValueChange}
      onCancel={() => setUpdated3rdPartyId(thirdPartyId)}
      onConfirm={handleSubmit}
      validate={val => {
        if (val.length > CREATIVE_THIRD_PARTY_ID_LIMIT) {
          return `Character limit of ${CREATIVE_THIRD_PARTY_ID_LIMIT} is exceeded`
        }
      }}
    >
      {({ inputProps, inputRef }) => (
        <TextField
          fullWidth
          variant="outlinedDash"
          inputRef={node => {
            inputRef.current = node?.parentElement ?? node
          }}
          onBlur={() => setFocused(false)}
          {...inputProps}
          onFocus={e => {
            inputProps?.onFocus(e)
            setFocused(true)
          }}
        />
      )}
    </InlineEdit>
  )
}
