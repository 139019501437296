import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  sx,
  TextField,
  Tooltip,
  Typography,
} from '@applift/factor'
import { ChevronRight, Delete, InfoCircle, Video } from '@applift/icons'
import { VideoJSPlayer } from '@applift/platform-creatives'
import { useForm } from '@tanstack/react-form'
import * as React from 'react'
import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
} from '../../../../../constants'
import { VideoCreativeUploadType } from '../../../../../models'
import {
  clickUrlValidationFn,
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
  getVideoMetaData,
  trackingUrlValidationFn,
} from '../../../../../utils'
import { FieldLabelWithTooltip } from '../../../../common'
import { CreateProps } from './Create'
import { getTrackingUrlFeildNumber } from './helper'

export interface FileVideoCardProps
  extends Pick<CreateProps, 'onChange' | 'onRemove'> {
  /**
   * Temp creative id generated
   */
  id: string
  /**
   * Need to pass vastXml Code
   */
  videoUrl?: string
  /**
   * Name of the creative
   */
  name?: string
  /**
   * 3rd Party ID of the creative
   */
  thirdPartyID?: string
  /**
   * Click URL of the creative
   */
  clickURL?: string
  /**
   * Warning show warning state of the card
   * @default false
   */
  warning?: boolean
  /**
   * Custom Tracking - Impression Tracking URL
   */
  impressionURL?: string
  /**
   * Custom Tracking - Start Tracking URL
   */
  startURL?: string
  /**
   * Custom Tracking - 1st Quartile Tracking URL
   */
  firstQuartileURL?: string
  /**
   * Custom Tracking - Midpoint Tracking URL
   */
  midPointURL?: string
  /**
   * Custom Tracking - 3rd Quartile Tracking URL
   */
  thirdQuartileURL?: string
  /**
   * Custom Tracking - Complete Tracking URL
   */
  completeURL?: string
}

export const FileVideoCard = (props: FileVideoCardProps) => {
  const {
    id,
    videoUrl = '',
    name = '',
    thirdPartyID = '',
    clickURL = '',
    warning = false,
    impressionURL = '',
    startURL = '',
    firstQuartileURL = '',
    midPointURL = '',
    thirdQuartileURL = '',
    completeURL = '',
    onChange,
    onRemove,
  } = props

  const [expandedCustomTracking, setExpandedCustomTracking] = React.useState<
    string | false
  >(false)

  const [filledTrackingFeildsNumber, setFilledTrackingFeildNumber] =
    React.useState(0)

  const form = useForm({
    defaultValues: {
      id: id,
      warning: warning,
      name: name,
      videoUrl: videoUrl,
      thirdPartyID: thirdPartyID,
      clickURL: clickURL,
      impressionURL: impressionURL,
      startURL: startURL,
      firstQuartileURL: firstQuartileURL,
      midPointURL: midPointURL,
      thirdQuartileURL: thirdQuartileURL,
      completeURL: completeURL,
    },
  })

  React.useEffect(() => {
    form.validateAllFields('change').then(() => {
      const formData = {
        videoCreativeType: 'file' as VideoCreativeUploadType,
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
      setFilledTrackingFeildNumber(getTrackingUrlFeildNumber(formData))
    })
  }, [form, onChange])

  React.useEffect(() => {
    form.store.setState(state => ({
      ...state,
      values: {
        ...state.values,
        clickURL: clickURL,
      },
    }))
    form.validateAllFields('change').then(() => {
      const formData = {
        videoCreativeType: 'file' as VideoCreativeUploadType,
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [clickURL, form, onChange])

  const handleChangeCustomTracking =
    (panel: string) => (event: any, isExpanded: boolean) => {
      setExpandedCustomTracking(isExpanded ? panel : false)
    }

  const clearTrackingUrlField = () => {
    form.store.setState(state => ({
      ...state,
      values: {
        ...state.values,
        impressionURL: '',
        startURL: '',
        firstQuartileURL: '',
        midPointURL: '',
        thirdQuartileURL: '',
        completeURL: '',
      },
    }))
    form.validateAllFields('change').then(() => {
      const formData = {
        videoCreativeType: 'file' as VideoCreativeUploadType,
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
      setFilledTrackingFeildNumber(getTrackingUrlFeildNumber(formData))
    })
  }

  const haveTrackingValue = () => {
    return Boolean(
      form.state.values.impressionURL ||
        form.state.values.startURL ||
        form.state.values.firstQuartileURL ||
        form.state.values.midPointURL ||
        form.state.values.thirdQuartileURL ||
        form.state.values.completeURL
    )
  }

  // We are momozing the value so that when array is passed its ref remains the same and useEffect inside of video player is not called causing the jittering
  const sources = React.useMemo(() => {
    return (
      form.state.values.videoUrl && [
        {
          src: form.state.values.videoUrl,
          type: 'video/mp4',
        },
      ]
    )
  }, [form.state.values.videoUrl])

  return (
    <form
      style={{ height: '100%' }}
      onChange={() => {
        const formData = {
          videoCreativeType: 'file' as VideoCreativeUploadType,
          ...form.state.values,
          hasError: form.state.canSubmit === false,
        }
        onChange?.([formData])

        setFilledTrackingFeildNumber(getTrackingUrlFeildNumber(formData))
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          border: 1,
          borderRadius: 4,
          p: 16,
          pb: 24,
          gap: 16,
          alignItems: 'center',
          position: 'relative',
          borderColor: warning ? 'warning-200' : 'neutral-100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 8,
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              onRemove?.(id)
            }}
          >
            <Delete />
          </IconButton>
          {form.state.values.videoUrl === '' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: 'auto',
                border: 1,
                borderRadius: 4,
                bgColor: 'neutral-75',
              }}
              style={{ aspectRatio: '4 / 3' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: 75,
                  gap: 8,
                }}
              >
                <Video fontSize={24} sx={{ textColor: 'neutral-400' }} />
                <Typography sx={{ textColor: 'neutral-600' }}>
                  Video preview will be visible here
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                width: 100,
                height: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              style={{ aspectRatio: '4 / 3', objectFit: 'contain' }}
            >
              <VideoJSPlayer
                sources={sources}
                onLoadedMetaData={async ({ duration }) => {
                  if (duration) {
                    const meta = await getVideoMetaData({
                      URL: form.state.values.videoUrl,
                    })
                    form.store.setState(state => ({
                      ...state,
                      values: {
                        ...state.values,
                        duration: duration,
                        height: meta?.height,
                        width: meta?.width,
                      },
                    }))
                  }
                }}
              />
            </Box>
          )}
        </Box>
        <Divider color="secondary" sx={{ width: 100 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          {warning && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 100,
                px: 12,
                gap: 8,
              }}
            >
              <Typography
                variant="label"
                lineHeight="single-line"
                sx={{ textColor: 'warning-500' }}
              >
                Dimensions & video length don’t match the recommendations and
                may affect the campaign’s reach
              </Typography>
            </Box>
          )}
          <form.Field
            name="name"
            validators={{
              onChange: ({ value }) => creativeNameValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label="Name"
                variant="outlinedDash"
                placeholder="Enter Name"
                value={field.state.value}
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                    </>
                  ),
                }}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="thirdPartyID"
            validators={{
              onChange: ({ value }) => creativeThirdPartyIDValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label={
                  <Box
                    Component={'span'}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography variant="label">3rd Party ID</Typography>
                    <Tooltip
                      title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                      placement="top"
                      arrow
                    >
                      <InfoCircle
                        style={{
                          width: '1.2em',
                          height: '1.2em',
                          transform: 'translateY(-1px)',
                        }}
                        sx={{
                          textColor: 'neutral-400',
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                variant="outlinedDash"
                placeholder="Enter 3rd Party ID"
                value={field.state.value}
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value.length}/{CREATIVE_THIRD_PARTY_ID_LIMIT}
                    </>
                  ),
                }}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="clickURL"
            validators={{
              onChange: ({ value }) => clickUrlValidationFn(value, true),
            }}
          >
            {field => (
              <TextField
                label={
                  <FieldLabelWithTooltip
                    label="Click URL"
                    tooltip="URL that will open when someone clicks on your ad"
                  />
                }
                variant="outlinedDash"
                placeholder="Enter Click URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
                required
              />
            )}
          </form.Field>
          <Accordion
            expanded={expandedCustomTracking === 'panelCustomTracking'}
            onChange={handleChangeCustomTracking('panelCustomTracking')}
            sx={{
              width: 100,
              boxShadow: 0,
              border: 1,
              borderRadius: 4,
              borderColor: 'neutral-100',
            }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ChevronRight fontSize={18} />}
              classes={{
                content: sx({
                  alignItems: 'center',
                  justifyContent: 'between',
                  my: 4,
                }),
              }}
              sx={{
                flexDirection: 'row-reverse',
                gap: 12,
                textColor: 'neutral-500',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <Typography
                  lineHeight="single-line"
                  sx={{ textColor: 'neutral-1000' }}
                >
                  Custom Tracking
                </Typography>
                {/* Count */}
                <Typography
                  lineHeight="single-line"
                  sx={{ textColor: 'neutral-500' }}
                >
                  {filledTrackingFeildsNumber
                    ? `(${filledTrackingFeildsNumber})`
                    : ''}
                </Typography>
              </Box>
              {haveTrackingValue() && (
                <IconButton
                  onClick={clearTrackingUrlField}
                  color="secondary"
                  size="small"
                >
                  <Delete />
                </IconButton>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 100,
                  gap: 16,
                }}
              >
                <form.Field
                  name="impressionURL"
                  validators={{
                    onChange: ({ value }) => trackingUrlValidationFn(value),
                  }}
                >
                  {field => (
                    <TextField
                      label="Impression Tracking URL"
                      variant="outlinedDash"
                      placeholder="Enter Impression Tracking URL"
                      value={field.state.value}
                      onChange={e => {
                        const value = e.target.value
                        if (value.trim().length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={field.state.meta.errors[0] ?? ''}
                    />
                  )}
                </form.Field>
                <form.Field
                  name="startURL"
                  validators={{
                    onChange: ({ value }) => trackingUrlValidationFn(value),
                  }}
                >
                  {field => (
                    <TextField
                      label="Start Tracking URL"
                      variant="outlinedDash"
                      placeholder="Enter Start Tracking URL"
                      value={field.state.value}
                      onChange={e => {
                        const value = e.target.value
                        if (value.trim().length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={field.state.meta.errors[0] ?? ''}
                    />
                  )}
                </form.Field>
                <form.Field
                  name="firstQuartileURL"
                  validators={{
                    onChange: ({ value }) => trackingUrlValidationFn(value),
                  }}
                >
                  {field => (
                    <TextField
                      label="1st Quartile Tracking URL"
                      variant="outlinedDash"
                      placeholder="Enter 1st Quartile Tracking URL"
                      value={field.state.value}
                      onChange={e => {
                        const value = e.target.value
                        if (value.trim().length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={field.state.meta.errors[0] ?? ''}
                    />
                  )}
                </form.Field>
                <form.Field
                  name="midPointURL"
                  validators={{
                    onChange: ({ value }) => trackingUrlValidationFn(value),
                  }}
                >
                  {field => (
                    <TextField
                      label="Midpoint Tracking URL"
                      variant="outlinedDash"
                      placeholder="Enter Midpoint Tracking URL"
                      value={field.state.value}
                      onChange={e => {
                        const value = e.target.value
                        if (value.trim().length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={field.state.meta.errors[0] ?? ''}
                    />
                  )}
                </form.Field>
                <form.Field
                  name="thirdQuartileURL"
                  validators={{
                    onChange: ({ value }) => trackingUrlValidationFn(value),
                  }}
                >
                  {field => (
                    <TextField
                      label="3rd Quartile Tracking URL"
                      variant="outlinedDash"
                      placeholder="Enter 3rd Quartile Tracking URL"
                      value={field.state.value}
                      onChange={e => {
                        const value = e.target.value
                        if (value.trim().length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={field.state.meta.errors[0] ?? ''}
                    />
                  )}
                </form.Field>
                <form.Field
                  name="completeURL"
                  validators={{
                    onChange: ({ value }) => trackingUrlValidationFn(value),
                  }}
                >
                  {field => (
                    <TextField
                      label="Complete Tracking URL"
                      variant="outlinedDash"
                      placeholder="Enter Complete Tracking URL"
                      value={field.state.value}
                      onChange={e => {
                        const value = e.target.value
                        if (value.trim().length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={field.state.meta.errors[0] ?? ''}
                    />
                  )}
                </form.Field>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </form>
  )
}
