import { MutateOptions, useMutation } from '@tanstack/react-query'

import { assingToCampaign } from '../api/campaign'

type useAssignToCampaignMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof assingToCampaign>>,
  any,
  Parameters<typeof assingToCampaign>[0],
  unknown
>

export const useAssignToCampaign = (
  options: useAssignToCampaignMutationOptions
) => {
  return useMutation(assingToCampaign, {
    ...options,
    mutationKey: ['assignToCampaign'],
  })
}
