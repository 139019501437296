import { WithResponse } from '@applift/platform'
import { AxiosError, AxiosResponse } from 'axios'
import { getInstance } from './instance'

interface DuplicateCreativesParams {
  creativeIds: number[]
  clickUrl?: string
}

interface DuplicateCreativesResponse
  extends WithResponse<{ duplicatedCreativeIds: number[]; message: string }> {}

export const duplicateCreatives = async (params: DuplicateCreativesParams) => {
  try {
    const res: AxiosResponse<DuplicateCreativesResponse> =
      await getInstance().post('v3/crt/creatives/duplicate', params)
    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
