import {
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../constants'
import {
  Creative,
  CreativeCreationObject,
  CreativeSoutceType,
  ImageDetails,
  NativeCreativeGenerationData,
  NativeDetails,
  VideoDetails,
} from '../../models'
import { getVideoMetaData } from '../../utils'
import { validateXmlUrl } from './AddNativeCreativeDialog/CreateWrapper/Upload/Dropzone/utils'

export const generateNativeCreativeMapData = (
  creativeList: NativeCreativeGenerationData[]
) => {
  return creativeList.map(creative => {
    return [String(creative?.uniqueId), creative]
  }) as Iterable<readonly [string, NativeCreativeGenerationData]>
}

export const getDefaultrackingURLs = () => ({
  impressionTrackingUrl: '',
  startTrackingUrl: '',
  firstQuartileTrackingUrl: '',
  midpointTrackingTrackingUrl: '',
  thirdQuartileTrackingUrl: '',
  completeTrackingUrl: '',
})

export const getNewNative = (
  temporaryId: number
): NativeCreativeGenerationData => ({
  creativeName: '',
  titles: [''],
  descriptions: [''],
  clickURL: '',
  pixelURL: '',
  brandName: '',
  thirdPartyId: '',
  nativeType: 'image',
  imageObjects: [],
  videoObjects: [],
  uniqueId: crypto.randomUUID(),
  temporaryId,
  videoThumbnail: null,
  brandIcon: null,
  customTracking: getDefaultrackingURLs(),
  cta: null,
})

export function transformCreativeData(
  inputMap: Map<string, NativeCreativeGenerationData>
) {
  const result: Record<string, CreativeCreationObject> = {}

  inputMap.forEach((value, key) => {
    const {
      creativeName,
      titles,
      descriptions,
      clickURL,
      pixelURL,
      brandName,
      brandIcon,
      cta,
      nativeType,
      imageObjects,
      videoObjects,
      customTracking,
      videoThumbnail,
      thirdPartyId,
    } = value

    // Construct `nativeDetails`
    const nativeDetails: NativeDetails = {
      title: titles[0]?.trim() || '', // Use the first title
      description: descriptions[0]?.trim() || '', // Use the first description
      brandName: brandName?.trim() || '',
      brandIconSourceUrl: brandIcon?.src?.trim() || '',
      cta: cta
        ? {
            id: cta.isDefault ? Number(cta.value) : undefined,
            ctaText: cta.label?.trim(),
            defaultCta: cta.isDefault,
          }
        : undefined,

      brandIconHeight: brandIcon?.height ?? 0,
      brandIconWidth: brandIcon?.width ?? 0,
      ...(nativeType === 'video' && {
        thumbnailHeight: videoThumbnail?.height ?? 0,
        thumbnailWidth: videoThumbnail?.width ?? 0,
        thumbnailSourceUrl: videoThumbnail?.src?.trim() || '',
      }),
    }

    // Construct `imageDetails` if applicable
    const imageDetails: ImageDetails | undefined =
      nativeType === 'image' && imageObjects.length > 0
        ? {
            pixelUrl: pixelURL?.trim(),
            creativeWidth: imageObjects[0]?.width ?? 0,
            creativeHeight: imageObjects[0]?.height ?? 0,
          }
        : undefined

    // Construct `videoDetails` if applicable
    const videoDetails: VideoDetails | undefined =
      nativeType === 'video' && videoObjects.length > 0
        ? {
            duration: videoObjects[0]?.duration ?? 0,
            creativeHeight: videoObjects[0]?.height ?? 0,
            creativeWidth: videoObjects[0]?.width ?? 0,
            customTracking: {
              completeURL: customTracking.completeTrackingUrl?.trim(),
              firstQuartileURL: customTracking.firstQuartileTrackingUrl?.trim(),
              impressionURL: customTracking.impressionTrackingUrl?.trim(),
              midPointURL: customTracking.midpointTrackingTrackingUrl?.trim(),
              startUrl: customTracking.startTrackingUrl?.trim(),
              thirdQuartileURL: customTracking.thirdQuartileTrackingUrl?.trim(),
            },
          }
        : undefined

    // Add to result
    let creativeSource = ''
    let creativeSourceType: CreativeSoutceType = 'URL'

    if (nativeType === 'image') {
      creativeSource = imageObjects[0]?.src?.trim() ?? ''
    } else if (nativeType === 'video') {
      if (videoObjects?.[0]?.creativeSource?.length) {
        creativeSource = videoObjects[0].creativeSource
        creativeSourceType = 'VAST_XML'
      } else if (videoObjects?.[0]?.creativeSourceURL?.length) {
        creativeSource = videoObjects[0].creativeSourceURL?.trim()
        creativeSourceType = 'VAST_URL'
      } else if (videoObjects?.[0]?.src) {
        creativeSource = videoObjects[0].src?.trim()
        creativeSourceType = 'URL'
      }
    }

    result[key] = {
      creativeName: creativeName?.trim(),
      ...(thirdPartyId?.trim()
        ? { externalCreativeId: thirdPartyId?.trim() }
        : {}),
      platformCreativeTypeId: NATIVE_CREATIVE_ID,
      subMediaType: nativeType,
      creativeSource,
      creativeSourceType,
      clickUrl: clickURL,
      nativeDetails,
      imageDetails,
      videoDetails,
    }
  })

  return result
}

export function decodeHtmlEntities(encodedString: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(encodedString, 'text/html')
  return doc.documentElement.textContent
}

export const transformExistingCreative = async (
  data: Creative[],
  selectedIds: string[],
  idGeneratorRef: number
) => {
  const existingGroupData: NativeCreativeGenerationData[] = []
  await Promise.all(
    data.map(async item => {
      if (selectedIds.includes(`${item.creativeId}`)) {
        const uniqueCreativeId = crypto.randomUUID()
        const temporaryDisplayId = idGeneratorRef++
        const nativeObj: NativeCreativeGenerationData = {
          creativeName: item.creativeName,
          videoThumbnail: null,
          brandIcon: null,
          brandName: '',
          clickURL: item.clickUrl ?? '',
          cta: null,
          uniqueId: uniqueCreativeId,
          customTracking: {
            completeTrackingUrl: '',
            firstQuartileTrackingUrl: '',
            impressionTrackingUrl: '',
            midpointTrackingTrackingUrl: '',
            startTrackingUrl: '',
            thirdQuartileTrackingUrl: '',
          },
          descriptions: [''],
          imageObjects: [],
          videoObjects: [],
          nativeType:
            item.platformCreativeTypeId === IMAGE_CREATIVE_ID
              ? 'image'
              : 'video',
          pixelURL: item?.pixelUrl ?? '',
          temporaryId: temporaryDisplayId,
          thirdPartyId: '',
          titles: [''],
        }

        if (item.platformCreativeTypeId === IMAGE_CREATIVE_ID) {
          nativeObj.imageObjects.push({
            src: item.creativeSource,
            height: item.creativeHeight ?? 0,
            width: item.creativeWidth ?? 0,
            name: item.creativeName,
            parentId: uniqueCreativeId,
            temporaryMediaId: `${temporaryDisplayId}`,
          })
        } else if (item.platformCreativeTypeId === VIDEO_CREATIVE_ID) {
          const validCreative = await validateXmlUrl(
            decodeHtmlEntities(item?.creativeSource) ?? ''
          )
          const videoSrc = validCreative?.mediaFiles[0]?.fileURL
          const meta = await getVideoMetaData({ URL: videoSrc })

          if (validCreative) {
            nativeObj.videoObjects.push({
              creativeSource: decodeHtmlEntities(item?.creativeSource) ?? '',
              duration: meta?.duration ?? 0,
              height: meta?.height ?? 0,
              width: meta?.width ?? 0,
              name: item.creativeName,
              parentId: uniqueCreativeId,
              temporaryMediaId: `${temporaryDisplayId}`,
              creativeSourceURL: '',
              src: validCreative?.mediaFiles?.[0]?.fileURL,
            })
          }
        }

        existingGroupData.push(nativeObj)
      }
    })
  )
  return existingGroupData
}
