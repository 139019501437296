import React from 'react'

import { Box } from '@applift/factor'
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'

import { ActionPanel } from './ActionPanel'
import { CreativeGroup } from '../../../../api'
import { useCreativesList } from '../../../../hooks'
import { Creative, DateRangeType, ViewType } from '../../../../models'
import { Grid, Table } from '../../../common'
import { useActions } from '../GroupCreativeActions'

export interface GroupDetailsProps {
  group: CreativeGroup
  sorting: SortingState
  columnVisibility: VisibilityState
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  viewType: ViewType
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>
  colDef: ColumnDef<Creative>[]
  setExpandedGroupedCreativesCount: React.Dispatch<React.SetStateAction<number>>
  statusIds?: number[]
  dateRange: DateRangeType
  search: string
  organizationTimezoneName?: string
}

export const GroupDetails = (props: GroupDetailsProps) => {
  const {
    group,
    colDef,
    viewType,
    columnVisibility,
    setColumnVisibility,
    setSorting,
    statusIds,
    sorting,
    setExpandedGroupedCreativesCount,
    search,
    dateRange,
    organizationTimezoneName,
  } = props

  const { setAction } = useActions({ groupId: group.creativeGroupId })

  // Only one item can be sorted at a time
  const sortBy = sorting.reduce((prev, curr) => {
    return [...prev, curr.desc ? `-${curr.id}` : `+${curr.id}`]
  }, [] as string[])[0]

  const { data, isError, isFetching, isLoading, fetchNextPage } =
    useCreativesList({
      creativeGroupId: group.creativeGroupId,
      creativeStatusIds: statusIds,
      sortBy,
      searchField: search,
      startTime: dateRange.startDate,
      endTime: dateRange.endDate,
    })

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

  const flatData = React.useMemo(
    () =>
      data?.pages?.reduce<Creative[]>(
        (prev, curr) => [...prev, ...curr.filteredList],
        []
      ) ?? [],
    [data]
  )

  React.useEffect(() => {
    setExpandedGroupedCreativesCount(flatData.length)
  }, [flatData.length, setExpandedGroupedCreativesCount])

  const filteredRecords = data?.pages?.[0]?.filteredRecords || 0

  const getRowId = (row: Creative) => String(row.creativeId)

  const selectionData = React.useMemo(
    () => flatData.filter(row => rowSelection[row.creativeId]),
    [rowSelection, flatData]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderColor: 'neutral-100',
        height: 100,
      }}
    >
      <ActionPanel
        filteredRecords={filteredRecords}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        selectionData={selectionData}
        {...group}
      />
      {viewType === 'table' ? (
        <Table
          colDef={colDef}
          data={flatData}
          sorting={sorting}
          isError={isError}
          getRowId={getRowId}
          setAction={setAction}
          setSorting={setSorting}
          rowSelection={rowSelection}
          fetchNextPage={fetchNextPage}
          loading={isFetching || isLoading}
          filteredRecords={filteredRecords}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          organizationTimezoneName={organizationTimezoneName}
        />
      ) : (
        <Grid
          data={flatData}
          isError={isError}
          getRowId={getRowId}
          setAction={setAction}
          rowSelection={rowSelection}
          filteredRecords={filteredRecords}
          isLoading={isLoading}
          isFetching={isFetching}
          setRowSelection={setRowSelection}
          fetchNextPage={fetchNextPage}
          organizationTimezoneName={organizationTimezoneName}
        />
      )}
    </Box>
  )
}
