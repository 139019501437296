import { TypoTooltip, SxProps } from '@applift/factor'
import { fromAbsolute, getLocalTimeZone } from '@internationalized/date'
import { dateFormat } from '../../utils/format'

interface DateCellType {
  /**
   * date should be in unix millisecond format.
   */
  date: number
  timezone?: string
  sx?: SxProps
}

export const DateCell = ({ date, sx, timezone }: DateCellType) => {
  const zonedTime = timezone?.length
    ? dateFormat(fromAbsolute(date, timezone).toDate(), {
        timeZone: timezone,
      })
    : dateFormat(fromAbsolute(date, getLocalTimeZone()).toDate(), {
        timeZone: getLocalTimeZone(),
      })

  return date != null && date !== 0 ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        sx,
      }}
    >
      {zonedTime}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
