import { DropItem } from '@applift/drag-drop'

import { verifySize, allowedMimeTypes } from '../../../../../utils/image'
import { ErrorCSVType } from '../../../../../models'
import { ERROR_MESSAGES } from '../../../../../constants'
import { errorFileToast, formatFileSize } from '../../../../../utils'

export const getValidImagePickerItems = (
  files: File[],
  closeSnackbar: (key?: any | undefined) => void
) => {
  const errorType: ErrorCSVType[] = []
  const validFiles: File[] = []
  for (const file of files) {
    if (verifySize(file)) {
      validFiles.push(file)
    } else {
      errorType.push({
        'File Name': file.name,
        'File Size': formatFileSize(file.size),
        'Reason for Rejection':
          allowedMimeTypes.indexOf(file.type) >= 0
            ? ERROR_MESSAGES.SIZE_EXCEED
            : ERROR_MESSAGES.UNSUPPORTED,
      })
    }
  }
  if (errorType.length > 0) {
    errorFileToast(errorType, closeSnackbar, 'audio_thumbmail_error_file')
  }
  return validFiles
}

export const getValidAudioThumbnailDropItems = async (
  items: DropItem[],
  closeSnackbar: (key?: any | undefined) => void
) => {
  const resultFiles: Promise<File>[] = []
  const invalidFiles: Promise<File>[] = []
  for (const item of items) {
    if (item.kind === 'file') {
      if (allowedMimeTypes.indexOf(item.type) >= 0) {
        resultFiles.push(item.getFile())
      } else {
        invalidFiles.push(item.getFile())
      }
    }
  }
  const finalResult = await Promise.all(resultFiles)
  const errorFiles = await Promise.all(invalidFiles)

  const validFiles: File[] = []

  for (const file of finalResult) {
    if (verifySize(file)) {
      validFiles.push(file)
    } else {
      errorFiles.push(file)
    }
  }

  const errorType = errorFiles.map<ErrorCSVType>(one => ({
    'File Name': one.name,
    'File Size': formatFileSize(one.size),
    'Reason for Rejection':
      allowedMimeTypes.indexOf(one.type) >= 0
        ? ERROR_MESSAGES.SIZE_EXCEED
        : ERROR_MESSAGES.UNSUPPORTED,
  }))

  if (errorType.length > 0) {
    errorFileToast(errorType, closeSnackbar, 'audio_thumbmail_error_file')
  }

  return validFiles
}
