import {
  Box,
  TypoTooltip,
  SvgIconProps,
  TypographyProps,
  Tooltip,
} from '@applift/factor'
import { getIcon, IconNamesTypes } from '../../registry'

interface IconTextCellType {
  iconName?: IconNamesTypes | string
  iconSize?: SvgIconProps['fontSize']
  iconTooltip?: string
  label: string
  position?: 'after' | 'before'
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const IconTextCell = ({
  label,
  iconSize,
  iconName,
  iconTooltip,
  position = 'before',
  textTypographyProps,
}: IconTextCellType) => {
  const icon =
    iconName && iconSize
      ? getIcon(iconName as any, { fontSize: iconSize })
      : null
  return (
    <Box
      sx={{
        width: 100,
        display: 'flex',
        alignItems: 'center',
        ...(position === 'after' ? { flexDirection: 'row-reverse' } : {}),
      }}
    >
      <Tooltip title={iconTooltip} arrow placement="top">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{icon}</Box>
      </Tooltip>
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
          sx: {
            flexGrow: 1,
            ...(position === 'after' ? { mr: 4 } : { ml: 4 }),
            ...textTypographyProps?.sx,
          },
        }}
      >
        {label}
      </TypoTooltip>
    </Box>
  )
}
