import axios, { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext } from '@tanstack/react-query'
import { getTimezoneListKey } from './QueryKeys'
import { Timezone, WithRecordResponse } from '../models'
import { getInstance } from './instance'

export const normalizeTimezoneList = (data?: Timezone[]) => {
  const newData = data?.reduce(
    (acc, one) => {
      acc[one.id] = one
      return acc
    },
    {} as Record<Timezone['id'], Timezone>
  )
  return newData
}

export const getTimezoneList = async ({
  signal,
}: QueryFunctionContext<ReturnType<(typeof getTimezoneListKey)['keys']>>) => {
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel(`/v3/master/timezones - Request cancelled`)
  })
  try {
    const response: AxiosResponse<WithRecordResponse<Timezone[]>> =
      await getInstance().get('/v3/master/timezones', {
        cancelToken: source.token,
      })

    return normalizeTimezoneList(response?.data?.data?.data)
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data || e)
  }
}
