import { DropItem } from '@applift/drag-drop'
import { enqueueSnackbar } from '@applift/factor'
import { validateVASTURL, validateVASTXML } from '@applift/platform-creatives'
import { read, utils } from 'xlsx'
import { ErrorCSVType, ErrorType } from '../../../../../models'
import {
  ALLOWED_EXCEL_FORMATS,
  formatFileSize,
  isValidURL,
  readFileAsArrayBuffer,
} from '../../../../../utils'
import { allowedVideoMIMETypes, allowedVideoMimeTypesSize } from './utiles'
import { ERROR_MESSAGES } from '../../../../../constants'

export type ValidFiles = File[]
export type ValidUrls = { url: string; name: string }[]
export type ValidXmls = { xml: string; name: string }[]
export type ErrorObjects = ErrorType[]

type TVASTCreativeRow = {
  Name: string
  VAST: string
  tempCreativeId: number
  [key: string]: any
}

export const isValidateXmlOrUrl = async (xmlUrl: string) => {
  let validateResult = null
  // let validResultCreative = null

  const isVASTurl = isValidURL(xmlUrl)
  if (isVASTurl) {
    validateResult = await validateVASTURL(xmlUrl)
  } else {
    validateResult = await validateVASTXML(xmlUrl)
  }

  return validateResult.data?.isValid
}

export const validateVideoFileUpload = async (files: File[]) => {
  const validFiles: ValidFiles = []
  const validUrls: ValidUrls = []
  const validXmls: ValidXmls = []
  const errorObjects: ErrorCSVType[] = []

  if (!files?.length || files === null) {
    enqueueSnackbar('File failed to upload. Please try again', {
      variant: 'error',
    })
    return {}
  }

  await Promise.all(
    Array.from(files).map(async file => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase()
      const allowedExtension = ['csv', 'xlsx', 'xls', 'mp4', 'mov']
      if (fileExtension && allowedExtension.indexOf(fileExtension) === -1) {
        errorObjects.push({
          'File Name': file.name,
          'File Size': formatFileSize(file.size),
          'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
        })
        return
      }
      if (!file.size) {
        errorObjects.push({
          'File Name': file.name,
          'File Size': formatFileSize(file.size),
          'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
        })
        return
      }
      // Strip any parameters from the file type
      const cleanFileType =
        file.type?.split(';')?.[0]?.trim().toLowerCase() || file.type
      if (allowedVideoMIMETypes.indexOf(cleanFileType) >= 0) {
        const isCorrectSize =
          file.size >
          allowedVideoMimeTypesSize[
            cleanFileType as keyof typeof allowedVideoMimeTypesSize
          ]
            ? false
            : true

        if (isCorrectSize) {
          validFiles.push(file)
        } else if (isCorrectSize === false) {
          errorObjects.push({
            'File Name': file.name,
            'File Size': formatFileSize(file.size),
            'Reason for Rejection': ERROR_MESSAGES.SIZE_EXCEED,
          })
        }
      } else if (ALLOWED_EXCEL_FORMATS.includes(cleanFileType)) {
        const buffer = await readFileAsArrayBuffer(file)
        const workbook = read(buffer, { type: 'array' })
        //constant
        const allowedHeaders = ['Creative Name', 'VAST/VAST URL']
        for (const sheetName of workbook.SheetNames) {
          const sheet = workbook.Sheets[sheetName]
          let rows: TVASTCreativeRow[] = []
          if (sheet) {
            rows = utils.sheet_to_json(sheet)?.map((r: any) => ({
              ...r,
              tempCreativeId: crypto.randomUUID(),
            }))
          }

          const headerKeys = rows[1]
          const headers = headerKeys && Object.keys(headerKeys)

          const headerRowKeys =
            headers &&
            headers.reduce(
              (
                headerRowKeys: { rawKey: string; key: string }[],
                rawKey: string
              ) => {
                const formattedKey = rawKey.trim().toLowerCase()
                const key = allowedHeaders.find(
                  key => key.toLowerCase() === formattedKey
                )
                if (key) {
                  headerRowKeys.push({
                    rawKey,
                    key,
                  })
                }
                return headerRowKeys
              },
              []
            )
          if (
            !headerKeys ||
            !headerKeys['VAST/VAST URL'] ||
            !headerKeys['Creative Name']
          ) {
            errorObjects.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
            })
            return
          }

          if (!headerRowKeys || !headerRowKeys.length) {
            errorObjects.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
            })
            return
          } else {
            for (const row of rows) {
              let formattedRow: TVASTCreativeRow | null = null
              headerRowKeys.forEach(headerRowKey => {
                const rowValue: any = row[headerRowKey.rawKey]
                if (rowValue) {
                  if (!formattedRow) {
                    formattedRow = {} as TVASTCreativeRow
                  }
                  formattedRow[headerRowKey.key] = rowValue
                }
              })
              if (formattedRow) {
                const xmlURL = formattedRow['VAST/VAST URL']
                const isURL = isValidURL(xmlURL)
                const isValid = await isValidateXmlOrUrl(xmlURL)

                if (isURL && isValid) {
                  validUrls.push({
                    url: formattedRow['VAST/VAST URL'],
                    name: formattedRow['Creative Name'],
                  })
                } else if (isValid) {
                  validXmls.push({
                    xml: formattedRow['VAST/VAST URL'],
                    name: formattedRow['Creative Name'],
                  })
                } else {
                  errorObjects.push({
                    'File Name': file.name,
                    'File Size': formatFileSize(file.size),
                    'Reason for Rejection': 'invalid XML/URL',
                  })
                }
              }
            }
          }
        }
      }
    })
  )

  return {
    validFiles,
    validUrls,
    validXmls,
    errorObjects,
  }
}

export const getValidDropItems = async (items: DropItem[]) => {
  const resultFiles: Promise<File>[] = []
  const invalidFiles: Promise<File>[] = []

  const allowedMimeTypes = [...ALLOWED_EXCEL_FORMATS, ...allowedVideoMIMETypes]
  for (const item of items) {
    if (item.kind === 'file') {
      if (allowedMimeTypes.indexOf(item.type) >= 0) {
        resultFiles.push(item.getFile())
      } else {
        invalidFiles.push(item.getFile())
      }
    }
  }
  const finalResult = await Promise.all(resultFiles)
  const errorFiles = await Promise.all(invalidFiles)
  const errorType = errorFiles.map<ErrorCSVType>(one => ({
    'File Name': one.name,
    'File Size': formatFileSize(one.size),
    'Reason for Rejection': ERROR_MESSAGES.UNSUPPORTED,
  }))

  if (finalResult?.length > 0) {
    const { validFiles, validUrls, validXmls, errorObjects } =
      await validateVideoFileUpload(finalResult)

    return {
      validFiles,
      validUrls,
      validXmls,
      errorObjects: [
        ...(errorObjects?.length ? errorObjects : []),
        ...(errorType.length ? errorType : []),
      ],
    }
  } else {
    return {
      validFiles: [],
      validUrls: [],
      validXmls: [],
      errorObjects: [...(errorType.length ? errorType : [])],
    }
  }
}
