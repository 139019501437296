import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { modifyGroup, scopeTypes } from '../api'

type useModifyGroupMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof modifyGroup>>,
  any,
  Parameters<typeof modifyGroup>[0],
  unknown
>

export const useModifyGroup = (options: useModifyGroupMutationOptions) => {
  const { onSuccess, ...other } = options
  const queryClient = useQueryClient()

  return useMutation(modifyGroup, {
    onSuccess: (...params) => {
      // Refetching the creatives list
      queryClient.resetQueries({
        predicate: (query: any) =>
          (query.queryKey?.[0]?.scope as scopeTypes) ===
            'getCreativeGroupsList' ||
          (query.queryKey?.[0]?.scope as scopeTypes) === 'getAllCreativeList',
      })
      onSuccess?.(...params)
    },
    ...other,
    mutationKey: ['modifyGroup'],
  })
}
