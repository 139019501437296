import { MutateOptions, useMutation } from '@tanstack/react-query'

import { uploadAssets } from '../api/assets'

type useAssignToCampaignMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof uploadAssets>>,
  any,
  Parameters<typeof uploadAssets>[0],
  unknown
>

export const useAssetUpload = (
  options?: useAssignToCampaignMutationOptions
) => {
  const mutationResult = useMutation(uploadAssets, {
    mutationKey: ['uploadAssets'],
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    onSettled: options?.onSettled,
  })

  return mutationResult
}
