import { AxiosError, AxiosResponse } from 'axios'
import { CreativeCreationObject, WithResponse } from '../models'
import { getInstance } from './instance'

export interface CreateCreativeResponse {
  failedData: Record<string, string>
  successData: Record<string, string>
}

export type CreateCreativeRequest = Record<string, CreativeCreationObject>

// could be use to create creatives except Native
export const createCreative = async (request: CreateCreativeRequest) => {
  const formData = new FormData()

  formData.append('creativeRequest', JSON.stringify(request))
  try {
    const response: AxiosResponse<WithResponse<CreateCreativeResponse>> =
      await getInstance().post('/v3/crt/creatives', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    return response.data.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}
