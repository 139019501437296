import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import { Box, Divider, Typography, IconButton } from '@applift/factor'
import { AudioPlayer } from '@applift/platform-creatives'
import { Delete } from '@applift/icons'

import { AudioCreationFormType, CreateProps } from '..'
import {
  AudioCreativeGenerationData,
  AudioCreativeUploadType,
} from '../../../../../../models'
import { CustomTrackingFields } from './CustomTrackingFields'
import { AudioThumbnail } from './AudioThumbnail'
import { AudioBasicInfo } from './AudioBasicInfo'
import { AUDIO_RECOMMENDED_DURATIONS } from '../../../../../../constants'

export interface FileAudioCardProps
  extends Pick<CreateProps, 'onChange' | 'onRemove'> {
  /**
   * Audio Creative
   */
  creative: AudioCreativeGenerationData
}

export const FileAudioCard = (props: FileAudioCardProps) => {
  const { onChange, onRemove, creative } = props

  const form: AudioCreationFormType = useForm<AudioCreativeGenerationData>({
    defaultValues: {
      ...creative,
      audioCreativeType: 'URL' as AudioCreativeUploadType,
      logoSource: creative.logoSource ?? '',
    },
  })

  const updateMasterList = React.useCallback(() => {
    form.validateAllFields('change').then(() => {
      const formData = {
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [form, onChange])

  React.useEffect(() => {
    updateMasterList()
  }, [updateMasterList])

  React.useEffect(() => {
    form.store.setState(state => ({
      ...state,
      values: {
        ...state.values,
        clickURL: creative.clickURL,
      },
    }))
    updateMasterList()
  }, [creative.clickURL, form, onChange, updateMasterList])

  const showWarning =
    Boolean(form.state.values.duration) &&
    AUDIO_RECOMMENDED_DURATIONS.includes(
      form.state.values.duration as number
    ) === false

  return (
    <form style={{ height: '100%' }} onChange={updateMasterList}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          border: 1,
          borderRadius: 4,
          p: 16,
          pb: 24,
          gap: 16,
          alignItems: 'center',
          position: 'relative',
          borderColor: showWarning ? 'warning-200' : 'neutral-100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 8,
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => onRemove?.(creative.id)}
          >
            <Delete />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              width: 100,
              height: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ minHeight: '64px' }}
          >
            {/* @ts-ignore */}
            <AudioPlayer src={form.state.values.creativeSource} />
          </Box>
        </Box>

        <AudioThumbnail updateMasterList={updateMasterList} form={form} />

        <Divider color="secondary" sx={{ width: 100 }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          {showWarning && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 100,
                px: 12,
                gap: 8,
              }}
            >
              <Typography
                variant="label"
                lineHeight="single-line"
                sx={{ textColor: 'warning-500' }}
              >
                Audio length doesn’t match the recommendations and may affect
                the campaign’s reach
              </Typography>
            </Box>
          )}

          <AudioBasicInfo form={form} />
          <CustomTrackingFields
            form={form}
            creative={creative}
            updateMasterList={updateMasterList}
          />
        </Box>
      </Box>
    </form>
  )
}
