import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  enqueueSnackbar,
  LoadingButton,
  Typography,
} from '@applift/factor'
import { AlertTriangle, CreativeNative } from '@applift/icons'
import * as React from 'react'

import { useForm } from '@tanstack/react-form'
import { useCompletionState, useCreateCreative } from '../../../hooks'
import { NativeCreativeGenerationData } from '../../../models'
import { noop } from '../../../utils'
import { generateNativeCreativeMapData, transformCreativeData } from '../helper'
import { CreateWrapper } from './CreateWrapper'
import { UploadingCreatives } from '../../common'
import { scopeTypes } from '../../../api'
import { useQueryClient } from '@tanstack/react-query'

interface AddNativeCreativeDialogProps extends DialogProps {
  onSuccess?: (response?: any, request?: any) => void
  onError?: (error?: any, request?: any) => void
  onSettle?: () => void
  initData: NativeCreativeGenerationData[]
  actionText?: string
  dialogTitle?: string
}

export const AddNativeCreativeDialog = (
  props: AddNativeCreativeDialogProps
) => {
  const {
    open,
    onClose,
    onSuccess,
    onError,
    initData = [],
    actionText = 'Create',
    dialogTitle = 'Native Creatives',
    ...others
  } = props

  //  ToDo to remove
  // eslint-disable-next-line
  const [hasError, setHasError] = React.useState<boolean>(false)

  const [isCreateClicked, setCreateClicked] = React.useState(false)
  const [selectedItemId, setSelectedItemId] = React.useState<null | string>(
    null
  )
  const [nativeState, setNativeState] = React.useState(
    new Map<string, NativeCreativeGenerationData>(
      generateNativeCreativeMapData([...initData])
    )
  )

  const updateCreativesBulk = React.useCallback(
    (creatives: NativeCreativeGenerationData[]) => {
      setNativeState(prevMap => {
        const newMap = new Map(prevMap)
        creatives.forEach(one => {
          newMap.set(one.uniqueId, one)
        })
        return newMap
      })
    },
    [setNativeState]
  )

  const removeCreative = React.useCallback(
    (id: string) => {
      setNativeState(prevMap => {
        const newMap = new Map(prevMap)
        newMap.delete(id)
        if (newMap?.size <= 0) {
          setCreateClicked(false)
        }
        return newMap
      })
    },
    [setNativeState]
  )

  const form = useForm<NativeCreativeGenerationData>()

  const completionState = useCompletionState(nativeState)
  React.useEffect(() => {
    if (initData && initData?.[0]) {
      const creative = initData?.[0]
      setSelectedItemId(creative?.uniqueId)
      form.store.setState(prev => {
        return {
          ...prev,
          values: creative,
        }
      })
    }
  }, [form.store, initData])

  const queryClient = useQueryClient()

  const { mutate, isLoading } = useCreateCreative({
    onSuccess: (response, request) => {
      onClose?.({}, 'escapeKeyDown')
      onSuccess?.(response, request)
      queryClient.refetchQueries({
        predicate: query =>
          (query.queryKey?.[0] as { scope?: scopeTypes })?.scope ===
          'getCreativeTypeCount',
      })
    },
    onError: (error, request) => {
      onClose?.({}, 'escapeKeyDown')
      enqueueSnackbar({
        // @ts-ignore
        message: error?.errorObjects?.[0]?.error || 'Something went wrong',
        variant: 'error',
      })
      onError?.(error, request)
    },
  })

  const onCreateClick = () => {
    form.handleSubmit()
    setCreateClicked(true)
    if (
      completionState.missingRequiredValueIds.length > 0 ||
      completionState.errorValueIds.length > 0
    ) {
      return
    }
    const data = transformCreativeData(nativeState)
    mutate(data)
  }

  const errorIds = React.useMemo(() => {
    const combinedIds = isCreateClicked
      ? [
          ...completionState.missingRequiredValueIds,
          ...completionState.errorValueIds,
        ]
      : completionState.errorValueIds

    return Array.from(new Set(combinedIds))
  }, [isCreateClicked, completionState])

  return (
    <>
      <Dialog
        fullScreen="bleed"
        open={open}
        onClose={isLoading ? noop : onClose}
        {...others}
      >
        <DialogTitle
          onClose={isLoading ? noop : e => onClose?.(e, 'escapeKeyDown')}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Box
              sx={{
                display: 'flex',
                fontSize: 32,
                textColor: 'primary-600',
              }}
            >
              <CreativeNative />
            </Box>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {isLoading && <UploadingCreatives />}
          {isLoading === false && (
            <CreateWrapper
              data={nativeState}
              onChange={updateCreativesBulk}
              onRemove={removeCreative}
              onError={setHasError}
              errorIds={errorIds}
              form={form}
              isCreateClicked={isCreateClicked}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex' }}>
          <Box
            sx={{
              display: isCreateClicked && errorIds.length > 0 ? 'flex' : 'none',
              alignItems: 'center',
            }}
          >
            <AlertTriangle
              sx={{ fontSize: 24, textColor: 'danger-500', mr: 4 }}
            />
            <Typography sx={{ textColor: 'danger-500' }}>
              Review and fix errors in {errorIds.length} Creative
              {errorIds.length > 1 ? 's' : ''}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 16, ml: 'auto' }}>
            <Button
              disabled={isLoading}
              color="secondary"
              onClick={e => onClose?.(e, 'escapeKeyDown')}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={nativeState.size <= 0}
              loading={isLoading}
              onClick={onCreateClick}
            >
              {actionText}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}
