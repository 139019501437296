import * as React from 'react'
import {
  Box,
  Button,
  Tooltip,
  Typography,
  enqueueSnackbar,
  useSnackbar,
} from '@applift/factor'
import { Add } from '@applift/icons'

import { NativeCreativeGenerationData } from '../../../../../models'
import { getNewNative } from '../../../helper'
import { MAX_NATIVES_COUNT_PER_CREATION } from '../../../../../constants'
import { CreativeList } from './CreativeList'
import { processCSVFile } from './helper'
import { MiniDropzone, Dropzone } from '../Upload'
import { queryClient } from '../../../../../cache'
import SidebarSkeleton from './SidebarSkeleton'

interface Props {
  creativeList: NativeCreativeGenerationData[]
  idGeneratorRef: React.MutableRefObject<number>
  onChange?: (data: NativeCreativeGenerationData[]) => void
  selectedItemId: string | null
  onRemove?: (id: string) => void
  onSelect: (creative: NativeCreativeGenerationData | null) => void
  errorIds: string[]
}
type CTA = {
  value: string
  label: string
  isDefault: boolean
}

export const Sidebar = (props: Props) => {
  const {
    creativeList,
    onChange,
    selectedItemId,
    idGeneratorRef,
    onRemove,
    onSelect,
    errorIds,
  } = props

  const { closeSnackbar } = useSnackbar()

  const [isCsvLoading, setIsCsvLoading] = React.useState(false)

  const addNewNative = () => {
    const canAddNewItem =
      creativeList.length + 1 <= MAX_NATIVES_COUNT_PER_CREATION
    if (canAddNewItem) {
      const newItem = getNewNative(idGeneratorRef.current++)
      onChange?.([newItem])
      if (creativeList.length === 0) {
        onSelect(newItem)
      }
    } else {
      enqueueSnackbar(
        `Cannot add more than ${MAX_NATIVES_COUNT_PER_CREATION} creatives`,
        { variant: 'error' }
      )
    }
  }

  const generateId = () => idGeneratorRef.current++

  const onUpload = async (fileList: FileList | null | File[]) => {
    setIsCsvLoading(true)
    if (fileList) {
      const validCreatives = await processCSVFile(
        fileList,
        creativeList.length,
        generateId,
        closeSnackbar
      )

      queryClient.setQueriesData(
        {
          predicate: (query: any) => {
            return query.queryKey?.[0]?.scope === 'getNativeCTAOptions'
          },
        },
        (oldData?: CTA[]) => {
          if (oldData) {
            const newCtas: CTA[] = []
            for (const item of validCreatives) {
              const currentCta = item?.cta
              if (!currentCta) continue // Skip if no CTA exists.
              const matchingDefaultCTA = oldData?.find(
                cta => cta?.label === currentCta.label
              )
              const matchingNewCTA = newCtas.find(
                cta => cta?.label === currentCta.label
              )
              if (matchingDefaultCTA) {
                item.cta = matchingDefaultCTA // Update item with the found default CTA.
              } else if (matchingNewCTA) {
                item.cta = matchingNewCTA // Update item with the found new CTA.
              } else {
                newCtas.push(currentCta) // Add to notFound array if no match is found.
              }
            }
            return [...oldData, ...newCtas]
          } else {
            const newCtas: CTA[] = []
            for (const item of validCreatives) {
              const currentCta = item?.cta
              if (!currentCta) continue // Skip if no CTA exists.

              const matchingNewCTA = newCtas.find(
                cta => cta?.label === currentCta.label
              )
              if (matchingNewCTA) {
                item.cta = matchingNewCTA // Update item with the found new CTA.
              } else {
                newCtas.push(currentCta) // Add to notFound array if no match is found.
              }
            }
            return [...newCtas]
          }
        }
      )
      if (validCreatives?.length) {
        onChange?.(validCreatives)
        onSelect(validCreatives[0] as NativeCreativeGenerationData)
      }
      setIsCsvLoading(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 100 }}>
      <Box sx={{ display: 'flex', justifyContent: 'between', px: 16, py: 12 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ textWeight: 'demi', textColor: 'neutral-600' }}
            variant="bodyMedium"
            gutterBottom={false}
          >
            Creatives
          </Typography>
          <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
            ({creativeList.length}/{MAX_NATIVES_COUNT_PER_CREATION})
          </Typography>
        </Box>
        <Tooltip
          title={
            creativeList?.length >= 200
              ? 'Maximum limit of 200 creatives is reached'
              : ''
          }
          placement="top"
        >
          <Box>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disabled={creativeList?.length >= 200}
              onClick={addNewNative}
              startIcon={<Add />}
            >
              Add
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: creativeList.length > 0 || isCsvLoading ? undefined : 'none',
          overflowY: 'auto',
        }}
      >
        <CreativeList
          onSelect={onSelect}
          idGeneratorRef={idGeneratorRef}
          creativeList={creativeList}
          onChange={onChange}
          onRemove={onRemove}
          selectedItemId={selectedItemId}
          errorIds={errorIds}
        />
        {new Array(isCsvLoading ? 10 : 0).fill(null).map((_, index) => (
          <SidebarSkeleton key={`skeleton_${index}`} />
        ))}
      </Box>
      <Box
        sx={{
          display: creativeList.length > 0 ? undefined : 'none',
          mt: 'auto',
        }}
      >
        <MiniDropzone onUpload={onUpload} setIsCsvLoading={setIsCsvLoading} />
      </Box>
      {isCsvLoading ? null : (
        <Box
          sx={{
            display: creativeList.length === 0 ? undefined : 'none',
            flexGrow: 1,
            mx: 12,
            my: 16,
          }}
        >
          <Dropzone onUpload={onUpload} setIsCsvLoading={setIsCsvLoading} />
        </Box>
      )}
    </Box>
  )
}
