export const CREATIVE_NAME_LIMIT = 255
export const CREATIVE_GROUP_NAME_LIMIT = 255
export const CREATIVE_THIRD_PARTY_ID_LIMIT = 200

export const IMAGE_CREATIVE_ID = 11
export const HTML_CREATIVE_ID = 13
export const VIDEO_CREATIVE_ID = 14
export const NATIVE_CREATIVE_ID = 15
export const AUDIO_CREATIVE_ID = 17

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500

export const CREATIVE_SECTION = {
  ALL: 'ALL',
  UNGROUPED: 'UNGROUPED',
  GROUPED: 'GROUPED',
}

export const CAMPAIGN_TYPE_BY_NAME = {
  ADVANCED: 1,
  PG: 2,
} as const

export const BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
} as const

export const routeToCreativeTypeIdMapper: { [key: string]: number[] } = {
  all: [
    IMAGE_CREATIVE_ID,
    VIDEO_CREATIVE_ID,
    NATIVE_CREATIVE_ID,
    AUDIO_CREATIVE_ID,
    HTML_CREATIVE_ID,
  ],
  image: [IMAGE_CREATIVE_ID],
  native: [NATIVE_CREATIVE_ID],
  video: [VIDEO_CREATIVE_ID],
  audio: [AUDIO_CREATIVE_ID],
  html: [HTML_CREATIVE_ID],
}

export const IO_STATUS_ID = {
  ACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
}
export const MANDATORY_CLICK_URL_SOURCE_TYPE = ['file', 'url']
