import { Box, Tooltip, Typography } from '@applift/factor'
import { InfoCircle } from '@applift/icons'

export const FieldLabelWithTooltip = ({
  label,
  tooltip,
}: {
  label: string
  tooltip: string
}) => {
  return (
    <Box
      Component={'span'}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant="label">{label}</Typography>
      <Tooltip title={tooltip} placement="top" arrow>
        <InfoCircle
          style={{
            width: '1.2em',
            height: '1.2em',
            transform: 'translateY(-1px)',
          }}
          sx={{
            textColor: 'neutral-400',
          }}
        />
      </Tooltip>
    </Box>
  )
}
