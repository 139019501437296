import * as React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Col,
  Row,
  LoadingButton,
  enqueueSnackbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  sx,
  Paper,
} from '@applift/factor'
import { useQueryClient } from '@tanstack/react-query'
import { DataGrid } from '@applift/datagrid'

import { Creative } from '../../../models'
import { useDuplicateCreatives, useEditClickUrl } from '../../../hooks'
import { colDef } from './creativeColDef'

interface EditClickUrlConfirmationDialogProps {
  url: string
  data: Creative[]
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

// TODO : remove the static height get it from the grid ref
const HEADER_HEIGHT = 56
const ROW_HEIGHT = 80

export const EditClickUrlConfirmationDialog = (
  props: EditClickUrlConfirmationDialogProps
) => {
  const { onClose, onSuccess, data, url, onError, onSettle } = props

  const queryClient = useQueryClient()

  const [confirmationOption, setConfirmationOption] = React.useState<
    'update' | 'duplicate'
  >('update')

  const editClickUrlMutation = useEditClickUrl({
    onSuccess: (response, request) => {
      enqueueSnackbar(response?.message, {
        variant: 'success',
      })
      // To reset query for the creative Preview dialog
      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === '__getCreativeDetails' &&
            Boolean(
              request.creativeIds?.includes(query.queryKey?.[0]?.creativeId)
            )
          )
        },
      })

      queryClient.resetQueries({
        predicate: (query: any) => {
          return query.queryKey?.[0]?.scope === 'getAllCreativeList'
        },
      })
      onSuccess?.(response)
    },
    onError: error => {
      enqueueSnackbar(
        error?.data?.errorObjects?.[0]?.error ||
          error?.data?.detail ||
          'Something went wrong',
        {
          variant: 'error',
        }
      )
      onError?.(error)
    },
    onSettled: () => {
      onSettle?.()
      onClose?.()
    },
  })
  const onSuccessDuplication = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' })
    onClose?.()
    onSuccess?.(message)
  }
  const onErrorDuplication = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
    onClose?.()
  }

  const duplicateCreativeMutation = useDuplicateCreatives({
    onSuccess: onSuccessDuplication,
    onError: onErrorDuplication,
  })

  const onUpdate = () => {
    if (confirmationOption === 'update') {
      editClickUrlMutation.mutate({
        clickUrl: url,
        creativeIds: data.map(creative => creative.creativeId),
        confirmStatusChange: true,
      })
    }
    if (confirmationOption === 'duplicate') {
      duplicateCreativeMutation.mutate({
        creativeIds: data.map(creative => creative.creativeId),
        clickUrl: url,
      })
    }
  }

  const gridHeight = React.useMemo(() => {
    // 1 is for the bottom border
    return `${HEADER_HEIGHT + 1 + Math.min(data.length, 5) * ROW_HEIGHT}px`
  }, [data])

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi" sx={{ ml: 8 }}>
          Edit Creative Details
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', gap: 16 }}
      >
        <Row sx={{ gapRow: 16 }}>
          <Typography>
            The following creative(s) are being used in other campaigns. If you
            update, they will go for re-approval and the effect will be applied
            in all the campaigns where they are used.
          </Typography>
        </Row>
        <Row>
          <Col xs={12}>
            <Paper style={{ height: gridHeight }} elevation={1}>
              <DataGrid
                data={data}
                rowCount={data.length}
                columns={colDef}
                rowHeight={80}
                getRowId={(row: Creative) => String(row.creativeId)}
                hideHeader
                hideFooter
                checkboxSelection={false}
                overscrollBehaviorX="contain"
                showColumnRightBorder
                showCellRightBorder
                disableRowSelectionOnClick
                classes={{
                  root: sx({
                    borderRadius: 0,
                    border: 0,
                    borderTop: 1,
                    height: 100,
                  }),
                }}
              />
            </Paper>
          </Col>
        </Row>
        <Row sx={{ mb: 16 }}>
          <Col xs={12}>
            <FormControl>
              <RadioGroup value={confirmationOption}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                  }}
                >
                  <FormControlLabel
                    style={{ flex: 1 }}
                    sx={{ alignItems: 'start' }}
                    control={
                      <Radio
                        onClick={() => setConfirmationOption('update')}
                        value="update"
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography weight="demi" sx={{ ml: 4 }}>
                          Update creatives and apply to the campaign
                        </Typography>
                        <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
                          Apply the change to selected creatives. The update
                          will be reflected in their associated campaigns,
                          including running campaigns.
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    style={{ flex: 1 }}
                    sx={{ alignItems: 'start' }}
                    control={
                      <Radio
                        value="duplicate"
                        onClick={() => setConfirmationOption('duplicate')}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography weight="demi" sx={{ ml: 4 }}>
                          Create a copy and update
                        </Typography>
                        <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
                          Create a duplicate copy of the selected creatives and
                          apply the change. All associated campaigns remain
                          untouched. It won’t affect the running campaigns.
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={
            editClickUrlMutation.isLoading ||
            duplicateCreativeMutation.isLoading
          }
          type="submit"
          color="primary"
          onClick={onUpdate}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
