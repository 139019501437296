import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  CreativeCampaignDetail,
  CreativeCampaignRequest,
  WithResponse,
} from '../models'
import { getCreativeCampaignDetail } from '../api/campaign'
import { getCreativeCampaignDetailKey } from '../api/QueryKeys'

interface useCreativeCampaignDetailProps extends CreativeCampaignRequest {
  options?: Omit<
    UseQueryOptions<
      WithResponse<CreativeCampaignDetail>,
      unknown,
      WithResponse<CreativeCampaignDetail>,
      any[]
    >,
    'queryFn' | 'queryKey' | 'getNextPageParam'
  >
}

export const useCreativeCampaignDetail = (
  props: useCreativeCampaignDetailProps
) => {
  const { options, ...params } = props
  const query = useQuery({
    queryFn: getCreativeCampaignDetail,
    queryKey: getCreativeCampaignDetailKey.keys({
      scope: 'getCreativeCampaignDetail',
      ...params,
    }),
    ...(options ?? {}),
  })

  return query
}
