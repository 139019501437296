import { SvgIconProps } from '@applift/factor'
import {
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeVideo,
  CreativeNative,
} from '@applift/icons'

export const creativeTypeIconMapping = {
  image: CreativeImage,
  html: CreativeHTML,
  video: CreativeVideo,
  audio: CreativeAudio,
  native: CreativeNative,
}

export interface CreativeTypeIconProps extends Omit<SvgIconProps, 'children'> {
  name?: string
}

export const CreativeTypeIcon = (props: CreativeTypeIconProps) => {
  const { name = '', ...other } = props

  if (name.toLowerCase() in creativeTypeIconMapping) {
    const Icon =
      creativeTypeIconMapping[
        name.toLowerCase() as keyof typeof creativeTypeIconMapping
      ]
    return <Icon {...other} />
  }

  return null
}
