import { getExcelOrCsvFileData } from '@applift/app-utils'
import { useClipboard, useDrop } from '@applift/drag-drop'
import {
  Box,
  Button,
  Divider,
  FileTrigger,
  Typography,
  useSnackbar,
} from '@applift/factor'
import { Add, Upload } from '@applift/icons'
import clsx from 'clsx'
import * as React from 'react'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'

import { ERROR_MESSAGES } from '../../../../../constants'
import { ErrorCSVType } from '../../../../../models'
import { errorFileToast, formatFileSize } from '../../../../../utils'
import { getValidDropItems, validateFile } from './helper'
import styles from './index.module.scss'

export interface DropZoneProps {
  onAddFile: (obj: { name: string; html: string }) => void
  onAdd: () => void
}

export const DropZone = (props: DropZoneProps) => {
  const { onAddFile, onAdd } = props
  const ref = React.useRef(null)
  const { closeSnackbar } = useSnackbar()

  const setFileData = async (files: File[]) => {
    const errorFiles: ErrorCSVType[] = []

    for (const file of files) {
      await new Promise<void>(resolve => {
        getExcelOrCsvFileData(
          file,
          fileData => {
            const headerArr =
              fileData[0]?.map(colHeader => colHeader.toLowerCase()) ?? []
            if (headerArr.includes('html') === false) {
              errorFiles.push({
                'File Name': file.name,
                'File Size': formatFileSize(file.size),
                'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
              })
              return resolve()
            }
            const nameheaderIndex = headerArr.indexOf('name')
            const htmlHeaderIndex = headerArr.indexOf('html')
            if (fileData.length <= 1) {
              errorFiles.push({
                'File Name': file.name,
                'File Size': formatFileSize(file.size),
                'Reason for Rejection': 'The file uploaded is empty',
              })
              return resolve()
            }

            for (let i = 1; i < fileData.length; i++) {
              const name = fileData?.[i]?.[nameheaderIndex] ?? ''
              const html = fileData?.[i]?.[htmlHeaderIndex] ?? ''
              onAddFile({ name, html })
            }
            resolve()
          },
          () => {
            errorFiles.push({
              'File Name': file.name,
              'File Size': formatFileSize(file.size),
              'Reason for Rejection': ERROR_MESSAGES.INVALID_FORMAT,
            })
            resolve()
          }
        )
      })
    }

    return errorFiles
  }

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      const errorFiles = []
      const { validFiles, errorType: validationErrors } =
        await getValidDropItems(e.items)

      if (validationErrors?.length) {
        errorFiles.push(...validationErrors)
      }
      if (validFiles.length > 0) {
        const formatErrors = await setFileData(validFiles)
        if (formatErrors) {
          errorFiles.push(...formatErrors)
        }
      }

      if (errorFiles.length > 0) {
        errorFileToast(errorFiles, closeSnackbar, 'html_error_files.csv')
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      const errorFiles = []
      const { validFiles, errorType: validationErrors } =
        await getValidDropItems(items)

      if (validationErrors?.length) {
        errorFiles.push(...validationErrors)
      }
      if (validFiles.length > 0) {
        const formatErrors = await setFileData(validFiles)
        if (formatErrors) {
          errorFiles.push(...formatErrors)
        }
      }
      if (errorFiles.length > 0) {
        errorFileToast(errorFiles, closeSnackbar, 'html_error_files.csv')
      }
    },
  })

  const onUpload = async (fileList: FileList | null) => {
    const errFiles: ErrorCSVType[] = []
    if (fileList) {
      const { validFiles, errorFiles: validationErrors } =
        validateFile(fileList)

      if (validationErrors?.length) {
        errFiles.push(...validationErrors)
      }

      if (validFiles?.length) {
        const formateError = await setFileData(validFiles)

        formateError?.map(item => {
          errFiles.push(item)
        })
      }
      if (errFiles?.length) {
        errorFileToast(errFiles, closeSnackbar, 'html_error_files.csv')
      }
    }
  }

  return (
    <>
      <Box
        {...dropProps}
        {...clipboardProps}
        role="button"
        tabIndex={0}
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
          height: 100,
          overflow: 'hidden',
          py: 24,
          px: 16,
          gap: 24,
          borderRadius: 4,
        }}
        className={clsx(styles.dropZone, isDropTarget && styles.active)}
      >
        <Button variant="outlined" startIcon={<Add />} onClick={onAdd}>
          Add HTML
        </Button>
        <FileTrigger
          allowsMultiple
          acceptedFileTypes={['.csv', '.xlsx']}
          onSelect={onUpload}
        >
          {({ onClick }) => {
            return (
              <Button
                variant="outlined"
                startIcon={<Upload />}
                onClick={onClick}
              >
                Upload HTML
              </Button>
            )
          }}
        </FileTrigger>
        <Integrations setFileData={setFileData} />
        <Divider sx={{ width: 100 }} color="secondary" />
        <Typography sx={{ textColor: 'neutral-600' }} lineHeight="single-line">
          or Drag & Drop
        </Typography>
      </Box>
    </>
  )
}

const Integrations = ({
  setFileData,
}: {
  setFileData: (files: File[]) => Promise<ErrorCSVType[]>
}) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 4,
      }}
    >
      <Typography sx={{ textColor: 'neutral-500' }} lineHeight="single-line">
        import from
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexWrap: 'wrap',
          gap: 8,
        }}
      >
        <GoogleFilePicker
          acceptedFileTypes="xlsx,csv"
          onSelect={async files => {
            const errorFiles = []
            if (files?.length > 0) {
              const formatErrors = await setFileData(files)
              if (formatErrors) {
                errorFiles.push(...formatErrors)
              }
            }
            if (errorFiles.length > 0) {
              errorFileToast(errorFiles, closeSnackbar, 'html_error_files.csv')
            }
          }}
        />
        <DropBoxFilePicker
          extensions={['.xlsx', '.csv']}
          onSelect={async files => {
            const errorFiles = []
            if (files?.length > 0) {
              const formatErrors = await setFileData(files)
              if (formatErrors) {
                errorFiles.push(...formatErrors)
              }
            }
            if (errorFiles.length > 0) {
              errorFileToast(errorFiles, closeSnackbar, 'html_error_files.csv')
            }
          }}
        />
      </Box>
    </Box>
  )
}
