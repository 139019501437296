import { Container, CircularProgress, Typography } from '@applift/factor'

interface ScreenLoaderProps {
  text?: string
}

export const ScreenLoader = (props: ScreenLoaderProps) => {
  const { text } = props
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 'auto',
      }}
    >
      <CircularProgress size={72} thickness={3} />
      {text?.length ? (
        <Typography variant="bodyLarge" sx={{ mt: 16 }}>
          {text}
        </Typography>
      ) : undefined}
    </Container>
  )
}
