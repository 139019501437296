import { Row, Col, Box, Button, enqueueSnackbar } from '@applift/factor'
import { UserAudio } from '@applift/illustrations'
import { Add, TargetAudio, PickUsers, FileUpload } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'
import { useDialogManager } from '../dialogManager'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'
import { AUDIO_CREATIVE_ID } from '../../constants'

export const AudioEmptyTab = () => {
  const { setAction } = useDialogManager()
  const queryClient = useQueryClient()
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={<UserAudio sx={{ width: 100, height: 100 }} />}
              title="Audio Creative"
              subText="Engage users through immersive sound experiences that bring your brand to life."
              action={
                <Button
                  startIcon={<Add />}
                  variant="outlined"
                  onClick={() =>
                    setAction('audio', undefined, {
                      onSuccess: (
                        response: CreateCreativeResponse,
                        request: CreateCreativeRequest
                      ) => {
                        const createdCreativesId = Object.values(
                          response.successData
                        )
                        const failedCreatives = Object.keys(response.failedData)
                        if (createdCreativesId.length) {
                          enqueueSnackbar({
                            message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                            variant: 'success',
                          })
                        }
                        if (failedCreatives.length) {
                          enqueueSnackbar({
                            message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                            variant: 'error',
                          })
                        }

                        queryClient.resetQueries({
                          predicate: (query: any) => {
                            return (
                              query.queryKey?.[0]?.scope ===
                                'getAllCreativeList' &&
                              query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                                AUDIO_CREATIVE_ID
                              )
                            )
                          },
                        })
                        const succeededCreative = Object.keys(
                          response.successData ?? {}
                        ).map(assetId => {
                          return {
                            creativeId: Number(
                              response.successData[`${assetId}`]
                            ),
                            platformCreativeTypeId: AUDIO_CREATIVE_ID,
                            creativeName: request[`${assetId}`]?.creativeName,
                          }
                        })
                        if (succeededCreative.length) {
                          setAction('group', succeededCreative)
                        }
                      },
                    })
                  }
                >
                  Add Audio Creative
                </Button>
              }
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<TargetAudio />}
                title="Hands-Free Engagement"
                subText="Reach consumers while they are engaged in offline activities."
              />
              <EmptyFeatureCard
                icon={<PickUsers />}
                title="Reach"
                subText="Tap into large, highly engaged audiences across audio categories and genres."
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple audio files at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
