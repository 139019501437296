import {
  Box,
  Button,
  Tooltip,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'

import { SemanticError } from '@applift/icons'
import { MAX_NATIVES_COUNT_PER_CREATION } from '../../../../../constants'
import { NativeCreativeGenerationData } from '../../../../../models'
import { useStore } from '@tanstack/react-store'
import { NativeCreationFormType } from '../CreateWrapper'
import { getVariations } from './helper'
interface Props {
  form: NativeCreationFormType
  idGeneratorRef: React.MutableRefObject<number>
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  onRemove?: (id: string) => void
  onSelect: (creative: NativeCreativeGenerationData) => void
  currentCreativesCount: number
  isCreateClicked: boolean
}

export const NativeContentHeader = (props: Props) => {
  const {
    form,
    idGeneratorRef,
    onChange,
    onRemove,
    onSelect,
    currentCreativesCount,
    isCreateClicked,
  } = props

  const { titles, descriptions, nativeType, imageObjects, videoObjects } =
    useStore(form.store, state => state.values)

  const addedMediaCount =
    nativeType === 'image' ? imageObjects.length : videoObjects.length

  const displayGenerateVariations =
    titles.length > 1 || descriptions.length > 1 || addedMediaCount > 1

  const enableGenerateVariations =
    titles.some(title => Boolean(title.trim())) &&
    descriptions.some(desc => Boolean(desc.trim())) &&
    addedMediaCount > 0 &&
    displayGenerateVariations

  const generateVariations = () => {
    const variations = getVariations(form.state.values, idGeneratorRef)

    if (
      currentCreativesCount + variations.length >
      MAX_NATIVES_COUNT_PER_CREATION
    ) {
      return enqueueSnackbar(
        `Cannot add more than ${MAX_NATIVES_COUNT_PER_CREATION} creatives`,
        { variant: 'error' }
      )
    }

    if (variations.length > 0) {
      onRemove?.(form.state.values.uniqueId)
      onChange?.(variations)
      onSelect(variations[0] as NativeCreativeGenerationData)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'between',
        py: 12,
        px: 24,
        alignItems: 'baseline',
      }}
    >
      <Typography
        sx={{ textWeight: 'demi', textColor: 'neutral-500' }}
        variant="bodyMedium"
      >
        Content
      </Typography>
      <Box sx={{ display: 'flex', gap: 8 }}>
        {displayGenerateVariations && isCreateClicked ? (
          <Tooltip
            placement="top"
            arrow
            title={
              'Generate Variations or reduce to a single creative, title and description.'
            }
          >
            <SemanticError fontSize={24} sx={{ textColor: 'danger-400' }} />
          </Tooltip>
        ) : (
          <></>
        )}

        <Tooltip
          placement="top"
          arrow
          title={
            enableGenerateVariations
              ? ''
              : 'Atleast one creative, title & description is required to generate variations'
          }
        >
          <Box>
            <Button
              variant="contained"
              sx={{
                visibility: displayGenerateVariations ? 'visible' : 'hidden',
              }}
              disabled={enableGenerateVariations === false}
              size="small"
              onClick={generateVariations}
            >
              Generate Variations
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}
