import * as React from 'react'
import {
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Tooltip,
} from '@applift/factor'
import { useForm } from '@tanstack/react-form'
import { Delete, InfoCircle } from '@applift/icons'
import { ImagePreview } from '@applift/platform-creatives'

import { ensureHttpUrl, isValidURL } from '../../../../../utils/helper'
import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
} from '../../../../../constants'
import { CreateProps } from './Create'
import {
  clickUrlValidationFn,
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
} from '../../../../../utils'

export interface ImageCardProps
  extends Pick<CreateProps, 'onChange' | 'onRemove'> {
  /**
   * Temp creative id generated
   */
  id: string
  /**
   * Need to pass image's URL
   */
  image: string
  /**
   * Width of image
   */
  width?: number
  /**
   * Height of image
   */
  height?: number
  /**
   * Name of the creative
   */
  name?: string
  /**
   * 3rd Party ID of the creative
   */
  thirdPartyID?: string
  /**
   * Click URL of the creative
   */
  clickURL?: string
  /**
   * Pixel URL of the creative
   */
  pixelURL?: string
  /**
   * is the creative having the recommened size
   * @default false
   */
  isRecommendedDimensionSize?: boolean
}

const FieldLabelWithTooltip = ({
  label,
  tooltip,
}: {
  label: string
  tooltip: string
}) => {
  return (
    <Box
      Component={'span'}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant="label">{label}</Typography>
      <Tooltip title={tooltip} placement="top" arrow>
        <InfoCircle
          style={{
            width: '1.2em',
            height: '1.2em',
            transform: 'translateY(-1px)',
          }}
          sx={{
            textColor: 'neutral-400',
          }}
        />
      </Tooltip>
    </Box>
  )
}

export const ImageCard = (props: ImageCardProps) => {
  const {
    id,
    image,
    width,
    height,
    name = '',
    thirdPartyID = '',
    clickURL = '',
    pixelURL = '',
    isRecommendedDimensionSize = false,
    onChange,
    onRemove,
  } = props
  const form = useForm({
    defaultValues: {
      id: id,
      image: image,
      width: width,
      height: height,
      name: name,
      thirdPartyID: thirdPartyID,
      clickURL: clickURL,
      pixelURL: pixelURL,
      isRecommendedDimensionSize: isRecommendedDimensionSize,
    },
  })

  React.useEffect(() => {
    form.validateAllFields('change').then(() => {
      const formData = {
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [form, onChange])

  React.useEffect(() => {
    form.store.setState(state => ({
      ...state,
      values: {
        ...state.values,
        clickURL: clickURL,
        pixelURL: pixelURL,
      },
    }))
    form.validateAllFields('change').then(() => {
      const formData = {
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [form, clickURL, pixelURL, onChange])

  return (
    <form
      style={{ height: '100%' }}
      onChange={() => {
        const formData = {
          ...form.state.values,
          hasError: form.state.canSubmit === false,
        }
        onChange?.([formData])
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          border: 1,
          borderRadius: 4,
          p: 16,
          pb: 24,
          gap: 16,
          alignItems: 'center',
          position: 'relative',
          borderColor: !isRecommendedDimensionSize
            ? 'warning-200'
            : 'neutral-100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 8,
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              onRemove?.(id)
            }}
          >
            <Delete />
          </IconButton>
          <ImagePreview
            onLoad={e => {
              form.store.setState(state => ({
                ...state,
                values: {
                  ...state.values,
                  height: (e.target as HTMLImageElement).naturalHeight,
                  width: (e.target as HTMLImageElement).naturalWidth,
                },
              }))
            }}
            src={image}
            alt="Image Preview"
            clickURL={ensureHttpUrl(form.state.values.clickURL)}
            pixelURL={form.state.values.pixelURL}
            debudMode="none"
            variant="create"
          />
        </Box>
        <Divider color="secondary" sx={{ width: 100 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 100,
              px: 12,
              gap: 8,
            }}
          >
            {width && height && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 4,
                }}
              >
                <Typography
                  variant="label"
                  lineHeight="single-line"
                  sx={{ textColor: 'neutral-600' }}
                >
                  Size :
                </Typography>
                <Typography lineHeight="single-line">{`${width}x${height}`}</Typography>
              </Box>
            )}
            {!isRecommendedDimensionSize && (
              <Typography
                variant="label"
                lineHeight="single-line"
                sx={{ textColor: 'warning-500' }}
              >
                Dimensions don’t match the recommended size and may affect the
                campaign’s reach
              </Typography>
            )}
          </Box>
          <form.Field
            name="name"
            validators={{
              onChange: ({ value }) => creativeNameValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label="Name"
                variant="outlinedDash"
                placeholder="Enter Name"
                value={field.state.value}
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                    </>
                  ),
                }}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="thirdPartyID"
            validators={{
              onChange: ({ value }) => creativeThirdPartyIDValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label={
                  <Box
                    Component={'span'}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography variant="label">3rd Party ID</Typography>
                    <Tooltip
                      title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                      placement="top"
                      arrow
                    >
                      <InfoCircle
                        style={{
                          width: '1.2em',
                          height: '1.2em',
                          transform: 'translateY(-1px)',
                        }}
                        sx={{
                          textColor: 'neutral-400',
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                variant="outlinedDash"
                placeholder="Enter 3rd Party ID"
                value={field.state.value}
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value.length}/{CREATIVE_THIRD_PARTY_ID_LIMIT}
                    </>
                  ),
                }}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="clickURL"
            validators={{
              onChange: ({ value }) => clickUrlValidationFn(value, true),
            }}
          >
            {field => (
              <TextField
                label={
                  <FieldLabelWithTooltip
                    label="Click URL"
                    tooltip="URL that will open when someone clicks on your ad"
                  />
                }
                variant="outlinedDash"
                placeholder="Enter Click URL"
                value={field.state.value}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
                required
              />
            )}
          </form.Field>
          <form.Field
            name="pixelURL"
            validators={{
              onChange: ({ value }) => {
                if (value.trim() && !isValidURL(value)) {
                  return 'Pixel URL must be a valid url address'
                }
                return undefined
              },
            }}
          >
            {field => (
              <TextField
                label={
                  <FieldLabelWithTooltip
                    label="Pixel URL"
                    tooltip="To verify the ad serving using a third-party system, you can copy and paste the Tracking Pixel URL from that system into this field"
                  />
                }
                variant="outlinedDash"
                placeholder="Enter Pixel URL"
                value={field.state.value}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
        </Box>
      </Box>
    </form>
  )
}
