import { VideoCreativeGenerationData } from '../../../../../models/Creative'

export const getTrackingUrlFeildNumber = (
  data: VideoCreativeGenerationData
) => {
  const urls = [
    data.impressionURL,
    data.startURL,
    data.firstQuartileURL,
    data.midPointURL,
    data.thirdQuartileURL,
    data.completeURL,
  ]

  return urls.filter(Boolean).length
}
