import * as React from 'react'
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Accordion,
  AccordionSummary,
  sx,
  AccordionDetails,
} from '@applift/factor'
import { ChevronRight, Delete } from '@applift/icons'

import { AudioCreationFormType } from '../Create'
import { trackingUrlValidationFn } from '../../../../../../utils'
import { AudioCreativeGenerationData } from '../../../../../../models'

interface Props {
  form: AudioCreationFormType
  updateMasterList: () => void
  /**
   * Audio Creative
   */
  creative: AudioCreativeGenerationData
}

export const CustomTrackingFields = (props: Props) => {
  const { form, updateMasterList, creative } = props

  const {
    impressionURL,
    startURL,
    firstQuartileURL,
    midPointURL,
    thirdQuartileURL,
    completeURL,
  } = creative

  const [expandedCustomTracking, setExpandedCustomTracking] = React.useState<
    string | false
  >(false)

  const handleChangeCustomTracking =
    (panel: string) => (_: any, isExpanded: boolean) => {
      setExpandedCustomTracking(isExpanded ? panel : false)
    }

  const clearTrackingUrlField = () => {
    form.store.setState(state => ({
      ...state,
      values: {
        ...state.values,
        impressionURL: '',
        startURL: '',
        firstQuartileURL: '',
        midPointURL: '',
        thirdQuartileURL: '',
        completeURL: '',
      },
    }))
    updateMasterList()
  }

  const trackingURLs = [
    impressionURL,
    startURL,
    firstQuartileURL,
    midPointURL,
    thirdQuartileURL,
    completeURL,
  ].map(val => val?.trim())

  const trackingURLCount = trackingURLs.filter(Boolean).length

  return (
    <Accordion
      expanded={expandedCustomTracking === 'panelCustomTracking'}
      onChange={handleChangeCustomTracking('panelCustomTracking')}
      sx={{
        width: 100,
        boxShadow: 0,
        border: 1,
        borderRadius: 4,
        borderColor: 'neutral-100',
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ChevronRight fontSize={18} />}
        classes={{
          content: sx({
            alignItems: 'center',
            justifyContent: 'between',
            my: 4,
          }),
        }}
        sx={{
          flexDirection: 'row-reverse',
          gap: 12,
          textColor: 'neutral-500',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Typography
            lineHeight="single-line"
            sx={{ textColor: 'neutral-1000' }}
          >
            Custom Tracking
          </Typography>
          <Typography
            lineHeight="single-line"
            sx={{ textColor: 'neutral-500' }}
          >
            {trackingURLCount ? `(${trackingURLCount})` : ''}
          </Typography>
        </Box>
        {trackingURLCount > 0 && (
          <IconButton
            onClick={e => {
              e.stopPropagation()
              clearTrackingUrlField()
            }}
            color="secondary"
            size="small"
          >
            <Delete />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          <form.Field
            name="impressionURL"
            validators={{
              onChange: ({ value }) => trackingUrlValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label="Impression Tracking URL"
                variant="outlinedDash"
                placeholder="Enter Impression Tracking URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="startURL"
            validators={{
              onChange: ({ value }) => trackingUrlValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label="Start Tracking URL"
                variant="outlinedDash"
                placeholder="Enter Start Tracking URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="firstQuartileURL"
            validators={{
              onChange: ({ value }) => trackingUrlValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label="1st Quartile Tracking URL"
                variant="outlinedDash"
                placeholder="Enter 1st Quartile Tracking URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="midPointURL"
            validators={{
              onChange: ({ value }) => trackingUrlValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label="Midpoint Tracking URL"
                variant="outlinedDash"
                placeholder="Enter Midpoint Tracking URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="thirdQuartileURL"
            validators={{
              onChange: ({ value }) => trackingUrlValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label="3rd Quartile Tracking URL"
                variant="outlinedDash"
                placeholder="Enter 3rd Quartile Tracking URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="completeURL"
            validators={{
              onChange: ({ value }) => trackingUrlValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label="Complete Tracking URL"
                variant="outlinedDash"
                placeholder="Enter Complete Tracking URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
