import { enqueueSnackbar } from '@applift/factor'
import * as React from 'react'

import { addAsset } from '../api'
import { MediaData, MediaFile } from '../models/asset'

export interface MediaQueue {
  queue: MediaFile[]
  enqueue: (files: MediaFile[]) => void
  currentProcessingBatch: MediaFile[]
}

export const useMediaQueue = (
  onFilesProcessed: (files: MediaData) => void
): MediaQueue => {
  const [queue, setQueue] = React.useState<MediaFile[]>([])
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [currentBatch, setCurrentBatch] = React.useState<MediaFile[]>([])

  const processBatch = React.useCallback(
    async (batch: MediaFile[]) => {
      const addAssetAPICall = async (currentBatch: MediaFile[]) => {
        let response
        try {
          response = await addAsset({
            files: currentBatch.map(fileObjInfo => fileObjInfo.fileObject.file),
            filesMetadata: currentBatch.map(fileObjInfo => ({
              attachedFileName: `${fileObjInfo.uniqueId}.${fileObjInfo.fileObject.file.name
                .split('.')
                .pop()}`,
              originalFileName: fileObjInfo.fileObject.file.name,
            })),
          })
        } catch {
          enqueueSnackbar('Media processing failed.', { variant: 'error' })
        }
        return response
      }
      setCurrentBatch(batch)
      const promise = [addAssetAPICall(batch)]
      const response = await Promise.all(promise)
      if (response?.length) {
        const assetResponse = response[0]
        const uniqueFileNames = Object.keys(assetResponse)
        uniqueFileNames.forEach(key => {
          assetResponse[key] = {
            ...assetResponse[key],
            metaData: batch?.find(
              item =>
                `${item.uniqueId}.${item.fileObject.file.name.split('.').pop()}` ===
                key
            )?.fileObject as MediaFile['fileObject'],
          }
        })

        await onFilesProcessed(assetResponse as any)
        setCurrentBatch([])
      }
    },
    [onFilesProcessed]
  )

  const processQueue = React.useCallback(async () => {
    setProcessing(true)
    const batch = queue.splice(0, 5) // Process 5 items at a time
    await processBatch(batch)
    setProcessing(false)
  }, [queue, processBatch])

  React.useEffect(() => {
    if (!processing && queue.length) {
      processQueue()
    }
  }, [queue, processing, processQueue])

  const enqueue = (files: MediaFile[]) =>
    setQueue(prevQueue => [...prevQueue, ...files])

  return {
    enqueue,
    queue,
    currentProcessingBatch: currentBatch,
  }
}
