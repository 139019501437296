import { Box, Typography, TypoTooltip } from '@applift/factor'

import styles from './index.module.scss'

export interface CardListItemProps {
  label: React.ReactNode
  value: React.ReactNode
  required?: boolean
}

export const CardListItem = (props: CardListItemProps) => {
  const { label, value, required = false } = props
  return (
    <Box
      sx={{
        width: 100,
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        alignItems: 'center',
      }}
      className={styles.listItem}
    >
      <Box
        className={styles.listItemLabel}
        sx={{ textColor: 'neutral-600', overflow: 'hidden', flexShrink: 0 }}
      >
        {label}
        {required && (
          <Typography
            component="sup"
            variant="bodySmall"
            sx={{ textColor: 'danger-400' }}
          >
            *
          </Typography>
        )}
      </Box>
      <Box className={styles.listItemValue} sx={{ overflow: 'hidden' }}>
        <TypoTooltip arrow sx={{ display: 'block' }}>
          {value}
        </TypoTooltip>
      </Box>
    </Box>
  )
}
