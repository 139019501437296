import { Delete, Duplicate, Edit, EllipsisVertical } from '@applift/icons'
import {
  bindMenu,
  bindTrigger,
  IconButton,
  Menu,
  MenuItem,
  usePopupState,
} from '@applift/factor'

import { CreativeGroup } from '../../../../api'
import { useAllCreativeDialogManager } from '../..'

export const GroupActions = (props: CreativeGroup) => {
  const actionPopupState = usePopupState({
    variant: 'popover',
    popupId: 'actionPopup',
  })

  const { setAction } = useAllCreativeDialogManager()

  const { onClick, onTouchStart, ...other } = bindTrigger(actionPopupState)

  const handleDeleteGroup = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setAction('deleteCreativeGroup', props)
    actionPopupState.close()
  }

  const handleDuplicateGroup = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setAction('duplicateCreativeGroup', props)
    actionPopupState.close()
  }

  const handleRenameGroup = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setAction('renameCreativeGroup', props)
    actionPopupState.close()
  }

  return (
    <>
      {/* @ts-ignore */}
      <IconButton
        selected={actionPopupState.isOpen}
        onClick={e => {
          e.stopPropagation()
          onClick(e as unknown as MouseEvent)
        }}
        onTouchStart={e => {
          e.stopPropagation()
          onTouchStart(e as unknown as TouchEvent)
        }}
        sx={{ textColor: 'neutral-500' }}
        {...other}
      >
        <EllipsisVertical fontSize={18} />
      </IconButton>
      {/* @ts-ignore */}
      <Menu
        {...bindMenu(actionPopupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            border: 1,
            borderColor: 'primary-600',
            marginTop: 2,
            py: 4,
          },
          style: { width: '120px' },
        }}
      >
        <MenuItem
          onClick={handleRenameGroup}
          startIcon={<Edit fontSize={16} sx={{ textColor: 'neutral-400' }} />}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={handleDuplicateGroup}
          startIcon={
            <Duplicate fontSize={16} sx={{ textColor: 'neutral-400' }} />
          }
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={handleDeleteGroup}
          startIcon={<Delete fontSize={16} sx={{ textColor: 'neutral-400' }} />}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}
