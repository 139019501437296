import { Box, Row, Col, Typography, Tooltip, Link } from '@applift/factor'
import { ExternalLink, InfoCircle } from '@applift/icons'
import {
  STANDARD_IMAGE_DIMENSIONS_LANDSCAPE,
  STANDARD_IMAGE_DIMENSIONS_PORTRAIT,
  STANDARD_IMAGE_DIMENSIONS_SQUARE,
} from '../../../../../constants'

const SpecificationsTooltip = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Typography lineHeight="single-line">
        For better performance, follow these IAB-standard image sizes for
        advertising creatives.
      </Typography>
      <Row xs={{ gutterSize: 24, col: 3 }}>
        <Col
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography lineHeight="single-line" weight="demi" sx={{ mb: 2 }}>
            Landscape
          </Typography>
          {STANDARD_IMAGE_DIMENSIONS_LANDSCAPE.map(one => {
            return (
              <Typography lineHeight="single-line" key={one}>
                {one}
              </Typography>
            )
          })}
        </Col>
        <Col
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography lineHeight="single-line" weight="demi" sx={{ mb: 2 }}>
            Portrait
          </Typography>
          {STANDARD_IMAGE_DIMENSIONS_PORTRAIT.map(one => {
            return (
              <Typography lineHeight="single-line" key={one}>
                {one}
              </Typography>
            )
          })}
        </Col>
        <Col
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography lineHeight="single-line" weight="demi" sx={{ mb: 2 }}>
            Square
          </Typography>
          {STANDARD_IMAGE_DIMENSIONS_SQUARE.map(one => {
            return (
              <Typography lineHeight="single-line" key={one}>
                {one}
              </Typography>
            )
          })}
        </Col>
      </Row>
    </Box>
  )
}

export const SpecificationsNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Tooltip arrow title={<SpecificationsTooltip />}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textColor: 'neutral-600',
            gap: 4,
          }}
          lineHeight="single-line"
        >
          <Box
            Component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            <InfoCircle sx={{ textColor: 'neutral-400' }} />
          </Box>
          Recommended Specifications
        </Typography>
      </Tooltip>
      <Link
        href="https://www.iab.com/wp-content/uploads/2019/04/IABNewAdPortfolio_LW_FixedSizeSpec.pdf"
        color="primary"
        // @ts-ignore
        target="_blank"
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 4,
          }}
          lineHeight="single-line"
        >
          <Box
            Component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            <ExternalLink />
          </Box>
          Refer Ad Spec
        </Typography>
      </Link>
    </Box>
  )
}
