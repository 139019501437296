import * as React from 'react'
import clsx from 'clsx'
import {
  Box,
  Divider,
  Typography,
  Button,
  FileTrigger,
  useSnackbar,
} from '@applift/factor'
import { Add, Upload } from '@applift/icons'
import { useDrop, useClipboard } from '@applift/drag-drop'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'

import {
  getValidDropItems,
  validateVideoFileUpload,
  ValidFiles,
  ValidUrls,
  ValidXmls,
} from './helper'
import { allowedVideoMIMETypes } from './utiles'
import { CreateProps } from '../Create'
import { ALLOWED_EXCEL_FORMATS, errorFileToast } from '../../../../../utils'
import { DropZoneStates, ErrorCSVType } from '../../../../../models'

import styles from './index.module.scss'

export interface DropZoneProps {
  onFileAdded: (file: File[]) => void
  onAddCreatives: CreateProps['onChange']
  setDropZoneState: React.Dispatch<React.SetStateAction<DropZoneStates>>
}

export const DropZone = (props: DropZoneProps) => {
  const { onFileAdded, onAddCreatives, setDropZoneState } = props
  const ref = React.useRef(null)
  const { closeSnackbar } = useSnackbar()

  const processValidData = (props: {
    validFiles?: ValidFiles
    validUrls?: ValidUrls
    validXmls?: ValidXmls
    errorObjects?: ErrorCSVType[]
  }) => {
    const { validFiles, validUrls, validXmls, errorObjects } = props
    if (errorObjects?.length) {
      errorFileToast(errorObjects, closeSnackbar, 'video_error_files.csv')
    }
    if (validUrls?.length) {
      validUrls.forEach(obj => {
        onAddCreatives?.([
          {
            id: crypto.randomUUID(),
            name: obj.name,
            videoCreativeType: 'vast',
            vastXml: obj.url,
            thirdPartyID: '',
          },
        ])
      })
    }
    if (validXmls?.length) {
      validXmls.forEach(obj => {
        onAddCreatives?.([
          {
            id: crypto.randomUUID(),
            name: obj.name,
            videoCreativeType: 'vast',
            vastXml: obj.xml,
            thirdPartyID: '',
          },
        ])
      })
    }
    if (!validFiles?.length) {
      setDropZoneState('normal')
    }
    if (validFiles?.length) {
      onFileAdded(validFiles)
    }
  }

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      const { validFiles, validUrls, validXmls, errorObjects } =
        await getValidDropItems(e.items)
      processValidData({ validFiles, validUrls, validXmls, errorObjects })
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      const { validFiles, validUrls, validXmls, errorObjects } =
        await getValidDropItems(items)
      processValidData({ validFiles, validUrls, validXmls, errorObjects })
    },
  })

  const onUpload = async (fileList: FileList | null) => {
    if (fileList) {
      const { validFiles, validUrls, validXmls, errorObjects } =
        await validateVideoFileUpload(Array.from(fileList))
      processValidData({ validFiles, validUrls, validXmls, errorObjects })
    }
  }

  return (
    <Box
      {...dropProps}
      {...clipboardProps}
      role="button"
      tabIndex={0}
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 24,
        px: 16,
        gap: 24,
        borderRadius: 4,
      }}
      className={clsx(styles.dropZone, isDropTarget && styles.active)}
    >
      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={() => {
          onAddCreatives?.([
            {
              id: crypto.randomUUID(),
              name: '',
              videoCreativeType: 'vast',
              vastXml: '',
              thirdPartyID: '',
            },
          ])
        }}
      >
        Add VAST XML / URL
      </Button>
      <FileTrigger
        allowsMultiple
        acceptedFileTypes={[...ALLOWED_EXCEL_FORMATS, ...allowedVideoMIMETypes]}
        onSelect={onUpload}
      >
        {({ onClick }) => {
          return (
            <Button variant="outlined" startIcon={<Upload />} onClick={onClick}>
              Upload Video
            </Button>
          )
        }}
      </FileTrigger>

      <IntegrationUpload
        processValidData={processValidData}
        setDropZoneState={setDropZoneState}
      />
      <Divider sx={{ width: 100 }} color="secondary" />
      <Typography sx={{ textColor: 'neutral-600' }} lineHeight="single-line">
        or Drag & Drop
      </Typography>
    </Box>
  )
}

const IntegrationUpload = ({
  processValidData,
  setDropZoneState,
}: {
  processValidData: (props: {
    validFiles?: ValidFiles
    validUrls?: ValidUrls
    validXmls?: ValidXmls
    errorObjects?: ErrorCSVType[]
  }) => void
  setDropZoneState: React.Dispatch<React.SetStateAction<DropZoneStates>>
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      gap: 4,
    }}
  >
    <Typography sx={{ textColor: 'neutral-500' }} lineHeight="single-line">
      import from
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexWrap: 'wrap',
        gap: 8,
      }}
    >
      <GoogleFilePicker
        acceptedFileTypes="xlsx,csv,xls,mov,mp4"
        onSelect={async files => {
          const { validFiles, validUrls, validXmls, errorObjects } =
            await validateVideoFileUpload(files)
          processValidData({ validFiles, validUrls, validXmls, errorObjects })
        }}
        onFilesDownloadingStart={() => {
          setDropZoneState('loading')
        }}
      />
      <DropBoxFilePicker
        extensions={['.xlsx', '.csv', '.xls', '.mov', '.mp4']}
        onSelect={async files => {
          const { validFiles, validUrls, validXmls, errorObjects } =
            await validateVideoFileUpload(files)
          processValidData({ validFiles, validUrls, validXmls, errorObjects })
        }}
        onFilesDownloadingStart={() => {
          setDropZoneState('loading')
        }}
      />
    </Box>
  </Box>
)
