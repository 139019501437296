import { enqueueSnackbar } from '@applift/factor'
import { useQuery } from '@tanstack/react-query'

import { getNativeCTAOptionsKey } from '../api/QueryKeys'
import { getNativeCTAOptions } from '../api/native'
import {
  CREATIVE_NAME_LIMIT,
  NATIVE_BRAND_NAME_CHAR_LIMIT,
  NATIVE_DESCRIPTION__CHAR_LIMIT,
  NATIVE_TITLE_CHAR_LIMIT,
} from '../constants'
import { NativeCreativeGenerationData } from '../models'
import { isValidURL } from '../utils'

interface NativeCTAProps {
  showOnlyDefault: boolean
}

export const useNativeCTAOptions = ({ showOnlyDefault }: NativeCTAProps) => {
  const data = useQuery(
    getNativeCTAOptionsKey.keys({
      scope: 'getNativeCTAOptions',
      showOnlyDefault: showOnlyDefault,
    }),
    getNativeCTAOptions,
    {
      cacheTime: Infinity,
    }
  )
  if (data.isError) {
    // @ts-ignore
    enqueueSnackbar(data?.error?.message ?? 'Something went wrong!', {
      variant: 'error',
    })
  }
  return data
}

export const useCompletionState = (
  nativeState: Map<string, NativeCreativeGenerationData>
) => {
  const missingRequiredValueIds: string[] = []
  const errorValueIds: string[] = []

  for (const creative of nativeState.values()) {
    let isErrorPresent = false
    let isRequiredFieldsMissing = false

    const addedMediaCount =
      creative?.nativeType === 'image'
        ? creative?.imageObjects?.length
        : creative?.videoObjects?.length
    const displayGenerateVariations =
      creative?.titles?.length > 1 ||
      creative?.descriptions?.length > 1 ||
      addedMediaCount > 1

    const enableGenerateVariations =
      creative?.titles?.some(title => Boolean(title?.trim())) &&
      creative?.descriptions?.some(desc => Boolean(desc?.trim())) &&
      addedMediaCount > 0 &&
      displayGenerateVariations

    if (displayGenerateVariations || enableGenerateVariations) {
      isRequiredFieldsMissing = true
    }

    if (
      !isValidURL(creative?.pixelURL) ||
      !isValidURL(creative?.clickURL) ||
      creative.brandName.trim().length > NATIVE_BRAND_NAME_CHAR_LIMIT ||
      creative.creativeName.trim().length > CREATIVE_NAME_LIMIT
    ) {
      isErrorPresent = true
    }

    creative.titles.forEach(title =>
      title.trim().length > NATIVE_TITLE_CHAR_LIMIT
        ? (isErrorPresent = true)
        : null
    )
    creative.descriptions.forEach(description =>
      description.trim().length > NATIVE_DESCRIPTION__CHAR_LIMIT
        ? (isErrorPresent = true)
        : null
    )
    if (creative.nativeType === 'video' && creative.customTracking) {
      const enteredCustomTrackingURLs = Object.values(
        creative.customTracking
      ).filter(val => Boolean(val.trim()))
      const hasInvalidURL = enteredCustomTrackingURLs.some(
        url => !isValidURL(url)
      )
      if (hasInvalidURL) {
        isErrorPresent = true
      }
    }

    if (creative?.videoObjects?.[0]) {
      if (
        creative?.videoObjects?.[0]?.creativeSource?.trim()?.length <= 0 &&
        creative?.videoObjects?.[0]?.creativeSourceURL?.trim()?.length <= 0 &&
        Boolean(creative?.clickURL?.trim()) === false
      ) {
        isRequiredFieldsMissing = true
      }
    }

    if (
      Boolean(creative.creativeName.trim()) === false ||
      Boolean(creative.brandName.trim()) === false ||
      (creative?.imageObjects?.length === 0 &&
        creative?.videoObjects?.length === 0) ||
      creative.brandIcon === null ||
      (creative.nativeType === 'video'
        ? Boolean(creative.videoThumbnail?.src?.trim()) === false
        : false) ||
      Boolean(creative.titles[0]?.trim()) === false ||
      Boolean(creative.descriptions[0]?.trim()) === false
    ) {
      isRequiredFieldsMissing = true
    }

    if (isErrorPresent) {
      errorValueIds.push(creative.uniqueId)
    }

    if (isRequiredFieldsMissing) {
      missingRequiredValueIds.push(creative.uniqueId)
    }
  }
  return {
    errorValueIds,
    missingRequiredValueIds,
  }
}
