import { useInfiniteQuery } from '@tanstack/react-query'
import { getCreativeGroupsList } from '../api'

export interface UseCreativeGroupsListParams {
  noOfEntries?: number
  platformCreativeTypeIds?: number[]
  creativeStatusIds?: number[]
  creativeIds?: string
  searchField?: string
  sortBy?: string
  creativeGroupIds?: string
  isSelectAll?: boolean
  startTime?: number | null
  endTime?: number | null
}

export const useCreativeGroupsList = (
  params: UseCreativeGroupsListParams,
  options?: {
    enabled?: boolean
  }
) => {
  const queryData = useInfiniteQuery({
    queryKey: [{ scope: 'getCreativeGroupsList', ...params }],
    queryFn: getCreativeGroupsList,
    enabled: options?.enabled ?? true,
    getNextPageParam: (lastPage, pages) => {
      const filteredRecords = lastPage?.data?.filteredRecords ?? 0
      const totalRecordsFetched = pages.reduce(
        (prev, one) => prev + (one?.data?.creativeGroupList?.length ?? 0),
        0
      )
      if (totalRecordsFetched < filteredRecords) {
        return pages.length + 1
      }
      return null
    },
  })
  return queryData
}
