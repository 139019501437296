import React from 'react'

import {
  Box,
  enqueueSnackbar,
  InlineEdit,
  sx,
  TextField,
  TypoTooltip,
} from '@applift/factor'

import { CreativeTypeIcon } from '../../../Mappings'
import { useCreativeTypes, useUpdateCreative } from '../../../../../hooks'
import { CREATIVE_NAME_LIMIT } from '../../../../../constants'
import {
  arraysAreEqual,
  getCreativeTypeIdFromRoute,
} from '../../../../../utils'
import { useRouter } from '@tanstack/react-router'
import { useQueryClient } from '@tanstack/react-query'

export interface CreativeNameValueTypeProps {
  creativeName: string
  creativeId: number
  platformCreativeTypeId: number
  readonly: boolean
}

export const CreativeNameValueType = (props: CreativeNameValueTypeProps) => {
  const { creativeName, creativeId, platformCreativeTypeId, readonly } = props

  const creativeTypes = useCreativeTypes().data
  const creativeTypeMappingById = creativeTypes?.creativeTypeMappingById
  const creativeTypeObject = creativeTypeMappingById?.[platformCreativeTypeId]

  const [updatedCreativeName, setUpdatedCreativeName] =
    React.useState(creativeName)

  const handleValueChange = (val: string = '') => {
    if (val.trim().length > 0) {
      setUpdatedCreativeName(val)
    } else {
      setUpdatedCreativeName('')
    }
  }

  const router = useRouter()
  const queryClient = useQueryClient()
  const onErrorUpdate = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
    setUpdatedCreativeName(creativeName)
  }

  const onSuccess = (success: any, data: any, errorObjects: any) => {
    if (success) {
      const creativeTypeIds = getCreativeTypeIdFromRoute(
        router?.history?.location?.href ?? ''
      )
      enqueueSnackbar(data?.message ?? 'Creative updated successfully', {
        variant: 'success',
      })

      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
            arraysAreEqual(
              query.queryKey?.[0]?.platformCreativeTypeIds ?? [],
              creativeTypeIds ?? []
            )
          )
        },
      })
    } else {
      const error = errorObjects?.[0]?.error
      if (error && typeof error === 'string') {
        onErrorUpdate(error)
      } else {
        onErrorUpdate('Something went wrong!')
      }
    }
  }
  const onError = (e: any) => {
    const error = e?.data?.errorObjects?.[0]?.error
    if (error && typeof error === 'string') {
      onErrorUpdate(error)
    } else {
      onErrorUpdate('Something went wrong!')
    }
  }

  const updateCreativeMutation = useUpdateCreative({ onSuccess, onError })

  const handleSubmit = () => {
    updateCreativeMutation.mutate({
      creativeName: updatedCreativeName,
      creativeId,
    })
  }

  return readonly ? (
    <Box sx={{ display: 'flex', alignItems: 'center', paddingY: 8 }}>
      <CreativeTypeIcon
        name={creativeTypeObject?.name}
        fontSize={24}
        sx={{ textColor: 'primary-600' }}
      />
      <TypoTooltip
        sx={{ ml: 8 }}
        arrow
        placement="left"
        TypgraphyProps={{
          variant: 'bodyMedium',
          weight: 'demi',
          gutterBottom: false,
        }}
      >
        {updatedCreativeName}
      </TypoTooltip>
    </Box>
  ) : (
    <InlineEdit
      value={updatedCreativeName}
      onChange={handleValueChange}
      onCancel={() => setUpdatedCreativeName(creativeName)}
      onConfirm={handleSubmit}
      validate={val => {
        if (val.trim().length > CREATIVE_NAME_LIMIT) {
          return `Character limit of ${CREATIVE_NAME_LIMIT} is exceeded`
        }
        if (!val.trim()) {
          return 'Name is required'
        }
      }}
    >
      {({ inputProps, inputRef }) => (
        <TextField
          fullWidth
          size="large"
          variant="outlinedDash"
          sx={{ flexShrink: 0 }}
          inputRef={node => {
            inputRef.current = node?.parentElement ?? node
          }}
          {...inputProps}
          inputProps={{ className: sx({ textWeight: 'demi' }) }}
          InputProps={{
            sx: { px: 8 },
            startAdornment: (
              <CreativeTypeIcon
                name={creativeTypeObject?.name}
                sx={{ textColor: 'primary-600' }}
              />
            ),
          }}
        />
      )}
    </InlineEdit>
  )
}
