import axios, { AxiosInstance } from 'axios'
import { attachIqmInstance as attachAppUtilsInstance } from '@applift/app-utils'
import { attachThirdPartyAuthInstance } from '@applift/platform-integration'

let IQMInstance: AxiosInstance

export function attachInstance(
  instance: AxiosInstance,
  token?: string,
  owid?: number,
  googleURL?: string
): void {
  attachAppUtilsInstance(instance)
  if (token && owid && googleURL) {
    attachThirdPartyAuthInstance({ google: googleURL }, token, owid)
  }

  IQMInstance = instance
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios
}
