import React from 'react'
import {
  BaseTextFieldProps,
  Box,
  SelectAdvanceComponents,
  SelectAdvanceProps,
  TextField,
  TypoTooltip,
  useDebounceValue,
  useEventCallback,
} from '@applift/factor'
import {
  useCreativeGroupsList,
  UseCreativeGroupsListParams,
} from '../../hooks/useCreativeGroupsList'
import { CreativeGroup } from '../../api/getCreativeGroupsList'
import { RowSelectionState } from '@applift/datagrid'
import { transformCreativeGroupInitDataToRowSelection } from './helpers'
import { Updater } from '@tanstack/react-table'
import { ListItemRenderer, renderOption } from './renderers'

type ChangeReason = 'userAction' | 'apiAction' | 'prepopulate'

type OmittedProps = Omit<
  SelectAdvanceProps<CreativeGroup>,
  | 'data'
  | 'rowCount'
  | 'hideSelectNav'
  | 'search'
  | 'hideSearch'
  | 'loading'
  | 'renderContentTop'
  | 'overlay'
  | 'renderListItem'
  | 'onFetchSubRows'
  | 'enableSubRowSelection'
  | 'getSubRows'
  | 'renderOption'
  | 'isRowLoading'
  | 'value'
  | 'onChange'
  | 'onInputChange'
> & {}

export type CreativeGroupSelectProps = OmittedProps & {
  TextFieldProps?: Omit<BaseTextFieldProps<CreativeGroup>, 'selectProps'>
  initialData?: Record<string, CreativeGroup>
  /**
   * @default true
   * Use this flag to enable/disable query execution until a particular condition is satisfied
   */
  enabled?: boolean
  /**
   * It will autoPopulate the first campaign and set it as selected and also call onChange handler
   */
  /**
   * @default false
   * Use this flag to enable client side searching
   */
  enableLocalSearch?: boolean
  /**
   * @default true
   * Use this flag to enable and disable lazy-loading
   */
  enableLazyLoading?: boolean
  prepopulate?: boolean
  onChange?: (
    data: Record<string, CreativeGroup>,
    reason?: ChangeReason,
    isSelectAll?: boolean
  ) => void
  params: Omit<
    UseCreativeGroupsListParams,
    'searchField' | 'isSelectAll' | 'noOfEntries'
  >
}

export interface CreativeGroupSelectApiRefType {
  getRowSelection: () => RowSelectionState
  getRowSelectionData: () => Map<string, CreativeGroup>
  getCurrentData: () => CreativeGroup[]
  removeSelection: (value: string) => void
  setSelection: (rowId: string) => void
  reinitializeRowSelection: (initData: Record<string, CreativeGroup>) => void
  setSearch: React.Dispatch<React.SetStateAction<string>>
  reset: (resetPopulateEffect?: boolean) => void
}

export const CreativeGroupSelect = React.forwardRef<
  CreativeGroupSelectApiRefType | undefined,
  CreativeGroupSelectProps
>((props, ref) => {
  const {
    TextFieldProps,
    multiple = false,
    slotProps,
    enabled = true,
    enableLocalSearch = false,
    enableLazyLoading = true,
    params,
    onChange,
    getOptionDisabled,
    initialData: _, // not using this
    ...others
  } = props
  let { initialData } = props

  const [search, setSearch] = React.useState('')
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    transformCreativeGroupInitDataToRowSelection(initialData)
  )
  const [isSelectAll, setIsSelectAll] = React.useState(false)

  const apiRef = SelectAdvanceComponents.useSelectAdvanceApiRef<CreativeGroup>()
  const rowSelectionRef = React.useRef(rowSelection)
  rowSelectionRef.current = rowSelection

  const isPrepopulateDataEffectExecuted = React.useRef(false)

  const rowSelectionData = React.useRef(
    new Map<string, CreativeGroup>(Object.entries(initialData ?? {}))
  )

  const debouncedSearch = useDebounceValue(search, 250)

  const alwaysAvailableOnChange = onChange!
  const stableOnChange = useEventCallback<
    Parameters<typeof alwaysAvailableOnChange>,
    void
  >((data, reason, isSelectAll) => {
    onChange?.(data, reason, isSelectAll)
  })

  const setSearchWrapper = React.useCallback<
    React.Dispatch<React.SetStateAction<string>>
  >(
    value => {
      setSearch(value)
      setIsSelectAll(false)
    },
    [setSearch]
  )

  const {
    data: creativeGroupData,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useCreativeGroupsList(
    {
      ...params,
      searchField: enableLocalSearch ? undefined : debouncedSearch,
      isSelectAll: enableLazyLoading ? isSelectAll : true,
    },
    {
      enabled: enabled,
    }
  )

  const data = React.useMemo(() => {
    return (
      creativeGroupData?.pages?.reduce<CreativeGroup[]>((prev, curr) => {
        return [...prev, ...(curr?.data?.creativeGroupList ?? [])]
      }, []) ?? []
    )
  }, [creativeGroupData])

  const dataRef = React.useRef(data)
  dataRef.current = data

  const filteredRecords = React.useMemo(
    () => creativeGroupData?.pages[0]?.data?.filteredRecords ?? 0,
    [creativeGroupData]
  )

  const overlay = React.useMemo(() => {
    if (!(isLoading || isFetching)) {
      if (!Array.isArray(data)) {
        return 'error'
      }
      if ((data?.length ?? 0) <= 0) {
        if (search) {
          return 'noResult'
        }
        return 'noRows'
      }
    }
    return undefined
  }, [data, isLoading, isFetching, search])

  // Here from the selectedRowsMap we filter out the rows that are part of current data
  // only include selection that is part of current selection
  const getSelectedRowsForCurrentData = () => {
    const selectedRowsEntries = rowSelectionData.current.entries()
    const allRowsById = apiRef.current.tableInstance.getRowModel().rowsById
    const filteredSelectedRows: Record<string, CreativeGroup> = {}
    for (const selectedRow of selectedRowsEntries) {
      const [id, row] = selectedRow
      if (allRowsById[id]) {
        filteredSelectedRows[id] = row
      }
    }
    return filteredSelectedRows
  }

  const setRowSelectionWrapper = (updater: Updater<RowSelectionState>) => {
    const selectedRows =
      typeof updater === 'function' ? updater(rowSelection) : updater

    if (multiple === false) {
      const selectedRow = Object.keys(selectedRows)[0]!
      const allRowsById = apiRef.current.tableInstance.getRowModel().rowsById
      const data = allRowsById[selectedRow]?.original
      rowSelectionData.current.clear()
      rowSelectionData.current.set(selectedRow, data!)
      setRowSelection(updater)
      stableOnChange(
        Object.fromEntries(rowSelectionData.current.entries()),
        'userAction'
      )
      return
    }

    const selectedRowsArray = Object.keys(selectedRows)
    const currentSelectedRows = getSelectedRowsForCurrentData()
    const currentSelectedRowsArray = Object.keys(currentSelectedRows)
    const allRowsById = apiRef.current.tableInstance.getRowModel().rowsById
    const additions = selectedRowsArray.filter(
      one => currentSelectedRows[one] === undefined
    )
    const removals = currentSelectedRowsArray.filter(
      one => selectedRows[one] === undefined
    )
    for (const one of additions) {
      // there are cases where we get invalid selection when currentSelectedRows are zero
      // so we want to guard against non existent selections
      if (allRowsById[one]?.original) {
        rowSelectionData.current.set(one, allRowsById[one]!.original!)
      }
    }
    for (const one of removals) {
      rowSelectionData.current.delete(one)
    }
    setRowSelection(updater)
    stableOnChange(
      Object.fromEntries(rowSelectionData.current.entries()),
      'userAction'
    )
  }

  React.useEffect(() => {
    if (isSelectAll && data.length > 0) {
      const rowSelection: RowSelectionState = {}
      rowSelectionData.current.clear()
      data.forEach(one => {
        // option disabled and not part of initial state do not set it to true for selectAll
        if ((getOptionDisabled?.(one) ?? false) === true) {
          if (Boolean(initialData?.[one.creativeGroupId]) === false) {
            return
          }
        }
        rowSelection[one.creativeGroupId] = true
        rowSelectionData.current.set(one.creativeGroupId.toString(), one)
      })
      setRowSelection(rowSelection)
      rowSelectionRef.current = rowSelection
      stableOnChange(
        Object.fromEntries(rowSelectionData.current.entries()),
        'userAction',
        true
      )
    }
  }, [isSelectAll, data, initialData, getOptionDisabled, stableOnChange])

  // API function to remove selection
  const removeSelectionFn: CreativeGroupSelectApiRefType['removeSelection'] =
    value => {
      rowSelectionData.current.delete(value)
      const rowSelectionCloned = { ...rowSelection }
      delete rowSelectionCloned[value]
      setRowSelection(rowSelectionCloned)
      stableOnChange(
        Object.fromEntries(rowSelectionData.current.entries()),
        'apiAction'
      )
    }

  // API function to perform selection
  const setRowSelectionFn: CreativeGroupSelectApiRefType['setSelection'] =
    rowId => {
      try {
        const row = apiRef.current.tableInstance.getRow(rowId)
        if (row) {
          // @ts-ignore
          rowSelectionData.current.set(row.id, row.original)
          // @ts-ignore
          setRowSelection({ ...rowSelection, [row.id]: true })
          stableOnChange(
            Object.fromEntries(rowSelectionData.current.entries()),
            'apiAction'
          )
        }
      } catch (e) {
        console.log(e)
        console.log('cannot add row that is not part of current dataset')
        // ignore error silently
      }
    }

  // API function to perform reinitialization of selection
  const reinitializeRowSelectionFn: CreativeGroupSelectApiRefType['reinitializeRowSelection'] =
    initData => {
      initialData = initData
      const rowSelection =
        transformCreativeGroupInitDataToRowSelection(initialData)
      rowSelectionData.current = new Map<string, CreativeGroup>(
        Object.entries(initialData ?? {})
      )
      setRowSelection(rowSelection)
      rowSelectionRef.current = rowSelection
    }

  const reset = (resetPopulateEffect: boolean = true) => {
    isPrepopulateDataEffectExecuted.current = !resetPopulateEffect
    setSearchWrapper('')
    const newMap = new Map<string, CreativeGroup>()
    const newSelectionObj: RowSelectionState = {}
    for (const one of rowSelectionData.current.entries()) {
      const [rowId, rowData] = one
      if ((getOptionDisabled?.(rowData) ?? false) === true) {
        newMap.set(rowId, rowData)
        newSelectionObj[rowId] = true
      }
    }
    setRowSelection(newSelectionObj)
    rowSelectionRef.current = newSelectionObj
    rowSelectionData.current = newMap
  }

  React.useImperativeHandle(ref, () => ({
    getRowSelection: () => rowSelectionRef.current,
    getRowSelectionData: () => rowSelectionData.current,
    getCurrentData: () => dataRef?.current ?? [],
    removeSelection: removeSelectionFn,
    setSelection: setRowSelectionFn,
    reinitializeRowSelection: reinitializeRowSelectionFn,
    setSearch: setSearchWrapper,
    selectAll: () => setIsSelectAll(true),
    reset: reset,
  }))

  return (
    <TextField
      size="medium"
      {...TextFieldProps}
      select="advanceSelect"
      placeholder="Select existing groups"
      SelectProps={{
        slotProps: {
          ...slotProps,
          InputBaseProps: {
            placeholder: 'Search by group name or ID',
            ...slotProps?.InputBaseProps,
          },
        },
        ...others,
        getOptionDisabled,
        data: data ?? [],
        apiRef: apiRef,
        inputValue: search,
        multiple: multiple,
        loading: isLoading || isFetching,
        overlay: overlay,
        getRowId: (row: CreativeGroup) => {
          if (row && typeof row.creativeGroupId === 'number') {
            return `${row.creativeGroupId}`
          }
          return String(row.creativeGroupId)
        },
        onInputChange: (_, newSearchVal, reason) => {
          if (reason !== 'reset') {
            setSearchWrapper(newSearchVal)
          }
        },
        rowCount: filteredRecords,
        onFetchRows: () => fetchNextPage(),
        renderValue: () => {
          const selectedItems = Object.keys(rowSelectionRef.current)
          if (multiple) {
            if (selectedItems.length) {
              return `${selectedItems.length} Selected`
            }
            return '\u200b'
          } else {
            const rowId = selectedItems[0] ?? ''
            const rowData = rowSelectionData.current.get(rowId)

            return (
              <Box
                Component="span"
                sx={{ display: 'flex', gap: 4, overflow: 'hidden' }}
              >
                <TypoTooltip
                  TypgraphyProps={{
                    component: 'span',
                  }}
                >
                  {rowData?.creativeGroupName ?? '\u200b'}
                </TypoTooltip>
              </Box>
            )
          }
        },
        renderListItem: ListItemRenderer,
        renderOption: ({ row }) => renderOption(row),
        onSelectAll: isSelectAll => {
          if (isSelectAll) {
            setIsSelectAll(true)
          }
        },
        onClearAll: () => {
          setTimeout(() => {
            const newMap = new Map<string, CreativeGroup>()
            const newSelectionObj: RowSelectionState = {}
            for (const one of rowSelectionData.current.entries()) {
              const [rowId, rowData] = one
              if ((getOptionDisabled?.(rowData) ?? false) === true) {
                newMap.set(rowId, rowData)
                newSelectionObj[rowId] = true
              }
            }

            setRowSelection(newSelectionObj)
            rowSelectionRef.current = newSelectionObj
            rowSelectionData.current = newMap
            stableOnChange(
              Object.fromEntries(rowSelectionData.current.entries()),
              'userAction'
            )
          }, 0)
        },
      }}
      value={rowSelection}
      onChange={setRowSelectionWrapper}
    />
  )
})

CreativeGroupSelect.displayName === 'CreativeGroupSelect'
