import {
  Avatar,
  Box,
  enqueueSnackbar,
  Skeleton,
  Typography,
  useSnackbar,
} from '@applift/factor'
import { useStore } from '@tanstack/react-store'
import { Delete } from '@applift/icons'
import * as React from 'react'
import {
  ERROR_MESSAGES,
  MAX_NATIVE_MEDIA_LIMIT,
  MAX_NATIVE_VIDEO_THUMBMAIL_LIMT,
  NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS,
} from '../../../../../../constants'
import { MediaQueue } from '../../../../../../hooks/useMediaQueue'
import { ErrorCSVType, MediaFile } from '../../../../../../models'
import { errorFileToast, isValidURL } from '../../../../../../utils'
import { NativeCreationFormType } from '../../CreateWrapper'
import { ImageDropZone } from './ImageDropZone'
import { VideoDropzone } from './VideoDropzone'
import styles from './index.module.scss'
import {
  validateImageDimensions,
  validateImageFileDimensions,
  validateVideoFileUpload,
} from './utils'

interface Props {
  form: NativeCreationFormType
  imageQueue: MediaQueue
  updateMasterList: () => void
  videoThumbmailQueue: MediaQueue
  videoQueue: MediaQueue
  isCreateClicked: boolean
}

export const Dropzone = (props: Props) => {
  const {
    form,
    imageQueue,
    updateMasterList,
    videoThumbmailQueue,
    videoQueue,
    isCreateClicked,
  } = props

  const { nativeType, imageObjects, uniqueId, videoThumbnail, videoObjects } =
    useStore(form.store, state => state.values)
  const { closeSnackbar } = useSnackbar()
  // how many images are being processed for currently selected creative
  const currentlyProcessingImageCount = React.useMemo(
    () =>
      [...imageQueue.currentProcessingBatch, ...imageQueue.queue]
        .map(processingItem => processingItem.uniqueId.split('_'))
        .filter(
          splitUniqueId => splitUniqueId[0] === form.state.values.uniqueId
        ).length,
    [form, imageQueue]
  )
  const currentlyProcessingVideoCount = React.useMemo(
    () =>
      [...videoQueue.currentProcessingBatch, ...videoQueue.queue]
        .map(processingItem => processingItem.uniqueId.split('_'))
        .filter(
          splitUniqueId => splitUniqueId[0] === form.state.values.uniqueId
        ).length,
    [form, videoQueue]
  )
  const currentlyProcessingVideoThumbmailCount = React.useMemo(
    () =>
      [
        ...videoThumbmailQueue.currentProcessingBatch,
        ...videoThumbmailQueue.queue,
      ]
        .map(processingItem => processingItem.uniqueId.split('_'))
        .filter(
          splitUniqueId => splitUniqueId[0] === form.state.values.uniqueId
        ).length,
    [form, videoThumbmailQueue]
  )

  const onImageUpload = React.useCallback(
    async (files: File[]) => {
      const exceedsLimit =
        currentlyProcessingImageCount + imageObjects.length + files.length >
        MAX_NATIVE_MEDIA_LIMIT

      if (exceedsLimit) {
        return enqueueSnackbar(
          `Cannot upload more than ${MAX_NATIVE_MEDIA_LIMIT} files.`,
          { variant: 'error' }
        )
      }

      const errorLogs: ErrorCSVType[] = []
      const validFiles = await validateImageFileDimensions(
        files,
        NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS,
        errorLogs
      )
      if (validFiles?.length) {
        const updatedFiles: MediaFile[] = validFiles.map(fileObject => ({
          fileObject,
          uniqueId: `${uniqueId}_${crypto.randomUUID()}`,
        }))
        imageQueue.enqueue(updatedFiles)
      }
      if (errorLogs?.length) {
        errorFileToast(errorLogs, closeSnackbar, 'native_error_file.csv')
      }
    },
    [
      closeSnackbar,
      currentlyProcessingImageCount,
      imageObjects,
      imageQueue,
      uniqueId,
    ]
  )
  const onVideoUpload = React.useCallback(
    async (files: File[]) => {
      const validUpload = await validateVideoFileUpload(files)
      const exceedsLimit =
        videoObjects.length +
          currentlyProcessingVideoCount +
          validUpload?.validFiles?.length +
          validUpload?.validUrls?.length +
          validUpload?.validXmls?.length >
        MAX_NATIVE_MEDIA_LIMIT
      if (exceedsLimit) {
        return enqueueSnackbar(
          `Cannot upload more than ${MAX_NATIVE_MEDIA_LIMIT} files.`,
          { variant: 'error' }
        )
      }

      const { validFiles, validUrls, validXmls, errorLogs } = validUpload
      if (validFiles.length) {
        const updatedFiles: MediaFile[] = validUpload.validFiles.map(item => {
          return {
            fileObject: { file: item.file, duration: item.duration },
            uniqueId: `${uniqueId}_${crypto.randomUUID()}`,
          }
        })
        videoQueue.enqueue(updatedFiles)
      }
      if (validXmls.length) {
        validXmls.map(item => {
          form.setFieldValue('videoObjects', [
            ...form.state.values.videoObjects,
            {
              src: item.videoURL,
              creativeSource: item.xmlCode,
              creativeSourceURL: '',
              duration: item.duration,
              name: '',
              parentId: uniqueId,
              temporaryMediaId: crypto.randomUUID(),
              height: item?.height,
              width: item?.width,
            },
          ])
        })
      }

      if (validUrls.length) {
        validUrls.map(item => {
          form.setFieldValue('videoObjects', [
            ...form.state.values.videoObjects,
            {
              src: item.videoURL,
              creativeSource: '',
              creativeSourceURL: item.xmlURL,
              duration: item.duration,
              name: '',
              parentId: uniqueId,
              temporaryMediaId: crypto.randomUUID(),
              height: item?.height,
              width: item?.width,
            },
          ])
        })
      }
      updateMasterList()
      if (errorLogs.length) {
        errorFileToast(errorLogs, closeSnackbar, 'native_error_file.csv')
      }

      // to-do setting form and calling error toast based on error log is left
    },
    [
      closeSnackbar,
      currentlyProcessingVideoCount,
      form,
      uniqueId,
      updateMasterList,
      videoObjects.length,
      videoQueue,
    ]
  )

  const onThumbmailUpload = React.useCallback(
    async (files: File[]) => {
      const exceedsLimit = Boolean(videoThumbnail)

      if (exceedsLimit) {
        return enqueueSnackbar(`Cannot upload more than ${1} files.`, {
          variant: 'error',
        })
      }
      const errorLogs: ErrorCSVType[] = []
      const validFiles = await validateImageFileDimensions(
        files,
        NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS,
        errorLogs
      )
      if (validFiles?.length) {
        const updatedFiles: MediaFile[] = validFiles.map(fileObject => ({
          fileObject,
          uniqueId: `${uniqueId}_${crypto.randomUUID()}`,
        }))
        videoThumbmailQueue.enqueue(updatedFiles)
      }
      if (errorLogs?.length) {
        errorFileToast(errorLogs, closeSnackbar, 'thumbmail_error_files.csv')
      }
    },
    [videoThumbnail, videoThumbmailQueue, uniqueId, closeSnackbar]
  )

  const addImageFromURL = async (onSuccess: () => void, imageURL: string) => {
    if (imageURL.length > 0 && isValidURL(imageURL)) {
      const exceedsLimit =
        currentlyProcessingImageCount + imageObjects.length >
        MAX_NATIVE_MEDIA_LIMIT

      if (exceedsLimit) {
        return enqueueSnackbar(
          `Maximum of ${MAX_NATIVE_MEDIA_LIMIT} files can be uploaded`,
          { variant: 'error' }
        )
      }

      const imageDimensions = await validateImageDimensions(
        imageURL,
        NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS
      )
        .then((image: HTMLImageElement) => ({
          height: image.naturalHeight,
          width: image.naturalWidth,
        }))
        .catch(() => {
          return false
        })

      if (imageDimensions && typeof imageDimensions === 'object') {
        form.setFieldValue('imageObjects', [
          ...form.state.values.imageObjects,
          {
            src: imageURL,
            height: imageDimensions.height,
            width: imageDimensions.width,
            name: '',
            temporaryMediaId: crypto.randomUUID(),
            parentId: uniqueId,
          },
        ])
        updateMasterList()
        enqueueSnackbar('Uploaded image from URL', { variant: 'success' })
        onSuccess()
      } else {
        enqueueSnackbar(ERROR_MESSAGES.INVALID_ASPECT_RATIO, {
          variant: 'error',
        })
      }
    }
  }
  const addThumbamailFromURL = async (
    onSuccess: () => void,
    imageURL: string
  ) => {
    if (imageURL.length > 0 && isValidURL(imageURL)) {
      const exceedsLimit = Boolean(videoThumbnail)

      if (exceedsLimit) {
        return enqueueSnackbar(
          `Maximum of ${MAX_NATIVE_VIDEO_THUMBMAIL_LIMT} files can be uploaded`,
          { variant: 'error' }
        )
      }

      const imageDimensions = await validateImageDimensions(
        imageURL,
        NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS
      )
        .then((image: HTMLImageElement) => ({
          height: image.naturalHeight,
          width: image.naturalWidth,
        }))
        .catch(() => {
          return false
        })

      if (imageDimensions && typeof imageDimensions === 'object') {
        form.setFieldValue('videoThumbnail', {
          src: imageURL,
          height: imageDimensions.height,
          width: imageDimensions.width,
          name: '',
          temporaryMediaId: crypto.randomUUID(),
          parentId: uniqueId,
        })
        updateMasterList()
        enqueueSnackbar('Uploaded image from URL', { variant: 'success' })
        onSuccess()
      } else {
        enqueueSnackbar(
          'Image from URL should match the mentioned specifications',
          { variant: 'error' }
        )
      }
    }
  }

  const hideVideoThumbmailDropzone =
    Boolean(currentlyProcessingVideoThumbmailCount) || Boolean(videoThumbnail)
  const hideImageError =
    Boolean(imageObjects?.length) || Boolean(currentlyProcessingImageCount)

  return nativeType === 'image' ? (
    <>
      <ImageDropZone
        onImageUpload={onImageUpload}
        addImageFromURL={addImageFromURL}
        allowsMultiple
      />

      {hideImageError === false && isCreateClicked ? (
        <Typography
          component="span"
          variant="label"
          lineHeight="single-line"
          sx={{
            marginTop: 4,
            marginX: 12,
            textColor: 'danger-500',
          }}
        >
          Creative is required
        </Typography>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          height: 100,
          mt: 12,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 8,
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <form.Field name="imageObjects" mode="array">
            {field => {
              return (
                <>
                  {field.state.value.map((imageItem, i) => {
                    return (
                      <form.Field key={i} name={`imageObjects[${i}].src`}>
                        {() => {
                          return (
                            <Box
                              sx={{
                                position: 'relative',
                                display: 'flex',
                                gap: 4,
                                alignItems: 'start',
                              }}
                              style={{
                                width: '80px',
                                height: '60px',
                                cursor: 'pointer',
                              }}
                              className={styles.imageWrapper}
                              key={`${imageItem.temporaryMediaId}_${i}`}
                              onClick={() => {
                                field.removeValue(i)
                                updateMasterList()
                              }}
                            >
                              <Avatar
                                variant="square"
                                src={imageItem.src}
                                sx={{
                                  width: 100,
                                  height: 100,
                                  border: 1,
                                  borderRadius: 4,
                                  borderColor: 'neutral-300',
                                }}
                              />
                              <Box
                                sx={{ position: 'absolute' }}
                                className={styles.actionIcon}
                              >
                                <Delete
                                  fontSize={14}
                                  sx={{
                                    fontSize: 16,
                                    textColor: 'neutral-0',
                                  }}
                                />
                              </Box>
                            </Box>
                          )
                        }}
                      </form.Field>
                    )
                  })}
                </>
              )
            }}
          </form.Field>

          {new Array(currentlyProcessingImageCount)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                variant="rectangular"
                height={60}
                width={80}
                key={`skeleton_${index}`}
              />
            ))}
        </Box>
      </Box>
    </>
  ) : (
    <>
      <VideoDropzone
        form={form}
        onVideoUpload={onVideoUpload}
        processingFileCount={currentlyProcessingVideoCount}
        isCreateClicked={isCreateClicked}
        updateMasterList={updateMasterList}
      />
      <Box sx={{ mb: 4, mt: 24 }}>
        <Typography variant="label" sx={{ textColor: 'neutral-500', ml: 12 }}>
          Thumbnail (only for video)
        </Typography>
        <Typography sx={{ textColor: 'danger-400', fontSize: 12, mx: 2 }}>
          *
        </Typography>
      </Box>
      {hideVideoThumbmailDropzone === false && (
        <ImageDropZone
          onImageUpload={onThumbmailUpload}
          addImageFromURL={addThumbamailFromURL}
        />
      )}
      {hideVideoThumbmailDropzone === false && isCreateClicked ? (
        <Typography
          component="span"
          variant="label"
          lineHeight="single-line"
          sx={{
            marginTop: 4,
            marginX: 12,
            textColor: 'danger-500',
          }}
        >
          Thumbnail is required
        </Typography>
      ) : null}

      <Box
        sx={{
          display: hideVideoThumbmailDropzone ? 'flex' : 'none',
          mb: 4,
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 8, height: 100 }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 8,
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <form.Field name="videoThumbnail">
              {field => {
                return (
                  <Box
                    sx={{
                      position: 'relative',
                      display: currentlyProcessingVideoThumbmailCount
                        ? 'none'
                        : 'flex',
                      gap: 4,
                      alignItems: 'start',
                    }}
                    style={{
                      width: '80px',
                      height: '60px',
                      cursor: 'pointer',
                    }}
                    className={styles.imageWrapper}
                    onClick={() => {
                      field.handleChange(null)
                      updateMasterList()
                    }}
                  >
                    {Boolean(field.state.value?.src) && (
                      <Avatar
                        variant="square"
                        src={field.state.value?.src}
                        sx={{
                          width: 100,
                          height: 100,
                          border: 1,
                          borderRadius: 4,
                          borderColor: 'neutral-300',
                        }}
                      />
                    )}
                    <Box
                      sx={{ position: 'absolute' }}
                      className={styles.actionIcon}
                    >
                      <Delete
                        fontSize={14}
                        sx={{
                          fontSize: 16,
                          textColor: 'neutral-0',
                        }}
                      />
                    </Box>
                  </Box>
                )
              }}
            </form.Field>

            {new Array(currentlyProcessingVideoThumbmailCount)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  variant="rectangular"
                  height={60}
                  width={80}
                  key={`skeleton_${index}`}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}
