import { Row, Col, Box, enqueueSnackbar } from '@applift/factor'
import { WebsiteResponsive } from '@applift/illustrations'
import { FileUpload, Id, Group } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'
import { useDialogManager } from '../dialogManager'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'
import { NATIVE_CREATIVE_ID } from '../../constants'
import { AddNewButton } from './AddNewButton'

export const NativeEmptyTab = () => {
  const { setAction } = useDialogManager()
  const queryClient = useQueryClient()
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={
                <WebsiteResponsive sx={{ width: 100, height: 100 }} />
              }
              title="Native Creative"
              subText="Deliver unobtrusive ads that compliment the content that surrounds them."
              action={
                <AddNewButton
                  onNewClick={() =>
                    setAction('native', undefined, {
                      onSuccess: (
                        response: CreateCreativeResponse,
                        request: CreateCreativeRequest
                      ) => {
                        const createdCreativesId = Object.values(
                          response.successData
                        )
                        const failedCreatives = Object.keys(response.failedData)
                        if (createdCreativesId.length) {
                          enqueueSnackbar({
                            message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                            variant: 'success',
                          })
                        }
                        if (failedCreatives.length) {
                          enqueueSnackbar({
                            message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                            variant: 'error',
                          })
                        }

                        queryClient.resetQueries({
                          predicate: (query: any) =>
                            query.queryKey?.[0]?.scope ===
                              'getAllCreativeList' &&
                            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                              NATIVE_CREATIVE_ID
                            ),
                        })
                        const succeededCreative = Object.keys(
                          response.successData ?? {}
                        ).map(assetId => {
                          return {
                            creativeId: Number(
                              response.successData[`${assetId}`]
                            ),
                            platformCreativeTypeId: NATIVE_CREATIVE_ID,
                            creativeName: request[`${assetId}`]?.creativeName,
                          }
                        })
                        if (succeededCreative.length) {
                          setAction('group', succeededCreative)
                        }
                      },
                    })
                  }
                  onExisitingClick={() =>
                    setAction('existingNative', undefined, {
                      onSuccess: (
                        response: CreateCreativeResponse,
                        request: CreateCreativeRequest
                      ) => {
                        const createdCreativesId = Object.values(
                          response.successData
                        )
                        const failedCreatives = Object.keys(response.failedData)
                        if (createdCreativesId.length) {
                          enqueueSnackbar({
                            message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                            variant: 'success',
                          })
                        }
                        if (failedCreatives.length) {
                          enqueueSnackbar({
                            message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                            variant: 'error',
                          })
                        }
                        queryClient.resetQueries({
                          predicate: (query: any) =>
                            query.queryKey?.[0]?.scope ===
                              'getAllCreativeList' &&
                            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                              NATIVE_CREATIVE_ID
                            ),
                        })
                        const succeededCreative = Object.keys(
                          response.successData ?? {}
                        ).map(assetId => {
                          return {
                            creativeId: Number(
                              response.successData[`${assetId}`]
                            ),
                            platformCreativeTypeId: NATIVE_CREATIVE_ID,
                            creativeName: request[`${assetId}`]?.creativeName,
                          }
                        })
                        if (succeededCreative.length) {
                          setAction('group', succeededCreative)
                        }
                      },
                    })
                  }
                  isOutlined
                />
              }
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<Id />}
                title="Define Text Variations"
                subText="Add up to five combinations of titles and descriptions to a single creative."
              />
              <EmptyFeatureCard
                icon={<Group />}
                title="Apply Visual Variation"
                subText="Generate multiple combinations of the titles, descriptions, images, and videos that you add to create variants of the same ad.  "
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple files at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
