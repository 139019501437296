import { Box, Divider } from '@applift/factor'

import { CardListItem } from './CardListItem'
import { Creative } from '../../../../models'
import { GirdCardBase } from '../../../../components'
import {
  CampaignsCountValueType,
  ClickUrlValueType,
  CreatedDateValueType,
  CreativeIDValueType,
  CreativeNameValueType,
  CreativePreviewImage,
  CreativeSizeValueType,
  CreativeStatusValueType,
  PixelUrlValueType,
  ThirdPartyIDValueType,
} from './CardValuesTypes'
import { InlineEditAction } from '../../CreativeTableActions'
import { useCreativeStatuses } from '../../../../hooks'
import { isImageCreative, isNativeImageCreatve } from '../../../../utils'
import { MANDATORY_CLICK_URL_SOURCE_TYPE } from '../../../../constants'

interface CardProps {
  selected: boolean
  setAction?: React.Dispatch<React.SetStateAction<InlineEditAction>>
  readonly?: boolean
  creativeData: Creative
  organizationTimezoneName?: string
  onSelectionChange: (
    selected: boolean,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

const defaultFn: React.Dispatch<
  React.SetStateAction<InlineEditAction>
> = () => {
  return undefined
}

// dd
export const Card = (props: CardProps) => {
  const {
    selected,
    onSelectionChange,
    creativeData,
    setAction = defaultFn,
    readonly = false,
    organizationTimezoneName,
  } = props

  const {
    creativeName,
    creativeId,
    creativeStatusId,
    externalCreativeId,
    clickUrl,
    pixelUrl,
    createdAt,
    creativeHeight,
    creativeWidth,
    duration,
    platformCreativeTypeId,
    campaignDetails,
  } = creativeData

  const { data: creativeStatusData } = useCreativeStatuses()

  const isDeletedCreative =
    creativeStatusId ===
    creativeStatusData?.creativeStatusMappingByName['deleted']?.id

  const isRejectedCreative =
    creativeStatusId ===
    creativeStatusData?.creativeStatusMappingByName['rejected']?.id

  const isImage = isImageCreative(creativeData)
  const isNativeImage = isNativeImageCreatve(creativeData)

  const isImageTypeCreative = isImage || isNativeImage

  return (
    <GirdCardBase
      selected={selected}
      onSelectionChange={onSelectionChange}
      sx={{ height: 100 }}
    >
      <CreativePreviewImage creative={creativeData} />
      <Divider sx={{ borderColor: 'neutral-200', flexShrink: 0 }} />
      <Box
        sx={{
          width: 100,
          height: 100,
          pt: 16,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <CreativeNameValueType
          creativeId={creativeId}
          creativeName={creativeName}
          platformCreativeTypeId={platformCreativeTypeId}
          readonly={isDeletedCreative || isRejectedCreative || readonly}
        />
        <Box
          sx={{
            px: 12,
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <CardListItem
            label="ID:"
            value={
              <CreativeIDValueType
                creativeId={creativeId}
                readonly={readonly}
                setAction={setAction}
              />
            }
          />
          <CardListItem
            label="Status"
            value={
              <CreativeStatusValueType
                creativeStatusId={creativeStatusId ?? -1}
              />
            }
          />
          <CardListItem
            label="3rd Party ID:"
            value={
              <ThirdPartyIDValueType
                readonly={isDeletedCreative || isRejectedCreative || readonly}
                thirdPartyId={externalCreativeId ?? ''}
                creativeId={creativeId}
                setAction={setAction}
              />
            }
          />
          <CardListItem
            label="Click URL:"
            required={MANDATORY_CLICK_URL_SOURCE_TYPE.includes(
              creativeData?.creativeSourceType
            )}
            value={
              <ClickUrlValueType
                clickUrl={clickUrl ?? ''}
                creativeId={creativeId}
                readonly={isDeletedCreative || isRejectedCreative || readonly}
                setAction={setAction}
                creative={creativeData}
              />
            }
          />
          <CardListItem
            label="Pixel URL:"
            value={
              <PixelUrlValueType
                pixelUrl={pixelUrl ?? ''}
                readonly={
                  isDeletedCreative ||
                  isRejectedCreative ||
                  isImageTypeCreative === false ||
                  readonly
                }
                creativeId={creativeId}
                setAction={setAction}
              />
            }
          />
          <CardListItem
            label="Created:"
            value={
              <CreatedDateValueType
                createdAt={createdAt}
                timezone={organizationTimezoneName}
              />
            }
          />
          <CardListItem
            label="Size:"
            value={
              <CreativeSizeValueType
                creativeWidth={creativeWidth ?? 0}
                creativeHeight={creativeHeight ?? 0}
                duration={duration ?? 0}
                platformCreativeTypeId={platformCreativeTypeId}
                nativeMediaType={creativeData.subMediaType ?? ''}
              />
            }
          />
          <CardListItem
            label="Campaigns:"
            value={
              <CampaignsCountValueType
                readonly={readonly}
                campaignCount={campaignDetails?.campaignIds?.length ?? 0}
                creativeData={creativeData}
                setAction={setAction}
              />
            }
          />
        </Box>
      </Box>
    </GirdCardBase>
  )
}
