import { MutateOptions, useMutation } from '@tanstack/react-query'

import { updateCreativeStatus } from '../api'

type useUpdateCreativeStatusMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof updateCreativeStatus>>,
  any,
  Parameters<typeof updateCreativeStatus>[0],
  unknown
>

export const useUpdateCreativeStatus = (
  options: useUpdateCreativeStatusMutationOptions
) => {
  return useMutation(updateCreativeStatus, {
    ...options,
    mutationKey: ['updateCreativeStatus'],
  })
}
