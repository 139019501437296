import * as React from 'react'
import { Box, Paper, Row, Col } from '@applift/factor'
import { HTMLCard } from './HTMLCard'
import { HTMLCreativeGenerationData } from '../../../../../models/Creative'
import { ActionPanel } from './ActionPanel'

export interface CreateProps {
  data: Map<string, HTMLCreativeGenerationData>
  onChange?: (creative: HTMLCreativeGenerationData[]) => void
  onRemove?: (id: string) => void
  onError?: (hasError: boolean) => void
}

export const Create = (props: CreateProps) => {
  const { data, onChange, onRemove, onError } = props
  const [actionPanelError, setActionPanelError] = React.useState<boolean>(false)

  const hasErrorInGrid = React.useMemo(() => {
    return Array.from(data.values()).some(creative => creative.hasError)
  }, [data])

  const creativeList = React.useMemo(() => Array.from(data.values()), [data])

  React.useEffect(() => {
    onError?.(actionPanelError || hasErrorInGrid)
  }, [actionPanelError, hasErrorInGrid, onError])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          width: 100,
          overflow: 'hidden',
          alignItems: 'center',
          px: 24,
          py: 12,
          gap: 16,
        }}
      >
        <ActionPanel
          data={data}
          setError={setActionPanelError}
          onChange={onChange}
        />
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          overflow: 'auto',
          p: 24,
        }}
      >
        <Row
          xs={{ gutterSize: 16, col: 1 }}
          md={{ gutterSize: 24, col: 2 }}
          lg={3}
        >
          {creativeList.map(creative => (
            <Col key={creative.id}>
              <HTMLCard
                id={creative.id}
                htmlCode={creative.htmlCode}
                width={creative?.width}
                height={creative?.height}
                name={creative?.name}
                thirdPartyID={creative?.thirdPartyID}
                clickURL={creative?.clickURL}
                warning={creative?.warning}
                onChange={onChange}
                onRemove={onRemove}
              />
            </Col>
          ))}
        </Row>
      </Box>
    </Box>
  )
}
