import {
  bindMenu,
  bindTrigger,
  Box,
  IconButton,
  Menu,
  MenuItem,
  sx,
  Typography,
  usePopupState,
} from '@applift/factor'
import { ArrowsSort } from '@applift/icons'
import { SortingState } from '@tanstack/react-table'
import { SetStateAction } from 'react'

export interface SortMenuProps {
  sorting: SortingState
  setSorting: React.Dispatch<SetStateAction<SortingState>>
}

const sortingOptions = [
  {
    label: 'Creative ID',
    value: [{ id: 'creativeId', desc: true }],
  },
  {
    label: 'Created',
    value: [{ id: 'createdAt', desc: true }],
  },
  {
    label: 'Last Modified',
    value: [{ id: 'modifiedAt', desc: true }],
  },
  {
    label: 'Creative Name A-Z',
    value: [{ id: 'creativeName', desc: true }],
  },
  {
    label: 'Creative Name Z-A',
    value: [{ id: 'creativeName', desc: false }],
  },
]

function isSortingStateEqual(arr1: SortingState, arr2: SortingState) {
  // Check if both sorting arrays are the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort the arrays
  const sortedArr1 = [...arr1].sort((a, b) => a.id.localeCompare(b.id))
  const sortedArr2 = [...arr2].sort((a, b) => a.id.localeCompare(b.id))

  // Compare each sorting object in the arrays
  return sortedArr1.every((obj1, index) => {
    const obj2 = sortedArr2[index]
    return obj1.id === obj2?.id && obj1.desc === obj2?.desc
  })
}

export const SortMenu = (props: SortMenuProps) => {
  const { sorting, setSorting } = props
  const filtersPopupState = usePopupState({
    variant: 'popover',
    popupId: 'filtersPopup',
  })
  return (
    <>
      {/* @ts-ignore */}
      <IconButton
        color="secondary"
        {...bindTrigger(filtersPopupState)}
        selected={filtersPopupState.isOpen}
      >
        <ArrowsSort />
      </IconButton>
      {/* @ts-ignore */}
      <Menu
        {...bindMenu(filtersPopupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        renderContentTop={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'between',
              p: 8,
              pt: 12,
              gap: 8,
            }}
          >
            <Typography weight="demi">Sort</Typography>
          </Box>
        }
        PaperProps={{
          sx: {
            border: 1,
            borderColor: 'primary-600',
            marginTop: 2,
          },
          style: { width: '200px' },
        }}
        classes={{
          root: sx({ height: 'auto' }),
          list: sx({ overflow: 'auto', height: 100 }),
        }}
      >
        {sortingOptions.map(option => (
          <MenuItem
            key={option.label}
            selected={isSortingStateEqual(sorting, option.value)}
            onClick={() => {
              setSorting(option.value)
              filtersPopupState.close()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
