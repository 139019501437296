import { GridActionCellParams } from '@applift/datagrid'
import { Link, TypographyProps, TypoTooltip } from '@applift/factor'

import { ACTION } from '../../modules/common'
import { Creative } from '../../models'

interface CreativeIdCellProps {
  rowData: Creative
  action?: (args: GridActionCellParams) => any
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CreativeIdCell = ({
  rowData,
  textTypographyProps,
  action,
}: CreativeIdCellProps) => {
  const value = rowData?.creativeId
  return value ? (
    <Link
      Component="button"
      color="primary"
      sx={{ textWeight: 'demi' }}
      onClick={() =>
        action?.({
          actionName: ACTION.OPEN_CREATIVE_DETAIL,
          metaData: { data: rowData },
        })
      }
    >
      <TypoTooltip
        arrow
        placement="top"
        color="primary"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
          weight: 'normal',
        }}
      >
        {value}
      </TypoTooltip>
    </Link>
  ) : (
    <>—</>
  )
}
