import { Row, Col, Box, Button, enqueueSnackbar } from '@applift/factor'
import { WebsiteImage } from '@applift/illustrations'
import { WebsiteUser, Website, FileUpload, Add } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
  EmptyFeaturesSection,
  EmptyHeroSection,
  EmptyFeatureCard,
} from '../../components'
import { useDialogManager } from '../dialogManager'
import { IMAGE_CREATIVE_ID } from '../../constants'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'

export const ImageEmptyTab = () => {
  const { setAction } = useDialogManager()
  const queryClient = useQueryClient()
  return (
    <>
      {/* This is empty page - show when there is no creative */}
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 56,
              px: 40,
              py: 48,
            }}
          >
            <EmptyHeroSection
              illustration={<WebsiteImage sx={{ width: 100, height: 100 }} />}
              title="Image Creative"
              subText="Captivate your audience with images that leave a lasting impression."
              action={
                <Button
                  onClick={() => {
                    setAction('image', undefined, {
                      onSuccess: (
                        response: CreateCreativeResponse,
                        request: CreateCreativeRequest
                      ) => {
                        const createdCreativesId = Object.values(
                          response.successData
                        )
                        const failedCreatives = Object.keys(response.failedData)
                        if (createdCreativesId.length) {
                          enqueueSnackbar({
                            message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                            variant: 'success',
                          })
                        }
                        if (failedCreatives.length) {
                          enqueueSnackbar({
                            message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                            variant: 'error',
                          })
                        }

                        queryClient.resetQueries({
                          predicate: (query: any) =>
                            query.queryKey?.[0]?.scope ===
                              'getAllCreativeList' &&
                            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                              IMAGE_CREATIVE_ID
                            ),
                        })
                        if (createdCreativesId.length) {
                          const succeededCreative = Object.keys(
                            response.successData ?? {}
                          ).map(assetId => {
                            return {
                              creativeId: Number(
                                response.successData[`${assetId}`]
                              ),
                              platformCreativeTypeId: IMAGE_CREATIVE_ID,
                              creativeName: request[`${assetId}`]?.creativeName,
                            }
                          })
                          setAction('group', succeededCreative)
                        }
                      },
                    })
                  }}
                  startIcon={<Add />}
                  variant="outlined"
                >
                  Add Image Creative
                </Button>
              }
            />
            <EmptyFeaturesSection>
              <EmptyFeatureCard
                icon={<WebsiteUser />}
                title="Stand Out"
                subText="Convey the brand’s message and capture consumers’ attention at a glance."
              />
              <EmptyFeatureCard
                icon={<Website />}
                title="Preview Visuals"
                subText="Check your creatives before submitting them to ensure that consumers receive the experience you envisioned."
              />
              <EmptyFeatureCard
                icon={<FileUpload />}
                title="Bulk Upload"
                subText="Add your campaign’s creatives faster by uploading multiple images at once."
              />
            </EmptyFeaturesSection>
          </Box>
        </Col>
      </Row>
    </>
  )
}
