import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import {
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Tooltip,
} from '@applift/factor'
import { Delete, InfoCircle, Video } from '@applift/icons'
import { CreateProps } from './Create'
import { VideoCreativeUploadType } from '../../../../../models'
import {
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
  isValidURL,
} from '../../../../../utils'
import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
} from '../../../../../constants'
import {
  validateVASTURL,
  validateVASTXML,
  VideoIMAPlayer,
} from '@applift/platform-creatives'

export interface VastVideoCardProps
  extends Pick<CreateProps, 'onChange' | 'onRemove'> {
  /**
   * Temp creative id generated
   */
  id: string
  /**
   * Need to pass VAST Code
   */
  vastXml?: string
  /**
   * Name of the creative
   */
  name?: string
  /**
   * 3rd Party ID of the creative
   */
  thirdPartyID?: string
  /**
   * Warning show warning state of the card
   * @default false
   */
  warning?: boolean
}

const isValidateXmlOrUrl = async (xmlUrl: string) => {
  let validateResult = null

  const isVASTurl = isValidURL(xmlUrl)
  if (isVASTurl) {
    validateResult = await validateVASTURL(xmlUrl)
  } else {
    validateResult = await validateVASTXML(xmlUrl)
  }

  return validateResult
}

export const VastVideoCard = (props: VastVideoCardProps) => {
  const {
    id,
    vastXml = '',
    name = '',
    thirdPartyID = '',
    warning = false,
    onChange,
    onRemove,
  } = props

  const form = useForm({
    defaultValues: {
      id: id,
      warning: warning,
      name: name,
      vastXml: vastXml,
      thirdPartyID: thirdPartyID,
    },
  })

  React.useEffect(() => {
    form.validateAllFields('change').then(() => {
      const formData = {
        videoCreativeType: 'vast' as VideoCreativeUploadType,
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [form, onChange])

  return (
    <form
      style={{ height: '100%' }}
      onChange={() => {
        const formData = {
          videoCreativeType: 'vast' as VideoCreativeUploadType,
          ...form.state.values,
          hasError: form.state.canSubmit === false,
        }
        onChange?.([formData])
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          border: 1,
          borderRadius: 4,
          p: 16,
          pb: 24,
          gap: 16,
          alignItems: 'center',
          position: 'relative',
          borderColor: warning ? 'warning-200' : 'neutral-100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 8,
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              onRemove?.(id)
            }}
          >
            <Delete />
          </IconButton>
          {form.state.values.vastXml === '' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: 'auto',
                border: 1,
                borderRadius: 4,
                bgColor: 'neutral-75',
              }}
              style={{ aspectRatio: '4 / 3' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: 75,
                  gap: 8,
                }}
              >
                <Video fontSize={24} sx={{ textColor: 'neutral-400' }} />
                <Typography sx={{ textColor: 'neutral-600' }}>
                  Video preview will be visible here
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                width: 100,
                height: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              style={{ aspectRatio: '4 / 3', objectFit: 'contain' }}
            >
              {isValidURL(form.state.values.vastXml) ? (
                <VideoIMAPlayer adTagUrl={form.state.values.vastXml} />
              ) : (
                <VideoIMAPlayer adTagXML={form.state.values.vastXml} />
              )}
            </Box>
          )}
        </Box>
        <Divider color="secondary" sx={{ width: 100 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          {warning && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 100,
                px: 12,
                gap: 8,
              }}
            >
              <Typography
                variant="label"
                lineHeight="single-line"
                sx={{ textColor: 'warning-500' }}
              >
                Dimensions & video length don’t match the recommendations and
                may affect the campaign’s reach
              </Typography>
            </Box>
          )}
          <form.Field
            name="vastXml"
            validators={{
              onChange: ({ value }) => {
                if (!value) {
                  return 'VAST XML/URL is required!'
                }
                return undefined
              },
              onChangeAsyncDebounceMs: 500,
              onChangeAsync: async ({ value }) => {
                const res = await isValidateXmlOrUrl(value)
                if (res.data?.isValid) {
                  return undefined
                }
                if (isValidURL(value)) {
                  return 'Invalid input. Unable to fetch the content.'
                }
                return 'Only allow to upload 2.0, 3.0, 4.0 versions or VAST XML/URL'
              },
            }}
          >
            {field => (
              <TextField
                label="VAST XML/URL"
                variant="outlinedDash"
                placeholder="Enter VAST XML/URL"
                value={field.state.value}
                required
                onChange={e => {
                  // TODO: check for validURL
                  // Check for validXML
                  field.handleChange(e.target.value)
                }}
                multiline
                rows={4}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="name"
            validators={{
              onChange: ({ value }) => creativeNameValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label="Name"
                variant="outlinedDash"
                placeholder="Enter Name"
                value={field.state.value}
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                    </>
                  ),
                }}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="thirdPartyID"
            validators={{
              onChange: ({ value }) => creativeThirdPartyIDValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label={
                  <Box
                    Component={'span'}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography variant="label">3rd Party ID</Typography>
                    <Tooltip
                      title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                      placement="top"
                      arrow
                    >
                      <InfoCircle
                        style={{
                          width: '1.2em',
                          height: '1.2em',
                          transform: 'translateY(-1px)',
                        }}
                        sx={{
                          textColor: 'neutral-400',
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                variant="outlinedDash"
                placeholder="Enter 3rd Party ID"
                value={field.state.value}
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value.length}/{CREATIVE_THIRD_PARTY_ID_LIMIT}
                    </>
                  ),
                }}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
        </Box>
      </Box>
    </form>
  )
}
