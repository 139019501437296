import { Box, Col, Paper, Row, Typography } from '@applift/factor'

import { MediaQueue } from '../../../../../../hooks'
import { NativeCreativeGenerationData } from '../../../../../../models'
import { NativeCreationFormType } from '../../CreateWrapper'
import { BrandName } from './BrandName'
import { DropzoneWrapper } from './DropzoneWrapper'

interface Props {
  form: NativeCreationFormType
  updateMasterList: () => void
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  creativesList: NativeCreativeGenerationData[]
  queue: MediaQueue
  isCreateClicked: boolean
}

export const BrandInfoCard = (props: Props) => {
  const {
    form,
    onChange,
    creativesList,
    updateMasterList,
    queue,
    isCreateClicked,
  } = props

  return (
    <Paper
      elevation={2}
      sx={{ p: 16, display: 'flex', flexDirection: 'column', gap: 16 }}
    >
      <Typography
        sx={{
          textWeight: 'demi',
          textColor: 'neutral-600',
        }}
      >
        Brand Info
      </Typography>
      <Row>
        <Col xs={6}>
          <BrandName
            form={form}
            creativesList={creativesList}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Box sx={{ display: 'flex', pl: 12 }}>
            <Typography
              sx={{ display: 'block', mr: 2, mb: 4, textColor: 'neutral-600' }}
              variant="label"
            >
              Brand Icon
            </Typography>
            <Typography sx={{ textColor: 'danger-400' }}>*</Typography>
          </Box>
          <DropzoneWrapper
            form={form}
            updateMasterList={updateMasterList}
            creativesList={creativesList}
            onChange={onChange}
            queue={queue}
            isCreateClicked={isCreateClicked}
          />
        </Col>
      </Row>
    </Paper>
  )
}
