import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import { Box, TextField, Tooltip, Typography } from '@applift/factor'

import { HTMLCreativeGenerationData } from '../../../../../models/Creative'
import { isValidURL } from '../../../../../utils/helper'
import { CreateProps } from './Create'
import { InfoCircle } from '@applift/icons'

interface ActionPanelProps extends Pick<CreateProps, 'onChange' | 'data'> {
  setError: React.Dispatch<React.SetStateAction<boolean>>
}

export const ActionPanel = (props: ActionPanelProps) => {
  const { data, onChange, setError } = props

  const creativesArray = Array.from(data.values())

  const applyClickUrlToAll = (value: string) => {
    const result = creativesArray.map<HTMLCreativeGenerationData>(creative => ({
      ...creative,
      clickURL: value,
    }))
    onChange?.(result)
  }

  const form = useForm({
    defaultValues: {
      clickURL: '',
    },
  })

  React.useEffect(() => {
    form.validateAllFields('change').then(() => {
      setError(form.state.canSubmit === false)
    })
  }, [form, setError])

  return (
    <form
      onChange={() => {
        setError(form.state.canSubmit === false)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <Typography lineHeight="single-line" sx={{ flexShrink: 0 }}>
          Apply to all
        </Typography>
        <form.Field
          name="clickURL"
          validators={{
            onChange: ({ value }) => {
              if (value.trim() && !isValidURL(value)) {
                return 'Click URL must be a valid url address'
              }
              return undefined
            },
          }}
        >
          {field => (
            <Box
              sx={{
                display: 'flex',
                overflow: 'hidden',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <Typography
                variant="label"
                lineHeight="single-line"
                sx={{
                  textColor: 'neutral-600',
                  flexShrink: 0,
                  display: 'flex',
                }}
              >
                Click URL :
                <Tooltip
                  title={'URL that will open when someone clicks on your ad'}
                  placement="top"
                  arrow
                >
                  <InfoCircle
                    style={{
                      width: '1.2em',
                      height: '1.2em',
                      transform: 'translateY(-1px)',
                    }}
                    sx={{
                      ml: 2,
                      textColor: 'neutral-400',
                    }}
                  />
                </Tooltip>
              </Typography>
              <TextField
                variant="outlinedDash"
                placeholder="Enter Click URL"
                value={field.state.value}
                onChange={e => {
                  const url = e.target.value
                  if (url.trim().length === 0) {
                    field.handleChange(url.trim())
                    return
                  }
                  if (url && isValidURL(url)) {
                    applyClickUrlToAll(url)
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
                style={{ width: 235 }}
              />
            </Box>
          )}
        </form.Field>
      </Box>
    </form>
  )
}
