import clsx from 'clsx'

import { Box, Checkbox, Paper, PaperProps } from '@applift/factor'

import styles from './index.module.scss'

export interface GridCardWrapperProps extends Partial<PaperProps> {
  /**
   * If true the card displays selected
   */
  selected: boolean
  /**
   * Function to call when the card selection changes
   */
  onSelectionChange: (
    selected: boolean,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

export const GirdCardBase = (props: GridCardWrapperProps) => {
  const { selected, onSelectionChange, className, children, sx, ...other } =
    props
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        p: 16,
        borderColor: selected ? 'primary-600' : 'neutral-200',
        ...sx,
      }}
      className={clsx(styles.root, selected ? styles.selected : '', className)}
      {...other}
    >
      <Box sx={{ flexShrink: 0 }}>
        <Checkbox
          checked={selected}
          onChange={e => onSelectionChange(e.target.checked, e)}
        />
      </Box>
      {children}
    </Paper>
  )
}
