import { MEGA_BYTE } from '../../../../../constants'

export const allowedVideoMimeTypesSize = {
  'video/quicktime': MEGA_BYTE * 400,
  'video/mp4': MEGA_BYTE * 400,
} as const

export const allowedVideoMIMETypes = Object.keys(allowedVideoMimeTypesSize)

export const verifySize = (file: File) => {
  const allowedSize = MEGA_BYTE * 400
  return allowedSize && file.size <= allowedSize ? true : false
}
