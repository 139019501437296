import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '@applift/platform'
import { QueryFunctionContext } from '@tanstack/react-query'

import { getInstance } from './instance'
import { groupByUnique } from '../utils'

interface CreativeStatus {
  id: number
  name: string
  order: number
  displayName: string
}

interface GetCreativeStatusesResponse
  extends WithResponse<{
    creativeStatusList: CreativeStatus[]
  }> {}

export const getCreativeStatuses = async ({
  queryKey,
}: QueryFunctionContext<
  {
    scope: string
    displayAllStatusOption?: boolean
  }[]
>) => {
  try {
    const { displayAllStatusOption } = queryKey[0] ?? {}

    const res: AxiosResponse<GetCreativeStatusesResponse> =
      await getInstance().get('v3/crt/master/static/creative-status', {
        params: {
          displayAllStatusOption: displayAllStatusOption ? true : false,
        },
      })

    // return res.data.data?.creativeStatusList

    const creativeStatusList = res.data.data?.creativeStatusList ?? []

    const creativeStatusMappingById = groupByUnique<CreativeStatus, 'id'>(
      creativeStatusList,
      'id'
    )

    const creativeStatusMappingByName = groupByUnique<CreativeStatus, 'name'>(
      creativeStatusList,
      'name'
    )

    const creativeStatusMappingByDisplayName = groupByUnique<
      CreativeStatus,
      'displayName'
    >(creativeStatusList, 'displayName')

    return {
      creativeStatusList,
      creativeStatusMappingById,
      creativeStatusMappingByName,
      creativeStatusMappingByDisplayName,
    } as const
  } catch (e) {
    throw Promise.reject((e as AxiosError).response)
  }
}
