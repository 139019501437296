import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Col,
  Row,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor'
import { useForm } from '@tanstack/react-form'
import { useQueryClient } from '@tanstack/react-query'

import { Creative } from '../../../models/Creative'
import { isValidURL } from '../../../utils'
import {
  AUDIO_CREATIVE_ID,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../constants'
import { getIcon } from '../../../registry'
import { useEditPixelUrl } from '../../../hooks'

interface EditPixelUrlDialogProps {
  data: Creative[]
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const EditPixelUrlDialog = (props: EditPixelUrlDialogProps) => {
  const { onClose, onSuccess, data, onError, onSettle } = props

  const queryClient = useQueryClient()

  const isSinglePixelEdited = data.length === 1

  const initPixelUrlValue =
    isSinglePixelEdited && data[0]?.pixelUrl ? data[0]?.pixelUrl : ''

  const isOnlyImageCreativeSelected = data.every(
    creative => creative.platformCreativeTypeId === IMAGE_CREATIVE_ID
  )
  const isOnlyHTMLCreativeSelected = data.every(
    creative => creative.platformCreativeTypeId === HTML_CREATIVE_ID
  )
  const isOnlyVideoCreativeSelected = data.every(
    creative => creative.platformCreativeTypeId === VIDEO_CREATIVE_ID
  )
  const isOnlyAudioCreativeSelected = data.every(
    creative => creative.platformCreativeTypeId === AUDIO_CREATIVE_ID
  )
  const isOnlyNativeCreativeSelected = data.every(
    creative => creative.platformCreativeTypeId === NATIVE_CREATIVE_ID
  )

  const getIconName = () => {
    if (isOnlyImageCreativeSelected) return 'Image'
    if (isOnlyHTMLCreativeSelected) return 'HTML'
    if (isOnlyVideoCreativeSelected) return 'Video'
    if (isOnlyAudioCreativeSelected) return 'Audio'
    if (isOnlyNativeCreativeSelected) return 'Native'
    return 'Layout'
  }

  const editPixelUrlMutation = useEditPixelUrl({
    onSuccess: (response, request) => {
      enqueueSnackbar(response?.message, {
        variant: 'success',
      })
      // To reset query for the creative Preview dialog
      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === '__getCreativeDetails' &&
            Boolean(
              request.creativeIds?.includes(query.queryKey?.[0]?.creativeId)
            )
          )
        },
      })

      queryClient.resetQueries({
        predicate: (query: any) => {
          return query.queryKey?.[0]?.scope === 'getAllCreativeList'
        },
      })
      onSuccess?.(response)
    },
    onError: error => {
      enqueueSnackbar(
        error?.data?.errorObjects?.[0]?.error ||
          error?.data?.detail ||
          'Something went wrong',
        {
          variant: 'error',
        }
      )
      onError?.(error)
    },
    onSettled: () => {
      onSettle?.()
      onClose?.()
    },
  })

  const form = useForm({
    defaultValues: {
      pixelUrl: initPixelUrlValue,
      creativeIds: data.map(creative => creative.creativeId),
    },
    onSubmit: async ({ value }) => {
      editPixelUrlMutation.mutate({
        pixelUrl: value.pixelUrl || null,
        creativeIds: value.creativeIds,
      })
    },
  })

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <form
        style={{ height: '100%' }}
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          {getIcon(getIconName(), { fontSize: 32 })}
          <Typography weight="demi" sx={{ ml: 8 }}>
            Edit Pixel URL
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ flexDirection: 'column', display: 'flex', gap: 24 }}
        >
          <Row>
            <Col xs={12}>
              <Typography sx={{ mb: 16 }}>
                Enter the
                <Typography weight="demi" sx={{ ml: 4 }}>
                  Pixel URL
                </Typography>{' '}
                for the
                {data.length === 1 ? (
                  <>
                    <Typography sx={{ ml: 4 }}>creative</Typography>
                    <Typography weight="demi" sx={{ mx: 4 }}>
                      {data?.[0]?.creativeName}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography weight="demi" sx={{ mx: 4 }}>
                      {data.length}
                    </Typography>
                    <Typography sx={{ ml: 4 }}>selected creatives.</Typography>
                  </>
                )}
              </Typography>
            </Col>
          </Row>
          <Row sx={{ mb: 16 }}>
            <Col xs={7}>
              <form.Field
                name="pixelUrl"
                validators={{
                  onChange: ({ value }) => {
                    if (value.trim() && !isValidURL(value)) {
                      return 'Enter a valid URL'
                    }
                    return undefined
                  },
                }}
              >
                {field => (
                  <TextField
                    sx={{ width: 100 }}
                    label="Pixel URL"
                    variant="outlinedDash"
                    placeholder="Enter Pixel URL"
                    value={field.state.value}
                    onChange={(e: any) => {
                      field.handleChange(e.target.value)
                    }}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <form.Subscribe selector={state => [state.canSubmit, state.values]}>
            {([canSubmit, values]) => {
              const isValueSame =
                (values as { pixelUrl: string })?.pixelUrl === initPixelUrlValue
              const disableSubmit = !canSubmit || isValueSame
              return (
                <>
                  <Button color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    disabled={disableSubmit}
                  >
                    Update
                  </LoadingButton>
                </>
              )
            }}
          </form.Subscribe>
        </DialogActions>
      </form>
    </Dialog>
  )
}
