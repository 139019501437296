import { SearchData, Warning } from '@applift/illustrations'
import { NoResultsOverlay } from '../../../components'

export const _NoResultsOverlay = () => {
  return (
    <NoResultsOverlay
      text="No results found"
      subText="We can't find any items matching your search."
      illustration={
        <SearchData
          sx={{
            textColor: 'primary-500',
            width: 100,
            height: 'auto',
            mb: 40,
          }}
        />
      }
    />
  )
}

export const _ErrorOverlay = () => {
  return (
    <NoResultsOverlay
      text=""
      subText="Oops, something went wrong. Please try again after some time."
      illustration={
        <Warning
          sx={{
            textColor: 'primary-500',
            width: 100,
            height: 'auto',
            mb: 40,
          }}
        />
      }
    />
  )
}
