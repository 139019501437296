import { Layout } from '@applift/factor'
import { Create, CreateProps } from './Create'
import { Upload } from './Upload'
import { CreateEmptySection } from '../../../common'

interface CreateWrapperProps extends CreateProps {}

export const CreateWrapper = (props: CreateWrapperProps) => {
  const { data, onChange, onRemove, onError } = props

  return (
    <Layout
      sidebarWidth={300}
      hideResizerHandle
      sidebar={<Upload onAddCreatives={onChange} />}
      sidebarAnchor="left"
    >
      {data.size <= 0 ? (
        <CreateEmptySection creativeType="image" />
      ) : (
        <Create
          data={data}
          onChange={onChange}
          onRemove={onRemove}
          onError={onError}
        />
      )}
    </Layout>
  )
}
