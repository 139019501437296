import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Col,
  IconButton,
  Row,
  TextField,
  Typography,
  sx,
} from '@applift/factor'
import { ChevronRight, Delete } from '@applift/icons'
import { useStore } from '@tanstack/react-store'
import { NativeCreationFormType } from '../../CreateWrapper'
import { trackingUrlValidationFn } from '../../../../../../utils'

interface Props {
  form: NativeCreationFormType
}

export const CustomTracking = (props: Props) => {
  const { form } = props

  const { customTracking } = useStore(form.store, state => state.values)

  const [isSectionVisible, toggleVisibility] = React.useState(false)

  const enteredURLCount = Object.values(customTracking).filter(url =>
    url.trim()
  ).length

  const removeValues = () => {
    form.store.setState(prev => ({
      ...prev,
      values: {
        ...form.state.values,
        customTracking: {
          impressionTrackingUrl: '',
          startTrackingUrl: '',
          firstQuartileTrackingUrl: '',
          midpointTrackingTrackingUrl: '',
          thirdQuartileTrackingUrl: '',
          completeTrackingUrl: '',
        },
      },
    }))
  }

  return (
    <Accordion
      expanded={isSectionVisible}
      onChange={() => toggleVisibility(prev => !prev)}
      sx={{
        width: 100,
        boxShadow: 0,
        border: 1,
        borderRadius: 4,
        borderColor: 'neutral-100',
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ChevronRight fontSize={18} />}
        classes={{
          content: sx({
            alignItems: 'center',
            justifyContent: 'between',
            my: 4,
          }),
        }}
        sx={{
          flexDirection: 'row-reverse',
          gap: 12,
          textColor: 'neutral-500',
        }}
      >
        <Typography lineHeight="single-line" sx={{ textColor: 'neutral-1000' }}>
          Custom Tracking{' '}
          <Typography
            sx={{
              textColor: 'neutral-500',
              display: enteredURLCount ? 'inline' : 'none',
            }}
          >
            ({enteredURLCount})
          </Typography>
        </Typography>
        <IconButton
          color="secondary"
          size="small"
          onClick={removeValues}
          sx={{ display: enteredURLCount ? 'inline' : 'none' }}
        >
          <Delete />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          <Row>
            <Col xs={6}>
              <form.Field
                name="customTracking.impressionTrackingUrl"
                validators={{
                  onChange: ({ value }) => trackingUrlValidationFn(value),
                }}
              >
                {field => (
                  <TextField
                    label="Impression Tracking URL"
                    fullWidth
                    variant="outlinedDash"
                    placeholder="Enter Impression Tracking URL"
                    value={field.state.value}
                    onChange={e => field.handleChange(e.target.value)}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
            <Col xs={6}>
              <form.Field
                name="customTracking.startTrackingUrl"
                validators={{
                  onChange: ({ value }) => trackingUrlValidationFn(value),
                }}
              >
                {field => (
                  <TextField
                    label="Start Tracking URL"
                    variant="outlinedDash"
                    placeholder="Enter Start Tracking URL"
                    value={field.state.value}
                    fullWidth
                    onChange={e => field.handleChange(e.target.value)}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <form.Field
                name="customTracking.firstQuartileTrackingUrl"
                validators={{
                  onChange: ({ value }) => trackingUrlValidationFn(value),
                }}
              >
                {field => (
                  <TextField
                    label="1st Quartile Tracking URL"
                    variant="outlinedDash"
                    placeholder="Enter 1st Quartile Tracking URL"
                    value={field.state.value}
                    fullWidth
                    onChange={e => field.handleChange(e.target.value)}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
            <Col xs={6}>
              <form.Field
                name="customTracking.midpointTrackingTrackingUrl"
                validators={{
                  onChange: ({ value }) => trackingUrlValidationFn(value),
                }}
              >
                {field => (
                  <TextField
                    label="Midpoint Tracking URL"
                    variant="outlinedDash"
                    fullWidth
                    placeholder="Enter Midpoint Tracking URL"
                    value={field.state.value}
                    onChange={e => field.handleChange(e.target.value)}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <form.Field
                name="customTracking.thirdQuartileTrackingUrl"
                validators={{
                  onChange: ({ value }) => trackingUrlValidationFn(value),
                }}
              >
                {field => (
                  <TextField
                    label="3rd Quartile Tracking URL"
                    variant="outlinedDash"
                    placeholder="Enter 3rd Quartile Tracking URL"
                    value={field.state.value}
                    fullWidth
                    onChange={e => field.handleChange(e.target.value)}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
            <Col xs={6}>
              <form.Field
                name="customTracking.completeTrackingUrl"
                validators={{
                  onChange: ({ value }) => trackingUrlValidationFn(value),
                }}
              >
                {field => (
                  <TextField
                    label="Complete Tracking URL"
                    variant="outlinedDash"
                    placeholder="Enter Complete Tracking URL"
                    value={field.state.value}
                    fullWidth
                    onChange={e => field.handleChange(e.target.value)}
                    error={Boolean(field.state.meta.errors.length)}
                    helperText={field.state.meta.errors[0] ?? ''}
                  />
                )}
              </form.Field>
            </Col>
          </Row>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
