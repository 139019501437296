import * as React from 'react'
import { Filter, Redo } from '@applift/icons'
import {
  Badge,
  bindMenu,
  bindTrigger,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  sx,
  Typography,
  usePopupState,
} from '@applift/factor'
import { GetCreativeStatusCountRequest } from '../../../../models'
import { useCreativeStatusCount, useCreativeStatuses } from '../../../../hooks'
import { CreativeStatusIcon } from '../../Mappings/CreativeStatusIcon'

export interface FilterProps {
  statusIds: number[]
  setStatusIds: React.Dispatch<React.SetStateAction<number[]>>
  countApiState?: GetCreativeStatusCountRequest
}

// eslint-disable-next-line
export const Filters = (props: FilterProps) => {
  const { statusIds, setStatusIds, countApiState } = props

  const { data: creativeStatus } = useCreativeStatuses({
    displayAllStatusOption: true,
  })
  const { data: creativeStatusCountData } =
    useCreativeStatusCount(countApiState)

  const statusIconMappingById = creativeStatus?.creativeStatusMappingById

  const countIdMapping = React.useMemo(
    () => creativeStatusCountData?.statusCountIdMapping,
    [creativeStatusCountData]
  )

  const filtersPopupState = usePopupState({
    variant: 'popover',
    popupId: 'filtersPopup',
  })

  const statusOptions = React.useMemo(() => {
    return creativeStatus?.creativeStatusList.map(data => ({
      ...data,
      count: countIdMapping?.[`${data.id}`] ?? 0,
      icon: (
        <CreativeStatusIcon name={statusIconMappingById?.[data.id]?.name} />
      ),
    }))
  }, [creativeStatus, countIdMapping, statusIconMappingById])

  const clearFilters = React.useCallback(() => {
    setStatusIds([])
  }, [setStatusIds])

  const handleStatusChange = (selectedId: number) => {
    if (statusIds.includes(selectedId)) {
      return setStatusIds(statusIds.filter(s => s !== selectedId))
    }
    return setStatusIds([...statusIds, selectedId])
  }

  return (
    <>
      <Badge
        badgeContent={statusIds.length}
        color="secondary"
        overlap="circular"
      >
        {/* @ts-ignore */}
        <IconButton
          color="secondary"
          {...bindTrigger(filtersPopupState)}
          selected={filtersPopupState.isOpen}
        >
          <Filter />
        </IconButton>
        {/* @ts-ignore */}
        <Menu
          {...bindMenu(filtersPopupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          renderContentTop={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'between',
                p: 8,
                pt: 12,
                gap: 8,
              }}
            >
              <Typography weight="demi">Filter</Typography>
              {statusIds.length ? (
                <Link
                  sx={{ mr: 4, display: 'flex', alignItems: 'end', gap: 4 }}
                  Component="button"
                  color="primary"
                  underline="none"
                  onClick={clearFilters}
                >
                  <Redo sx={{ display: 'flex', fontSize: 18 }} />
                  <Typography gutterBottom={false}>Clear Filters</Typography>
                </Link>
              ) : null}
            </Box>
          }
          PaperProps={{
            sx: {
              border: 1,
              borderColor: 'primary-600',
              marginTop: 2,
            },
            style: { width: '200px', height: '270px' },
          }}
          classes={{ list: sx({ overflow: 'auto', height: 100 }) }}
        >
          <Typography weight="demi" sx={{ p: 8, py: 12 }}>
            Status
          </Typography>
          {statusOptions?.map(status => (
            <MenuItem
              key={status.id}
              onClick={() => {
                handleStatusChange(status.id)
              }}
              selected={statusIds.includes(status.id)}
              startIcon={status.icon}
            >
              {`${status.displayName} (${status.count})`}
            </MenuItem>
          ))}
        </Menu>
      </Badge>
    </>
  )
}
