import * as React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Typography,
  TextField,
  CircularProgress,
  Container,
  Tooltip,
  enqueueSnackbar,
  LoadingButton,
} from '@applift/factor'
import { Creative } from '../../../models'
import { CreativeGroupSelect } from '../../CreativeGroupSelect'
import {
  AUDIO_CREATIVE_ID,
  CREATIVE_GROUP_NAME_LIMIT,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../constants'
import { getIcon } from '../../../registry'
import {
  useCreateGroup,
  useModifyGroup,
  useCreativeGroupsList,
} from '../../../hooks'

import styles from './index.module.scss'

const CREAT_GROUP_OPTION = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
}

export type CREAT_GROUP_OPTION = keyof typeof CREAT_GROUP_OPTION

export type CreativeGroup = {
  groupName: string
  groupId: number
  totalCreatives: number
}

interface AddToGroupDialogProps {
  initState?: {
    option?: CREAT_GROUP_OPTION
    successActionName?: string
    cancelActionName?: string
  }
  data: Partial<Creative>[]
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const AddCreativeToGroupDialog = (props: AddToGroupDialogProps) => {
  const { data, initState, onClose, onSuccess, onError, onSettle } = props
  const [groupOption, setGroupOption] = React.useState(
    initState?.option ?? CREAT_GROUP_OPTION.EXISTING
  )
  const [hasError, setHasError] = React.useState(false)
  const [group, setGroup] = React.useState<Record<string, CreativeGroup>>({})
  const [groupName, setGroupName] = React.useState<string>('')
  const handleGroupChange = (
    event: React.SyntheticEvent,
    newGroupOption: CREAT_GROUP_OPTION
  ) => {
    setGroupOption(newGroupOption)
    // Resetting group value
    setGroup({})
    setGroupName('')
  }

  const selectedCreativeIds = React.useMemo(
    () => data.map(creative => creative.creativeId),
    [data]
  )

  const isSingleCreativeSelected = React.useMemo(
    () => data.length === 1,
    [data]
  )

  const creativeTypeId = React.useMemo(
    () => data[0]?.platformCreativeTypeId,
    [data]
  )

  const creativeTypeName = React.useMemo(() => {
    switch (creativeTypeId) {
      case IMAGE_CREATIVE_ID:
        return 'Image'
      case HTML_CREATIVE_ID:
        return 'HTML'
      case VIDEO_CREATIVE_ID:
        return 'Video'
      case AUDIO_CREATIVE_ID:
        return 'Audio'
      case NATIVE_CREATIVE_ID:
        return 'Native'
    }
    return ''
  }, [creativeTypeId])

  const {
    data: creativeGroupData,
    isLoading,
    isFetching,
    isFetched,
  } = useCreativeGroupsList({
    sortBy: '-createdAt',
    platformCreativeTypeIds: creativeTypeId ? [creativeTypeId] : undefined,
    isSelectAll: true,
    searchField: '',
  })

  const isExistingTabDisable = React.useMemo(
    () =>
      isFetched &&
      (creativeGroupData?.pages[0]?.data?.filteredRecords ?? 0) <= 0,
    [creativeGroupData, isFetched]
  )

  React.useEffect(() => {
    if (isExistingTabDisable) {
      setGroupOption(CREAT_GROUP_OPTION.NEW)
    }
  }, [isExistingTabDisable])

  const addToNewGroupMutation = useCreateGroup({
    onSuccess: (response, request) => {
      enqueueSnackbar(
        isSingleCreativeSelected ? (
          <Typography>
            <Typography weight="demi">{data[0]?.creativeName}</Typography>{' '}
            creative added to the group successfully.
          </Typography>
        ) : (
          <Typography>
            <Typography weight="demi">{request.creativeGroupName}</Typography>{' '}
            group created successfully
          </Typography>
        ),
        {
          variant: 'success',
        }
      )
      onSuccess?.(response)
    },
    onError: (error, request) => {
      enqueueSnackbar(
        <Typography>
          Failed to create{' '}
          <Typography weight="demi">{request.creativeGroupName}</Typography>{' '}
          group
        </Typography>,
        {
          variant: 'error',
        }
      )
      onError?.(error)
    },
    onSettled: () => {
      onClose?.()
      onSettle?.()
    },
  })

  const addToExistingGroupMutation = useModifyGroup({
    onSuccess: response => {
      enqueueSnackbar({
        message: response?.data?.message,
        variant: 'success',
      })
      //TODO: Forward to the group page
      onSuccess?.(response)
    },
    onError: error => {
      enqueueSnackbar(
        <Typography>Failed to group {data.length} creatives</Typography>,
        {
          variant: 'error',
        }
      )
      onError?.(error)
    },
    onSettled: () => {
      onClose?.()
      onSettle?.()
    },
  })

  const isRequiredFieldEmpty = React.useMemo(() => {
    if (groupOption === CREAT_GROUP_OPTION.NEW) {
      return Boolean(groupName.trim()) === false
    }
    if (groupOption === CREAT_GROUP_OPTION.EXISTING) {
      return Object.keys(group).length <= 0
    }
  }, [groupOption, groupName, group])

  const actionDisabled = isRequiredFieldEmpty || hasError

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Box
            sx={{
              display: 'flex',
              fontSize: 32,
              textColor: 'primary-600',
            }}
          >
            {creativeTypeName
              ? getIcon(creativeTypeName, { fontSize: 32 })
              : null}
          </Box>
          Group Creatives
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading || isFetching ? (
          <Container
            sx={{
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 24,
            }}
          >
            <CircularProgress size={36} thickness={3} />
          </Container>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Typography lineHeight="single-line">
                Save{' '}
                <Typography lineHeight="single-line" weight="demi">
                  {isSingleCreativeSelected
                    ? data[0]?.creativeName
                    : `${data.length} ${creativeTypeName} Creatives`}
                </Typography>{' '}
                to
              </Typography>
              <Tabs
                Component={'div'}
                value={groupOption}
                onChange={handleGroupChange}
                aria-label="basic tabs example"
                variant="pill"
              >
                <Tooltip
                  title={
                    isExistingTabDisable ? 'No Creative Groups added yet' : ''
                  }
                  arrow
                  placement="top"
                >
                  <Tab
                    className={isExistingTabDisable ? styles.disabledTab : ''}
                    value={CREAT_GROUP_OPTION.EXISTING}
                    selected={groupOption === CREAT_GROUP_OPTION.EXISTING}
                    disabled={isExistingTabDisable}
                    label="Existing Group"
                  />
                </Tooltip>
                <Tab
                  value={CREAT_GROUP_OPTION.NEW}
                  selected={groupOption === CREAT_GROUP_OPTION.NEW}
                  label="New Group"
                />
              </Tabs>
            </Box>
            {groupOption === CREAT_GROUP_OPTION.EXISTING ? (
              <Box sx={{ width: 50 }}>
                <CreativeGroupSelect
                  slotProps={{
                    PaperProps: {
                      style: {
                        maxWidth: 500,
                      },
                    },
                  }}
                  enableLazyLoading={false}
                  onChange={value => {
                    setGroup(value as any)
                  }}
                  params={{
                    sortBy: '-createdAt',
                    platformCreativeTypeIds: creativeTypeId
                      ? [creativeTypeId]
                      : undefined,
                  }}
                  TextFieldProps={{
                    required: true,
                    label: 'Creative Group',
                    fullWidth: true,
                    variant: 'outlinedDash',
                  }}
                />
              </Box>
            ) : (
              <TextField
                label="Group Name"
                variant="outlinedDash"
                placeholder="Enter Group Name"
                value={groupName}
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {groupName!.length}/{CREATIVE_GROUP_NAME_LIMIT}
                    </>
                  ),
                }}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    setGroupName(value.trim())
                    return
                  }

                  setGroupName(value)

                  if (value.length > CREATIVE_GROUP_NAME_LIMIT) {
                    setHasError(true)
                  } else {
                    setHasError(false)
                  }
                }}
                error={
                  groupName!.length > CREATIVE_GROUP_NAME_LIMIT ? true : false
                }
                helperText={
                  groupName!.length > CREATIVE_GROUP_NAME_LIMIT
                    ? 'Character limit exceeded'
                    : ''
                }
                style={{ width: '300px' }}
              />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={addToExistingGroupMutation.isLoading}
        >
          {initState?.cancelActionName ?? 'Cancel'}
        </Button>
        <LoadingButton
          loading={
            addToNewGroupMutation.isLoading ||
            addToExistingGroupMutation.isLoading
          }
          disabled={actionDisabled}
          onClick={() => {
            if (groupOption === CREAT_GROUP_OPTION.NEW) {
              addToNewGroupMutation.mutate({
                creativeTypeId: creativeTypeId as number,
                creativeGroupName: groupName.trim(),
                creativeIds: selectedCreativeIds as number[],
              })
            }
            if (groupOption === CREAT_GROUP_OPTION.EXISTING) {
              addToExistingGroupMutation.mutate({
                actionType: 'ADD',
                creativeIds: selectedCreativeIds as number[],
                creativeGroupIds: Object.keys(group).map(id => Number(id)),
              })
            }
          }}
        >
          {initState?.successActionName ?? 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
