import * as React from 'react'
import { Box, sx, Typography } from '@applift/factor'

import { Creative } from '../../../../../../models'
import { VIDEO_CREATIVE_ID } from '../../../../../../constants'
import { getPlaceholderIcon } from '../../../../../../components/CellTypes/CreativePreviewCell/helper'

import styles from './index.module.scss'

interface VideoWrapperProps {
  creative: Creative
}

export const VideoWrapper = (props: VideoWrapperProps) => {
  const { creative } = props
  const {
    creativeCardSource,
    platformCreativeTypeId,
    isTransformed,
    creativePreviewFlag,
  } = creative

  const [localThumbmailSrc, setLocalThumbmailSrc] = React.useState<string>('')
  const [hideImage, setHideImage] = React.useState<boolean>(false)
  const [retryCount, setRetryCount] = React.useState<number>(0)

  const checkIfImageExists = (
    url: string,
    callback: (exists: boolean) => void
  ) => {
    const img = new Image()
    img.src = url
    img.onload = () => callback(true)
    img.onerror = () => callback(false)
  }

  const handleImageCheck = React.useCallback(() => {
    checkIfImageExists(creativeCardSource, exists => {
      if (exists) {
        setLocalThumbmailSrc(creativeCardSource)
        setHideImage(false)
      } else {
        setHideImage(true)
      }
    })
  }, [creativeCardSource])

  React.useEffect(() => {
    if (creativePreviewFlag === 1) {
      handleImageCheck()
    } else if (retryCount < 3) {
      const timer = setTimeout(() => {
        handleImageCheck()
        setRetryCount(retryCount + 1)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [creativePreviewFlag, handleImageCheck, retryCount])

  React.useEffect(() => {
    handleImageCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeCardSource])

  return (
    <>
      {platformCreativeTypeId === VIDEO_CREATIVE_ID && isTransformed === 0 && (
        <Box className={styles.overlay}>
          <Typography sx={{ textColor: 'neutral-0' }}>
            Video is Processing...
          </Typography>
        </Box>
      )}
      {localThumbmailSrc?.length &&
      !hideImage &&
      creativePreviewFlag !== undefined ? (
        <Box
          sx={{
            p: 8,
            mx: 'auto',
            height: 100,
            width: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={localThumbmailSrc}
            alt="Creative Card Preview"
            height={200}
            className={sx({ width: 100, flexShrink: 0 })}
            style={{ height: 200, objectFit: 'contain' }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: 100,
            height: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getPlaceholderIcon(platformCreativeTypeId)}
        </Box>
      )}
    </>
  )
}
