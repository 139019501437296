import {
  Box,
  TextField,
  Tooltip,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { InfoCircle } from '@applift/icons'

import { NativeCreationFormType } from '../../CreateWrapper'
import { clickUrlValidationFn } from '../../../../../../utils'
import { NativeCreativeGenerationData } from '../../../../../../models'
import { useStore } from '@tanstack/react-store'
import React from 'react'

interface Props {
  form: NativeCreationFormType
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  creativesList: NativeCreativeGenerationData[]
}

export const ClickURL = (props: Props) => {
  const { form, onChange, creativesList } = props
  const isMultipleCreativesPresent = creativesList.length > 1

  const updateinAllCreatives = () => {
    const value = form.getFieldValue('clickURL')

    onChange?.(
      creativesList.map(creative => ({ ...creative, clickURL: value }))
    )
    enqueueSnackbar(
      <>
        <Typography sx={{ textWeight: 'demi' }}>Click URL</Typography> updated
        succesfully for all creatives
      </>,
      { variant: 'success' }
    )
  }
  const { videoObjects } = useStore(form.store, state => state.values)
  const isClickUrlMandatory =
    (Boolean(videoObjects[0]?.creativeSource.length) ||
      Boolean(videoObjects[0]?.creativeSourceURL.length)) === false

  React.useEffect(() => {
    if (isClickUrlMandatory === false) {
      form.validateField('clickURL', 'change')
    }
  }, [form, isClickUrlMandatory])
  return (
    <form.Field
      name="clickURL"
      validators={{
        onChange: ({ value }) => {
          return clickUrlValidationFn(value, isClickUrlMandatory)
        },
      }}
    >
      {field => {
        const hasError = Boolean(field.state.meta.errors[0])
        const enableUpdateBtn =
          hasError === false &&
          isMultipleCreativesPresent === true &&
          Boolean(field.state.value)
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <TextField
              label={<Label />}
              variant="outlinedDash"
              fullWidth
              placeholder="Enter Click URL"
              required={isClickUrlMandatory}
              value={field.state.value}
              onChange={(e: any) => {
                const value = e.target.value
                if (value?.trim()?.length === 0) {
                  field.handleChange(value.trim())
                  return
                }
                field.handleChange(e.target.value)
              }}
              error={Boolean(field.state.meta.errors.length)}
              helperText={field.state.meta.errors[0] ?? ''}
            />
            <Box
              onClick={updateinAllCreatives}
              style={{
                cursor: enableUpdateBtn ? 'pointer' : 'auto',
                pointerEvents: enableUpdateBtn ? 'auto' : 'none',
                opacity: enableUpdateBtn ? 1 : 0.4,
              }}
              sx={{ pl: 12 }}
            >
              <Typography
                sx={{
                  textWeight: 'demi',
                  textColor: 'primary-600',
                  display: hasError ? 'none' : 'block',
                }}
              >
                Update in all creatives
              </Typography>
            </Box>
          </Box>
        )
      }}
    </form.Field>
  )
}

const Label = () => {
  return (
    <Tooltip
      title="URL that will open when someone clicks on your ad"
      placement="top"
      arrow
    >
      <Box
        Component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="label">Click URL</Typography>
        <InfoCircle fontSize={14} sx={{ textColor: 'neutral-400' }} />
      </Box>
    </Tooltip>
  )
}
