import * as React from 'react'
import { ReactChildrenType } from '../../models/utils'

export type ActionType =
  | 'all'
  | 'image'
  | 'html'
  | 'audio'
  | 'video'
  | 'native'
  | 'group'
  | 'imageGroup'
  | 'HTMLGroup'
  | 'videoGroup'
  | 'audioGroup'
  | 'existingNative'
  | undefined

interface DialogManagerOptions {
  onSuccess?: (response?: any, request?: any) => void
  onError?: (error: any) => void
  onSettle?: () => void
}

interface DialogManagerContextType {
  setAction: (
    actionName: ActionType,
    payload?: any,
    options?: DialogManagerOptions
  ) => void
  actionData?: {
    actionName: ActionType
    payload: any
    options?: DialogManagerOptions
  }
}

const DialogManagerContext = React.createContext<DialogManagerContextType>(
  undefined!
)

interface DialogManagerProviderProps {
  children: ReactChildrenType
}

export const DialogManagerProvider = (props: DialogManagerProviderProps) => {
  const { children } = props

  const [actionData, setActionData] =
    React.useState<DialogManagerContextType['actionData']>()

  const setAction = React.useCallback(
    (action: ActionType, payload: any, options?: DialogManagerOptions) => {
      setActionData({
        actionName: action,
        payload: payload,
        options: options,
      })
    },
    [setActionData]
  )

  return (
    <DialogManagerContext.Provider
      value={{
        setAction: setAction,
        actionData: actionData,
      }}
    >
      {children}
    </DialogManagerContext.Provider>
  )
}

export const useDialogManager = () => {
  const { setAction, actionData } = React.useContext(DialogManagerContext)
  return { setAction, actionData }
}
