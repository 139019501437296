import * as React from 'react'
import { Box } from '@applift/factor'
import { DropZone } from './DropZone'
import { SupportedFilesNote } from './SupportedFilesNote'
import { SpecificationsNote } from './SpecificationsNote'
import { CreateProps } from '../Create'
import { ErrorState, LoadingState } from './DropZoneStates'

interface UploadProps {
  onAddCreatives: CreateProps['onChange']
}

type DropZoneStates = 'normal' | 'loading' | 'timeout' | 'error'

export const Upload = (props: UploadProps) => {
  const { onAddCreatives } = props

  const [dropZoneState] = React.useState<DropZoneStates>('normal')

  const onFileAdded = (obj: { name: string; html: string }) => {
    onAddCreatives?.([
      {
        id: crypto.randomUUID(),
        htmlCode: obj.html,
        width: 0,
        height: 0,
        name: obj.name,
        clickURL: '',
      },
    ])
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 16,
        pr: 24,
        gap: 24,
      }}
    >
      {dropZoneState === 'loading' ? (
        <LoadingState />
      ) : dropZoneState === 'error' ? (
        <ErrorState />
      ) : (
        <DropZone
          onAddFile={onFileAdded}
          onAdd={() => {
            onAddCreatives?.([
              {
                id: crypto.randomUUID(),
                htmlCode: '',
                name: '',
                clickURL: '',
              },
            ])
          }}
        />
      )}
      <SupportedFilesNote />
      <SpecificationsNote />
    </Box>
  )
}
