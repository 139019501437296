import clsx from 'clsx'
import { MenuList, MenuListProps } from '@applift/factor'
import styles from './index.module.scss'
export const SidebarMenu = (props: MenuListProps) => {
  const { className, children, ...others } = props
  return (
    <MenuList className={clsx(styles.root, className)} {...others}>
      {children}
    </MenuList>
  )
}
