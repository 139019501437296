import {} from '@applift/emails'
import { SvgIconProps } from '@applift/factor'
import {
  StatusDeleted,
  StatusRunning,
  StatusPaused,
  StatusPending,
  StatusRejected,
  StatusAll,
} from '@applift/icons'

export const creativeStatusIconMapping = {
  pending: ({ sx, ...props }: Partial<SvgIconProps>) => (
    <StatusPending sx={{ textColor: 'warning-400', ...sx }} {...props} />
  ),
  paused: ({ sx, ...props }: Partial<SvgIconProps>) => (
    <StatusPaused sx={{ textColor: 'warning-400', ...sx }} {...props} />
  ),
  running: ({ sx, ...props }: Partial<SvgIconProps>) => (
    <StatusRunning sx={{ textColor: 'success-400', ...sx }} {...props} />
  ),
  deleted: ({ sx, ...props }: Partial<SvgIconProps>) => (
    <StatusDeleted sx={{ textColor: 'danger-400', ...sx }} {...props} />
  ),
  rejected: ({ sx, ...props }: Partial<SvgIconProps>) => (
    <StatusRejected sx={{ textColor: 'danger-400', ...sx }} {...props} />
  ),
  all: ({ sx, ...props }: Partial<SvgIconProps>) => (
    <StatusAll sx={{ textColor: 'info-400', ...sx }} {...props} />
  ),
}

export interface CreativeTypeIconProps extends Omit<SvgIconProps, 'children'> {
  name?: string
}

export const CreativeStatusIcon = (props: CreativeTypeIconProps) => {
  const { name = '', ...other } = props

  if (name.toLowerCase() in creativeStatusIconMapping) {
    const Icon =
      creativeStatusIconMapping[
        name.toLowerCase() as keyof typeof creativeStatusIconMapping
      ]
    return <Icon {...other} />
  }

  return null
}
