import React from 'react'
import { Box, Button, enqueueSnackbar } from '@applift/factor'
import {
  Add,
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeNative,
  CreativeVideo,
  EventAll,
} from '@applift/icons'
import { useLocation, useNavigate } from '@tanstack/react-router'
import { useQueryClient } from '@tanstack/react-query'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'
import { SidebarMenu } from '../../components/SidebarMenu'
import { SidebarMenuItem } from '../../components/SidebarMenuItem'
import {
  AUDIO_CREATIVE_ID,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../constants'
import { useCreativeTypeCount, useCreativeTypes } from '../../hooks'
import { useDialogManager } from '../dialogManager'
import { AddNewButton } from '../native'

export const Sidebar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { setAction } = useDialogManager()
  const { data: cretiveTypeCountData } = useCreativeTypeCount()
  const { data: creativeTypeList } = useCreativeTypes()

  const queryClient = useQueryClient()

  const creativeTypeMappingByName = React.useMemo(
    () => creativeTypeList?.creativeTypeMappingByName,
    [creativeTypeList]
  )

  const typeCountMappingById = React.useMemo(
    () => cretiveTypeCountData?.typeCountMappingById,
    [cretiveTypeCountData]
  )

  return (
    <Box sx={{ py: 16, pl: 12, pr: 24 }}>
      <SidebarMenu>
        <SidebarMenuItem
          itemIcon={<EventAll fontSize={24} />}
          title="All"
          count={
            typeCountMappingById?.[
              creativeTypeMappingByName?.['All']?.id as number
            ] ?? 0
          }
          description="Determine which creative formats align with your marketing strategy."
          actionButton={
            <Button startIcon={<Add />} onClick={() => setAction('all')}>
              Add New
            </Button>
          }
          selected={pathname.split('/').includes('all')}
          onClick={() => navigate({ to: '/' })}
        />
        <SidebarMenuItem
          itemIcon={<CreativeImage fontSize={24} />}
          title="Image"
          count={
            typeCountMappingById?.[
              creativeTypeMappingByName?.['Image']?.id as number
            ] ?? 0
          }
          description="Capture consumers’ valuable attention with bold, static visuals."
          actionButton={
            <Button
              startIcon={<Add />}
              onClick={() =>
                setAction('image', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          IMAGE_CREATIVE_ID
                        ),
                    })
                    if (createdCreativesId.length) {
                      const succeededCreative = Object.keys(
                        response.successData ?? {}
                      ).map(assetId => {
                        return {
                          creativeId: Number(
                            response.successData[`${assetId}`]
                          ),
                          platformCreativeTypeId: IMAGE_CREATIVE_ID,
                          creativeName: request[`${assetId}`]?.creativeName,
                        }
                      })
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add New
            </Button>
          }
          selected={pathname.split('/').includes('image')}
          onClick={() => navigate({ to: '/image' })}
        />

        <SidebarMenuItem
          itemIcon={<CreativeHTML fontSize={24} />}
          title="HTML"
          count={
            typeCountMappingById?.[
              creativeTypeMappingByName?.['HTML']?.id as number
            ] ?? 0
          }
          description="Engage users with dynamic, interactive content that adapts seamlessly across devices."
          actionButton={
            <Button
              startIcon={<Add />}
              onClick={() =>
                setAction('html', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          HTML_CREATIVE_ID
                        ),
                    })

                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: HTML_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add New
            </Button>
          }
          selected={pathname.split('/').includes('html')}
          onClick={() => navigate({ to: '/html' })}
        />

        <SidebarMenuItem
          itemIcon={<CreativeVideo fontSize={24} />}
          title="Video"
          count={
            typeCountMappingById?.[
              creativeTypeMappingByName?.['Video']?.id as number
            ] ?? 0
          }
          description="Deliver compelling stories with moving images and sound."
          actionButton={
            <Button
              startIcon={<Add />}
              onClick={() =>
                setAction('video', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) =>
                        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                          VIDEO_CREATIVE_ID
                        ),
                    })
                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: VIDEO_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add New
            </Button>
          }
          selected={pathname.split('/').includes('video')}
          onClick={() => navigate({ to: '/video' })}
        />

        <SidebarMenuItem
          itemIcon={<CreativeAudio fontSize={24} />}
          title="Audio"
          count={
            typeCountMappingById?.[
              creativeTypeMappingByName?.['Audio']?.id as number
            ] ?? 0
          }
          description="Reach on-the-go users with tailored audio ads."
          actionButton={
            <Button
              startIcon={<Add />}
              onClick={() =>
                setAction('audio', undefined, {
                  onSuccess: (
                    response: CreateCreativeResponse,
                    request: CreateCreativeRequest
                  ) => {
                    const createdCreativesId = Object.values(
                      response.successData
                    )
                    const failedCreatives = Object.keys(response.failedData)
                    if (createdCreativesId.length) {
                      enqueueSnackbar({
                        message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                        variant: 'success',
                      })
                    }
                    if (failedCreatives.length) {
                      enqueueSnackbar({
                        message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                        variant: 'error',
                      })
                    }

                    queryClient.resetQueries({
                      predicate: (query: any) => {
                        return (
                          query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                          query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                            AUDIO_CREATIVE_ID
                          )
                        )
                      },
                    })
                    const succeededCreative = Object.keys(
                      response.successData ?? {}
                    ).map(assetId => {
                      return {
                        creativeId: Number(response.successData[`${assetId}`]),
                        platformCreativeTypeId: AUDIO_CREATIVE_ID,
                        creativeName: request[`${assetId}`]?.creativeName,
                      }
                    })
                    if (succeededCreative.length) {
                      setAction('group', succeededCreative)
                    }
                  },
                })
              }
            >
              Add New
            </Button>
          }
          selected={pathname.split('/').includes('audio')}
          onClick={() => navigate({ to: '/audio' })}
        />
        <SidebarMenuItem
          itemIcon={<CreativeNative fontSize={24} />}
          title="Native"
          count={
            typeCountMappingById?.[
              creativeTypeMappingByName?.['Native']?.id as number
            ] ?? 0
          }
          description="Blend in seamlessly with surrounding content to drive cohesive, authentic interactions."
          actionButton={
            <>
              <AddNewButton
                onNewClick={() =>
                  setAction('native', undefined, {
                    onSuccess: (
                      response: CreateCreativeResponse,
                      request: CreateCreativeRequest
                    ) => {
                      const createdCreativesId = Object.values(
                        response.successData
                      )
                      const failedCreatives = Object.keys(response.failedData)
                      if (createdCreativesId.length) {
                        enqueueSnackbar({
                          message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                          variant: 'success',
                        })
                      }
                      if (failedCreatives.length) {
                        enqueueSnackbar({
                          message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                          variant: 'error',
                        })
                      }

                      queryClient.resetQueries({
                        predicate: (query: any) =>
                          query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                          query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                            NATIVE_CREATIVE_ID
                          ),
                      })
                      const succeededCreative = Object.keys(
                        response.successData ?? {}
                      ).map(assetId => {
                        return {
                          creativeId: Number(
                            response.successData[`${assetId}`]
                          ),
                          platformCreativeTypeId: NATIVE_CREATIVE_ID,
                          creativeName: request[`${assetId}`]?.creativeName,
                        }
                      })
                      if (succeededCreative.length) {
                        setAction('group', succeededCreative)
                      }
                    },
                  })
                }
                onExisitingClick={() =>
                  setAction('existingNative', undefined, {
                    onSuccess: (
                      response: CreateCreativeResponse,
                      request: CreateCreativeRequest
                    ) => {
                      const createdCreativesId = Object.values(
                        response.successData
                      )
                      const failedCreatives = Object.keys(response.failedData)
                      if (createdCreativesId.length) {
                        enqueueSnackbar({
                          message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
                          variant: 'success',
                        })
                      }
                      if (failedCreatives.length) {
                        enqueueSnackbar({
                          message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
                          variant: 'error',
                        })
                      }
                      queryClient.resetQueries({
                        predicate: (query: any) =>
                          query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                          query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
                            NATIVE_CREATIVE_ID
                          ),
                      })
                      const succeededCreative = Object.keys(
                        response.successData ?? {}
                      ).map(assetId => {
                        return {
                          creativeId: Number(
                            response.successData[`${assetId}`]
                          ),
                          platformCreativeTypeId: NATIVE_CREATIVE_ID,
                          creativeName: request[`${assetId}`]?.creativeName,
                        }
                      })
                      if (succeededCreative.length) {
                        setAction('group', succeededCreative)
                      }
                    },
                  })
                }
              />
            </>
          }
          selected={pathname.split('/').includes('native')}
          onClick={() => navigate({ to: '/native' })}
        />
      </SidebarMenu>
    </Box>
  )
}
