import { Box, Divider, Typography, Tooltip, Link } from '@applift/factor'
import { ExternalLink, InfoCircle } from '@applift/icons'

export const SpecificationsTooltip = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Typography lineHeight="single-line" weight="demi">
        Video Specifications
      </Typography>
      <Box Component="table" style={{ borderCollapse: 'collapse' }}>
        <Box Component="tr">
          <Box Component="td" sx={{ borderBottom: 1, pr: 12 }}>
            <Typography lineHeight="single-line" weight="demi">
              Ratio
            </Typography>
          </Box>
          <Box Component="td" sx={{ borderBottom: 1, pr: 12 }}>
            <Typography lineHeight="single-line" weight="demi">
              Recommended
            </Typography>
          </Box>
          <Box Component="td" sx={{ borderBottom: 1 }}>
            <Typography lineHeight="single-line" weight="demi">
              Minimum
            </Typography>
          </Box>
        </Box>
        <Box Component="tr">
          <Box
            Component="td"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral-100', pr: 12 }}
          >
            <Typography lineHeight="single-line">4x3 video</Typography>
          </Box>
          <Box
            Component="td"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral-100', pr: 12 }}
          >
            <Typography lineHeight="single-line">640X480</Typography>
          </Box>
          <Box
            Component="td"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral-100' }}
          >
            <Typography lineHeight="single-line">480X360</Typography>
          </Box>
        </Box>
        <Box Component="tr">
          <Box
            Component="td"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral-100', pr: 12 }}
          >
            <Typography lineHeight="single-line">16x9 video</Typography>
          </Box>
          <Box
            Component="td"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral-100', pr: 12 }}
          >
            <Typography lineHeight="single-line">1920X1080</Typography>
          </Box>
          <Box
            Component="td"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral-100' }}
          >
            <Typography lineHeight="single-line">640X360</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography lineHeight="single-line" weight="demi">
          Ideal Video Lengths
        </Typography>
        <Typography lineHeight="single-line">15, 30, and 60 seconds</Typography>
      </Box>
      <Divider sx={{ width: 100 }} color="secondary" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography lineHeight="single-line" weight="demi">
          VAST & VPAID Specifications
        </Typography>
        <Box Component="ul" sx={{ pl: 24 }}>
          <Box Component="li">Supported VAST versions: 2.0, 3.0, 4.0</Box>
          <Box Component="li">VAST Wrappers - Supported</Box>
          <Box Component="li">VPAID - Not supported</Box>
        </Box>
      </Box>
    </Box>
  )
}

export const SpecificationsNote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 8,
      }}
    >
      <Tooltip arrow title={<SpecificationsTooltip />}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textColor: 'neutral-600',
            gap: 4,
          }}
          lineHeight="single-line"
        >
          <Box
            Component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            <InfoCircle sx={{ textColor: 'neutral-400' }} />
          </Box>
          Recommended Specifications
        </Typography>
      </Tooltip>
      <Link
        href="https://www.iab.com/wp-content/uploads/2019/04/IABNewAdPortfolio_LW_FixedSizeSpec.pdf"
        color="primary"
        // @ts-ignore
        target="_blank"
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 4,
          }}
          lineHeight="single-line"
        >
          <Box
            Component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            <ExternalLink />
          </Box>
          Know More
        </Typography>
      </Link>
    </Box>
  )
}
