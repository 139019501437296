import React from 'react'

import { useVirtualizer } from '@tanstack/react-virtual'
import { throttle, useEventCallback } from '@applift/factor'

interface UseResponsiveVirtualizerParams {
  count: number
  getScrollElement: () => Element | null
}

const breakpoints = {
  small: window.matchMedia('(max-width: 767px)'),
  medium: window.matchMedia('(min-width: 768px) and (max-width: 1200px)'),
  large: window.matchMedia('(min-width: 1200px)'),
}

export const useResponsiveVirtualizer = (
  params: UseResponsiveVirtualizerParams &
    Partial<Parameters<typeof useVirtualizer>[0]>
) => {
  const [lanes, setLanes] = React.useState(3)

  const rowVirtualizer = useVirtualizer({
    gap: 24,
    overscan: 6,
    lanes: lanes,
    estimateSize: () => 610,
    ...params,
  })

  const handleBreakPointChanges = useEventCallback(() => {
    rowVirtualizer.scrollToIndex(0)
    if (breakpoints.large.matches) {
      setLanes(3)
    } else if (breakpoints.medium.matches) {
      setLanes(2)
    } else {
      setLanes(1)
    }
    rowVirtualizer.measure()
  })

  const throttledBreakpointHandler = throttle(handleBreakPointChanges, 100)

  React.useEffect(() => {
    Object.values(breakpoints).forEach(breakpoint => {
      breakpoint.addEventListener('change', throttledBreakpointHandler)
    })
    return () => {
      Object.values(breakpoints).forEach(breakpoint => {
        breakpoint.removeEventListener('change', throttledBreakpointHandler)
      })
    }
    // eslint-disable-next-line
  }, [rowVirtualizer])

  // eslint-disable-next-line
  React.useEffect(throttledBreakpointHandler, [lanes])

  return rowVirtualizer
}
