import * as React from 'react'
import { Box, useSnackbar } from '@applift/factor'

import { DropZone } from './DropZone'
import { SupportedFilesNote } from './SupportedFilesNote'
import { SpecificationsNote } from './SpecificationsNote'
import {
  AssetsResponse,
  AudioCreativeGenerationData,
  DropZoneStates,
} from '../../../../../models'
import { filterAllowedAudioFiles, getDuration } from './helper'
import { useAssetUpload } from '../../../../../hooks'
import { LoadingState, ErrorState } from './DropzoneStates'
import { errorFileToast } from '../../../../../utils'

interface Props {
  onChange?: (creative: AudioCreativeGenerationData[]) => void
}

export const Upload = (props: Props) => {
  const { onChange } = props

  const [dropZoneState, setDropZoneState] =
    React.useState<DropZoneStates>('normal')

  const onAdd = () => {
    onChange?.([
      {
        id: crypto.randomUUID(),
        name: '',
        audioCreativeType: 'INVALID',
        creativeSource: '',
        thirdPartyID: '',
        clickURL: '',
      },
    ])
  }

  const onSuccess = async (res: AssetsResponse) => {
    const assetsWithDuration = await getDuration(res)

    const audioData = assetsWithDuration.map(
      (audioItem): AudioCreativeGenerationData => ({
        id: crypto.randomUUID(),
        audioCreativeType: 'URL',
        name: audioItem.name,
        duration: audioItem.duration ?? undefined,
        creativeSource: audioItem.asset.assetCDNURL,
        clickURL: '',
        thirdPartyID: '',
        completeURL: '',
        thirdQuartileURL: '',
        midPointURL: '',
        impressionURL: '',
        startURL: '',
        firstQuartileURL: '',
      })
    )
    onChange?.(audioData)
    setDropZoneState('normal')
  }

  const onError = () => setDropZoneState('error')

  const assetUpload = useAssetUpload({ onError, onSuccess })
  const { closeSnackbar } = useSnackbar()

  const onUpload = async (fileList: File[]) => {
    const { validFiles, errorFiles } = filterAllowedAudioFiles(fileList)
    if (errorFiles?.length) {
      errorFileToast(errorFiles, closeSnackbar, 'audio_error_files.csv')
    }
    if (validFiles.length === 0) return
    setDropZoneState('loading')
    assetUpload.mutate(validFiles)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
        py: 16,
        pr: 24,
        gap: 24,
      }}
    >
      {dropZoneState === 'loading' ? (
        <LoadingState />
      ) : dropZoneState === 'error' ? (
        <ErrorState onClose={() => setDropZoneState('normal')} />
      ) : (
        <DropZone
          state="normal"
          onAdd={onAdd}
          onUpload={onUpload}
          setDropZoneState={setDropZoneState}
        />
      )}
      <SupportedFilesNote />
      <SpecificationsNote />
    </Box>
  )
}
