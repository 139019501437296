/**
 * This function is only works properly when the the values in T are unique across the array
 */

export function groupByUnique<T, K extends keyof T>(array: T[], key: K) {
  type ReturnType = Record<T[K] & (string | number | symbol), Omit<T, K>>

  return array.reduce<ReturnType>((result, item) => {
    const { [key]: value, ...other } = item
    return { ...result, [value as string]: other }
  }, {} as ReturnType)
}

export function arraysAreEqual(
  arr1: (string | number)[],
  arr2: (string | number)[]
) {
  if (arr1?.length !== arr2?.length) return false

  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  return sortedArr1.every((val, index) => val === sortedArr2[index])
}
