import React, { useEffect } from 'react'

import { Box } from '@applift/factor'
import { useVirtualizer } from '@tanstack/react-virtual'
import { ColumnDef, VisibilityState } from '@tanstack/react-table'

import { VirtualItem } from '../..'
import { GroupDetails } from '../GroupDetails'
import { CreativeGroup } from '../../../../api'
import { Creative, DateRangeType, ViewType } from '../../../../models'
import { GroupAccordion, GroupAccordionSkeleton } from '../GroupAccordion'
import { SortMenuProps } from '../ActionPanel/SortMenu'
import { DEFAULT_PAGE_SIZE } from '../../../../constants'
import { _ErrorOverlay, _NoResultsOverlay } from './overlays'

export interface GroupsListProps extends SortMenuProps {
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  data: CreativeGroup[]
  viewType: ViewType
  filteredRecords: number
  fetchNextPage: () => void
  columnVisibility: VisibilityState
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  colDef: ColumnDef<Creative>[]
  statusIds?: number[]
  dateRange: DateRangeType
  search: string
  organizationTimezoneName?: string
}

export const GroupsList = (props: GroupsListProps) => {
  const {
    data,
    fetchNextPage,
    filteredRecords,
    isLoading,
    isFetching,
    isError,
    ...other
  } = props

  const scrollElementRef = React.useRef<HTMLDivElement>(null)

  const [expandedGroupIndex, setExpandedGroupIndex] = React.useState<number>(-1)

  const count = isLoading
    ? DEFAULT_PAGE_SIZE
    : Math.min(filteredRecords, data.length + 6)

  const [expandedGroupedCreativesCount, setExpandedGroupedCreativesCount] =
    React.useState(DEFAULT_PAGE_SIZE)

  const STATIC_CONTENT_HEIGHT_IN_PIXEL = 220
  const COLLAPSED_GROUP_HEIGHT = 72
  const EXTENDED_GROUP_MAX_HEIGHT = 588
  const TABLE_ROW_HEIGHT = 80

  const expandedGroupHeight = React.useMemo(() => {
    if (other.viewType === 'grid' || expandedGroupedCreativesCount <= 0) {
      return EXTENDED_GROUP_MAX_HEIGHT
    }

    return Math.min(
      EXTENDED_GROUP_MAX_HEIGHT,
      STATIC_CONTENT_HEIGHT_IN_PIXEL +
        TABLE_ROW_HEIGHT * expandedGroupedCreativesCount
    )
  }, [expandedGroupedCreativesCount, other.viewType])

  const virtualizer = useVirtualizer({
    gap: 16,
    lanes: 1,
    overscan: 2,
    count: count,
    estimateSize: index =>
      expandedGroupIndex === index
        ? expandedGroupHeight
        : COLLAPSED_GROUP_HEIGHT,
    getScrollElement: () => scrollElementRef?.current,
  })

  const handleExpand = (expanded: boolean, index: number) => {
    if (expanded === true) {
      setExpandedGroupIndex(index)
    } else {
      setExpandedGroupIndex(-1)
    }
  }

  useEffect(
    () => virtualizer.measure(),
    [
      expandedGroupIndex,
      expandedGroupedCreativesCount,
      expandedGroupHeight,
      virtualizer,
    ]
  )

  // This closes all accordions while filter applies, hence accordions skeleton renders properly
  React.useEffect(() => {
    if (isLoading === true) {
      setExpandedGroupIndex(-1)
      virtualizer.measure()
    }
  }, [isLoading, virtualizer])

  if (isError) {
    return (
      <Box sx={{ width: 100, height: 100 }}>
        <_ErrorOverlay />
      </Box>
    )
  }

  if (data.length <= 0 && isLoading === false && isFetching === false) {
    return (
      <Box sx={{ width: 100, height: 100 }}>
        <_NoResultsOverlay />
      </Box>
    )
  }

  return (
    <Box sx={{ height: 100, p: 16, overflow: 'auto' }} ref={scrollElementRef}>
      <Box style={{ position: 'relative', height: virtualizer.getTotalSize() }}>
        {virtualizer.getVirtualItems()?.map(item => {
          const group = data[item.index] as CreativeGroup
          if (
            item.index == data.length &&
            isLoading === false &&
            isFetching === false
          ) {
            fetchNextPage()
          }
          return (
            <VirtualItem {...item} totalLanes={1} key={item.key}>
              {item.index < data.length ? (
                <GroupAccordion
                  {...group}
                  {...item}
                  key={group.creativeGroupId}
                  expanded={item.index === expandedGroupIndex}
                  onChange={(_, expanded) => handleExpand(expanded, item.index)}
                >
                  <GroupDetails
                    group={group as CreativeGroup}
                    setExpandedGroupedCreativesCount={
                      setExpandedGroupedCreativesCount
                    }
                    {...other}
                  />
                </GroupAccordion>
              ) : (
                <GroupAccordionSkeleton />
              )}
            </VirtualItem>
          )
        })}
      </Box>
    </Box>
  )
}
