import {
  Avatar,
  Box,
  Skeleton,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { Delete } from '@applift/icons'
import * as React from 'react'
import { useStore } from '@tanstack/react-store'
import {
  ERROR_MESSAGES,
  NATIVE_BRAND_ICON_ALLOWED_DIMENSIONS,
} from '../../../../../../constants'
import { MediaQueue } from '../../../../../../hooks'
import { NativeCreativeGenerationData } from '../../../../../../models'
import { MediaFile } from '../../../../../../models/asset'
import { NativeCreationFormType } from '../../CreateWrapper'
import { BrandIconDropzone } from '../../Upload/Dropzone'
import {
  validateImageDimensions,
  validateImageFileDimensions,
} from '../../Upload/Dropzone/utils'

import { isValidURL } from '../../../../../../utils'
import styles from './index.module.scss'

interface Props {
  form: NativeCreationFormType
  updateMasterList: () => void
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  creativesList: NativeCreativeGenerationData[]
  queue: MediaQueue
  isCreateClicked: boolean
}

export const DropzoneWrapper = (props: Props) => {
  const {
    form,
    onChange,
    creativesList,
    updateMasterList,
    queue,
    isCreateClicked,
  } = props

  const { uniqueId, brandIcon } = useStore(form.store, state => state.values)

  const onUpload = React.useCallback(
    async (files: File[]) => {
      const validFiles = await validateImageFileDimensions(
        files,
        NATIVE_BRAND_ICON_ALLOWED_DIMENSIONS
      )
      if (validFiles?.length) {
        const updatedFiles: MediaFile[] = validFiles.map(fileObject => ({
          fileObject,
          uniqueId: `${uniqueId}_${crypto.randomUUID()}`,
        }))
        queue.enqueue(updatedFiles)
      } else {
        enqueueSnackbar(ERROR_MESSAGES.INVALID_ASPECT_RATIO, {
          variant: 'error',
        })
      }
    },
    [queue, uniqueId]
  )

  // how many images are being processed for currently selected creative
  const currentlyProcessingImageCount = React.useMemo(
    () =>
      [...queue.currentProcessingBatch, ...queue.queue]
        .map(processingItem => processingItem.uniqueId.split('_'))
        .filter(
          splitUniqueId => splitUniqueId[0] === form.state.values.uniqueId
        ).length,
    [form, queue]
  )

  const updateinAllCreatives = () => {
    const value = form.getFieldValue('brandIcon')
    if (value) {
      const updatedCreatives = creativesList.map(creative => ({
        ...creative,
        brandIcon: { ...value, parentId: creative.uniqueId },
      }))

      onChange?.(updatedCreatives)

      enqueueSnackbar(
        <>
          <Typography sx={{ textWeight: 'demi' }}>Brand Icon</Typography>{' '}
          updated succesfully for all creatives
        </>,
        { variant: 'success' }
      )
    }
  }

  const enableUpdateBtn = creativesList.length > 1 && Boolean(brandIcon)

  const onURLAdd = async (imageURL: string, onSuccess: () => void) => {
    if (imageURL.length > 0 && isValidURL(imageURL)) {
      const imageDimensions = await validateImageDimensions(
        imageURL,
        NATIVE_BRAND_ICON_ALLOWED_DIMENSIONS
      )
        .then((image: HTMLImageElement) => ({
          height: image.naturalHeight,
          width: image.naturalWidth,
        }))
        .catch(() => {
          return false
        })

      if (imageDimensions && typeof imageDimensions === 'object') {
        form.setFieldValue('brandIcon', {
          src: imageURL,
          height: imageDimensions.height,
          width: imageDimensions.width,
          name: '',
          parentId: uniqueId,
        })
        updateMasterList()
        enqueueSnackbar('Uploaded image from URL', { variant: 'success' })
        onSuccess()
      } else {
        enqueueSnackbar(ERROR_MESSAGES.INVALID_ASPECT_RATIO, {
          variant: 'error',
        })
      }
    }
  }

  const isUploading = Boolean(currentlyProcessingImageCount)
  const hideDropzone = isUploading || Boolean(brandIcon)
  return (
    <>
      {hideDropzone === false && (
        <BrandIconDropzone onUpload={onUpload} onURLAdd={onURLAdd} />
      )}
      {hideDropzone === false && isCreateClicked ? (
        <Typography
          component="span"
          variant="label"
          lineHeight="single-line"
          sx={{
            marginTop: 4,
            marginX: 12,
            textColor: 'danger-500',
          }}
        >
          Brand Icon is required
        </Typography>
      ) : null}
      <Box sx={{ pl: 12, display: hideDropzone ? undefined : 'none', mb: 4 }}>
        <ImagesAndSkeleton
          isLoading={isUploading}
          form={form}
          updateMasterList={updateMasterList}
        />
      </Box>

      <Box
        onClick={updateinAllCreatives}
        sx={{ pl: 12, mt: 2 }}
        style={{
          cursor: enableUpdateBtn ? 'pointer' : 'auto',
          pointerEvents: enableUpdateBtn ? 'auto' : 'none',
          opacity: enableUpdateBtn ? 1 : 0.4,
        }}
      >
        <Typography
          sx={{
            textWeight: 'demi',
            textColor: 'primary-600',
          }}
        >
          Update in all creatives
        </Typography>
      </Box>
    </>
  )
}

interface ImagesAndSkeletonProps {
  isLoading: boolean
  form: NativeCreationFormType
  updateMasterList: () => void
}

const ImagesAndSkeleton = (props: ImagesAndSkeletonProps) => {
  const { isLoading, form, updateMasterList } = props

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 8,
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <form.Field name="brandIcon">
        {field => {
          return (
            <Box
              sx={{
                position: 'relative',
                display: isLoading ? 'none' : 'flex',
                gap: 4,
                alignItems: 'start',
              }}
              style={{
                width: '80px',
                height: '60px',
                cursor: 'pointer',
              }}
              className={styles.imageWrapper}
              onClick={() => {
                field.handleChange(null)
                updateMasterList()
              }}
            >
              {Boolean(field.state.value?.src) && (
                <Avatar
                  variant="square"
                  src={field.state.value?.src}
                  sx={{
                    width: 100,
                    height: 100,
                    border: 1,
                    borderRadius: 4,
                    borderColor: 'neutral-300',
                  }}
                />
              )}
              <Box sx={{ position: 'absolute' }} className={styles.actionIcon}>
                <Delete
                  fontSize={14}
                  sx={{
                    fontSize: 16,
                    textColor: 'neutral-0',
                  }}
                />
              </Box>
            </Box>
          )
        }}
      </form.Field>

      {isLoading && <Skeleton variant="rectangular" height={60} width={80} />}
    </Box>
  )
}
