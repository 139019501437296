export const XBrandIconMinSize = '100x100'
export const XMinSizeImage = '1200x628'
export const XAspectRatio = '16:9'

export const TWITTER_NATIVE_ASPECT_RATIO_VALUE = 16 / 9
export const TWITTER_NATIVE_ASPECT_RATIO_MIN_WIDTH = 16
export const TWITTER_NATIVE_ASPECT_RATIO_MIN_HEIGHT = 9

export const IMAGE_HEIGHT_MIN = 200
export const IMAGE_WIDTH_MIN = 200

export const NATIVE_TITLE_CHAR_LIMIT = 140
export const NATIVE_DESCRIPTION__CHAR_LIMIT = 140
export const TWITTER_NATIVE_TITLE_LIMIT = 25
export const TWITTER_NATIVE_DESCRIPTION_LIMIT = 90
export const MAX_TITLE_DESCRIPTION_VARIATION = 5
export const NATIVE_BRAND_NAME_CHAR_LIMIT = 25
export const MAX_NATIVE_VARIATION_LIMIT = 5
export const MAX_NATIVE_MEDIA_LIMIT = 5
export const MAX_NATIVE_VIDEO_THUMBMAIL_LIMT = 1
export const NATIVE_VIDEO_DURATION_MIN = 15
export const NATIVE_VIDEO_DURATION_MAX = 120
export const NATIVE_CTA_CHAR_LIMIT = 15

export const NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS = [
  { width: IMAGE_WIDTH_MIN, height: IMAGE_HEIGHT_MIN, aspectRatio: 1 / 1 },
  { width: 267, height: 200, aspectRatio: 4 / 3 },
  { width: 382, height: 200, aspectRatio: 1.91 / 1 },
]

export const NATIVE_BRAND_ICON_ALLOWED_DIMENSIONS = [
  { width: 50, height: 50, aspectRatio: 1 / 1 },
]

export const NATIVE_ALLOWED_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg',
]
export const VIDEO_FILE_TYPE = ['video/mp4', 'video/quicktime']
export const ALLOWED_EXCEL_FORMATS = [
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'text/csv', // CSV
]
export const VIDEO_ALLOWED_TYPES = [
  ...VIDEO_FILE_TYPE,
  ...ALLOWED_EXCEL_FORMATS,
]

export const MAX_NATIVES_COUNT_PER_CREATION = 200

export const ERROR_MESSAGES = {
  UNSUPPORTED: 'Unsupported file type',
  INVALID_ASPECT_RATIO:
    "Aspect ratio & dimensions don't match the recommendations",
  MAXIMUM_200_LIMTI: 'Maximum limit of 200 creatives is reached',
  INVALID_DURATION: 'Duration must be between 15s to 120s',
  INVALID_FORMAT: 'Invalid File Format',
  SIZE_EXCEED: 'File size exceeded',
}

export const CSV_UPLOAD_ERROR_MESSAGES = {
  UPLOAD_FAILED: 'File failed to upload. Please try again',
  PARSE_FAILED: 'Unable to read the file. Please upload a valid CSV',
  TOO_MANY_FILES: 'Only one file is allowed per upload',
  WRONG_FILE_EXTENSION: 'Please upload a valid CSV file',
  TOO_MANY_RECORDS: 'File should contain an maximum of 200 records',
  EMPTY_FILE: 'No data in the uploaded file',
  COLUMN_MISMATCH: 'File does not match the required template',
}

export const MANDATORY_NATIVE_COL = [
  'creative ad name',
  'creative ad type',
  'title',
  'description',
  'cta url',
]
