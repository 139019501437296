import { AxiosError, AxiosResponse } from 'axios'
import {
  CreateGroupRequest,
  CreateGroupResponse,
  WithResponse,
} from '../models'
import { getInstance } from './instance'

export const createGroup = async (request: CreateGroupRequest) => {
  try {
    const response: AxiosResponse<WithResponse<CreateGroupResponse>> =
      await getInstance().post('/v3/crt/creatives/groups', request)
    return response.data.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}
