import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface DeleteCreativeGroupParams {
  creativeGroupIds: number[]
}

interface DeleteCreativeGroupResponse
  extends WithResponse<{ message: string }> {}

export const deleteCreativeGroup = async (
  params: DeleteCreativeGroupParams
) => {
  try {
    const creativeGroupIdsString = params.creativeGroupIds
      .filter(Boolean)
      .join(',')

    const res: AxiosResponse<DeleteCreativeGroupResponse> =
      await getInstance().delete('/v3/crt/creatives/groups', {
        params: {
          creativeGroupIds: creativeGroupIdsString,
        },
      })

    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
