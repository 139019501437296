import { useQuery } from '@tanstack/react-query'
import { GetCreativeStatusCountRequest } from '../models'
import { getCreativeStatusCount } from '../api/creative'

export const useCreativeStatusCount = (
  params?: GetCreativeStatusCountRequest,
  options?: {
    enabled?: boolean
  }
) => {
  const queryData = useQuery({
    queryKey: [{ scope: 'getCreativeStatusCount', ...params }],
    queryFn: getCreativeStatusCount,
    enabled: options?.enabled ?? true,
  })
  return queryData
}
