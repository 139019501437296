import { useMutation, useQueryClient } from '@tanstack/react-query'
import { WithResponse } from '@applift/platform'
import { duplicateCreatives, scopeTypes } from '../api'

interface UseDuplicateCreativesParams {
  onSuccess: (message: string) => void
  onError: (message: string) => void
}

export const useDuplicateCreatives = (params: UseDuplicateCreativesParams) => {
  const { onSuccess, onError } = params

  const queryClient = useQueryClient()

  const mutationData = useMutation({
    mutationKey: [{ scope: 'duplicateCreatives' }],
    mutationFn: duplicateCreatives,
    onSuccess: ({ success, data, errorObjects }) => {
      if (success) {
        onSuccess(data?.message ?? 'External Party Id updated successfully')
        queryClient.resetQueries({
          predicate: query =>
            (query.queryKey[0] as { scope: scopeTypes })?.scope ===
            'getAllCreativeList',
        })
      } else {
        const error = errorObjects?.[0]?.error
        if (error && typeof error === 'string') {
          onError(error)
        } else {
          onError('Something went wrong!')
        }
      }
    },

    onError: (e: WithResponse<any>) => {
      const error = e?.errorObjects?.[0]?.error
      if (error && typeof error === 'string') {
        onError(error)
      } else {
        onError('Something went wrong!')
      }
    },
  })

  return mutationData
}
