import { GridActionCellParams } from '@applift/datagrid'
import { IconButton, Box } from '@applift/factor'
import { Delete } from '@applift/icons'
import { IOBasicDetailsListType } from '@applift/platform'

export interface ActionCellProps {
  onAction?: (params: GridActionCellParams<string, any>) => void
  row?: IOBasicDetailsListType
  meta?: any
}

export const ActionCell = (props: ActionCellProps) => {
  const { onAction, row } = props

  const handleRemoveSelection = () => {
    onAction?.({
      actionName: 'REMOVE',
      metaData: { row: row },
    })
  }

  return (
    <Box>
      <IconButton
        onClick={handleRemoveSelection}
        sx={{ fontSize: 24, textColor: 'neutral-400' }}
        borderRadius="circle"
      >
        <Delete />
      </IconButton>
    </Box>
  )
}
