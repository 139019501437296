import * as React from 'react'
import { Box, Typography } from '@applift/factor'

export interface EmptyFeatureCardType {
  /**
   * Need to pass  feature's icon
   */
  icon?: React.ReactNode
  /**
   * Title of the feature
   */
  title?: string
  /**
   * Sub-text of the feature
   */
  subText?: string
}

export const EmptyFeatureCard = (props: EmptyFeatureCardType) => {
  const { icon, title, subText } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: { xs: 'center', md: 'start' },
        alignItems: { xs: 'center', md: 'start' },
        gap: 16,
      }}
    >
      <Box sx={{ display: 'flex', textColor: 'primary-500', fontSize: 40 }}>
        {icon}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'center', md: 'start' },
          alignItems: { xs: 'center', md: 'start' },
          gap: 4,
        }}
      >
        {title && (
          <Typography
            component={'h3'}
            weight="demi"
            variant="bodyMedium"
            lineHeight="single-line"
            gutterBottom={false}
          >
            {title}
          </Typography>
        )}
        {subText && (
          <Typography
            variant="bodySmall"
            gutterBottom={false}
            weight="normal"
            lineHeight="multi-line"
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            {subText}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
