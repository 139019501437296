import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  enqueueSnackbar,
  LoadingButton,
  Typography,
} from '@applift/factor'

import { CreativeGroup } from '../../../api'
import { CreativeTypeIcon } from '../../../modules/common'
import { useCreativeTypes, useDuplicateCreativeGroup } from '../../../hooks'

interface ConfirmationDialogProps {
  data: CreativeGroup
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const DuplicateCreativeGroupDialog = (
  props: ConfirmationDialogProps
) => {
  const {
    data,
    onClose,
    onSuccess: _onSuccess,
    onError: _onError,
    onSettle,
  } = props

  const onSuccess = () => {
    const message = (
      <Typography>
        <Typography weight="demi">{data.creativeGroupName}</Typography> group
        duplicated successfully
      </Typography>
    )
    enqueueSnackbar(message, { variant: 'success' })
    _onSuccess?.()
    onSettle?.()
    onClose()
  }

  const onError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
    _onError?.(message)
    onSettle?.()
    onClose()
  }

  const duplicateMutation = useDuplicateCreativeGroup({ onSuccess, onError })

  const handleSubmit = () => {
    duplicateMutation.mutate({ groupId: data.creativeGroupId })
  }

  const { creativeTypeMappingById } = useCreativeTypes().data ?? {}
  const creativeType = creativeTypeMappingById?.[data.creativeTypeId]?.name

  return (
    <Dialog open maxWidth={'sm'} fullWidth>
      <DialogTitle>Duplicate Creative Group</DialogTitle>
      <DialogContent>
        <Typography sx={{ flexShrink: 0 }} variant="bodySmall" component="span">
          Are you sure you want to duplicate the creative group{' '}
          <CreativeTypeIcon
            fontSize={18}
            sx={{ mr: 4 }}
            name={creativeType}
            style={{ verticalAlign: 'middle' }}
          />
          <Typography weight="demi" component="span">
            {data.creativeGroupName}
          </Typography>
          ? Duplicating the group will duplicate the nested creatives.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={duplicateMutation.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={duplicateMutation.isLoading}
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
