import { Box, TextField, Typography, enqueueSnackbar } from '@applift/factor'

import { NativeCreationFormType } from '../../CreateWrapper'
import { NATIVE_BRAND_NAME_CHAR_LIMIT } from '../../../../../../constants'
import { nativeBrandNameValidationFn } from '../../../../../../utils'
import { NativeCreativeGenerationData } from '../../../../../../models'

interface Props {
  form: NativeCreationFormType
  creativesList: NativeCreativeGenerationData[]
  onChange?: (data: NativeCreativeGenerationData[]) => void
}

export const BrandName = (props: Props) => {
  const { form, creativesList, onChange } = props

  const updateinAllCreatives = () => {
    const value = form.getFieldValue('brandName')

    onChange?.(
      creativesList.map(creative => ({
        ...creative,
        brandName: value,
      }))
    )
    enqueueSnackbar(
      <>
        <Typography sx={{ textWeight: 'demi' }}>Brand Name</Typography> updated
        succesfully for all creatives
      </>,
      { variant: 'success' }
    )
  }

  return (
    <form.Field
      name="brandName"
      validators={{
        onChange: ({ value }) => nativeBrandNameValidationFn(value),
      }}
    >
      {field => {
        const hasError = Boolean(field.state.meta.errors[0])
        const isValidName =
          hasError === false && Boolean(field.state.value.trim())
        const enableUpdateBtn = creativesList.length > 1 && isValidName
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <TextField
              label="Brand Name"
              variant="outlinedDash"
              fullWidth
              placeholder="Enter Brand Name"
              required
              InputLabelProps={{
                renderSuffix: (
                  <>
                    {field.state.value!.length}/{NATIVE_BRAND_NAME_CHAR_LIMIT}
                  </>
                ),
              }}
              value={field.state.value}
              onChange={(e: any) => {
                const value = e.target.value
                if (value?.trim()?.length === 0) {
                  field.handleChange(value.trim())
                  return
                }
                field.handleChange(e.target.value)
              }}
              error={Boolean(field.state.meta.errors.length)}
              helperText={field.state.meta.errors[0] ?? ''}
            />
            <Box
              onClick={updateinAllCreatives}
              style={{
                cursor: enableUpdateBtn ? 'pointer' : 'auto',
                pointerEvents: enableUpdateBtn ? 'auto' : 'none',
                opacity: enableUpdateBtn ? 1 : 0.4,
                display: hasError ? 'none' : 'block',
              }}
            >
              <Typography
                sx={{
                  textWeight: 'demi',
                  textColor: 'primary-600',
                  pl: 12,
                }}
              >
                Update in all creatives
              </Typography>
            </Box>
          </Box>
        )
      }}
    </form.Field>
  )
}
