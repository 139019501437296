import * as React from 'react'

import { Container } from '@applift/factor'
import {
  ColumnDef,
  constructListFromColumnDef,
  RowSelectionState,
} from '@applift/datagrid'
import { useAppContext } from '@applift/platform'
import { getLocalTimeZone } from '@internationalized/date'

import { ActionPanel } from './ActionPanel'
import { Creative, DateRangeType, ViewType } from '../../../models'
import { CreativeSection } from '../../../components'
import { Grid, Table, useActions } from '..'
import {
  AUDIO_CREATIVE_ID,
  DEFAULT_CREATIVE_LIST_SORTING,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../constants'
import {
  useCreativesList,
  useCreativeStatuses,
  useCreativeTypes,
  useTimezoneList,
} from '../../../hooks'
import { AllCreativeDialogManager } from '../DialogManager'
import { ImageEmptyTab } from '../../image'
import { VideoEmptyTab } from '../../video'
import { AudioEmptyTab } from '../../audio'
import { NativeEmptyTab } from '../../native'
import { HTMLEmptyTab } from '../../html'
import { AllEmptyTab } from '../../all'

interface Props {
  creativeType: 'all' | 'image' | 'native' | 'video' | 'audio' | 'html'
  colDef: ColumnDef<Creative>[]
}

const creativeTypeIdMapper = {
  all: [
    HTML_CREATIVE_ID,
    NATIVE_CREATIVE_ID,
    IMAGE_CREATIVE_ID,
    VIDEO_CREATIVE_ID,
    AUDIO_CREATIVE_ID,
  ],
  image: [IMAGE_CREATIVE_ID],
  audio: [AUDIO_CREATIVE_ID],
  native: [NATIVE_CREATIVE_ID],
  video: [VIDEO_CREATIVE_ID],
  html: [HTML_CREATIVE_ID],
}

export const AllCreatives = (props: Props) => {
  const { creativeType, colDef } = props

  const ctx = useAppContext()
  const organizationTimezoneId = ctx.appMeta.organizationTimezone

  const { data: timezoneList } = useTimezoneList()
  const organizationTimezoneName =
    timezoneList?.[Number(organizationTimezoneId)]?.name ?? getLocalTimeZone()

  const [viewType, setViewType] = React.useState<ViewType>('table')
  // Filter out the default column visibility in case needed custom default column visibility
  const [columnOptions, defaultColumnVisibility] =
    constructListFromColumnDef(colDef)

  const [columnVisibility, setColumnVisibility] = React.useState(
    defaultColumnVisibility
  )
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [sorting, setSorting] = React.useState(DEFAULT_CREATIVE_LIST_SORTING)
  const [search, setSearch] = React.useState('')
  const [statusId, setStatusId] = React.useState<number[]>([])
  const [dateRange, setDateRange] = React.useState<DateRangeType>({
    startDate: null,
    endDate: null,
  })

  const sortBy = React.useMemo(() => {
    return sorting[0]?.id
      ? `${sorting[0]?.desc ? '-' : '+'}${sorting[0]?.id}`
      : undefined
  }, [sorting])

  const {
    data: allCreativeData,
    isFetching,
    isLoading,
    isError,
    fetchNextPage,
  } = useCreativesList({
    searchField: search,
    creativeStatusIds: statusId,
    platformCreativeTypeIds: creativeTypeIdMapper[creativeType],
    sortBy,
    startTime: dateRange?.startDate,
    endTime: dateRange?.endDate,
  })
  const { setAction } = useActions()

  const data = React.useMemo(() => {
    return (
      allCreativeData?.pages?.reduce<Creative[]>((prev, curr) => {
        return [...prev, ...curr.filteredList]
      }, []) ?? []
    )
  }, [allCreativeData])

  const filteredRecords = allCreativeData?.pages?.[0]?.filteredRecords || 0

  const getRowId = (row: Creative) => String(row.creativeId)
  const creativeTypesQueryData = useCreativeTypes({ displayAllType: true })
  const creativeStatusTypeQueryData = useCreativeStatuses({
    displayAllStatusOption: true,
  })

  const loading =
    isFetching ||
    creativeStatusTypeQueryData.isFetching ||
    creativeTypesQueryData.isFetching

  if (
    loading === false &&
    allCreativeData?.pages?.[0]?.filteredRecords === 0 &&
    // If user haven't applied any filter and still the filteredRecords are empty means there are no creatives for that particular type
    Boolean(search) === false &&
    Boolean(Array.isArray(statusId) && statusId.length > 0) === false &&
    !dateRange?.endDate &&
    !dateRange?.startDate
  ) {
    switch (creativeType) {
      case 'all':
        return <AllEmptyTab />
      case 'image':
        return <ImageEmptyTab />
      case 'video':
        return <VideoEmptyTab />
      case 'audio':
        return <AudioEmptyTab />
      case 'native':
        return <NativeEmptyTab />
      case 'html':
        return <HTMLEmptyTab />
      default:
        return <AllEmptyTab />
    }
  }

  return (
    <CreativeSection>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          width: 100,
          height: 100,
          p: 0,
        }}
      >
        <ActionPanel
          viewType={viewType}
          setViewType={setViewType}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          columnOptions={columnOptions}
          sorting={sorting}
          search={search}
          setSearch={setSearch}
          statusId={statusId}
          setStatusId={setStatusId}
          setSorting={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          filteredRecords={filteredRecords}
          data={data}
          creativeTypeIds={creativeTypeIdMapper[creativeType]}
          dateRange={dateRange}
          setDateRange={setDateRange}
          organizationTimezoneName={organizationTimezoneName}
        />
        {viewType === 'table' ? (
          <Table
            data={data}
            colDef={colDef}
            setAction={setAction}
            isError={isError}
            setSorting={setSorting}
            filteredRecords={filteredRecords}
            loading={loading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            setRowSelection={setRowSelection}
            columnPinning={{
              left: ['__check__', 'creativeId'],
            }}
            rowSelection={rowSelection}
            sorting={sorting}
            fetchNextPage={fetchNextPage}
            getRowId={getRowId}
            organizationTimezoneName={organizationTimezoneName}
          />
        ) : (
          <Grid
            data={data}
            setAction={setAction}
            isError={isError}
            isFetching={isFetching}
            isLoading={isLoading}
            filteredRecords={filteredRecords}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            getRowId={getRowId}
            fetchNextPage={fetchNextPage}
            organizationTimezoneName={organizationTimezoneName}
          />
        )}
      </Container>
      <AllCreativeDialogManager />
    </CreativeSection>
  )
}
