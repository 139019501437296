import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
  AssignToCampaignRequest,
  CreativeCampaignDetail,
  WithResponse,
} from '../models'
import { getInstance } from './instance'
import {
  getCreativeCampaignDetailKey,
  getBasicIoCampaignListKey,
} from './QueryKeys'
import { BasicIoCampaignListApiData } from '../models'

export const getBasicIoCampaignList = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getBasicIoCampaignListKey)['keys']>
>): Promise<BasicIoCampaignListApiData> => {
  const {
    searchField,
    noOfEntries,
    ids,
    campaignIds,
    sortBy,
    pageNo,
    creativeTypeIds,
    ioBudgetTypeIdsList,
    ioIdList,
    ioStatusIdsList,
    isAllOWIds,
    offset,
    owIdList,
    campaignStatus,
  } = queryKey[0] || {}
  try {
    const response: AxiosResponse<WithResponse<BasicIoCampaignListApiData>> =
      await getInstance().post('/v3/cmp/io/campaign/basic/list', {
        searchField,
        noOfEntries,
        pageNo,
        ids,
        campaignIds,
        creativeTypeIds,
        ioBudgetTypeIdsList,
        ioIdList,
        ioStatusIdsList,
        isAllOWIds,
        offset,
        owIdList,
        ...(campaignStatus?.length ? { status: campaignStatus } : {}),
        ...(sortBy?.length
          ? { sortBy: sortBy[0]?.id, order: sortBy[0]?.desc ? 'desc' : 'asc' }
          : {}),
      })

    return response.data.data
  } catch (e) {
    throw Promise.reject((e as AxiosError).response)
  }
}

export const assingToCampaign = async (params: AssignToCampaignRequest) => {
  try {
    const response: AxiosResponse<WithResponse<{ message: string }>> =
      await getInstance().patch(`/v3/cmp/target/creative`, {
        ...params,
      })

    return response.data.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

export const getCreativeCampaignDetail = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getCreativeCampaignDetailKey)['keys']>
>) => {
  const { scope: _, creativeId, includeModelingInfo } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<CreativeCampaignDetail>> =
      await getInstance().get(
        `/v3/crt/creatives/${creativeId}/creative-campaign-details`,
        {
          params: { includeModelingInfo },
          cancelToken: source.token,
        }
      )

    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
