import React from 'react'

import { RowSelectionState } from '@tanstack/react-table'
import { VirtualItem as VirtualItemType } from '@tanstack/react-virtual'

import { Card } from './Card'
import { Creative } from '../../../models'
import { VirtualItem } from '../Virtulization'
import { CardSkeleton } from './Card/CardSkeleton'
import { InlineEditAction } from '../CreativeTableActions'

export interface GridItemProps extends VirtualItemType {
  totalLanes: number
  data: Creative[]
  getRowId: (row: Creative) => string
  readonly?: boolean
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  setAction?: React.Dispatch<React.SetStateAction<InlineEditAction>>
  organizationTimezoneName?: string
}

export const GridItem = (props: GridItemProps) => {
  const {
    index,
    totalLanes,
    end,
    lane,
    size,
    start,
    data,
    rowSelection,
    setRowSelection,
    setAction,
    getRowId,
    readonly,
    organizationTimezoneName,
  } = props

  const creative = data[index]
  const rowId = getRowId(creative ?? ({} as Creative))

  return (
    <VirtualItem
      key={index}
      end={end}
      lane={lane}
      size={size}
      start={start}
      index={index}
      totalLanes={totalLanes}
    >
      {creative ? (
        <Card
          setAction={setAction}
          readonly={readonly}
          selected={rowSelection[rowId] ?? false}
          onSelectionChange={selected => {
            if (selected) {
              setRowSelection(prev => ({ ...prev, [rowId]: selected }))
            } else {
              setRowSelection(prev =>
                Object.fromEntries(
                  Object.entries(prev).filter(([key]) => key !== rowId)
                )
              )
            }
          }}
          organizationTimezoneName={organizationTimezoneName}
          creativeData={creative}
        />
      ) : (
        <CardSkeleton />
      )}
    </VirtualItem>
  )
}
