import * as React from 'react'

import {
  ColumnDef,
  ColumnPinningState,
  constructListFromColumnDef,
  DataGrid,
  RowSelectionState,
  SortingState,
  useGridApiRef,
  VisibilityState,
} from '@applift/datagrid'
import { sx } from '@applift/factor'

import { Creative } from '../../../models'
import { isImageCreative, isNativeImageCreatve } from '../../../utils'
import { InlineEditAction } from '../CreativeTableActions'
import { generateRowUpdateAction } from './helper'
import { _ErrorOverlay, _NoResultsOverlay } from './overlays'
import { useCreativeStatuses } from '../../../hooks'

interface TableWrapperProps {
  data: Creative[]
  loading: boolean
  isError: boolean
  sorting: SortingState
  filteredRecords: number
  fetchNextPage: () => void
  rowSelection: RowSelectionState
  columnPinning?: ColumnPinningState
  columnVisibility?: VisibilityState
  getRowId: (row: Creative) => string
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  setAction?: React.Dispatch<React.SetStateAction<InlineEditAction>>
  setRowSelection?: React.Dispatch<React.SetStateAction<RowSelectionState>>
  setColumnVisibility?: React.Dispatch<React.SetStateAction<VisibilityState>>
  colDef: ColumnDef<Creative>[]
  organizationTimezoneName?: string
}

export const Table = (props: TableWrapperProps) => {
  const {
    data,
    loading,
    isError,
    sorting,
    getRowId,
    setAction,
    setSorting,
    rowSelection,
    columnPinning,
    fetchNextPage,
    setRowSelection,
    filteredRecords,
    columnVisibility,
    setColumnVisibility,
    organizationTimezoneName,
    colDef,
  } = props
  const apiRef = useGridApiRef()

  const { data: creativeStatusData } = useCreativeStatuses()

  const defaultData = React.useMemo(
    () => constructListFromColumnDef(colDef),
    [colDef]
  )
  const defaultDeselectedColumns = React.useState(() =>
    Object.keys(defaultData[1]).reduce((prev, one) => {
      // eslint-disable-next-line
      prev[one] = false
      return prev
    }, {} as VisibilityState)
  )[0]

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if ((data?.length ?? 0) <= 0 && loading === false) {
      return 'noResult'
    }
  }, [data?.length, loading, isError])

  return (
    <DataGrid
      apiRef={apiRef}
      loading={loading}
      data={data}
      rowCount={filteredRecords}
      overlay={overlay}
      columns={colDef}
      onAction={setAction as any}
      rowHeight={80}
      components={{
        NoResultsOverlay: _NoResultsOverlay,
        ErrorOverlay: _ErrorOverlay,
      }}
      getRowId={getRowId}
      state={{
        rowSelection,
        sorting,
        columnVisibility: {
          ...defaultDeselectedColumns,
          ...columnVisibility,
        },
        ...(columnPinning ? { columnPinning } : {}),
      }}
      hideHeader
      hideFooter
      checkboxSelection={true}
      overscrollBehaviorX="contain"
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      onRowSelectionChange={setRowSelection}
      onFetchRows={fetchNextPage}
      onSortingChange={setSorting}
      onColumnVisibilityChange={setColumnVisibility}
      meta={{ organizationTimezoneName }}
      processRowUpdate={async (newRow: any, oldRow: any) => {
        const rowAction = generateRowUpdateAction(newRow, oldRow)
        if (rowAction) {
          setAction?.(rowAction as any)
        }
      }}
      isCellEditable={({ rowId, field }) => {
        const row = apiRef.current.tableInstance.getRow(rowId).original
        const deleted =
          row.creativeStatusId ===
          creativeStatusData?.creativeStatusMappingByName['deleted']?.id
        const rejected =
          row.creativeStatusId ===
          creativeStatusData?.creativeStatusMappingByName['rejected']?.id

        if (field === 'pixelUrl') {
          const isImage = isImageCreative(row)
          const isImageNative = isNativeImageCreatve(row)
          return (
            (isImage || isImageNative) &&
            deleted === false &&
            rejected === false
          )
        }
        if (field === 'creativeName') {
          return deleted === false && rejected === false
        }
        if (field === 'clickUrl') {
          return deleted === false && rejected === false
        }
        if (field === 'externalCreativeId') {
          return deleted === false && rejected === false
        }
        return true
      }}
      onCellClick={params => {
        if (params.cellMode === 'view' && apiRef.current) {
          apiRef.current.startCellEditMode({
            rowId: params.rowId,
            field: params.field,
          })
        }
      }}
    />
  )
}
