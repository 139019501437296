import * as React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TypoTooltip,
  Box,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor'
import { Creative } from '../../../models/Creative'
import { useCreativeStatuses, useUpdateCreativeStatus } from '../../../hooks'
import { useQueryClient } from '@tanstack/react-query'
import { WithResponse } from '../../../models'
import { scopeTypes } from '../../../api'

interface ConfirmationDialogProps {
  data: Creative[]
  onClose: () => void
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const DeleteCreativeDialog = (props: ConfirmationDialogProps) => {
  const { data, onClose, onSuccess, onError, onSettle } = props

  const { data: creativeStatusData } = useCreativeStatuses()
  const queryClient = useQueryClient()

  const updateStatusMutation = useUpdateCreativeStatus({
    onSuccess: response => {
      enqueueSnackbar(
        data.length <= 1 ? (
          <Typography>
            <Typography weight="demi">{data[0]?.creativeName}</Typography>{' '}
            creative deleted successfully.
          </Typography>
        ) : (
          <Typography>
            <Typography weight="demi">{data.length}</Typography> Creatives
            deleted successfully
          </Typography>
        ),
        {
          variant: 'success',
        }
      )

      queryClient.resetQueries({
        predicate: (query: any) => {
          const isCreativeListQuery =
            query.queryKey?.[0]?.scope === 'getAllCreativeList'

          const isSameCreativeTypeInQuery =
            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
              data[0]?.platformCreativeTypeId // Refetch query which can be affected by this mutation
            )

          const isCreativeGroupListQuery =
            query.queryKey?.[0]?.scope === 'getCreativeGroupsList'

          const isStatusCountQuery =
            (query.queryKey?.[0]?.scope as scopeTypes) ===
            'getCreativeStatusCount'

          if (
            (isCreativeListQuery && isSameCreativeTypeInQuery) ||
            isCreativeGroupListQuery
          ) {
            return true
          } else if (isStatusCountQuery) {
            return true
          }
          return false
        },
      })
      queryClient.removeQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getAllCreativeList',
      })
      onSuccess?.(response)
    },
    onError: (error: WithResponse<any>) => {
      const message = error.errorObjects?.[0]?.error
      if (message && typeof message === 'string') {
        enqueueSnackbar(message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
      }
      onError?.(error)
    },
    onSettled: () => {
      onSettle?.()
      onClose?.()
    },
  })

  const creativeIds = React.useMemo(
    () => data.map(creative => creative.creativeId),
    [data]
  )

  return (
    <Dialog open maxWidth={'sm'} fullWidth>
      <DialogTitle>
        <Typography weight="demi"> {'Delete Creative?'} </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', width: 100, overflow: 'hidden', gap: 4 }}>
          {data.length > 1 ? (
            <>
              <Typography
                sx={{ flexShrink: 0 }}
                variant="bodySmall"
                component="span"
              >
                {'Are you sure you want to delete '}
              </Typography>
              <TypoTooltip
                arrow
                TypgraphyProps={{
                  sx: {
                    textColor: 'neutral-1000',
                  },
                  weight: 'demi',
                  component: 'span',
                }}
              >
                {`${data.length} creatives?`}
              </TypoTooltip>
            </>
          ) : (
            <>
              <Typography
                sx={{ flexShrink: 0 }}
                variant="bodySmall"
                component="span"
              >
                {'Are you sure you want to delete the selected creative '}
              </Typography>
              <TypoTooltip
                arrow
                placement="top"
                TypgraphyProps={{
                  weight: 'demi',
                  component: 'span',
                }}
              >
                {data[0]?.creativeName}
              </TypoTooltip>
              <Typography
                sx={{ textColor: 'neutral-500', flexShrink: 0 }}
                component="span"
              >
                ?
              </Typography>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={updateStatusMutation.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={updateStatusMutation.isLoading}
          color={'error'}
          variant="contained"
          onClick={() => {
            updateStatusMutation.mutate({
              creativeIds,
              creativeStatusId: creativeStatusData?.creativeStatusMappingByName[
                'deleted'
              ]?.id as number,
            })
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
