import { Box, Tab, Tabs, useDebounceValue } from '@applift/factor'
import { Grid, TableCell } from '@applift/icons'
import { DateRangePicker } from '@applift/app-utils'
import { FilterProps, Filters } from './Filters'
import { ViewType } from '../../../../models/generic'
import { SortMenu, SortMenuProps } from './SortMenu'
import {
  ColumnVisibility,
  VisibilityState,
  constructListFromColumnDef,
} from '@applift/datagrid'
import { SearchField, SearchFieldProps } from './SearchField'
import { allCreativesColDef } from '../..'
import { DEFAULT_CREATIVE_LIST_SORTING } from '../../../../constants'
import { DateRangeType } from '../../../../models'

interface ActionPanelProps
  extends SortMenuProps,
    SearchFieldProps,
    FilterProps {
  viewType: ViewType
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  filteredRecords: number
  statusIds: number[]
  setStatusIds: React.Dispatch<React.SetStateAction<number[]>>
  creativeTypeIds?: number[]
  dateRange: DateRangeType
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>
  organizationTimezoneName: string
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    viewType,
    setViewType,
    setColumnVisibility,
    columnVisibility,
    sorting,
    setSorting,
    search,
    setSearch,
    statusIds,
    setStatusIds,
    creativeTypeIds,
    dateRange,
    setDateRange,
    organizationTimezoneName,
  } = props

  const [columnOptions] = constructListFromColumnDef(allCreativesColDef)

  const debounsedSearch = useDebounceValue(search, 500)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        flexShrink: 0,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 100,
          flexShrink: 0,
          overflow: 'hidden',
          p: 16,
          gap: 16,
          flexWrap: 'wrap-reverse',
          borderBottom: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto',
          }}
        >
          <DateRangePicker
            dateRange={dateRange}
            onChange={value => {
              setDateRange(value)
            }}
            timezoneName={organizationTimezoneName}
            showPreselects={false}
            maxValueParams={{
              param: 'days',
              subtractor: 0,
            }}
            optional
            placeholder="Select Date Range"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: 100,
          flexShrink: 0,
          overflow: 'hidden',
          p: 16,
          gap: 16,
          flexWrap: 'wrap-reverse',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            mr: 'auto',
          }}
        >
          <SearchField search={search} setSearch={setSearch} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            gap: 8,
            ml: 'auto',
          }}
        >
          <Filters
            statusIds={statusIds}
            setStatusIds={setStatusIds}
            countApiState={{
              searchField: debounsedSearch,
              platformCreativeTypeIds: creativeTypeIds,
              groupedCreatives: true,
              startTime: dateRange?.startDate,
              endTime: dateRange?.endDate,
            }}
          />
          {viewType === 'table' ? (
            <ColumnVisibility
              data={columnOptions}
              onChange={setColumnVisibility}
              value={columnVisibility}
              slotProps={{
                SelectAdvancePopoverProps: {
                  slotProps: {
                    PopoverProps: {
                      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                      transformOrigin: { vertical: 'top', horizontal: 'right' },
                    },
                  },
                },
                IconButtonProps: { color: 'secondary' },
              }}
            />
          ) : (
            <SortMenu sorting={sorting} setSorting={setSorting} />
          )}
          <Tabs
            Component={'div'}
            value={viewType ?? 'table'}
            onChange={(_, value) => {
              setViewType(value as ViewType)
              setSorting(DEFAULT_CREATIVE_LIST_SORTING)
            }}
            aria-label="View Option Tabs"
            variant="pill"
            size="small"
          >
            <Tab
              icon={<TableCell />}
              value="table"
              iconPosition="start"
              label="Table"
            />
            <Tab
              icon={<Grid />}
              value="grid"
              iconPosition="start"
              label="Grid"
            />
          </Tabs>
        </Box>
      </Box>
    </Box>
  )
}
