import { useClipboard, useDrop } from '@applift/drag-drop'
import { SampleFile } from '@applift/app-utils'
import {
  Box,
  Button,
  enqueueSnackbar,
  FileTrigger,
  Typography,
} from '@applift/factor'
import { Upload } from '@applift/icons'
import clsx from 'clsx'
import React from 'react'
import {
  DropBoxFilePicker,
  GoogleFilePicker,
} from '@applift/platform-integration'

import { fileToFileList } from '../../../../../utils'
import { CSV_UPLOAD_ERROR_MESSAGES } from '../../../../../constants'

import styles from './index.module.scss'

interface MiniDropzoneProps {
  onUpload: (fileList: FileList | null | File[]) => Promise<void>
  setIsCsvLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const MiniDropzone = (props: MiniDropzoneProps) => {
  const { onUpload, setIsCsvLoading } = props

  const ref = React.useRef<HTMLDivElement>(null)

  const { dropProps, isDropTarget } = useDrop({
    onDrop: async e => {
      if (e?.items?.length > 1) {
        enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.TOO_MANY_FILES, {
          variant: 'error',
        })
        return
      }
      const item = e.items?.[0]
      if (item?.kind === 'file') {
        const file = await item.getFile()
        onUpload(fileToFileList(file))
      }
    },
    ref,
  })

  const { clipboardProps } = useClipboard({
    onPaste: async items => {
      if (items?.length > 1) {
        return enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.TOO_MANY_FILES, {
          variant: 'error',
        })
      }
      if (items.length && items[0]?.kind === 'file') {
        const file = await items[0].getFile()
        onUpload(fileToFileList(file))
      }
    },
  })

  return (
    <Box
      className={clsx(styles.dropZone, isDropTarget && styles.active)}
      {...dropProps}
      {...clipboardProps}
      role="button"
      tabIndex={0}
      ref={ref}
      sx={{
        py: 8,
        px: 12,
        gap: 12,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          justifyContent: 'center',
        }}
      >
        <FileTrigger acceptedFileTypes={['text/csv']} onSelect={onUpload}>
          {({ onClick }) => {
            return (
              <Button
                startIcon={<Upload />}
                variant="outlined"
                onClick={onClick}
                color="secondary"
                size="small"
              >
                Upload CSV
              </Button>
            )
          }}
        </FileTrigger>
        <Typography
          sx={{ textColor: 'neutral-300' }}
          component="span"
          variant="bodyLarge"
          gutterBottom={false}
        >
          &bull;
        </Typography>
        <SampleFile
          sampleFileText="Sample File"
          sampleFileURL={`${window.location.origin}/creatives/example-files/sample_native_creatives.csv`}
          sampleFileName="sample_native_creatives.csv"
          buttonProps={{
            sx: { textWeight: 'normal', fontSize: 12, px: 0, py: 0 },
            startIcon: undefined,
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 4,
        }}
      >
        <Typography
          sx={{ textColor: 'neutral-500', display: 'block', fontSize: 12 }}
        >
          Drag or Paste files here
        </Typography>
        <GoogleFilePicker
          acceptedFileTypes="csv"
          onSelect={async files => {
            onUpload(files)
          }}
          onFilesDownloadingStart={() => {
            setIsCsvLoading(true)
          }}
        />
        <DropBoxFilePicker
          extensions={['.csv']}
          onSelect={async files => {
            onUpload(files)
          }}
          onFilesDownloadingStart={() => {
            setIsCsvLoading(true)
          }}
        />
      </Box>
    </Box>
  )
}
