import * as React from 'react'
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  TypoTooltip,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { Copy, Delete, Image, SemanticError } from '@applift/icons'

import { NativeCreativeGenerationData } from '../../../../../models'
import { MAX_NATIVES_COUNT_PER_CREATION } from '../../../../../constants'

import styles from './index.module.scss'

interface Props {
  creativeList: NativeCreativeGenerationData[]
  idGeneratorRef: React.MutableRefObject<number>
  onChange?: (data: NativeCreativeGenerationData[]) => void
  selectedItemId: string | null
  onRemove?: (id: string) => void
  onSelect: (data: NativeCreativeGenerationData | null) => void
  errorIds: string[]
}

export const CreativeList = (props: Props) => {
  const {
    creativeList,
    onChange,
    selectedItemId,
    idGeneratorRef,
    errorIds,
    onRemove,
    onSelect,
  } = props

  const duplicateItem = (creative: NativeCreativeGenerationData) => {
    if (creativeList.length === MAX_NATIVES_COUNT_PER_CREATION) {
      return enqueueSnackbar(
        `Cannot add more than ${MAX_NATIVES_COUNT_PER_CREATION} creatives`,
        { variant: 'error' }
      )
    }
    onChange?.([
      {
        ...creative,
        temporaryId: idGeneratorRef.current++,
        uniqueId: crypto.randomUUID(),
      },
    ])

    enqueueSnackbar(
      `Creative N${creative.temporaryId.toString().padStart(3, '0')} copied successfully`,
      { variant: 'success' }
    )
  }

  const removeItem = (creative: NativeCreativeGenerationData) => {
    if (selectedItemId === creative.uniqueId) {
      if (creativeList.length > 1) {
        const newList = creativeList.filter(
          item => item.uniqueId != creative.uniqueId
        )
        const newSelection = newList[0]
        onSelect(newSelection as NativeCreativeGenerationData)
      } else if (creativeList.length === 1) {
        onSelect(null)
      }
    }
    onRemove?.(creative.uniqueId)
    enqueueSnackbar(
      `Creative N${creative.temporaryId.toString().padStart(3, '0')} deleted successfully`,
      { variant: 'success' }
    )
  }

  return (
    <>
      {creativeList.map(creative => {
        const imageSrc =
          creative.imageObjects?.[0]?.src ?? creative.videoThumbnail?.src
        const temporaryId = `N${creative.temporaryId.toString().padStart(3, '0')}`
        const hasError = errorIds.includes(creative.uniqueId)

        return (
          <MenuItem
            onClick={() => onSelect(creative)}
            key={`sidebarItem_${creative.uniqueId}`}
            size="large"
            selected={selectedItemId === creative.uniqueId}
            className={styles.menuItem}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                width: 100,
              }}
              className={styles.menuItem}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}
              >
                {imageSrc ? (
                  <Avatar
                    style={{ width: '80px', height: '60px' }}
                    src={imageSrc}
                    variant="square"
                    alt={creative.creativeName}
                    sx={{
                      border: 1,
                      borderRadius: 4,
                      borderColor: 'neutral-300',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      border: 1,
                      borderRadius: 4,
                      borderColor: 'neutral-300',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    style={{ width: '80px', height: '60px' }}
                  >
                    <Image sx={{ textColor: 'neutral-300' }} fontSize={32} />
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  overflowX: 'hidden',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      textWeight: 'demi',
                      display: 'block',
                      textColor: hasError ? 'danger-500' : undefined,
                    }}
                    variant="bodySmall"
                    gutterBottom={false}
                  >
                    {temporaryId}
                  </Typography>
                  {hasError ? (
                    <SemanticError
                      sx={{ textColor: 'danger-500', ml: 4 }}
                      fontSize={20}
                    />
                  ) : null}
                </Box>

                <TypoTooltip
                  arrow
                  placement="top"
                  TypgraphyProps={{ variant: 'bodySmall', gutterBottom: false }}
                >
                  {creative.creativeName || 'Creative Name'}
                </TypoTooltip>
              </Box>

              <Box
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  flexShrink: 0,
                }}
                className={styles.actionIcons}
              >
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    duplicateItem(creative)
                  }}
                  size="small"
                  color="secondary"
                >
                  <Copy fontSize={16} />
                </IconButton>
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    removeItem(creative)
                  }}
                  size="small"
                  color="secondary"
                >
                  <Delete fontSize={16} />
                </IconButton>
              </Box>
            </Box>
          </MenuItem>
        )
      })}
    </>
  )
}
