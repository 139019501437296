import * as React from 'react'
import { Row, Col } from '@applift/factor'
import { ReactChildrenType } from '../../models/utils'

interface EmptyCreativeSectionType {
  children: ReactChildrenType
}

export const EmptyCreativeSection = (props: EmptyCreativeSectionType) => {
  const { children } = props

  const mappedChildren = React.Children.map(children, child => (
    <Col>{child}</Col>
  ))

  return (
    <Row xs={{ gutterSize: 24, col: 1 }} md={2} lg={3}>
      {mappedChildren}
    </Row>
  )
}
