import { Box, TypographyProps, TruncateMiddle } from '@applift/factor'

import { getIcon } from '../../registry'
import { useCreativeTypes } from '../../hooks'

interface CampaignNameCellProp {
  creativeTypeId: number
  campaignName: string
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CampaignNameCell = ({
  campaignName,
  creativeTypeId,
  textTypographyProps,
}: CampaignNameCellProp) => {
  const { data: creativeData } = useCreativeTypes({ displayAllType: true })
  const creative =
    creativeData?.creativeTypeMappingById[creativeTypeId]?.displayName
  const icon = creative ? getIcon(creative as any, { fontSize: 24 }) : null

  return (
    <Box
      sx={{
        width: 100,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: 8,
      }}
    >
      <Box sx={{ flexShrink: 0, display: 'flex' }}>{icon}</Box>
      <TruncateMiddle
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
          sx: {
            overflow: 'hidden',
            ...textTypographyProps?.sx,
          },
        }}
      >
        {campaignName}
      </TruncateMiddle>
    </Box>
  )
}
