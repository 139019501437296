import { VIDEO_CREATIVE_ID } from '../../../constants'
import { CreateCreativeRequest } from '../../../api'
import { VideoCreativeGenerationData } from '../../../models'
import { isValidURL } from '../../../utils'

export function transformVideoStateToApiRequest(
  state: Map<string, VideoCreativeGenerationData>
): CreateCreativeRequest {
  const request = {} as CreateCreativeRequest

  for (const [key, value] of state.entries()) {
    if (value.videoCreativeType === 'file') {
      // @ts-ignore
      request[key] = {
        creativeName: value.name?.trim() as string,
        ...(value.thirdPartyID?.trim()
          ? { externalCreativeId: value.thirdPartyID?.trim() }
          : {}),
        platformCreativeTypeId: VIDEO_CREATIVE_ID,
        creativeSourceType: 'URL',
        creativeSource: value.videoUrl as string,
        ...(value.clickURL?.trim()
          ? { clickUrl: value.clickURL?.trim() as string }
          : {}),
        videoDetails: {
          creativeWidth: value.width as number,
          creativeHeight: value.height as number,
          duration: value.duration as number,
          customTracking: {
            ...(value.startURL?.trim()
              ? { startUrl: value.startURL?.trim() }
              : {}),
            ...(value.firstQuartileURL?.trim()
              ? { firstQuartileURL: value.firstQuartileURL?.trim() }
              : {}),
            ...(value.midPointURL?.trim()
              ? { midPointURL: value.midPointURL?.trim() }
              : {}),
            ...(value.thirdQuartileURL?.trim()
              ? { thirdQuartileURL: value.thirdQuartileURL?.trim() }
              : {}),
            ...(value.completeURL?.trim()
              ? { completeURL: value.completeURL?.trim() }
              : {}),
            ...(value.impressionURL?.trim()
              ? { impressionURL: value.impressionURL?.trim() }
              : {}),
          },
        },
      }
    }
    if (value.videoCreativeType === 'vast') {
      // @ts-ignore
      request[key] = {
        creativeName: value.name?.trim() as string,
        ...(value.thirdPartyID?.trim()
          ? { externalCreativeId: value.thirdPartyID?.trim() }
          : {}),
        platformCreativeTypeId: VIDEO_CREATIVE_ID,
        creativeSourceType: isValidURL(value.vastXml ?? '')
          ? 'VAST_URL'
          : 'VAST_XML',
        creativeSource: value.vastXml as string,
      }
    }
  }

  return request
}
