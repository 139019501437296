import { Box, Skeleton } from '@applift/factor'

export const CardSkeleton = () => {
  const imageSkeleton = (
    <Skeleton variant="rounded" height={180} sx={{ bgColor: 'neutral-100' }} />
  )
  const textFieldSkeleton = (
    <Skeleton
      variant="rounded"
      height={32}
      sx={{ bgColor: 'neutral-100', my: 4 }}
    />
  )
  const keyValueSkeleton = (
    <Skeleton
      variant="rounded"
      width={'70%'}
      height={24}
      sx={{ bgColor: 'neutral-100', my: 12 }}
    />
  )

  return (
    <Box
      sx={{
        height: 100,
        border: 1,
        borderColor: 'neutral-100',
        borderRadius: 4,
        width: 100,
      }}
    >
      <Box sx={{ px: 24, pt: 56, width: 100 }}>{imageSkeleton}</Box>
      <Box
        sx={{
          width: 100,
          height: 100,
          pt: 16,
          px: 24,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {textFieldSkeleton}
        <Box
          sx={{
            pt: 12,
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {keyValueSkeleton}
          {keyValueSkeleton}
          {keyValueSkeleton}
          {keyValueSkeleton}
          {keyValueSkeleton}
          {keyValueSkeleton}
        </Box>
      </Box>
    </Box>
  )
}
