import { GridActionCellParams } from '@applift/datagrid'
import {
  Box,
  Link,
  Tooltip,
  Typography,
  TypographyProps,
} from '@applift/factor'

import { Creative } from '../../models'
import { ACTION } from '../../modules/common'

interface CampaignCountCellProps {
  rowData: Creative
  action?: (args: GridActionCellParams) => any
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CampaignCountCell = ({
  rowData,
  action,
}: CampaignCountCellProps) => {
  const campaigns = rowData?.campaignDetails?.campaignIds ?? []
  return (
    <Box>
      {campaigns?.length > 0 ? (
        <Tooltip
          arrow
          placement="top"
          title="Click to view the assigned campaigns"
        >
          <Link
            Component="button"
            color="primary"
            onClick={() => {
              action?.({
                actionName: ACTION.OPEN_ASSIGN_TO_CAMPAIGN,
                metaData: { data: rowData },
              })
            }}
          >
            {campaigns.length}
          </Link>
        </Tooltip>
      ) : (
        <Typography variant="bodySmall" gutterBottom={false}>
          0
        </Typography>
      )}
    </Box>
  )
}
