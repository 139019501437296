import { NativeCreativeGenerationData } from '../../../../../models'
import { MediaData } from '../../../../../models/asset'
import {
  NativeBrandIcon,
  NativeImage,
  NativeVideo,
} from '../../../../../models/native'
import { getVideoMetaData } from '../../../../../utils'
import { NativeCreationFormType } from '../CreateWrapper'

export const onImageProcessed = (
  files: MediaData,
  creativeList: NativeCreativeGenerationData[],
  form: NativeCreationFormType,
  onChange?: (param: NativeCreativeGenerationData[]) => void
) => {
  const images: NativeImage[] = []
  const currentItemId = form.state.values.uniqueId

  if (files) {
    Object.keys(files || {}).forEach(key => {
      const parentId = key.split('_')[0] as string
      const file = files[key]?.metaData.file as File
      const originalFilename = file.name.slice(0, file.name.lastIndexOf('.')) // logic to get filename without extension
      if (files[key]) {
        images.push({
          src: files[key]?.assetCDNURL as string,
          width: files[key]?.metaData.width as number,
          height: files[key]?.metaData.height as number,
          name: originalFilename,
          temporaryMediaId: crypto.randomUUID(),
          parentId,
        })
      }
    })
  }

  const currentNativeIds = creativeList.map(item => item.uniqueId)

  const validImages = images.filter(item =>
    currentNativeIds.includes(item.parentId)
  )

  const currentlyDisplayedFormImages = validImages.filter(
    item => item.parentId === currentItemId
  )

  const updatedList = creativeList.map(creative => ({
    ...creative,
    imageObjects: [
      ...creative.imageObjects,
      ...validImages.filter(image => image.parentId === creative.uniqueId),
    ],
  }))

  onChange?.(updatedList)

  form.setFieldValue('imageObjects', [
    ...form.state.values.imageObjects,
    ...currentlyDisplayedFormImages,
  ])
}

export const onVideoThumbmailProcessed = (
  files: MediaData,
  creativeList: NativeCreativeGenerationData[],
  form: NativeCreationFormType,
  onChange?: (param: NativeCreativeGenerationData[]) => void
) => {
  const images: NativeImage[] = []
  const currentItemId = form.state.values.uniqueId

  if (files) {
    Object.keys(files || {}).forEach(key => {
      const parentId = key.split('_')[0] as string
      const file = files[key]?.metaData.file as File
      const originalFilename = file.name.slice(0, file.name.lastIndexOf('.')) // logic to get filename without extension
      if (files[key]) {
        images.push({
          src: files[key]?.assetCDNURL as string,
          width: files[key]?.metaData.width as number,
          height: files[key]?.metaData.height as number,
          name: originalFilename,
          temporaryMediaId: crypto.randomUUID(),
          parentId,
        })
      }
      const currentNativeIds = creativeList.map(item => item.uniqueId)

      const validImages = images.filter(item =>
        currentNativeIds.includes(item.parentId)
      )

      const currentlyDisplayedFormImages = validImages.filter(
        item => item.parentId === currentItemId
      )

      const updatedList = creativeList.map(creative => ({
        ...creative,
        videoThumbnail:
          currentlyDisplayedFormImages[0]?.parentId === creative.uniqueId
            ? currentlyDisplayedFormImages[0]
            : creative?.videoThumbnail,
      }))

      onChange?.(updatedList)

      form.setFieldValue(
        'videoThumbnail',
        currentlyDisplayedFormImages[0] ?? null
      )
    })
  }
}
export const onVideoProcessed = async (
  files: MediaData,
  creativeList: NativeCreativeGenerationData[],
  form: NativeCreationFormType,
  onChange?: (param: NativeCreativeGenerationData[]) => void
) => {
  const video: NativeVideo[] = []
  const currentItemId = form.state.values.uniqueId
  if (files) {
    for (const key of Object.keys(files)) {
      const parentId = key.split('_')[0] as string
      const file = files[key]?.metaData.file as File
      const duration = files[key]?.metaData.duration
      const videoSrc = files[key]?.assetCDNURL ?? ''
      const metaData = await getVideoMetaData({ URL: videoSrc })

      const originalFilename = file.name.slice(0, file.name.lastIndexOf('.')) // logic to get filename without extension
      if (files[key]) {
        video.push({
          src: files[key]?.assetCDNURL,
          creativeSource: '',
          creativeSourceURL: '',
          duration: duration ?? 0,
          name: originalFilename,
          temporaryMediaId: crypto.randomUUID(),
          parentId,
          height: metaData?.height ?? 0,
          width: metaData?.width ?? 0,
        })
      }
    }
  }

  const currentNativeIds = creativeList.map(item => item.uniqueId)

  const validVideos = video.filter(item =>
    currentNativeIds.includes(item.parentId)
  )

  const currentlyDisplayedFormVideos = validVideos.filter(
    item => item.parentId === currentItemId
  )

  const updatedList = creativeList.map(creative => ({
    ...creative,
    videoObjects: [
      ...creative.videoObjects,
      ...validVideos.filter(image => image.parentId === creative.uniqueId),
    ],
  }))

  onChange?.(updatedList)

  form.setFieldValue('videoObjects', [
    ...form.state.values.videoObjects,
    ...currentlyDisplayedFormVideos,
  ])
}

export const onBrandIconProcessed = (
  files: MediaData,
  creativeList: NativeCreativeGenerationData[],
  form: NativeCreationFormType,
  onChange?: (param: NativeCreativeGenerationData[]) => void
) => {
  const images: NativeBrandIcon[] = []
  const currentItemId = form.state.values.uniqueId

  if (files) {
    Object.keys(files || {}).forEach(key => {
      const parentId = key.split('_')[0] as string
      const file = files[key]?.metaData.file as File
      const originalFilename = file.name.slice(0, file.name.lastIndexOf('.')) // logic to get filename without extension
      if (files[key]) {
        images.push({
          src: files[key]?.assetCDNURL as string,
          width: files[key]?.metaData.width as number,
          height: files[key]?.metaData.height as number,
          name: originalFilename,
          parentId,
        })
      }
    })
  }

  const currentNativeIds = creativeList.map(item => item.uniqueId)

  const validImages = images.filter(item =>
    currentNativeIds.includes(item.parentId)
  )

  const currentlyDisplayedFormImages = validImages.filter(
    item => item.parentId === currentItemId
  )

  const updatedList = creativeList.map(creative => ({
    ...creative,
    brandIcon:
      validImages.filter(image => image.parentId === creative.uniqueId)?.[0] ??
      creative.brandIcon,
  }))

  onChange?.(updatedList)

  form.setFieldValue('brandIcon', currentlyDisplayedFormImages[0] ?? null)
}

function addKeyValue<NativeCreativeGenerationData>(
  key: keyof NativeCreativeGenerationData,
  arr: NativeCreativeGenerationData[keyof NativeCreativeGenerationData][],
  combinationsArr: Partial<NativeCreativeGenerationData>[]
): Partial<NativeCreativeGenerationData>[] {
  const newCombination: Partial<NativeCreativeGenerationData>[] = []
  arr.forEach(val => {
    combinationsArr.forEach(obj =>
      newCombination.push({ ...obj, [key]: [val] })
    )
  })
  return newCombination
}

export const getVariations = (
  obj: NativeCreativeGenerationData,
  idRef: React.MutableRefObject<number>
) => {
  const initialCombination: Partial<NativeCreativeGenerationData> = {}
  for (const i in obj) {
    const val = obj[i as keyof NativeCreativeGenerationData]
    if (!Array.isArray(val) || val.length < 2) {
      ;(initialCombination as any)[i] = val
    }
  }
  let combinationsArr = [initialCombination]
  for (const i in obj) {
    const val = obj[i as keyof NativeCreativeGenerationData]
    if (Array.isArray(val) && val.length > 1) {
      combinationsArr = addKeyValue(
        i as keyof NativeCreativeGenerationData,
        val as NativeCreativeGenerationData[keyof NativeCreativeGenerationData][],
        combinationsArr
      )
    }
  }
  for (const i in obj) {
    if (!Array.isArray(obj[i as keyof NativeCreativeGenerationData])) {
      ;(combinationsArr as any)[0][i] = (obj as any)[i]
    }
  }

  combinationsArr = combinationsArr.map((combination, combinationIdx) => ({
    ...combination,
    temporaryId: idRef.current++,
    // copy unique id for original native and generate new ones for variations
    uniqueId: combinationIdx === 0 ? combination.uniqueId : crypto.randomUUID(),
    // attach temp id name to creative
    creativeName: `${
      (combination.nativeType === 'image'
        ? combination?.imageObjects?.[0]?.name
        : combination?.videoObjects?.[0]?.name) || combination.creativeName
    }`,
  }))

  return combinationsArr as NativeCreativeGenerationData[]
}
