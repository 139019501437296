import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import {
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Tooltip,
} from '@applift/factor'
import { Delete, InfoCircle, Audio } from '@applift/icons'
import { AudioDAASTPlayer, AudioVASTPlayer } from '@applift/platform-creatives'

import { AudioCreativeGenerationData } from '../../../../../../models'
import { CreateProps } from '..'
import {
  AUDIO_RECOMMENDED_DURATIONS,
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
} from '../../../../../../constants'
import {
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
} from '../../../../../../utils'
import { DaastVastInput } from './DaastVastInput'

export interface DaastAudioCardProps
  extends Pick<CreateProps, 'onChange' | 'onRemove'> {
  /**
   * Audio Creative
   */
  creative: AudioCreativeGenerationData
}

export const DaastAudioCard = (props: DaastAudioCardProps) => {
  const { creative, onChange, onRemove } = props

  const form = useForm<AudioCreativeGenerationData>({
    defaultValues: {
      ...creative,
      audioCreativeType: 'INVALID',
    },
  })

  const updateMasterList = React.useCallback(() => {
    form.validateAllFields('change').then(() => {
      const formData = {
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [form, onChange])

  React.useEffect(() => {
    updateMasterList()
  }, [updateMasterList])

  const showWarning =
    Boolean(form.state.values.duration) &&
    AUDIO_RECOMMENDED_DURATIONS.includes(
      form.state.values.duration as number
    ) === false

  return (
    <form
      style={{ height: '100%' }}
      onChange={() => {
        onChange?.([
          { ...form?.state?.values, hasError: form.state.canSubmit === false },
        ])
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          border: 1,
          borderRadius: 4,
          p: 16,
          pb: 24,
          gap: 16,
          alignItems: 'center',
          position: 'relative',
          borderColor: showWarning ? 'warning-200' : 'neutral-100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 8,
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => onRemove?.(creative.id)}
          >
            <Delete />
          </IconButton>

          <form.Field name="audioCreativeType">
            {field =>
              field.state.value === 'INVALID' ||
              form.getFieldMeta('creativeSource')?.errors.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 'auto',
                    border: 1,
                    borderRadius: 4,
                    bgColor: 'neutral-75',
                  }}
                  style={{ minHeight: '64px' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: 75,
                      gap: 8,
                    }}
                  >
                    <Audio fontSize={24} sx={{ textColor: 'neutral-400' }} />
                    <Typography
                      sx={{ textColor: 'neutral-600', paddingTop: 4 }}
                    >
                      Audio preview will be visible here
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    width: 100,
                    height: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  style={{ minHeight: '64px' }}
                >
                  {['DAAST_XML', 'DAAST_URL'].includes(field.state.value) && (
                    <AudioDAASTPlayer
                      ref={r => {
                        r?.addEventListener('loadedmetadata', () => {
                          form.setFieldValue('duration', Math.round(r.duration))
                          updateMasterList()
                        })
                      }}
                      adTagXML={
                        'DAAST_XML' === field.state.value
                          ? form.state.values.creativeSource
                          : undefined
                      }
                      adTagUrl={
                        'DAAST_URL' === field.state.value
                          ? form.state.values.creativeSource
                          : undefined
                      }
                    />
                  )}
                  {['VAST_XML', 'VAST_URL'].includes(field.state.value) && (
                    <AudioVASTPlayer
                      ref={r => {
                        r?.addEventListener('loadedmetadata', () => {
                          form.setFieldValue('duration', Math.round(r.duration))
                          updateMasterList()
                        })
                      }}
                      adTagXML={
                        'VAST_XML' === field.state.value
                          ? form.state.values.creativeSource
                          : undefined
                      }
                      adTagUrl={
                        'VAST_URL' === field.state.value
                          ? form.state.values.creativeSource
                          : undefined
                      }
                    />
                  )}
                </Box>
              )
            }
          </form.Field>
        </Box>
        <Divider color="secondary" sx={{ width: 100 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          {showWarning && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 100,
                px: 12,
                gap: 8,
              }}
            >
              <Typography
                variant="label"
                lineHeight="single-line"
                sx={{ textColor: 'warning-500' }}
              >
                Audio length doesn’t match the recommendations and may affect
                the campaign’s reach
              </Typography>
            </Box>
          )}

          <DaastVastInput form={form} updateMasterList={updateMasterList} />

          <form.Field
            name="name"
            validators={{
              onChange: ({ value }) => creativeNameValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label="Name"
                variant="outlinedDash"
                placeholder="Enter Name"
                value={field.state.value}
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                    </>
                  ),
                }}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="thirdPartyID"
            validators={{
              onChange: ({ value }) =>
                creativeThirdPartyIDValidationFn(value as string),
            }}
          >
            {field => (
              <TextField
                label={
                  <Tooltip
                    title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                    placement="top"
                    arrow
                  >
                    <Box
                      Component={'span'}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                      style={{ pointerEvents: 'all' }}
                    >
                      <Typography variant="label">3rd Party ID</Typography>
                      <InfoCircle
                        fontSize={14}
                        sx={{ textColor: 'neutral-400' }}
                      />
                    </Box>
                  </Tooltip>
                }
                variant="outlinedDash"
                placeholder="Enter 3rd Party ID"
                value={field.state.value}
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value?.length}/
                      {CREATIVE_THIRD_PARTY_ID_LIMIT}
                    </>
                  ),
                }}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
        </Box>
      </Box>
    </form>
  )
}
