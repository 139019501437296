import {
  Box,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { Add } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { useStore } from '@tanstack/react-store'

import { NATIVE_CTA_CHAR_LIMIT } from '../../../../../../constants'
import { useNativeCTAOptions } from '../../../../../../hooks'
import { NativeCreativeGenerationData } from '../../../../../../models'
import { isValidURL } from '../../../../../../utils'
import { NativeCreationFormType } from '../../CreateWrapper'

interface Props {
  form: NativeCreationFormType
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  creativesList: NativeCreativeGenerationData[]
}

export const CTA = (props: Props) => {
  const { form, onChange, creativesList } = props

  const queryClient = useQueryClient()
  const { data } = useNativeCTAOptions({ showOnlyDefault: true })
  const { cta: currentSelection, clickURL } = useStore(
    form.store,
    state => state.values
  )

  const [customCTA, setCustomCTA] = React.useState<string>('')

  const updateinAllCreatives = () => {
    const value = form.getFieldValue('cta')

    onChange?.(
      creativesList
        .filter(item => item.clickURL)
        .map(creative => ({ ...creative, cta: value }))
    )
    enqueueSnackbar(
      <>
        <Typography sx={{ textWeight: 'demi' }}>CTA</Typography> updated
        succesfully for all creatives
      </>,
      { variant: 'success' }
    )
  }

  const isValidClickURLAdded = isValidURL(clickURL) && Boolean(clickURL)

  const customFieldCharLimitError =
    customCTA.trim().length > NATIVE_CTA_CHAR_LIMIT

  const isCustomCTAAlreadyPresent = customCTA.length
    ? (data ?? [])
        .map(item => item?.label?.toLowerCase())
        .includes(customCTA.trim().toLowerCase())
    : false

  let error = ''
  if (customFieldCharLimitError) {
    error = 'Character limit exceeded'
  } else if (isCustomCTAAlreadyPresent) {
    error = 'CTA name already exists in the list'
  }

  const disableAddCustomCTA =
    Boolean(error) || Boolean(customCTA.trim()) === false

  const updateCache = (newSelection: {
    label: string
    value: string
    isDefault: boolean
  }) => {
    queryClient.setQueriesData(
      {
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getNativeCTAOptions',
      },
      (oldData: any) => {
        if (!oldData) return [newSelection]
        return [...oldData, newSelection]
      }
    )
  }

  const addCustomCTA = () => {
    const newSelection = {
      label: customCTA.trim(),
      value: customCTA.trim(),
      isDefault: false,
    }
    updateCache(newSelection)
    form.setFieldValue('cta', newSelection)
    setCustomCTA('')
  }

  const enableUpdateBtn =
    creativesList.length > 1 && !error && Boolean(currentSelection?.value)

  return (
    <Tooltip
      title={
        isValidClickURLAdded
          ? ''
          : 'CTA can be selected once the Click URL is added'
      }
      placement="top"
      arrow
    >
      <Box sx={{ width: 100 }}>
        <form.Field name="cta">
          {field => {
            return (
              // @ts-ignore
              <TextField
                label="CTA"
                variant="outlined"
                select
                disabled={isValidClickURLAdded === false}
                sx={{ width: 100 }}
                SelectProps={{
                  // it's an single select dropdown but since not able to deselect an item for multiple=false, keeping multiple as true
                  multiple: true,
                  placeholder: 'Select CTA',
                  hideSelectNav: true,
                  hideSearch: true,
                  allowClearingSingleSelect: true,
                  renderContentBottom: (
                    <>
                      <TextField
                        onChange={e => setCustomCTA(e.target.value)}
                        value={customCTA}
                        variant="outlinedDash"
                        label={
                          <Typography sx={{ visibility: 'hidden' }}>
                            Dummy label
                          </Typography>
                        }
                        placeholder="Enter Custom CTA"
                        sx={{ m: 8 }}
                        InputLabelProps={{
                          renderSuffix: (
                            <>
                              {customCTA.trim().length}/{NATIVE_CTA_CHAR_LIMIT}
                            </>
                          ),
                        }}
                        error={Boolean(error)}
                        helperText={error}
                        InputProps={{
                          endAdornment: (
                            <Box
                              style={{
                                opacity: disableAddCustomCTA ? 0.4 : 1,
                                cursor: 'pointer',
                                pointerEvents: disableAddCustomCTA
                                  ? 'none'
                                  : 'auto',
                              }}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                              }}
                              onClick={addCustomCTA}
                            >
                              <Add
                                fontSize={16}
                                sx={{ textColor: 'primary-500' }}
                              />{' '}
                              <Typography
                                sx={{
                                  textWeight: 'demi',
                                  textColor: 'primary-500',
                                }}
                              >
                                Add
                              </Typography>
                            </Box>
                          ),
                        }}
                      />
                    </>
                  ),
                }}
                value={currentSelection?.value ? [currentSelection.value] : []}
              >
                {data?.map(cta => {
                  return (
                    <MenuItem
                      key={cta.value}
                      value={cta.value}
                      onClick={() =>
                        field.handleChange(
                          cta?.value === currentSelection?.value ? null : cta
                        )
                      }
                    >
                      {cta.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          }}
        </form.Field>
        <Box
          onClick={updateinAllCreatives}
          style={{
            cursor: enableUpdateBtn ? 'pointer' : 'auto',
            pointerEvents: enableUpdateBtn ? 'auto' : 'none',
            opacity: enableUpdateBtn ? 1 : 0.4,
          }}
          sx={{ pl: 12, mt: 4 }}
        >
          <Typography
            sx={{
              textWeight: 'demi',
              textColor: 'primary-600',
            }}
          >
            Update in all creatives
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}
