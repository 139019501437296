import { Box, ButtonBase } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'

import { VIDEO_CREATIVE_ID } from '../../../constants'
import { ImageCellWrapper } from './ImageCellWrapper'

import { Creative } from '../../../models'
import { VideoCellWrapper } from './VideoCellWrapper'
import { ACTION } from '../../../modules/common'

interface CreativePreviewCellProps {
  creative: Creative
  action?: (args: GridActionCellParams) => any
}
export const CreativePreviewCell = (props: CreativePreviewCellProps) => {
  const { creative, action } = props
  const { platformCreativeTypeId } = creative

  return action ? (
    <ButtonBase
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() =>
        action?.({
          actionName: ACTION.OPEN_CREATIVE_DETAIL,
          metaData: { data: creative },
        })
      }
    >
      <Box sx={{ width: 100, height: 100, position: 'relative' }}>
        {platformCreativeTypeId === VIDEO_CREATIVE_ID ? (
          <VideoCellWrapper creative={creative} />
        ) : (
          <ImageCellWrapper creative={creative} />
        )}
      </Box>
    </ButtonBase>
  ) : (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: 100, height: 100, position: 'relative' }}>
        {platformCreativeTypeId === VIDEO_CREATIVE_ID ? (
          <VideoCellWrapper creative={creative} />
        ) : (
          <ImageCellWrapper creative={creative} />
        )}
      </Box>
    </Box>
  )
}
