import * as React from 'react'
import { AddAllCreativeDialog } from '../all'
import { AddHTMLCreativeDialog } from '../html'
import { AddImageCreativeDialog } from '../image'
import { AddVideoCreativeDialog } from '../video'

import { AddCreativeToGroupDialog } from '../../components/Dialog'
import { ReactChildrenType } from '../../models/utils'
import { AddAudioCreativeDialog } from '../audio'
import { AddNativeCreativeDialog } from '../native/AddNativeCreativeDialog'
// import { ExisitingNativeCreativeDialog } from '../native/ExisitingNativeCreativeDialog/ExisitingNativeCreativeDialog'
import { ExisitingNativeCreativeDialog } from '../native'
import { useDialogManager } from './context'

export const DialogManager = () => {
  const { actionData, setAction } = useDialogManager()

  const onClose = React.useCallback(() => {
    setAction(undefined, undefined)
  }, [setAction])

  return (
    <>
      <ShowDialog condition={actionData?.actionName === 'all'}>
        <AddAllCreativeDialog open={true} onClose={onClose} />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'image'}>
        <AddImageCreativeDialog
          open={true}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'html'}>
        <AddHTMLCreativeDialog
          open
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'video'}>
        <AddVideoCreativeDialog
          open={true}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'audio'}>
        <AddAudioCreativeDialog
          open={true}
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'native'}>
        <AddNativeCreativeDialog
          open
          onClose={onClose}
          onSuccess={actionData?.options?.onSuccess}
          onError={actionData?.options?.onError}
          onSettle={actionData?.options?.onSettle}
          initData={actionData?.payload}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'existingNative'}>
        <ExisitingNativeCreativeDialog
          open
          onClose={onClose}
          onAction={nativeData => {
            setAction('native', nativeData, {
              onSuccess: actionData?.options?.onSuccess,
              onError: actionData?.options?.onError,
              onSettle: actionData?.options?.onSettle,
            })
          }}
        />
      </ShowDialog>
      <ShowDialog condition={actionData?.actionName === 'group'}>
        <AddCreativeToGroupDialog
          data={actionData?.payload}
          onClose={onClose}
          initState={{ cancelActionName: 'Skip' }}
        />
      </ShowDialog>
    </>
  )
}

interface ShowDialogType {
  condition: boolean
  children: ReactChildrenType
}

const ShowDialog = (props: ShowDialogType) => {
  const { condition, children } = props
  return condition ? children : null
}
