import { ColumnDef } from '@applift/datagrid'

import { Creative } from '../../../models'
import {
  CreativeNameCell,
  CreativePreviewCell,
  RawDataCell,
} from '../../../components'

export const colDef: ColumnDef<Creative>[] = [
  {
    accessorKey: 'creativeId',
    id: 'creativeId',
    header: 'ID',
    enableResizing: false,
    enableSorting: false,
    cell: props => <RawDataCell value={props.renderValue() as number} />,
    size: 70,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Creative Preview Thumbnail',
    },
    cell: props => <CreativePreviewCell creative={props.row.original} />,
  },
  {
    accessorKey: 'creativeName',
    id: 'creativeName',
    header: 'Creative Name',
    enableResizing: false,
    enableSorting: false,
    cell: props => (
      <>
        <CreativeNameCell
          creativeTypeId={props.row.original.platformCreativeTypeId}
          label={props.row.original.creativeName}
        />
      </>
    ),
    size: 330,
  },
]
