import React from 'react'

import { GridActionCellParams } from '@applift/datagrid'
import { useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import { useRouter } from '@tanstack/react-router'

import { Creative, ErrorObject } from '../../../models'
import { ACTION, ActionNameType } from './action'
import { useAllCreativeDialogManager } from '../DialogManager'
import { useUpdateCreative } from '../../../hooks'
import { getCreativeTypeIdFromRoute, arraysAreEqual } from '../../../utils'
import { UpdateCreativeNameParams, UpdateCreativeResponse } from '../../../api'

export type InlineEditAction =
  | GridActionCellParams<
      ActionNameType,
      { data?: Creative; id: number; params?: any }
    >
  | undefined

export const useActions = () => {
  const { setAction: setDialogAction } = useAllCreativeDialogManager()
  const router = useRouter()

  const [action, setAction] = React.useState<InlineEditAction>()

  const queryClient = useQueryClient()

  const onSuccess = (
    success: UpdateCreativeResponse,
    data: UpdateCreativeNameParams,
    errorObjects: ErrorObject[]
  ) => {
    if (success) {
      const creativeTypeIds = getCreativeTypeIdFromRoute(
        router.history.location.href
      )
      enqueueSnackbar(
        success?.data?.message ?? 'Creative updated successfully',
        {
          variant: 'success',
        }
      )
      // To reset query for the creative Preview dialog
      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === '__getCreativeDetails' &&
            query.queryKey?.[0]?.creativeId === data.creativeId
          )
        },
      })

      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
            arraysAreEqual(
              query.queryKey?.[0]?.platformCreativeTypeIds ?? [],
              creativeTypeIds ?? []
            )
          )
        },
      })
    } else {
      const error = errorObjects?.[0]?.error
      if (error && typeof error === 'string') {
        enqueueSnackbar(error, { variant: 'error' })
      } else {
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
      }
    }
  }
  const onError = (e: any) => {
    const error = e?.data?.errorObjects?.[0]?.error
    if (error && typeof error === 'string') {
      enqueueSnackbar(error, { variant: 'error' })
    } else {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }
  }

  // @ts-ignore
  const updateCreativeMutation = useUpdateCreative({ onSuccess, onError })

  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.EDIT_CREATIVE_NAME: {
        const actionRowId = action?.metaData?.data?.creativeId
        if (actionRowId) {
          updateCreativeMutation.mutate({
            creativeName: action?.metaData?.params?.creativeName.trim(),
            creativeId: actionRowId as number,
          })
        }
        break
      }
      case ACTION.EDIT_THIRD_PARTY_ID: {
        const actionRowId = action?.metaData?.id
        if (actionRowId) {
          updateCreativeMutation.mutate({
            externalCreativeId:
              action?.metaData?.params?.externalCreativeId?.trim?.()
                ? action?.metaData?.params?.externalCreativeId?.trim()
                : null,
            creativeId: Number(actionRowId) as number,
          })
        }
        break
      }
      case ACTION.EDIT_CLICK_URL: {
        const cretiveData = action.metaData.data
        if (cretiveData?.approvalRequiredPostUrlUpdate === false) {
          updateCreativeMutation.mutate({
            clickUrl: cretiveData?.clickUrl?.trim?.()
              ? cretiveData?.clickUrl?.trim()
              : null,
            creativeId: cretiveData.creativeId,
          })
        } else {
          setDialogAction('editClickUrlConfirmation', {
            url: cretiveData?.clickUrl?.trim?.()
              ? cretiveData?.clickUrl?.trim()
              : null,
            creatives: [cretiveData],
          })
        }
        break
      }
      case ACTION.EDIT_PIXEL_URL: {
        const actionRowId = action?.metaData?.id
        if (actionRowId) {
          updateCreativeMutation.mutate({
            pixelUrl: action?.metaData?.params?.pixelUrl?.trim?.()
              ? action?.metaData?.params?.pixelUrl?.trim()
              : null,
            creativeId: actionRowId,
          })
        }
        break
      }
      case ACTION.OPEN_ASSIGN_TO_CAMPAIGN: {
        const creativeData = action?.metaData?.data
        if (creativeData) {
          setDialogAction('assignedCampaign', [creativeData], {
            onSuccess: () => {
              const creativeTypeIds = getCreativeTypeIdFromRoute(
                router.history.location.href
              )
              queryClient.resetQueries({
                predicate: (query: any) => {
                  return (
                    query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
                    arraysAreEqual(
                      query.queryKey?.[0]?.platformCreativeTypeIds,
                      creativeTypeIds ?? []
                    )
                  )
                },
              })
            },
          })
        }
        break
      }
      case ACTION.OPEN_CREATIVE_DETAIL: {
        const actionRowId = action?.metaData?.data?.creativeId
        if (actionRowId) {
          setDialogAction('showPreview', {
            id: actionRowId,
          })
        }
        break
      }
    }
    // eslint-disable-next-line
  }, [action, setDialogAction])
  return { action, setAction }
}
