import * as React from 'react'
import { Box, MenuItem, Typography, sx as sxFunc } from '@applift/factor'
import type { MenuItemProps } from '@applift/factor'

import styles from './index.module.scss'

export interface SidebarMenuItemProps extends MenuItemProps {
  itemIcon?: React.ReactNode
  title: string
  description?: string
  count?: number
  actionButton?: React.ReactNode
}

export const SidebarMenuItem = (props: SidebarMenuItemProps) => {
  const {
    selected,
    itemIcon,
    title,
    description,
    count,
    actionButton,
    sx,
    ...others
  } = props
  return (
    <MenuItem
      disableRipple
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        overflow: 'auto',
        p: 8,
        ...sx,
      }}
      classes={{
        content: sxFunc({ display: 'block' }),
        selected: styles.selectedItem,
      }}
      selected={selected}
      {...others}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <>
          {itemIcon && <Box className={styles.itemIcon}>{itemIcon}</Box>}
          <Typography
            sx={{
              textWeight: selected ? 'demi' : 'normal',
              marginLeft: 8,
            }}
            gutterBottom={false}
            variant="bodyMedium"
            component={'h2'}
          >
            {/* Design Team - needs to show '0' as count in navigation */}
            {`${title} ${typeof count === 'number' ? `(${count})` : ''}`}
          </Typography>
        </>
      </Box>
      {selected ? (
        <>
          {description && (
            <Typography
              sx={{
                wordBreak: 'keep-all',
                whiteSpace: 'normal',
                mt: 4,
                mb: 8,
              }}
              variant="bodySmall"
              gutterBottom={false}
            >
              {description}
            </Typography>
          )}
          {actionButton}
        </>
      ) : null}
    </MenuItem>
  )
}
