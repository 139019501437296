import { SortingState } from '@tanstack/react-table'

export const DEFAULT_CREATIVE_LIST_SORTING = [
  { id: 'createdAt', desc: true },
] as SortingState
export const DEFAULT_PAGE_SIZE = 48

export const BYTE = 1
export const kILO_BYTE = BYTE * 1024
export const MEGA_BYTE = kILO_BYTE * 1024

export const ASSET_SEQUENCE_SPLIT_CHARACTER = '~'

export const AUDIO_RECOMMENDED_DURATIONS = [15, 30]

export const EMPTY_CREATIVE_TYPE_ID = 0
