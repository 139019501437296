import React from 'react'

import { Box } from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'

import { Creative } from '../../../models'

import { DEFAULT_PAGE_SIZE } from '../../../constants'
import { useResponsiveVirtualizer } from '../../../hooks'
import { InlineEditAction } from '../CreativeTableActions'

import { GridItem } from './GridItem'
import { _NoResultsOverlay, _ErrorOverlay } from './overlays'

interface GridProps {
  data: Creative[]
  setAction: React.Dispatch<React.SetStateAction<InlineEditAction>>
  filteredRecords: number
  isFetching: boolean
  isLoading: boolean
  isError: boolean
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  getRowId: (row: Creative) => string
  fetchNextPage: () => void
  organizationTimezoneName?: string
}

export const Grid = (props: GridProps) => {
  const {
    setAction,
    data,
    getRowId,
    filteredRecords,
    rowSelection,
    setRowSelection,
    fetchNextPage,
    isFetching,
    isLoading,
    isError,
    organizationTimezoneName,
  } = props

  const scrollElementRef = React.useRef<HTMLDivElement | null>(null)

  /**
   * Need to keep this state because of the responsiveness of the grid, if grid having 2 columns then skeleton count must be multiple of 2,
     here we are keeping a single row of skeletons so that only API triggers only once
   */
  const [skeletonCount, setSkeletonCount] = React.useState(3)

  const dataCount = isLoading
    ? DEFAULT_PAGE_SIZE
    : Math.min(filteredRecords, data.length + skeletonCount)

  const virtualizer = useResponsiveVirtualizer({
    count: dataCount,
    getScrollElement: () => scrollElementRef.current,
  })
  const totalLanes = virtualizer.options.lanes

  React.useEffect(() => {
    setSkeletonCount(virtualizer.options.lanes) // Adjust the number of skeleton items as lane changes
    virtualizer.measure()
  }, [virtualizer.options.lanes, virtualizer])

  if (isError) {
    return <_ErrorOverlay />
  }

  if (data.length <= 0 && isLoading === false && isFetching === false) {
    return <_NoResultsOverlay />
  }

  return (
    <Box
      sx={{
        px: 4,
        pb: 24,
        width: 100,
        height: 100,
        overflow: 'auto',
      }}
      ref={scrollElementRef}
    >
      <Box
        sx={{ position: 'relative' }}
        style={{ height: virtualizer.getTotalSize() }}
      >
        {virtualizer.getVirtualItems().map(item => {
          if (item.index == data.length && isFetching === false) {
            fetchNextPage()
          }
          return (
            <GridItem
              {...item}
              data={data}
              getRowId={getRowId}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              setAction={setAction}
              totalLanes={totalLanes}
              organizationTimezoneName={organizationTimezoneName}
              key={data[item.index]?.creativeId ?? item.index}
            />
          )
        })}
      </Box>
    </Box>
  )
}
