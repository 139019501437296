import { Box, Link, Typography } from '@applift/factor'
import { InlineEditAction, ACTION } from '../../../CreativeTableActions'
import { Creative } from '../../../../../models'

export interface CampaignsCountValueTypeProps {
  campaignCount: number
  setAction: React.Dispatch<React.SetStateAction<InlineEditAction>>
  creativeData: Creative
  readonly?: boolean
}

export const CampaignsCountValueType = (
  props: CampaignsCountValueTypeProps
) => {
  const { campaignCount, setAction, creativeData, readonly } = props
  return readonly ? (
    <Typography sx={{ ml: 12 }}>{campaignCount}</Typography>
  ) : (
    <Box>
      {campaignCount > 0 ? (
        <Link
          Component="button"
          color="primary"
          sx={{ ml: 8 }}
          onClick={() => {
            setAction?.({
              actionName: ACTION.OPEN_ASSIGN_TO_CAMPAIGN,
              metaData: { id: creativeData.creativeId, data: creativeData },
            })
          }}
        >
          {campaignCount}
        </Link>
      ) : (
        <Typography variant="bodySmall" gutterBottom={false} sx={{ ml: 8 }}>
          0
        </Typography>
      )}
    </Box>
  )
}
