import { WithResponse } from '@applift/platform'
import { AxiosError, AxiosResponse } from 'axios'
import { getInstance } from './instance'

export interface UpdateCreativeNameParams {
  creativeId: number
  creativeName?: string
  externalCreativeId?: string | null
  creativeRejectionReason?: string
  creativeRemarks?: string
  clickUrl?: string | null
  pixelUrl?: string | null
}

export type UpdateCreativeResObj = { id: number; message: string }

export interface UpdateCreativeResponse
  extends WithResponse<UpdateCreativeResObj> {}

export const updateCreative = async (params: UpdateCreativeNameParams) => {
  const {
    creativeName,
    creativeId,
    creativeRejectionReason,
    creativeRemarks,
    externalCreativeId,
    clickUrl,
    pixelUrl,
  } = params

  try {
    const res: AxiosResponse<UpdateCreativeResponse> =
      await getInstance().patch(`/v3/crt/creatives/${creativeId}`, {
        creativeName,
        externalCreativeId,
        creativeRejectionReason,
        creativeRemarks,
        clickUrl,
        pixelUrl,
      })

    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
