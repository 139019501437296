import React from 'react'
import { Grid, TableCell } from '@applift/icons'
import {
  Tab,
  Box,
  Chip,
  Tabs,
  Button,
  Tooltip,
  useDebounceValue,
} from '@applift/factor'
import {
  ColumnVisibility,
  constructListFromColumnDef,
  RowSelectionState,
  VisibilityState,
} from '@applift/datagrid'
import { DateRangePicker } from '@applift/app-utils'
import { useLocation, useNavigate } from '@tanstack/react-router'

import { Creative, DateRangeType } from '../../../../models'
import { Actions } from './Actions'
import { ViewType } from '../../../../models'
import { FilterProps, Filters } from './Filters'
import { SortMenu, SortMenuProps } from './SortMenu'
import { SearchField, SearchFieldProps } from './SearchField'
import { CampaignActions } from './CampaignActions'
import { useAllCreativeDialogManager } from '../../DialogManager'
import { useCreativeStatuses } from '../../../../hooks'
import { DEFAULT_CREATIVE_LIST_SORTING } from '../../../../constants'

interface ActionPanelProps
  extends SortMenuProps,
    SearchFieldProps,
    FilterProps {
  data: Creative[]
  viewType: ViewType
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>
  columnVisibility: VisibilityState
  columnOptions: ReturnType<typeof constructListFromColumnDef>[0]
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  filteredRecords: number
  creativeTypeIds?: number[]
  dateRange: DateRangeType
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>
  organizationTimezoneName: string
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    data,
    viewType,
    setViewType,
    columnOptions,
    setColumnVisibility,
    columnVisibility,
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
    filteredRecords,
    search,
    setSearch,
    statusId,
    setStatusId,
    creativeTypeIds,
    dateRange,
    setDateRange,
    organizationTimezoneName,
  } = props

  const { setAction } = useAllCreativeDialogManager()
  const { data: creativeStatusType } = useCreativeStatuses()

  const debounsedSearch = useDebounceValue(search, 500)

  const selectionCount = Object.values(rowSelection).filter(Boolean).length ?? 0
  const selectedRowId = React.useMemo(
    () => Object.keys(rowSelection).map(id => Number(id)),
    [rowSelection]
  )
  const selectedRows = React.useMemo(
    () => data.filter(creative => selectedRowId.includes(creative.creativeId)),
    [data, selectedRowId]
  )

  const isDifferentCreativeSelected = React.useMemo(
    () =>
      selectedRows.reduce((hasDifferent, curr, _, selectedCreatives) => {
        if (hasDifferent) return true
        return (
          curr.platformCreativeTypeId !==
          selectedCreatives[0]?.platformCreativeTypeId
        )
      }, false),
    [selectedRows]
  )
  const isDeletedCreativeSelected = React.useMemo(() => {
    return selectedRows.some(
      creative =>
        creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['deleted']?.id
    )
  }, [selectedRows, creativeStatusType])
  const isRejectedCreativeSelected = React.useMemo(() => {
    return selectedRows.some(
      creative =>
        creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['rejected']?.id
    )
  }, [selectedRows, creativeStatusType])

  const actionErrorTooltip = React.useMemo(() => {
    if (isDeletedCreativeSelected) {
      return 'Deleted creatives can’t be edited, duplicated, or removed.'
    }
  }, [isDeletedCreativeSelected])

  const campaignActionTooltip = React.useMemo(() => {
    if (isDifferentCreativeSelected) {
      return 'Choose creatives with the same Creative Type to add them to a campaign.'
    }
    if (isDeletedCreativeSelected || isRejectedCreativeSelected) {
      return 'Pending, Running, or Paused creative can be added to a campaign'
    }
  }, [
    isDifferentCreativeSelected,
    isDeletedCreativeSelected,
    isRejectedCreativeSelected,
  ])

  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        flexShrink: 0,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 100,
          flexShrink: 0,
          overflow: 'hidden',
          p: 16,
          gap: 16,
          flexWrap: 'wrap-reverse',
          borderBottom: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto',
          }}
        >
          <DateRangePicker
            dateRange={dateRange}
            onChange={value => {
              setDateRange(value)
            }}
            timezoneName={organizationTimezoneName}
            showPreselects={false}
            maxValueParams={{
              param: 'days',
              subtractor: 0,
            }}
            optional
            placeholder="Select Date Range"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: 100,
          flexShrink: 0,
          overflow: 'hidden',
          p: 16,
          gap: 16,
          flexWrap: 'wrap-reverse',
        }}
      >
        {selectionCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              mr: 'auto',
            }}
          >
            <Chip
              size="small"
              color="secondary"
              onDelete={() => setRowSelection({})}
              label={`${selectionCount} of ${filteredRecords} selected `}
            />
            <Tooltip title={actionErrorTooltip} arrow placement="top">
              <Box>
                <Actions
                  selectionData={selectedRows}
                  setRowSelection={setRowSelection}
                />
              </Box>
            </Tooltip>
            <Tooltip
              title={
                isDifferentCreativeSelected
                  ? 'Choose creatives with the same Creative Type to add them to a group.'
                  : ''
              }
              arrow
              placement="top"
            >
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={isDifferentCreativeSelected}
                  onClick={() => {
                    setAction('addToGroup', selectedRows, {
                      onSettle: () => {
                        setRowSelection({})
                      },
                      onSuccess: () => {
                        const basePathArray = pathname.split('/').slice(0, -1)
                        basePathArray.push('grouped')
                        navigate({
                          to: `${basePathArray.join('/')}`,
                        })
                      },
                    })
                  }}
                >
                  Save To Group
                </Button>
              </Box>
            </Tooltip>
            <Tooltip title={campaignActionTooltip} arrow placement="top">
              <Box>
                <CampaignActions
                  selectionData={selectedRows}
                  setRowSelection={setRowSelection}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            gap: 8,
            ml: 'auto',
          }}
        >
          <SearchField
            search={search}
            setSearch={setSearch}
            setRowSelection={setRowSelection}
          />
          <Filters
            statusId={statusId}
            setStatusId={status => {
              setStatusId(status)
              setRowSelection({})
            }}
            countApiState={{
              searchField: debounsedSearch,
              platformCreativeTypeIds: creativeTypeIds,
              allCreatives: false,
              groupedCreatives: false,
              startTime: dateRange?.startDate,
              endTime: dateRange?.endDate,
            }}
          />
          {viewType === 'table' ? (
            <ColumnVisibility
              data={columnOptions}
              onChange={setColumnVisibility}
              value={columnVisibility}
              slotProps={{
                SelectAdvancePopoverProps: {
                  slotProps: {
                    // @ts-ignore
                    PopoverProps: {
                      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                      transformOrigin: { vertical: 'top', horizontal: 'right' },
                    },
                  },
                },
                IconButtonProps: { color: 'secondary' },
              }}
            />
          ) : (
            <SortMenu
              sorting={sorting}
              setSorting={sorting => {
                setSorting(sorting)
                setRowSelection({})
              }}
            />
          )}
          <Tabs
            Component={'div'}
            value={viewType}
            onChange={(_, value) => {
              setViewType(value as ViewType)
              setSorting(DEFAULT_CREATIVE_LIST_SORTING)
              setRowSelection({})
            }}
            aria-label="View Option Tabs"
            variant="pill"
            size="small"
          >
            <Tab
              icon={<TableCell />}
              value="table"
              iconPosition="start"
              label="Table"
            />
            <Tab
              icon={<Grid />}
              value="grid"
              iconPosition="start"
              label="Grid"
            />
          </Tabs>
        </Box>
      </Box>
    </Box>
  )
}
