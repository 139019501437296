import * as React from 'react'
import { Box, LinearProgress, Typography } from '@applift/factor'

export interface EmptyCardType {
  /**
   * Need to pass empty state's illustration
   */
  illustration: React.ReactNode
  /**
   * isLoading - shows the progressbar
   * @default false
   */
  isLoading?: boolean
  /**
   * Title of the empty state
   */
  title?: React.ReactNode
  /**
   * Sub-text of the empty state
   */
  subText?: React.ReactNode
  /**
   * Action of the empty state
   */
  action?: React.ReactNode
}

export const EmptyCard = (props: EmptyCardType) => {
  const { illustration, isLoading, title, subText, action } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 24,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textColor: 'primary-500',
          width: 75,
        }}
      >
        {illustration}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          width: 100,
          gap: 8,
        }}
      >
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            color={'primary'}
            sx={{ width: 100 }}
            thickness={8}
          />
        )}
        {title && title}
        {subText && (
          <Typography component="span" variant="bodySmall" gutterBottom={false}>
            {subText}
          </Typography>
        )}
        {action && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {action}
          </Box>
        )}
      </Box>
    </Box>
  )
}
