import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  LoadingButton,
  Paper,
  Tooltip,
  Typography,
  useDebounceValue,
} from '@applift/factor'
import { CreativeNative, InfoCircle } from '@applift/icons'
import * as React from 'react'

import {
  constructListFromColumnDef,
  RowSelectionState,
} from '@applift/datagrid'
import {
  DEFAULT_CREATIVE_LIST_SORTING,
  IMAGE_CREATIVE_ID,
  IMAGE_HEIGHT_MIN,
  IMAGE_WIDTH_MIN,
  NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS,
  NATIVE_VIDEO_DURATION_MAX,
  NATIVE_VIDEO_DURATION_MIN,
  VIDEO_CREATIVE_ID,
} from '../../../constants'
import { useCreativesList } from '../../../hooks'
import { Creative, NativeCreativeGenerationData } from '../../../models'
import { Table } from '../../common'
import { Filters } from '../../common/AllCreatives/ActionPanel/Filters'
import { SearchField } from '../../common/AllCreatives/ActionPanel/SearchField'
import { validateAspectRatio } from '../AddNativeCreativeDialog/CreateWrapper/Upload/Dropzone/utils'
import { transformExistingCreative } from '../helper'
import { ExistingNativeColDef } from './colDef'
import { SupportedFiles } from './SupportedFiles'
interface ExisitingNativeCreativeDialogProps extends DialogProps {
  onAction?: (data: NativeCreativeGenerationData[]) => void
  dialogTitle?: string
  actionText?: string
}

export const ExisitingNativeCreativeDialog = (
  props: ExisitingNativeCreativeDialogProps
) => {
  const {
    open,
    onClose,
    dialogTitle = 'Native Creatives',
    actionText = 'Next',
    onAction,
    ...others
  } = props
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [sorting, setSorting] = React.useState(DEFAULT_CREATIVE_LIST_SORTING)
  const [search, setSearch] = React.useState('')
  const [statusId, setStatusId] = React.useState<number[]>([])
  const [_, defaultColumnVisibility] =
    constructListFromColumnDef(ExistingNativeColDef)

  const [columnVisibility, setColumnVisibility] = React.useState(
    defaultColumnVisibility
  )
  const sortBy = React.useMemo(() => {
    return sorting[0]?.id
      ? `${sorting[0]?.desc ? '-' : '+'}${sorting[0]?.id}`
      : undefined
  }, [sorting])
  const debouncedSearch = useDebounceValue(search, 500)
  const {
    data: allCreativeData,
    isFetching,
    isError,
    fetchNextPage,
  } = useCreativesList({
    searchField: debouncedSearch,
    creativeStatusIds: statusId,
    platformCreativeTypeIds: [IMAGE_CREATIVE_ID, VIDEO_CREATIVE_ID],
    sortBy,
    includeCreativeSourceDetails: true,
  })
  const data = React.useMemo(() => {
    return (
      allCreativeData?.pages?.reduce<Creative[]>((prev, curr) => {
        return [...prev, ...curr.filteredList]
      }, []) ?? []
    )
  }, [allCreativeData])

  const filterData = data.filter(item => {
    if (
      item.platformCreativeTypeId === VIDEO_CREATIVE_ID &&
      item?.duration &&
      item?.duration >= NATIVE_VIDEO_DURATION_MIN &&
      item?.duration <= NATIVE_VIDEO_DURATION_MAX
    ) {
      return true
    }
    if (
      item.platformCreativeTypeId === IMAGE_CREATIVE_ID &&
      item?.creativeSource &&
      item?.creativeHeight &&
      item?.creativeHeight > IMAGE_HEIGHT_MIN &&
      item?.creativeWidth &&
      item?.creativeWidth > IMAGE_WIDTH_MIN &&
      validateAspectRatio(NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS, {
        naturalWidth: item?.creativeWidth,
        naturalHeight: item?.creativeHeight,
      })
    ) {
      return true
    }
    return false
  })
  // Removing the frontend-filtered record from the API filter record to get the exact fetch count
  const filteredRecords =
    (allCreativeData?.pages?.[0]?.filteredRecords ?? 0) -
      (data.length - filterData?.length) || 0

  const getRowId = (row: Creative) => String(row.creativeId)
  const selectionCount = Object.values(rowSelection).filter(Boolean).length ?? 0
  const [isTransfroming, setIsTransforming] = React.useState(false)

  const generateCreative = async () => {
    setIsTransforming(true)
    const selectedIds = Object.keys(rowSelection ?? {})
    const existingGroupData: NativeCreativeGenerationData[] = []
    const idGeneratorRef = 1

    const transfromedData = await transformExistingCreative(
      data,
      selectedIds,
      idGeneratorRef
    )

    existingGroupData.push(...transfromedData)

    onAction?.(existingGroupData)
    setIsTransforming(false)
  }

  return (
    <>
      <Dialog fullScreen="bleed" open={open} onClose={onClose} {...others}>
        <DialogTitle onClose={e => onClose?.(e, 'escapeKeyDown')}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Box
              sx={{
                display: 'flex',
                fontSize: 32,
                textColor: 'primary-600',
              }}
            >
              <CreativeNative />
            </Box>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ py: 24, px: 32, display: 'flex' }}>
          <Paper
            sx={{
              height: 100,
              width: 100,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box style={{ height: '66px', display: 'flex' }}>
              {selectionCount > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    mr: 'auto',
                    py: 16,
                    px: 12,
                  }}
                >
                  <Chip
                    size="small"
                    color="secondary"
                    onDelete={() => setRowSelection({})}
                    label={`${selectionCount} of ${filteredRecords} selected `}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 0,
                  gap: 8,
                  ml: 'auto',
                  py: 16,
                  px: 12,
                }}
              >
                <SearchField
                  search={search}
                  setSearch={setSearch}
                  setRowSelection={setRowSelection}
                />
                <Filters
                  statusId={statusId}
                  setStatusId={setStatusId}
                  countApiState={{
                    searchField: debouncedSearch,
                    platformCreativeTypeIds: [
                      IMAGE_CREATIVE_ID,
                      VIDEO_CREATIVE_ID,
                    ],
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
              <Table
                data={filterData}
                colDef={ExistingNativeColDef}
                isError={isError}
                setSorting={setSorting}
                filteredRecords={filteredRecords}
                loading={isFetching}
                setRowSelection={setRowSelection}
                columnPinning={{
                  left: ['__check__', 'creativeId'],
                }}
                rowSelection={rowSelection}
                sorting={sorting}
                fetchNextPage={fetchNextPage}
                getRowId={getRowId}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
            <Typography>
              Only eligible creatives for native are listed above.
            </Typography>
            <Tooltip title={<SupportedFiles />} placement="top">
              <Link sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <InfoCircle fontSize={18} />
                <Typography>View Criteria</Typography>
              </Link>
            </Tooltip>
          </Box>

          <Box sx={{ display: 'flex', gap: 16, ml: 'auto' }}>
            <Button
              color="secondary"
              onClick={e => onClose?.(e, 'escapeKeyDown')}
            >
              Cancel
            </Button>
            <Tooltip
              title={
                selectionCount > 200
                  ? 'Maximum limit of 200 creatives is reached'
                  : ''
              }
              placement="top"
            >
              <Box>
                <LoadingButton
                  disabled={selectionCount === 0 || selectionCount > 200}
                  onClick={generateCreative}
                  loading={isTransfroming}
                >
                  {actionText}
                </LoadingButton>
              </Box>
            </Tooltip>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}
