import {
  Audio,
  Image as ImageIcon,
  Html,
  Video,
  CreativeNative,
  AlertCircle,
} from '@applift/icons'

import {
  AUDIO_CREATIVE_ID,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../constants'

export const getPlaceholderIcon = (creativeTypeId: number) => {
  switch (creativeTypeId) {
    case AUDIO_CREATIVE_ID:
      return <Audio sx={{ width: 75, height: 75, textColor: 'neutral-400' }} />
    case VIDEO_CREATIVE_ID:
      return <Video sx={{ width: 75, height: 75, textColor: 'neutral-400' }} />
    case IMAGE_CREATIVE_ID:
      return (
        <ImageIcon sx={{ width: 75, height: 75, textColor: 'neutral-400' }} />
      )
    case HTML_CREATIVE_ID:
      return <Html sx={{ width: 75, height: 75, textColor: 'neutral-400' }} />
    case NATIVE_CREATIVE_ID:
      // TODO: change creative icon
      return (
        <CreativeNative
          sx={{ width: 75, height: 75, textColor: 'neutral-400' }}
        />
      )
    default:
      return <AlertCircle />
  }
}
