import { Layout, sx } from '@applift/factor'
import * as React from 'react'
import { ReactFormExtendedApi } from '@tanstack/react-form'
import { NativeCreativeGenerationData } from '../../../../models'
import { CreateEmptySection } from '../../../common'
import { Sidebar } from '../SidebarWrapper'
import { CreateNativeCreativeSection } from './Create'
import { useNativeCTAOptions } from '../../../../hooks'

export type NativeCreationFormType = ReactFormExtendedApi<
  NativeCreativeGenerationData,
  undefined
>
interface CreateNativeCreativeProps {
  data: Map<string, NativeCreativeGenerationData>
  onChange?: (creative: NativeCreativeGenerationData[]) => void
  onRemove?: (id: string) => void
  onError?: (hasError: boolean) => void
  errorIds: string[]
  form: ReactFormExtendedApi<NativeCreativeGenerationData, undefined>
  isCreateClicked: boolean
  selectedItemId: string | null
  setSelectedItemId: React.Dispatch<React.SetStateAction<string | null>>
}

export const CreateWrapper = (props: CreateNativeCreativeProps) => {
  const {
    data,
    onChange,
    onRemove,
    onError,
    errorIds,
    form,
    isCreateClicked,
    selectedItemId,
    setSelectedItemId,
  } = props

  const creativeList = React.useMemo(() => Array.from(data.values()), [data])
  const idGeneratorRef = React.useRef(creativeList.length + 1)
  useNativeCTAOptions({ showOnlyDefault: true })
  const onSelect = (creative: NativeCreativeGenerationData | null) => {
    form.reset()
    if (creative === null) {
      return setSelectedItemId(null)
    }
    form.store.setState(prev => {
      return {
        ...prev,
        values: creative,
      }
    })
    if (isCreateClicked) {
      form.validateAllFields('change')
    }
    setSelectedItemId(creative.uniqueId)
  }

  return (
    <Layout
      sidebarWidth={300}
      hideResizerHandle
      classes={{
        sidebarContent: sx({ overflowY: 'hidden' }),
      }}
      sidebar={
        <Sidebar
          creativeList={creativeList}
          onChange={onChange}
          selectedItemId={selectedItemId}
          idGeneratorRef={idGeneratorRef}
          onRemove={onRemove}
          onSelect={onSelect}
          errorIds={errorIds}
        />
      }
      sidebarAnchor="left"
    >
      {selectedItemId && data.size > 0 ? (
        <CreateNativeCreativeSection
          isCreateClicked={isCreateClicked}
          data={data}
          onChange={onChange}
          onSelect={onSelect}
          onError={onError}
          form={form}
          idGeneratorRef={idGeneratorRef}
        />
      ) : (
        <CreateEmptySection creativeType="native" />
      )}
    </Layout>
  )
}
