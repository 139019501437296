import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'

import {
  CreativeTableResponse,
  CreativeType,
  getCreativeStatusCountResponse,
  getCreativeTypeCountResponse,
} from '../models/Creative'
import { WithResponse } from '../models/Response'
import { getInstance } from './instance'
import {
  getAllCreativeListKey,
  getCreativeStatusCountKey,
  getCreativeTypeCountKey,
  getCreativeTypesKey,
} from './QueryKeys'

import { groupByUnique } from '../utils'

export const getAllCreativeList = async ({
  pageParam = 1,
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getAllCreativeListKey)['keys']>
>): Promise<CreativeTableResponse> => {
  const {
    searchField,
    sortBy,
    noOfEntries,
    creativeStatusIds,
    platformCreativeTypeIds,
    creativeIds,
    campaignIds,
    owIds,
    activeCreatives,
    createdAfter,
    allOwIds,
    includeOrganizationDetails,
    includeUserDetails,
    creativeGroupId,
    startTime,
    endTime,
    includeCreativeSourceDetails,
  } = queryKey[0] || {}
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel(`/v3/crt/creatives/list - Request cancelled`)
  })
  try {
    const params = {
      ...(typeof creativeGroupId === 'number' ? { creativeGroupId } : {}),
      ...(searchField ? { searchField: searchField } : {}),
      ...(noOfEntries ? { noOfEntries: noOfEntries } : {}),
      ...(creativeStatusIds?.length
        ? { creativeStatusIds: creativeStatusIds }
        : {}),
      ...(platformCreativeTypeIds?.length
        ? { platformCreativeTypeIds: platformCreativeTypeIds }
        : {}),
      ...(creativeIds?.length ? { creativeIds: creativeIds } : {}),
      ...(campaignIds?.length ? { campaignIds: campaignIds } : {}),
      ...(owIds?.length ? { owIds: owIds } : {}),
      ...(typeof activeCreatives === 'boolean'
        ? { activeCreatives: activeCreatives }
        : {}),
      ...(createdAfter ? { createdAfter: createdAfter } : {}),
      ...(typeof allOwIds === 'boolean' ? { allOwIds: allOwIds } : {}),
      ...(typeof includeOrganizationDetails === 'boolean'
        ? { includeOrganizationDetails }
        : {}),
      ...(typeof includeUserDetails === 'boolean'
        ? { includeUserDetails: includeUserDetails }
        : {}),
      ...(sortBy?.length ? { sortBy: sortBy } : {}),
      ...(typeof startTime === 'number' ? { startTime } : {}),
      ...(typeof endTime === 'number' ? { endTime } : {}),

      includeCreativeSourceDetails,
      pageNo: pageParam,
    }
    const response: AxiosResponse<WithResponse<CreativeTableResponse>> =
      await getInstance().post(
        `/v3/crt/creatives/list`,
        { ...params },
        {
          cancelToken: source.token,
        }
      )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export interface CreativeTypesResponse {
  creativeTypeList: CreativeType[]
}

export const getCreativeTypes = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getCreativeTypesKey)['keys']>>) => {
  const { scope: _, displayAllType = true, ...other } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<CreativeTypesResponse>> =
      await getInstance().get(`/v3/crt/master/static/creative-types`, {
        params: { displayAllType, ...other },
        cancelToken: source.token,
      })

    const creativeTypeList = response.data.data.creativeTypeList ?? []

    const creativeTypeMappingById = groupByUnique<CreativeType, 'id'>(
      creativeTypeList,
      'id'
    )

    const creativeTypeMappingByName = groupByUnique<CreativeType, 'name'>(
      creativeTypeList,
      'name'
    )

    const creativeTypeMappingByDisplayName = groupByUnique<
      CreativeType,
      'displayName'
    >(creativeTypeList, 'displayName')

    return {
      creativeTypeList,
      creativeTypeMappingById,
      creativeTypeMappingByName,
      creativeTypeMappingByDisplayName,
    }
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

interface CreativeStatusListObject {
  id: number
  name: string
  displayName: string
}

export interface CreativeStatusAPIResponse {
  creativeStatusList: CreativeStatusListObject[]
  totalRecords: number
  filteredRecords: number
}

export interface CreativeStatusResponse {
  data: CreativeStatusListObject[]
  creativeStatusIdMapping: { [key: number]: string }
  creativeStatusNameMapping: { [key: string]: number }
}

export const getCreativeStatusCount = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getCreativeStatusCountKey)['keys']>
>) => {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled: /v3/crt/creatives/count-by-status')
  })

  const { scope: _, ...param } = queryKey[0]!

  try {
    const response: AxiosResponse<
      WithResponse<getCreativeStatusCountResponse>
    > = await getInstance().post('v3/crt/creatives/count-by-status', param, {
      cancelToken: source.token,
    })

    const statusCountList = response?.data?.data?.creativeStatusList ?? []

    const statusCountIdMapping = statusCountList.reduce(
      (acc, curr) => {
        acc[`${curr.id}`] = curr.creativeCount
        return acc
      },
      {} as { [key: number]: number }
    )

    return { statusCountList, statusCountIdMapping }
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}

export const getCreativeTypeCount = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getCreativeTypeCountKey)['keys']>
>) => {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled: /v3/crt/creatives/count-by-type')
  })

  const { scope: _, ...param } = queryKey[0]!

  try {
    const response: AxiosResponse<WithResponse<getCreativeTypeCountResponse>> =
      await getInstance().post('/v3/crt/creatives/count-by-type', param)

    const typeCountList = response?.data?.data?.creativeTypeList ?? []

    const typeCountMappingById = typeCountList.reduce(
      (acc, curr) => {
        acc[`${curr.id}`] = curr.creativeCount
        return acc
      },
      {} as { [key: number]: number }
    )

    return { typeCountList, typeCountMappingById }
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}
