import * as React from 'react'
import { ReactFormExtendedApi } from '@tanstack/react-form'
import { Box, Col, Paper, Row } from '@applift/factor'

import { ActionPanel } from './ActionPanel'
import { FileAudioCard } from './FileAudioCard'
import { DaastAudioCard } from './DaastAudioCard'
import { AudioCreativeGenerationData } from '../../../../../models'

export interface CreateProps {
  data: Map<string, AudioCreativeGenerationData>
  onChange?: (creative: AudioCreativeGenerationData[]) => void
  onRemove?: (id: string) => void
  onError?: (hasError: boolean) => void
}

export type AudioCreationFormType = ReactFormExtendedApi<
  AudioCreativeGenerationData,
  undefined
>

export const Create = (props: CreateProps) => {
  const { data, onChange, onRemove, onError } = props
  const [actionPanelError, setActionPanelError] = React.useState<boolean>(false)

  const hasErrorInGrid = React.useMemo(() => {
    return Array.from(data.values()).some(creative => creative.hasError)
  }, [data])

  const creativeList = React.useMemo(() => Array.from(data.values()), [data])

  React.useEffect(() => {
    onError?.(actionPanelError || hasErrorInGrid)
  }, [actionPanelError, hasErrorInGrid, onError])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        overflow: 'hidden',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          width: 100,
          overflow: 'hidden',
          alignItems: 'center',
          px: 24,
          py: 12,
          gap: 16,
        }}
      >
        <ActionPanel
          data={data}
          setError={setActionPanelError}
          onChange={onChange}
        />
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          overflow: 'auto',
          p: 24,
        }}
      >
        <Row
          xs={{ gutterSize: 16, col: 1 }}
          md={{ gutterSize: 24, col: 2 }}
          lg={3}
        >
          {creativeList.map(creative => {
            if (creative.audioCreativeType !== 'URL') {
              return (
                <Col key={creative.id}>
                  <DaastAudioCard
                    creative={creative}
                    onChange={onChange}
                    onRemove={onRemove}
                  />
                </Col>
              )
            }
            return (
              <Col key={creative.id}>
                <FileAudioCard
                  creative={creative}
                  onChange={onChange}
                  onRemove={onRemove}
                />
              </Col>
            )
          })}
        </Row>
      </Box>
    </Box>
  )
}
