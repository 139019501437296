import * as React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor'
import { CreativeHTML } from '@applift/icons'

import { UploadingCreatives } from '../../common'
import { CreateWrapper } from './CreateWrapper'
import { HTMLCreativeGenerationData } from '../../../models/Creative'
import { generateCreativeMapData } from '../helper'
import { noop } from '../../../utils'
import { useCreateCreative } from '../../../hooks'
import { transformHtmlStateToApiRequest } from './helper'
import { useQueryClient } from '@tanstack/react-query'
import { scopeTypes } from '../../../api'

interface AddHTMLCreativeDialogProps extends DialogProps {
  onSuccess?: (response?: any, request?: any) => void
  onError?: (error?: any, request?: any) => void
  onSettle?: () => void
  actionText?: string
  dialogTitle?: string
}

export const AddHTMLCreativeDialog = (props: AddHTMLCreativeDialogProps) => {
  const {
    open,
    onClose,
    onSuccess,
    onError,
    onSettle,
    actionText = 'Add Creative',
    dialogTitle = 'HTML Creative',
    ...others
  } = props

  const [hasError, setHasError] = React.useState<boolean>(false)

  const [creativeState, setCreativeState] = React.useState(
    new Map<string, HTMLCreativeGenerationData>(generateCreativeMapData([]))
  )

  const updateCreativesBulk = React.useCallback(
    (creatives: HTMLCreativeGenerationData[]) => {
      setCreativeState(prevMap => {
        const newMap = new Map(prevMap)
        creatives.forEach(creative => {
          newMap.set(creative.id, creative)
        })
        return newMap
      })
    },
    [setCreativeState]
  )

  const removeCreative = React.useCallback(
    (id: string) => {
      setCreativeState(prevMap => {
        const newMap = new Map(prevMap)
        newMap.delete(id)
        return newMap
      })
    },
    [setCreativeState]
  )

  const queryClient = useQueryClient()

  const createHtmlCreative = useCreateCreative({
    onSuccess: (response, request) => {
      onClose?.({}, 'escapeKeyDown')
      onSuccess?.(response, request)
      queryClient.refetchQueries({
        predicate: query =>
          (query.queryKey?.[0] as { scope?: scopeTypes })?.scope ===
          'getCreativeTypeCount',
      })
    },
    onError: (error, request) => {
      onClose?.({}, 'escapeKeyDown')
      enqueueSnackbar({
        // @ts-ignore
        message: error?.errorObjects?.[0]?.error || 'Something went wrong',
        variant: 'error',
      })
      onError?.(error, request)
    },
    onSettled: onSettle,
  })

  const loading = createHtmlCreative.isLoading as boolean

  const isAddCreativeCTADisable = React.useMemo(() => {
    return hasError || creativeState.size === 0 || loading
  }, [hasError, creativeState, loading])

  return (
    <>
      <Dialog
        fullScreen="bleed"
        open={open}
        onClose={loading ? noop : onClose}
        {...others}
      >
        <DialogTitle
          onClose={loading ? noop : e => onClose?.(e, 'escapeKeyDown')}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Box
              sx={{
                display: 'flex',
                fontSize: 32,
                textColor: 'primary-600',
              }}
            >
              <CreativeHTML />
            </Box>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ py: 0, pr: 0 }}>
          {loading && <UploadingCreatives />}
          {loading === false && (
            <CreateWrapper
              data={creativeState}
              onChange={updateCreativesBulk}
              onRemove={removeCreative}
              onError={setHasError}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            color="secondary"
            onClick={e => onClose?.(e, 'escapeKeyDown')}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={isAddCreativeCTADisable}
            onClick={() => {
              const param = transformHtmlStateToApiRequest(creativeState)
              createHtmlCreative.mutate(param)
            }}
          >
            {actionText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
