import {
  Box,
  Button,
  Divider,
  FileTrigger,
  TextField,
  Typography,
} from '@applift/factor'
import { Add, Upload } from '@applift/icons'
import * as React from 'react'

import { NATIVE_ALLOWED_TYPES } from '../../../../../../constants'
import { isValidURL } from '../../../../../../utils'

interface Props {
  onUpload: (files: File[]) => void
  onURLAdd: (url: string, onSuccess: () => void) => void
}

export const BrandIconDropzone = (props: Props) => {
  const { onUpload, onURLAdd } = props

  const ref = React.useRef<HTMLDivElement>(null)

  const [displayTextArea, toggleTextAreaVisibility] = React.useState(false)
  const [imageURL, setImageURL] = React.useState('')

  const onURlSuccess = () => {
    setImageURL('')
    toggleTextAreaVisibility(false)
  }
  const addImage = () => {
    onURLAdd(imageURL, onURlSuccess)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Box
        role="button"
        ref={ref}
        tabIndex={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 100,
          overflow: 'hidden',
          p: 24,
          gap: 24,
          borderRadius: 4,
          border: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 8,
            alignItems: 'center',
            flexDirection: 'column',
            flexShrink: 0,
          }}
        >
          <FileTrigger
            allowsMultiple
            acceptedFileTypes={NATIVE_ALLOWED_TYPES}
            onSelect={files => {
              if (files) {
                onUpload(Array.from(files))
              }
            }}
          >
            {({ onClick }) => {
              return (
                <Button
                  startIcon={<Upload />}
                  variant="outlined"
                  onClick={onClick}
                  size="small"
                >
                  Upload File
                </Button>
              )
            }}
          </FileTrigger>

          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            onClick={() => toggleTextAreaVisibility(true)}
          >
            Add Image URL
          </Button>
        </Box>
        <Divider
          orientation="vertical"
          color="secondary"
          flexItem
          sx={{ mx: 8 }}
        />
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 12, width: 100 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              flexWrap: 'wrap',
              width: 100,
            }}
          >
            <Box sx={{ display: displayTextArea ? 'flex' : 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 8,
                  width: 100,
                }}
              >
                <TextField
                  label="Image URL"
                  variant="outlinedDash"
                  required
                  value={imageURL}
                  fullWidth
                  placeholder="Enter Image URL"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      addImage()
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        size="small"
                        variant="text"
                        disabled={
                          Boolean(imageURL?.length) === false ||
                          isValidURL(imageURL) === false
                        }
                        onClick={addImage}
                      >
                        Add
                      </Button>
                    ),
                  }}
                  onChange={e => setImageURL(e.target.value)}
                  error={isValidURL(imageURL) === false}
                  helperText={isValidURL(imageURL) ? '' : 'URL must be valid'}
                />
              </Box>
            </Box>
            <SupportedImageInfo />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const SupportedImageInfo = () => (
  <>
    <Box>
      <Typography sx={{ mr: 2, textColor: 'neutral-500' }} variant="label">
        Supported Type:
      </Typography>
      <Typography variant="label">JPG, JPEG, PNG, GIF</Typography>
    </Box>
    <Box>
      <Typography sx={{ mr: 2, textColor: 'neutral-500' }} variant="label">
        Minimum Size:
      </Typography>
      <Typography variant="label">50x50</Typography>
      <Typography
        variant="label"
        sx={{ textColor: 'neutral-300', mx: 4 }}
        lineHeight="single-line"
      >
        &bull;
      </Typography>
      <Typography variant="label">For X (Twitter) 100x100</Typography>
    </Box>
    <Box>
      <Typography sx={{ mr: 2, textColor: 'neutral-500' }} variant="label">
        Aspect ratio:
      </Typography>
      <Typography variant="label">1:1</Typography>
    </Box>
  </>
)
