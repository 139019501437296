import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { createGroup, scopeTypes } from '../api'

type useCreateGroupMutationOptions = MutateOptions<
  Awaited<ReturnType<typeof createGroup>>,
  any,
  Parameters<typeof createGroup>[0],
  unknown
>

export const useCreateGroup = (options: useCreateGroupMutationOptions) => {
  const { onSuccess, ...other } = options
  const queryClient = useQueryClient()
  return useMutation(createGroup, {
    onSuccess: (...params) => {
      // Refetching the creatives list

      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
              params['1'].creativeTypeId
            )
          )
        },
      })

      queryClient.refetchQueries({
        predicate: (query: any) =>
          (query.queryKey?.[0]?.scope as scopeTypes) ===
          'getCreativeGroupsList',
      })
      onSuccess?.(...params)
    },
    ...other,
    mutationKey: ['createGroup'],
  })
}
