import { SingleAsset, VideoCreativeGenerationData } from '../models'
import { removeFileExtension, removeSequenceFromAssets } from './helper'

export const ALLOWED_EXCEL_FORMATS = [
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'text/csv', // CSV
]

interface VideoMetaData {
  duration: number
  height: number
  width: number
}

const STANDARD_VIDEO_DIMENSIONS = ['640x480', '1920x1080', '480x360', '640x360']

export const hasDeviationFromStandard = ({
  height,
  width,
  duration,
}: {
  height: number
  width: number
  duration: number
}) => {
  if (duration < 15 || duration > 60) {
    return true
  }
  const dimemsion = `${width}x${height}`
  return STANDARD_VIDEO_DIMENSIONS.indexOf(dimemsion) === -1
}

export const computeVideoDimensions = (
  fileName: string,
  asset: SingleAsset
) => {
  return new Promise<VideoCreativeGenerationData>((res, rej) => {
    const video = document.createElement('video')
    video.src = asset.assetCDNURL
    video.addEventListener('loadedmetadata', () => {
      const obj: VideoCreativeGenerationData = {
        name: removeFileExtension(removeSequenceFromAssets(fileName)),
        id: crypto.randomUUID(),
        videoUrl: asset.assetCDNURL,
        duration: video.duration,
        height: video.videoHeight,
        width: video.videoWidth,
        videoCreativeType: 'file',
        warning: hasDeviationFromStandard({
          height: video.videoHeight,
          width: video.videoWidth,
          duration: video.duration,
        }),
      }
      console.log('trying upload, in event', {
        video,
        obj,
        duration: video.duration,
        height: video.videoHeight,
        width: video.videoWidth,
      })
      res(obj)
    })
    video.addEventListener('error', e => {
      console.log('error while setting values')
      rej(e)
    })
  })
}

export const getVideoMetaData = async (param: {
  file?: File
  URL?: string
}) => {
  let videoMetadata: VideoMetaData | null = null
  const video = document.createElement('video')

  video.src = param.file
    ? URL.createObjectURL(param.file)
    : (param.URL as string)

  const loadedMetadata = new Promise<VideoMetaData>((resolve, reject) => {
    video.onloadedmetadata = () =>
      resolve({
        duration: video.duration,
        height: video.videoHeight,
        width: video.videoWidth,
      })
    video.onerror = e => reject(e)
  })

  video.load()

  try {
    videoMetadata = await loadedMetadata
  } catch (error) {
    console.log(error)
  }

  return videoMetadata
}
