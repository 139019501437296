import * as React from 'react'
import clsx from 'clsx'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogProps,
  ButtonBase,
  enqueueSnackbar,
} from '@applift/factor'
import {
  CampaignGrowth,
  DevicesImage,
  UserAudio,
  UserVideo,
  // WebsiteImage,
  WebsiteResponsive,
} from '@applift/illustrations'

import { EmptyCreativeCard } from '../../components/EmptyCreativeCard'
import { EmptyCreativeSection } from '../../components/EmptyCreativeSection'

import styles from './index.module.scss'
import { useDialogManager } from '../dialogManager'
import { CreateCreativeRequest, CreateCreativeResponse } from '../../api'
import {
  IMAGE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
  AUDIO_CREATIVE_ID,
  HTML_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
} from '../../constants'
import { useQueryClient } from '@tanstack/react-query'

type CardType = 'image' | 'html' | 'video' | 'audio' | 'native'

export const AddAllCreativeDialog = (props: DialogProps) => {
  const { open, onClose, ...others } = props
  const [selectedCard, setSelectedCard] = React.useState<CardType | undefined>(
    undefined
  )
  const { setAction } = useDialogManager()
  const queryClient = useQueryClient()

  const getCreativeType = () => {
    switch (selectedCard) {
      case 'image':
        return IMAGE_CREATIVE_ID
      case 'html':
        return HTML_CREATIVE_ID
      case 'video':
        return VIDEO_CREATIVE_ID
      case 'audio':
        return AUDIO_CREATIVE_ID
      case 'native':
        return NATIVE_CREATIVE_ID
    }
  }

  const refetchImageList = () => {
    queryClient.resetQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
          IMAGE_CREATIVE_ID
        ),
    })
  }

  const refetchHtmlList = () => {
    queryClient.resetQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
          HTML_CREATIVE_ID
        ),
    })
  }

  const refetchVideoList = () => {
    queryClient.resetQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
          VIDEO_CREATIVE_ID
        ),
    })
  }

  const refetchAudioList = () => {
    queryClient.resetQueries({
      predicate: (query: any) => {
        return (
          query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
          query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
            AUDIO_CREATIVE_ID
          )
        )
      },
    })
  }

  const refetchNativeList = () => {
    queryClient.resetQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
        query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
          NATIVE_CREATIVE_ID
        ),
    })
  }

  const onAction = () => {
    setAction(selectedCard, undefined, {
      onSuccess: (
        response: CreateCreativeResponse,
        request: CreateCreativeRequest
      ) => {
        const createdCreativesId = Object.values(response.successData)
        const failedCreatives = Object.keys(response.failedData)
        if (createdCreativesId.length) {
          enqueueSnackbar({
            message: `${createdCreativesId.length} Creative${createdCreativesId.length > 1 ? 's' : ''} added successfully`,
            variant: 'success',
          })
        }
        if (failedCreatives.length) {
          enqueueSnackbar({
            message: `Failed to add ${failedCreatives.length} Creative${failedCreatives.length > 1 ? 's' : ''}`,
            variant: 'error',
          })
        }
        if (selectedCard === 'image') {
          refetchImageList()
        }
        if (selectedCard === 'html') {
          refetchHtmlList()
        }
        if (selectedCard === 'video') {
          refetchVideoList()
        }
        if (selectedCard === 'audio') {
          refetchAudioList()
        }
        if (selectedCard === 'native') {
          refetchNativeList()
        }

        const succeededCreative = Object.keys(response.successData ?? {}).map(
          assetId => {
            return {
              creativeId: Number(response.successData[`${assetId}`]),
              platformCreativeTypeId: getCreativeType(),
              creativeName: request[`${assetId}`]?.creativeName,
            }
          }
        )
        if (succeededCreative.length) {
          setAction('group', succeededCreative)
        }
      },
    })
  }
  return (
    <Dialog fullScreen="bleed" open={open} onClose={onClose} {...others}>
      <DialogTitle onClose={e => onClose?.(e, 'escapeKeyDown')}>
        Add Creative
      </DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ display: 'block', mb: 16 }}>
          Select any one type of creative which you want to add
        </Typography>
        <EmptyCreativeSection>
          <EmptyCreativeCard
            Component={ButtonBase}
            className={clsx(
              styles.EmptyCreativeCard,
              selectedCard === 'image' ? styles.active : null
            )}
            illustration={<CampaignGrowth sx={{ width: 100, height: 100 }} />}
            title="Image Creative"
            subText="Image ads represent the richest medium to learn about customers and collect data."
            onClick={() => {
              setSelectedCard('image')
            }}
          />
          <EmptyCreativeCard
            Component={ButtonBase}
            className={clsx(
              styles.EmptyCreativeCard,
              selectedCard === 'html' ? styles.active : null
            )}
            illustration={<DevicesImage sx={{ width: 100, height: 100 }} />}
            title="HTML Creative"
            subText="Blend interactive creative into the format of surrounding context for better performance."
            onClick={() => {
              setSelectedCard('html')
            }}
          />
          <EmptyCreativeCard
            Component={ButtonBase}
            className={clsx(
              styles.EmptyCreativeCard,
              selectedCard === 'video' ? styles.active : null
            )}
            illustration={<UserVideo sx={{ width: 100, height: 100 }} />}
            title="Video Creative"
            subText="Incorporate video across every screen and video platform to reach customers across screens."
            onClick={() => {
              setSelectedCard('video')
            }}
          />
          <EmptyCreativeCard
            Component={ButtonBase}
            className={clsx(
              styles.EmptyCreativeCard,
              selectedCard === 'audio' ? styles.active : null
            )}
            illustration={<UserAudio sx={{ width: 100, height: 100 }} />}
            title="Audio Creative"
            subText="Connect the experience of audio ads with the precise targeting and metric capabilities of digital marketing."
            onClick={() => {
              setSelectedCard('audio')
            }}
          />
          <EmptyCreativeCard
            Component={ButtonBase}
            className={clsx(
              styles.EmptyCreativeCard,
              selectedCard === 'native' ? styles.active : null
            )}
            illustration={
              <WebsiteResponsive sx={{ width: 100, height: 100 }} />
            }
            title="Native Creative"
            subText="Native Ads seamlessly blend into the surrounding content, providing a non-disruptive and cohesive user experience."
            onClick={() => {
              setSelectedCard('native')
            }}
          />
        </EmptyCreativeSection>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={e => onClose?.(e, 'escapeKeyDown')}>
          Cancel
        </Button>
        <Button disabled={selectedCard === undefined} onClick={onAction}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
