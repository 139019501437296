import { Box, TypoTooltip, TypographyProps } from '@applift/factor'
import { getIcon } from '../../registry'
import { useCreativeTypes } from '../../hooks'

interface CreativeNameCellType {
  creativeTypeId: number
  label: string
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CreativeNameCell = ({
  creativeTypeId,
  label,
  textTypographyProps,
}: CreativeNameCellType) => {
  const { data: creativeTypeData } = useCreativeTypes({ displayAllType: true })

  const creativeIdMapping = creativeTypeData?.creativeTypeMappingById

  const creativeTypeName =
    creativeIdMapping?.[`${creativeTypeId}`]?.displayName ?? ''

  const creativeTypeIcon = creativeTypeName
    ? getIcon(creativeTypeName as any, {
        fontSize: 20,
      })
    : null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
      }}
    >
      {creativeTypeIcon ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: 4,
            gap: 4,
          }}
        >
          {creativeTypeIcon}
        </Box>
      ) : null}
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
        }}
      >
        {label}
      </TypoTooltip>
    </Box>
  )
}
