import * as React from 'react'
import {
  createFileRoute,
  Outlet,
  useNavigate,
  useLocation,
} from '@tanstack/react-router'
import { Box, Tabs, Tab } from '@applift/factor'
import { Grid, SquaresUnmerged, SquaresMerged } from '@applift/icons'

import { CREATIVE_SECTION } from '../constants'

import styles from './index.module.scss'
import { getSelectedCreativeSection } from '../utils'
import { AllCreativeDialogManagerProvider } from '../modules/common'

export const Route = createFileRoute('/all')({
  component: TabsComponent,
})

function TabsComponent() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const selectedSection = React.useMemo(
    () => getSelectedCreativeSection(pathname),
    [pathname]
  )

  return (
    <AllCreativeDialogManagerProvider>
      <Box className={styles.tabs}>
        <Box sx={{ borderBottom: 1 }}>
          <Tabs value={selectedSection} size="large" variant="standard">
            <Tab
              value={CREATIVE_SECTION.ALL}
              icon={<Grid />}
              iconPosition="start"
              label="All Creatives"
              onClick={() =>
                navigate({
                  to: '/all',
                })
              }
            />
            <Tab
              value={CREATIVE_SECTION.UNGROUPED}
              icon={<SquaresUnmerged />}
              iconPosition="start"
              label="Ungrouped Creatives"
              onClick={() =>
                navigate({
                  to: '/all/ungrouped',
                })
              }
            />
            <Tab
              value={CREATIVE_SECTION.GROUPED}
              icon={<SquaresMerged />}
              iconPosition="start"
              label="Grouped Creatives"
              onClick={() =>
                navigate({
                  to: '/all/grouped',
                })
              }
            />
          </Tabs>
        </Box>
      </Box>
      <Box className={styles.tabContainer}>
        <Outlet />
      </Box>
    </AllCreativeDialogManagerProvider>
  )
}
