import * as React from 'react'
import {
  Button,
  Dialog,
  Typography,
  DialogTitle,
  TypoTooltip,
  LoadingButton,
  DialogActions,
  enqueueSnackbar,
} from '@applift/factor'
import {
  normalizeCampaignData,
  normalizeIOCampaignData,
  NormalizedIOCampaignList,
} from '@applift/platform'

import { AssignToCampaignsDialogContent } from './AssignToCampaignsDialogContent'
import {
  AssignToCampaignRequest,
  CampaignInfoType,
  Creative,
} from '../../../models'
import {
  useAssignToCampaign,
  useBasicIoCampaignList,
  useCreativeCampaignDetail,
} from '../../../hooks'
import { ScreenLoader } from '../../ScreenLoader'
import { compareWithoutOrder, removeDeletedEntries } from './helper'
import { useQueryClient } from '@tanstack/react-query'

interface AssignToCampaignDialogProps {
  onClose: () => void
  title?: string
  selectedCreativeData: Creative[]
  showAddedCampaign?: boolean
  onSuccess?: (response?: any) => void
  onError?: (error?: any) => void
  onSettle?: () => void
}

export const AssignToCampaignDialog = (props: AssignToCampaignDialogProps) => {
  const {
    onClose,
    selectedCreativeData,
    onSuccess,
    onError,
    onSettle,
    showAddedCampaign,
    title = 'Assign To Campaigns',
  } = props
  const [campaignsInfo, setCampaignsInfo] = React.useState<CampaignInfoType[]>(
    []
  )
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)

  const queryClient = useQueryClient()

  const isSingleCreativeSelected = React.useMemo(
    () => selectedCreativeData.length === 1,
    [selectedCreativeData]
  )
  const showCampaign = isSingleCreativeSelected && showAddedCampaign

  const initCampaignsIds = React.useMemo(() => {
    if (isSingleCreativeSelected) {
      return selectedCreativeData?.[0]?.campaignDetails?.campaignIds ?? []
    }
    // only wanted data in initCampaign
    return []
  }, [selectedCreativeData, isSingleCreativeSelected])

  const { data: ioCampaignList, isFetching } = useBasicIoCampaignList({
    searchField: '',
    campaignIds: initCampaignsIds,
    options: {
      enabled: !!initCampaignsIds.length && showCampaign,
    },
  })

  const flatInitIoData = React.useMemo(() => {
    return (
      ioCampaignList?.pages
        ?.map(page => {
          return page?.ioCampaignsList ?? []
        })
        .flat(1) || []
    )
  }, [ioCampaignList?.pages])

  const flatInitCampaignData = React.useMemo(() => {
    return (
      flatInitIoData
        .map(io => {
          return (
            io?.campaigns.map(cmp => ({
              ...cmp,
              ioId: io.ioId,
              ioName: io.ioName,
              ioBudgetTypeId: io.ioBudgetTypeId,
            })) ?? []
          )
        })
        .flat(1) || []
    )
  }, [flatInitIoData])

  // Formatting the data for the selection
  const tableInitData = React.useMemo(
    () =>
      isSingleCreativeSelected
        ? flatInitCampaignData.map(cmp => {
            return {
              id: cmp.campaignId,
              name: cmp.name,
              creativeTypeId: cmp.creativeTypeId,
              status: cmp.status,
              ioId: cmp.ioId,
              ioName: cmp.ioName,
              ioBudgetTypeId: cmp.ioBudgetTypeId,
            }
          })
        : [],
    [flatInitCampaignData, isSingleCreativeSelected]
  )

  React.useEffect(() => {
    if (
      tableInitData?.length &&
      !campaignsInfo.length &&
      isSingleCreativeSelected &&
      !isInitialized
    ) {
      setCampaignsInfo(tableInitData)
      setIsInitialized(true)
    }
  }, [tableInitData, campaignsInfo, isInitialized, isSingleCreativeSelected])

  const normalizedParent = React.useMemo(
    () => normalizeIOCampaignData(flatInitIoData),
    [flatInitIoData]
  )

  const initialNormalizedData = React.useMemo(() => {
    if (isSingleCreativeSelected === false) {
      return {}
    }
    const normalizedData: Record<string, NormalizedIOCampaignList> = {}
    flatInitIoData?.forEach((item, index) => {
      const parent = normalizedParent?.[index]
      if (parent !== undefined && item.campaigns !== undefined) {
        const data = normalizeCampaignData(item.campaigns, parent)
        data?.map(val => {
          normalizedData[val.value] = val
        })
      }
    })
    return removeDeletedEntries(normalizedData)
  }, [flatInitIoData, normalizedParent, isSingleCreativeSelected])

  const disableUpdate =
    compareWithoutOrder(tableInitData, campaignsInfo) || isFetching

  const getHeadingText = () => {
    if (isSingleCreativeSelected) {
      return (
        <Typography component="p" sx={{ display: 'flex' }}>
          {`Choose campaign(s) where you want to assign the `}
          <TypoTooltip
            style={{ maxWidth: '450px' }}
            TypgraphyProps={{ component: 'span', weight: 'demi' }}
            sx={{ mx: 4 }}
            arrow
            placement="top"
          >
            {`${selectedCreativeData[0]?.creativeName} Creative:`}
          </TypoTooltip>
        </Typography>
      )
    }
    return (
      <Typography component="p" sx={{ display: 'flex' }}>
        {`Choose campaign(s) where you want to assign the selected `}
        <TypoTooltip
          style={{ maxWidth: '450px' }}
          TypgraphyProps={{ component: 'span', weight: 'demi' }}
          sx={{ mx: 4 }}
          arrow
          placement="top"
        >
          {`${selectedCreativeData.length} Creatives:`}
        </TypoTooltip>
      </Typography>
    )
  }

  const assignToCampaignMutation = useAssignToCampaign({
    onSuccess: response => {
      enqueueSnackbar(response.message ?? 'Changes saved successfully', {
        variant: 'success',
      })
      queryClient.resetQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === 'getAllCreativeList' &&
            query.queryKey?.[0]?.platformCreativeTypeIds?.includes(
              selectedCreativeData[0]?.platformCreativeTypeId
            )
          )
        },
      })
      onSuccess?.(response)
    },
    onError: error => {
      enqueueSnackbar(
        error?.errorObjects?.[0].error ?? 'Something went wrong',
        {
          variant: 'error',
        }
      )
      onError?.(error)
    },
    onSettled: () => {
      onClose?.()
      onSettle?.()
    },
  })

  const getAssignToCampaignRequest = (): AssignToCampaignRequest => {
    const campaignIds = campaignsInfo.map(cmp => cmp.id)
    const campaignIdsFilterInitIds = campaignIds.filter(
      cmpId => initCampaignsIds.includes(cmpId) === false
    )

    const creativeIds = selectedCreativeData.map(
      creative => creative.creativeId
    )
    const removedIds: number[] = []

    if (isSingleCreativeSelected) {
      initCampaignsIds.forEach(id => {
        if (campaignIds.includes(id) === false) {
          removedIds.push(id)
        }
      })
    }
    return {
      creativeIds: creativeIds,
      ...(campaignIdsFilterInitIds.length
        ? { assignedCampaignIds: campaignIdsFilterInitIds }
        : {}),
      ...(removedIds.length ? { removedCampaignIds: removedIds } : {}),
    }
  }

  const { isFetching: isFetchingCreativeCmpDetail } = useCreativeCampaignDetail(
    {
      creativeId: selectedCreativeData[0]?.creativeId as number,
      includeModelingInfo: true,
      options: {
        enabled:
          Boolean(selectedCreativeData[0]?.creativeId) &&
          isSingleCreativeSelected,
      },
    }
  )

  return (
    <Dialog
      open
      maxWidth="xl"
      fullWidth
      sx={{ height: 100 }}
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
        onClose={onClose}
      >
        <Typography weight="demi">{title}</Typography>
      </DialogTitle>
      {isFetching || isFetchingCreativeCmpDetail ? (
        <ScreenLoader />
      ) : (
        <AssignToCampaignsDialogContent
          selectedCreativeData={selectedCreativeData}
          campaignsInfo={campaignsInfo}
          setCampaignsInfo={setCampaignsInfo}
          tableInitData={tableInitData}
          initialNormalizedData={initialNormalizedData}
          headingText={getHeadingText()}
        />
      )}
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={assignToCampaignMutation.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={assignToCampaignMutation.isLoading}
          disabled={disableUpdate}
          onClick={() => {
            assignToCampaignMutation.mutate(getAssignToCampaignRequest())
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
