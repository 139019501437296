import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@applift/factor'
import { Add, Delete, InfoCircle } from '@applift/icons'
import { useStore } from '@tanstack/react-store'
import {
  MAX_TITLE_DESCRIPTION_VARIATION,
  NATIVE_TITLE_CHAR_LIMIT,
  TWITTER_NATIVE_TITLE_LIMIT,
} from '../../../../../../constants'
import { nativeTitlesValidationFn } from '../../../../../../utils'
import { NativeCreationFormType } from '../../CreateWrapper'

export interface Props {
  form: NativeCreationFormType
}

export const Title = (props: Props) => {
  const { form } = props

  const { titles } = useStore(form.store, state => state.values)

  const showAddTitleVariation = titles.length < MAX_TITLE_DESCRIPTION_VARIATION
  const disableAddTitleVariation = Boolean(titles[titles.length - 1]) === false

  return (
    <form.Field name="titles" mode="array">
      {titleValues => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          {titleValues.state.value.map((_, idx) => (
            <form.Field
              name={`titles[${idx}]`}
              key={`titles[${idx}]`}
              validators={{
                onChange: ({ value }) => nativeTitlesValidationFn(value),
              }}
            >
              {field => {
                const titleValue = field.state.value.trim()
                const showTwitterWarning =
                  titleValue.length > TWITTER_NATIVE_TITLE_LIMIT &&
                  Boolean(field.state.meta.errors.length) == false
                let helperText: React.ReactNode | string = ''
                if (showTwitterWarning) {
                  helperText = (
                    <Typography
                      sx={{
                        textColor: 'orange-300',
                        mt: 2,
                        ml: 4,
                        display: showTwitterWarning ? 'block' : 'none',
                      }}
                    >
                      {`X (Twitter) supports ${TWITTER_NATIVE_TITLE_LIMIT} characters only`}
                    </Typography>
                  )
                }
                if (field.state.meta.errors[0]) {
                  helperText = field.state.meta.errors[0]
                }

                return (
                  <Box key={`title_${idx}`} sx={{ display: 'flex', gap: 4 }}>
                    <TextField
                      label={<Label fieldIndex={idx + 1} />}
                      variant="outlinedDash"
                      fullWidth
                      placeholder="Enter Title"
                      value={field.state.value}
                      required
                      InputLabelProps={{
                        renderSuffix: (
                          <>
                            {field.state.value.length}/{NATIVE_TITLE_CHAR_LIMIT}
                          </>
                        ),
                      }}
                      onChange={(e: any) => {
                        const value = e.target.value
                        if (value?.trim()?.length === 0) {
                          field.handleChange(value.trim())
                          return
                        }
                        field.handleChange(e.target.value)
                      }}
                      error={Boolean(field.state.meta.errors.length)}
                      helperText={helperText}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        onClick={() => titleValues.removeValue(idx)}
                        size="small"
                        color="secondary"
                        sx={{
                          display:
                            titleValues.state.value.length > 1
                              ? 'block'
                              : 'none',
                          alignSelf: helperText ? undefined : 'end',
                        }}
                      >
                        <Delete fontSize={16} />
                      </IconButton>
                    </Box>
                  </Box>
                )
              }}
            </form.Field>
          ))}
          <Box
            sx={{
              gap: 4,
              ml: 12,
              display: showAddTitleVariation ? 'flex' : 'none',
              alignItems: 'center',
            }}
            onClick={() => form.pushFieldValue('titles', '')}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              style={{
                opacity: disableAddTitleVariation ? 0.4 : 1,
                cursor: 'pointer',
                pointerEvents: disableAddTitleVariation ? 'none' : 'auto',
              }}
            >
              <Add sx={{ textColor: 'primary-600', mr: 2 }} fontSize={14} />
              <Typography
                sx={{
                  textColor: 'primary-600',
                  textWeight: 'demi',
                }}
              >
                Add Variation
              </Typography>
            </Box>
            <Typography sx={{ textColor: 'neutral-500' }}>
              ({titles.length}/{MAX_TITLE_DESCRIPTION_VARIATION})
            </Typography>
          </Box>
        </Box>
      )}
    </form.Field>
  )
}

interface LabelProps {
  fieldIndex: number
}
const Label = (props: LabelProps) => {
  const { fieldIndex } = props

  return (
    <Tooltip
      title="Recommended character limits: 25, 90, or 140, with X(Twitter) supporting up to 25 characters"
      placement="top"
      arrow
    >
      <Box
        Component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="label">Title {fieldIndex}</Typography>
        <InfoCircle fontSize={14} sx={{ textColor: 'neutral-400' }} />
      </Box>
    </Tooltip>
  )
}
