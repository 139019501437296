import * as React from 'react'
import {
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  sx,
} from '@applift/factor'
import { useForm } from '@tanstack/react-form'
import { HTMLPreview, HTMLValidator } from '@applift/platform-creatives'
import { Code, Delete, InfoCircle } from '@applift/icons'

import {
  creativeNameValidationFn,
  creativeThirdPartyIDValidationFn,
  htmlWidthValidationFn,
  htmlHeightValidationFn,
  isValidURL,
} from '../../../../../utils'

import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
} from '../../../../../constants'
import { CreateProps } from './Create'

import styles from './index.module.scss'

const FieldLabelWithTooltip = ({
  label,
  tooltip,
}: {
  label: string
  tooltip: string
}) => {
  return (
    <Box
      Component={'span'}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant="label">{label}</Typography>
      <Tooltip title={tooltip} placement="top" arrow>
        <InfoCircle
          style={{
            width: '1.2em',
            height: '1.2em',
            transform: 'translateY(-1px)',
          }}
          sx={{
            textColor: 'neutral-400',
          }}
        />
      </Tooltip>
    </Box>
  )
}

export interface HTMLCardProps
  extends Pick<CreateProps, 'onChange' | 'onRemove'> {
  /**
   * Temp creative id generated
   */
  id: string
  /**
   * Need to pass HTML Code
   */
  htmlCode?: string
  /**
   * Width of HTML iframe
   */
  width?: number
  /**
   * Height of HTML iframe
   */
  height?: number
  /**
   * Name of the creative
   */
  name?: string
  /**
   * 3rd Party ID of the creative
   */
  thirdPartyID?: string
  /**
   * Click URL of the creative
   */
  clickURL?: string
  /**
   * Warning show warning state of the card
   * @default false
   */
  warning?: boolean
}

export const HTMLCard = (props: HTMLCardProps) => {
  const {
    id,
    htmlCode = '',
    width,
    height,
    name = '',
    thirdPartyID = '',
    clickURL = '',
    warning = false,
    onChange,
    onRemove,
  } = props

  const form = useForm({
    defaultValues: {
      id: id,
      warning: warning,
      htmlCode: htmlCode,
      name: name,
      thirdPartyID: thirdPartyID,
      clickURL: clickURL,
      width: width,
      height: height,
    },
  })

  React.useEffect(() => {
    form.validateAllFields('change').then(() => {
      const formData = {
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [form, onChange])

  React.useEffect(() => {
    form.store.setState(state => ({
      ...state,
      values: {
        ...state.values,
        clickURL: clickURL,
      },
    }))
    form.validateAllFields('change').then(() => {
      const formData = {
        ...form.state.values,
        hasError: form.state.canSubmit === false,
      }
      onChange?.([formData])
    })
  }, [clickURL, form, onChange])

  return (
    <form
      style={{ height: '100%' }}
      onChange={() => {
        const formData = {
          ...form.state.values,
          hasError: form.state.canSubmit === false,
        }
        onChange?.([formData])
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
          border: 1,
          borderRadius: 4,
          p: 16,
          pb: 24,
          gap: 16,
          alignItems: 'center',
          position: 'relative',
          borderColor: warning ? 'warning-200' : 'neutral-100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 8,
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              onRemove?.(id)
            }}
          >
            <Delete />
          </IconButton>
          {htmlCode === '' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: 'auto',
                border: 1,
                borderRadius: 4,
                bgColor: 'neutral-75',
              }}
              style={{ aspectRatio: '4 / 3' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: 75,
                  gap: 8,
                }}
              >
                <Code fontSize={24} sx={{ textColor: 'neutral-400' }} />
                <Typography sx={{ textColor: 'neutral-600' }}>
                  HTML preview will be visible here
                </Typography>
              </Box>
            </Box>
          ) : (
            <HTMLPreview
              htmlCode={htmlCode}
              title="HTML Preview"
              classes={{
                root: `${styles.previewContainer} ${sx({
                  display: 'flex',
                  width: 100,
                  height: 'auto',
                  overflow: 'auto',
                  border: 1,
                  borderRadius: 4,
                  bgColor: 'neutral-75',
                })}`,
              }}
              onEstimateDimensions={dims => {
                form.store.setState(state => ({
                  ...state,
                  values: {
                    ...state.values,
                    height: dims.height,
                    width: dims.width,
                  },
                }))
                form.validateAllFields('change').then(() => {
                  const formData = {
                    ...form.state.values,
                    hasError: form.state.canSubmit === false,
                  }
                  onChange?.([formData])
                })
              }}
            />
          )}
        </Box>
        <Divider color="secondary" sx={{ width: 100 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            gap: 16,
          }}
        >
          <form.Field
            name="htmlCode"
            validators={{
              onChange: ({ value }) => {
                if (!value) {
                  return 'HTML code is required'
                }
                const res = HTMLValidator.valid(value)
                if (!res) {
                  return 'Invalid HTML Code'
                }
                return undefined
              },
            }}
          >
            {field => (
              <TextField
                label="HTML Code"
                variant="outlinedDash"
                placeholder="Enter HTML Code"
                required
                value={field.state.value}
                onChange={e => {
                  field.handleChange(e.target.value)
                }}
                multiline
                rows={4}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="name"
            validators={{
              onChange: ({ value }) => creativeNameValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label="Name"
                variant="outlinedDash"
                placeholder="Enter Name"
                value={field.state.value}
                required
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value!.length}/{CREATIVE_NAME_LIMIT}
                    </>
                  ),
                }}
                onChange={(e: any) => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="thirdPartyID"
            validators={{
              onChange: ({ value }) => creativeThirdPartyIDValidationFn(value),
            }}
          >
            {field => (
              <TextField
                label={
                  <Box
                    Component={'span'}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography variant="label">3rd Party ID</Typography>
                    <Tooltip
                      title="The ID assigned to a creative on another platform, used to reconcile performance data with IQM."
                      placement="top"
                      arrow
                    >
                      <InfoCircle
                        style={{
                          width: '1.2em',
                          height: '1.2em',
                          transform: 'translateY(-1px)',
                        }}
                        sx={{
                          textColor: 'neutral-400',
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                variant="outlinedDash"
                placeholder="Enter 3rd Party ID"
                value={field.state.value}
                InputLabelProps={{
                  renderSuffix: (
                    <>
                      {field.state.value.length}/{CREATIVE_THIRD_PARTY_ID_LIMIT}
                    </>
                  ),
                }}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <form.Field
            name="clickURL"
            validators={{
              onChange: ({ value }) => {
                if (value.trim() && !isValidURL(value)) {
                  return 'Enter a valid URL'
                }
                return undefined
              },
            }}
          >
            {field => (
              <TextField
                label={
                  <FieldLabelWithTooltip
                    label="Click URL"
                    tooltip="URL that will open when someone clicks on your ad"
                  />
                }
                variant="outlinedDash"
                placeholder="Enter Click URL"
                value={field.state.value}
                onChange={e => {
                  const value = e.target.value
                  if (value.trim().length === 0) {
                    field.handleChange(value.trim())
                    return
                  }
                  field.handleChange(e.target.value)
                }}
                error={Boolean(field.state.meta.errors.length)}
                helperText={field.state.meta.errors[0] ?? ''}
              />
            )}
          </form.Field>
          <Box sx={{ display: 'flex', gap: 16 }}>
            <form.Field
              name="width"
              validators={{
                onChange: ({ value }) => htmlWidthValidationFn(value),
              }}
            >
              {field => (
                <TextField
                  label={
                    <Box
                      Component={'span'}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Typography variant="label">Width</Typography>
                      <Tooltip
                        arrow
                        title="Width must be between 120-2000"
                        placement="top"
                      >
                        <InfoCircle
                          style={{
                            width: '1.2em',
                            height: '1.2em',
                            transform: 'translateY(-1px)',
                          }}
                          sx={{
                            textColor: 'neutral-400',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  }
                  inputProps={{ min: '0' }}
                  style={{ flex: 1 }}
                  type="number"
                  required
                  variant="outlinedDash"
                  placeholder="Enter Width"
                  value={field.state.value}
                  onChange={e => {
                    const value =
                      e.target.value === '' ? undefined : Number(e.target.value)
                    field.handleChange(Number(value))
                  }}
                  error={Boolean(field.state.meta.errors.length)}
                  helperText={field.state.meta.errors[0] ?? ''}
                />
              )}
            </form.Field>
            <form.Field
              name="height"
              validators={{
                onChange: ({ value }) => htmlHeightValidationFn(value),
              }}
            >
              {field => (
                <TextField
                  label={
                    <Box
                      Component={'span'}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Typography variant="label">Height</Typography>
                      <Tooltip
                        arrow
                        title="Height must be between 50-1800"
                        placement="top"
                      >
                        <InfoCircle
                          style={{
                            width: '1.2em',
                            height: '1.2em',
                            transform: 'translateY(-1px)',
                          }}
                          sx={{
                            textColor: 'neutral-400',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  }
                  required
                  style={{ flex: 1 }}
                  inputProps={{ min: '0' }}
                  type="number"
                  variant="outlinedDash"
                  placeholder="Enter Height"
                  value={field.state.value}
                  onChange={e => {
                    const value =
                      e.target.value === '' ? undefined : Number(e.target.value)
                    field.handleChange(Number(value))
                  }}
                  error={Boolean(field.state.meta.errors.length)}
                  helperText={field.state.meta.errors[0] ?? ''}
                />
              )}
            </form.Field>
          </Box>
        </Box>
      </Box>
    </form>
  )
}
