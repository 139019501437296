import { HTML_CREATIVE_ID } from '../../../constants'
import { CreateCreativeRequest } from '../../../api'
import { HTMLCreativeGenerationData } from '../../../models'

export function transformHtmlStateToApiRequest(
  state: Map<string, HTMLCreativeGenerationData>
): CreateCreativeRequest {
  const request = {} as CreateCreativeRequest

  for (const [key, value] of state.entries()) {
    // @ts-ignore
    request[key] = {
      creativeName: value.name?.trim() as string,
      ...(value.thirdPartyID?.trim()
        ? { externalCreativeId: value.thirdPartyID?.trim() }
        : {}),
      platformCreativeTypeId: HTML_CREATIVE_ID,
      clickUrl: value.clickURL?.trim() as string,
      creativeSource: value.htmlCode,
      creativeSourceType: 'HTML',
      htmlDetails: {
        creativeWidth: value.width ? value.width : 0,
        creativeHeight: value.height ? value.height : 0,
      },
    }
  }

  return request
}
