import { Box, Tooltip } from '@applift/factor'
import { ColumnDef } from '@applift/datagrid'
import { getLocalTimeZone } from '@internationalized/date'

import { Creative } from '../../../models'
import {
  CreativeNameCell,
  DateCell,
  RawDataCell,
  CreativePreviewCell,
  CreativeSizeCell,
  CreativeStatusCell,
  CampaignCountCell,
  CreativeIdCell,
} from '../../../components'
import { EditableCell } from '../../../components/CellTypes/EditableCell'
import {
  clickUrlValidationFn,
  isImageCreative,
  isNativeImageCreatve,
  isValidURL,
} from '../../../utils'
import {
  CREATIVE_NAME_LIMIT,
  CREATIVE_THIRD_PARTY_ID_LIMIT,
  MANDATORY_CLICK_URL_SOURCE_TYPE,
} from '../../../constants'

export const allCreativesColDef: ColumnDef<Creative>[] = [
  {
    accessorKey: 'creativeId',
    id: 'creativeId',
    header: 'ID',
    enableResizing: false,
    meta: {
      description: 'Unique system-generated number assigned to each Creative',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <CreativeIdCell rowData={props.row.original} action={props.onAction} />
    ),
    size: 70,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Creative Preview Thumbnail',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <CreativePreviewCell
        creative={props.row.original}
        action={props.onAction}
      />
    ),
  },
  {
    accessorKey: 'creativeName',
    id: 'creativeName',
    header: 'Creative Name',
    meta: {
      editable: true,
      preProcessEditCellProps: params => {
        const value = params?.props?.value
        let error = ''

        if (
          !value ||
          value.trimStart().trimEnd().length > CREATIVE_NAME_LIMIT
        ) {
          error = !value
            ? 'Name is required'
            : `${CREATIVE_NAME_LIMIT} character limit for name is exceeded`
        }

        return {
          ...params.props,
          error,
        }
      },
      renderEditCell: EditableCell,
    },
    cell: props => (
      <>
        <CreativeNameCell
          creativeTypeId={props.row.original.platformCreativeTypeId}
          label={props.row.original.creativeName}
        />
      </>
    ),
    size: 330,
  },
  {
    accessorKey: 'creativeStatusId',
    id: 'creativeStatusId',
    header: 'Status',
    size: 120,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Creative Status',
    },
    cell: props => {
      return (
        <CreativeStatusCell statusId={props.row.original.creativeStatusId} />
      )
    },
  },
  {
    accessorKey: 'externalCreativeId',
    id: 'externalCreativeId',
    header: '3rd Party ID',
    meta: {
      editable: true,
      preProcessEditCellProps: params => {
        const value = params?.props?.value
        let error = ''

        if (value.length > CREATIVE_THIRD_PARTY_ID_LIMIT) {
          error = '200 character limit for 3rd party ID is exceeded'
        }

        return {
          ...params.props,
          error,
        }
      },
      renderEditCell: EditableCell,
    },
    size: 180,
    maxSize: 500,
    minSize: 100,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },

  {
    accessorKey: 'clickUrl',
    id: 'clickUrl',
    header: 'Click URL',
    enableSorting: false,
    meta: {
      editable: true,
      preProcessEditCellProps: params => {
        const value = params?.props?.value
        const isClickUrlMandatory = MANDATORY_CLICK_URL_SOURCE_TYPE.includes(
          params?.ctx?.row?.original?.creativeSourceType
        )
        const error = clickUrlValidationFn(value, isClickUrlMandatory)

        return {
          ...params.props,
          error,
        }
      },
      renderEditCell: EditableCell,
    },
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 180,
    maxSize: 500,
    minSize: 100,
  },
  {
    accessorKey: 'pixelUrl',
    id: 'pixelUrl',
    header: 'Pixel URL',
    enableSorting: false,
    meta: {
      editable: true,
      preProcessEditCellProps: params => {
        const value = params?.props?.value
        let error = ''
        if (value?.trim() && !isValidURL(value)) {
          error = 'Pixel URL must be a valid url address'
        }
        return {
          ...params.props,
          error,
        }
      },
      renderEditCell: EditableCell,
    },
    cell: props => {
      const row = props.row.original
      const isImage = isImageCreative(row)
      const isNativeImage = isNativeImageCreatve(row)
      let tooltip = ''

      if (isImage === false && isNativeImage === false) {
        tooltip = 'Only editable for Image and Native Image'
      }

      return (
        <Tooltip
          enterDelay={500}
          enterNextDelay={500}
          title={tooltip}
          arrow
          placement="bottom"
        >
          <Box sx={{ width: 100 }}>
            <RawDataCell
              value={props.row.original?.pixelUrl ?? ''}
              textTypographyProps={{ sx: { display: 'block' } }}
            />
          </Box>
        </Tooltip>
      )
    },
    size: 180,
    maxSize: 500,
    minSize: 100,
  },
  {
    accessorKey: 'createdAt',
    id: 'createdAt',
    header: 'Created',
    meta: {
      align: 'right',
    },
    cell: props => {
      const orgTimezone =
        (props?.table?.options?.meta as any)?.organizationTimezoneName ??
        getLocalTimeZone()

      return (
        <DateCell date={props.row.original.createdAt} timezone={orgTimezone} />
      )
    },

    size: 200,
    maxSize: 250,
    minSize: 100,
  },
  {
    accessorKey: 'duration',
    id: 'duration',
    header: 'Size',
    enableSorting: false,
    meta: {
      description:
        'Creative dimensions in pixels or the duration of the video or audio ad',
      align: 'right',
    },
    cell: props => <CreativeSizeCell creative={props.row.original} />,
    size: 120,
    maxSize: 250,
    minSize: 80,
  },
  {
    accessorKey: 'campaignCount',
    id: 'campaignCount',
    header: 'Campaigns',
    enableSorting: false,
    meta: {
      align: 'right',
    },
    cell: props => (
      <CampaignCountCell action={props.onAction} rowData={props.row.original} />
    ),
    size: 120,
    maxSize: 250,
    minSize: 80,
  },
]

export const videoCreativesColDef = allCreativesColDef.filter(
  item => item.id !== 'pixelUrl'
)

export const audioCreativesColDef = videoCreativesColDef

export const htmlCreativesColDef = videoCreativesColDef

export const nativeCreativesColDef = allCreativesColDef

export const imageCreativesColDef = allCreativesColDef
