import { Box, TypoTooltip, TypographyProps } from '@applift/factor'
import { useCreativeStatuses } from '../../hooks'
import { CreativeStatusIcon } from '../../modules/common/Mappings/CreativeStatusIcon'

interface CreativeStatusCellType {
  statusId?: number
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CreativeStatusCell = ({
  statusId = -1,
  textTypographyProps,
}: CreativeStatusCellType) => {
  const { data: creativeStatusList } = useCreativeStatuses({
    displayAllStatusOption: true,
  })

  const creativeStatusIdMapping = creativeStatusList?.creativeStatusMappingById

  const creativeStatusName = creativeStatusIdMapping?.[statusId]?.name
  const creativeStatusDisplayName =
    creativeStatusIdMapping?.[statusId]?.displayName

  const creativeStatusIcon = (
    <CreativeStatusIcon name={creativeStatusName} sx={{ fontSize: 20 }} />
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 4,
          gap: 2,
        }}
      >
        {creativeStatusIcon ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 4,
              gap: 4,
            }}
          >
            {creativeStatusIcon}
          </Box>
        ) : null}
      </Box>

      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
        }}
      >
        {creativeStatusDisplayName}
      </TypoTooltip>
    </Box>
  )
}
