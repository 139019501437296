import React from 'react'

import { Close, Search } from '@applift/icons'
import { _debounce, IconButton, TextField } from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'

import { DEBOUNCE_TIME_IN_MILLISECONDS } from '../../../../constants'

export interface SearchFieldProps {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const SearchField = (props: SearchFieldProps) => {
  const { search, setSearch, setRowSelection } = props
  const [localSearch, setLocalSearch] = React.useState(search)

  // eslint-disable-next-line
  const setDebouncedSearch = React.useCallback(
    _debounce(
      (val: string) => setSearch(val),
      DEBOUNCE_TIME_IN_MILLISECONDS,
      {}
    ),
    [setSearch]
  )

  React.useEffect(
    () => setDebouncedSearch(localSearch),
    [localSearch, setDebouncedSearch]
  )

  return (
    <TextField
      value={localSearch}
      variant="outlinedDash"
      onChange={e => {
        setLocalSearch(e.target.value)
        setRowSelection({})
      }}
      placeholder="Search by ID, Name, 3rd Party ID"
      InputProps={{
        startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
        endAdornment:
          search?.length > 0 ? (
            <IconButton
              onClick={() => {
                setLocalSearch('')
                setRowSelection({})
              }}
              color="secondary"
              size="small"
            >
              <Close fontSize={16} />
            </IconButton>
          ) : null,
      }}
      style={{ width: 270 }}
    />
  )
}
