import { enqueueSnackbar } from '@applift/factor'
import {
  CREATIVE_NAME_LIMIT,
  CSV_UPLOAD_ERROR_MESSAGES,
  NATIVE_BRAND_ICON_ALLOWED_DIMENSIONS,
  NATIVE_BRAND_NAME_CHAR_LIMIT,
  NATIVE_CTA_CHAR_LIMIT,
  NATIVE_DESCRIPTION__CHAR_LIMIT,
  NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS,
  NATIVE_TITLE_CHAR_LIMIT,
  NATIVE_VIDEO_DURATION_MAX,
  NATIVE_VIDEO_DURATION_MIN,
} from '../../../../../constants'
import {
  CreativeRow,
  NativeBrandIcon,
  NativeCSVColKey,
  NativeImage,
  NativeVideo,
} from '../../../../../models'
import { getVideoMetaData, toLowerCase } from '../../../../../utils'
import {
  getValidURL,
  validateImageDimensions,
  validateXmlUrl,
} from '../../CreateWrapper/Upload/Dropzone/utils'

// Individual Row Validation
export const validateRow = async (
  row: CreativeRow,
  uniqueCreativeId: string
) => {
  const errors: string[] = []
  const imageObject: NativeImage[] = []
  const videoObject: NativeVideo[] = []
  const brandIcon: NativeBrandIcon[] = []

  // Creative Name Validation
  if (
    !row[NativeCSVColKey.CREATIVE_AD_NAME] ||
    row[NativeCSVColKey.CREATIVE_AD_NAME].length > CREATIVE_NAME_LIMIT
  ) {
    errors.push('Creative Name is invalid')
  }

  // Type Validation
  const normalizedType = toLowerCase(row[NativeCSVColKey.CREATIVE_AD_TYPE])
  if (!['display', 'video'].includes(normalizedType)) {
    errors.push('Creative Type is invalid')
  }

  if (
    row[NativeCSVColKey.BRAND_NAME] &&
    row[NativeCSVColKey.BRAND_NAME].length > NATIVE_BRAND_NAME_CHAR_LIMIT
  ) {
    errors.push('Brand Name is invalid')
  }

  const clickURL = getValidURL(row[NativeCSVColKey.CTA_URL] ?? '')
  if (!clickURL) {
    errors.push('Click URL is invalid')
  }

  if (row[NativeCSVColKey.PIXEL_URL]) {
    const pixelURL = getValidURL(row[NativeCSVColKey.PIXEL_URL] ?? '')
    if (!pixelURL) {
      errors.push('Pixel URL is invalid')
    }
  }

  const ctaText = row[NativeCSVColKey.CTA_TEXT] ?? ''
  if (ctaText.length > NATIVE_CTA_CHAR_LIMIT) {
    errors.push('CTA text is invalid')
  }

  const brandIconURL = getValidURL(row[NativeCSVColKey.BRAND_ICON_URL] ?? '')
  if (brandIconURL) {
    const brandIconDimensions = await validateImageDimensions(
      brandIconURL,
      NATIVE_BRAND_ICON_ALLOWED_DIMENSIONS
    )
      .then((image: HTMLImageElement) => ({
        height: image.naturalHeight,
        width: image.naturalWidth,
      }))
      .catch(() => undefined)
    if (brandIconDimensions?.height && brandIconDimensions?.width) {
      brandIcon.push({
        src: brandIconURL,
        height: brandIconDimensions.height,
        width: brandIconDimensions.width,
        name: '',
        parentId: uniqueCreativeId,
      })
    } else {
      errors.push('Brand icon dimensions is incorrect')
    }
  }
  // Image URL Validation
  const imageUrl = getValidURL(row[NativeCSVColKey.CREATIVE_AD_IMAGE_URL] ?? '')
  if (!imageUrl) {
    errors.push('Image URL is missing')
  } else if (imageUrl) {
    const imageDimensions = await validateImageDimensions(
      imageUrl,
      NATIVE_IMAGE_URL_ALLOWED_DIMENSIONS
    )
      .then((image: HTMLImageElement) => ({
        height: image.naturalHeight,
        width: image.naturalWidth,
      }))
      .catch(() => undefined)
    if (imageDimensions?.height && imageDimensions?.width) {
      imageObject.push({
        src: imageUrl,
        height: imageDimensions.height,
        width: imageDimensions.width,
        name: '',
        temporaryMediaId: crypto.randomUUID(),
        parentId: uniqueCreativeId,
      })
    } else {
      errors.push('Image dimension is incorrect')
    }
  }

  if (
    !row[NativeCSVColKey.TITLE] ||
    row[NativeCSVColKey.TITLE].length > NATIVE_TITLE_CHAR_LIMIT
  ) {
    errors.push('Title is invalid')
  }

  if (
    !row[NativeCSVColKey.DESCRIPTION] ||
    row[NativeCSVColKey.DESCRIPTION].length > NATIVE_DESCRIPTION__CHAR_LIMIT
  ) {
    errors.push('Description is invalid')
  }

  // Video-specific Validations
  if (normalizedType === 'video') {
    const validCreative = await validateXmlUrl(
      row[NativeCSVColKey.VAST_URL] ?? ''
    )
    const videoUrl = validCreative?.mediaFiles?.[0]?.fileURL
    const metaData = await getVideoMetaData({ URL: videoUrl })
    if (!videoUrl) {
      errors.push('Invalid VAST URL')
    } else {
      if (
        validCreative.duration &&
        validCreative.duration <= NATIVE_VIDEO_DURATION_MAX &&
        validCreative.duration >= NATIVE_VIDEO_DURATION_MIN
      ) {
        videoObject.push({
          src: videoUrl,
          duration: validCreative.duration,
          parentId: uniqueCreativeId,
          name: '',
          temporaryMediaId: crypto.randomUUID(),
          creativeSource: '',
          creativeSourceURL: row[NativeCSVColKey.VAST_URL] ?? '',
          height: metaData?.height,
          width: metaData?.width,
        })
      } else {
        errors.push('Duration of video must be between 15s to 120s')
      }
    }
  }

  // Additional detailed validations can be added here

  return { errors, imageObject, videoObject, brandIcon }
}

export const validateFilePreProcessing = (fileList: FileList | File[]) => {
  if (!fileList?.length) {
    enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.UPLOAD_FAILED, {
      variant: 'error',
    })
    return true
  }

  if (fileList.length > 1) {
    enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.TOO_MANY_FILES, {
      variant: 'error',
    })
    return true
  }

  const file = fileList[0] as File
  const fileExtension = file.name.split('.').pop()?.toLowerCase()

  if (fileExtension !== 'csv') {
    enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.WRONG_FILE_EXTENSION, {
      variant: 'error',
    })
    return true
  }

  if (!file.size) {
    enqueueSnackbar(CSV_UPLOAD_ERROR_MESSAGES.EMPTY_FILE, { variant: 'error' })
    return true
  }

  return false
}
