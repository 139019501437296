import { GridActionCellParams } from '@applift/datagrid'
import { Creative } from '../../../models'
import { ActionMetaData } from '../CreativeTableActions'

export function generateRowUpdateAction(
  newRow: Creative,
  oldRow: Creative
): GridActionCellParams<any, ActionMetaData> | undefined {
  const allKeys = Object.keys(newRow)
  const modifiedKeys = allKeys.filter(
    key => newRow[key as keyof Creative] !== oldRow[key as keyof Creative]
  )
  if (modifiedKeys.includes('clickUrl')) {
    return {
      actionName: 'EDIT_CLICK_URL',
      metaData: { id: newRow.creativeId, data: newRow },
    }
  }
  if (modifiedKeys.includes('creativeName')) {
    return {
      actionName: 'EDIT_CREATIVE_NAME',
      metaData: {
        id: newRow.creativeId,
        data: newRow,
        params: { creativeName: newRow.creativeName },
      },
    }
  }
  if (modifiedKeys.includes('externalCreativeId')) {
    return {
      actionName: 'EDIT_THIRD_PARTY_ID',
      metaData: {
        id: newRow.creativeId,
        data: newRow,
        params: { externalCreativeId: newRow.externalCreativeId },
      },
    }
  }
  if (modifiedKeys.includes('pixelUrl')) {
    return {
      actionName: 'EDIT_PIXEL_URL',
      metaData: {
        id: newRow.creativeId,
        data: newRow,
        params: { pixelUrl: newRow.pixelUrl },
      },
    }
  }
  return undefined
}
