import React from 'react'
import {
  DialogContent,
  Container,
  Paper,
  Row,
  Col,
  Box,
  sx,
  Typography,
} from '@applift/factor'
import {
  SelectIOCampaignApiRefType,
  NormalizedIOCampaignList,
  SelectIOCampaignList,
  useAppContext,
} from '@applift/platform'
import {
  DataGrid,
  SortingState,
  useGridApiRef,
  getSortedRowModel,
  GridActionCellParams,
} from '@applift/datagrid'
import { Warning } from '@applift/illustrations'

import { colDef } from './CampaignInfogrid/colDef'
import { CampaignInfoType, Creative } from '../../../models'
import {
  CAMPAIGN_TYPE_BY_NAME,
  EMPTY_CREATIVE_TYPE_ID,
} from '../../../constants'
import { NEGLECTED_CAMPAIGN_STATUS } from './helper'
import { useCreativeCampaignDetail, useCreativeStatuses } from '../../../hooks'
import { SemanticError } from '@applift/icons'

interface AssignToCampaignsDialogContentProps {
  campaignsInfo: CampaignInfoType[]
  setCampaignsInfo: React.Dispatch<React.SetStateAction<CampaignInfoType[]>>
  tableInitData: CampaignInfoType[]
  initialNormalizedData: any
  headingText?: JSX.Element
  selectedCreativeData: Creative[]
}

export interface TableMetaData {
  isRejectedCreativeSelected: boolean
  isDeletedCreativeSelected: boolean
}

export const AssignToCampaignsDialogContent = (
  props: AssignToCampaignsDialogContentProps
) => {
  const {
    selectedCreativeData,
    campaignsInfo,
    setCampaignsInfo,
    initialNormalizedData,
    headingText,
    tableInitData,
  } = props
  const [sorting, setSortinig] = React.useState<SortingState>([
    { id: 'id', desc: true },
  ])

  const initCampaignId = React.useMemo(() => {
    return tableInitData.map(cmp => cmp.id)
  }, [tableInitData])

  const creativeTypeId = React.useMemo(
    () => selectedCreativeData[0]?.platformCreativeTypeId,
    [selectedCreativeData]
  )
  const isSingleCreativeSelected = React.useMemo(
    () => selectedCreativeData.length === 1,
    [selectedCreativeData]
  )

  const { data: creativeCampaignDetail } = useCreativeCampaignDetail({
    creativeId: selectedCreativeData[0]?.creativeId as number,
    includeModelingInfo: true,
    options: {
      enabled:
        Boolean(selectedCreativeData[0]?.creativeId) &&
        isSingleCreativeSelected,
    },
  })

  const { data: creativeStatusType } = useCreativeStatuses()
  const ctx = useAppContext()
  const owId = ctx.appMeta.loggedInOwId

  const apiRef = React.useRef<SelectIOCampaignApiRefType>()
  const tableApiRef = useGridApiRef()

  const isDeletedCreativeSelected = React.useMemo(() => {
    return selectedCreativeData.some(
      creative =>
        creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['deleted']?.id
    )
  }, [selectedCreativeData, creativeStatusType])

  const isRejectedCreativeSelected = React.useMemo(() => {
    return selectedCreativeData.some(
      creative =>
        creative.creativeStatusId ===
        creativeStatusType?.creativeStatusMappingByName['rejected']?.id
    )
  }, [selectedCreativeData, creativeStatusType])

  const preSelectedRejectedStatusCampaign = React.useMemo(
    () =>
      tableInitData.filter(data =>
        NEGLECTED_CAMPAIGN_STATUS.includes(data.status)
      ),
    [tableInitData]
  )

  const setCampaignsInfoWrapper = React.useCallback(
    (value: Record<string, NormalizedIOCampaignList>, reason: any) => {
      if (reason === 'userAction') {
        const campaign = Object.values(value).map(data => ({
          id: Number(data?.value),
          name: data?.label || '',
          creativeTypeId: Number(data?.creativeTypeId),
          status: data?.status || '',
          ioId: Number(data?.parent?.value),
          ioName: data?.parent?.label || '',
          ioBudgetTypeId: Number(data?.parent?.ioBudgetTypeId),
        }))
        setCampaignsInfo([...preSelectedRejectedStatusCampaign, ...campaign])
      }
    },
    [preSelectedRejectedStatusCampaign, setCampaignsInfo]
  )

  const advanceModeledCampaign = React.useMemo(
    () =>
      creativeCampaignDetail?.data.campaignDetails.filter(
        cmpDetail => cmpDetail.isModelled
      ),
    [creativeCampaignDetail]
  )

  const campaignWithOnlyOneCreative = React.useMemo(
    () =>
      creativeCampaignDetail?.data.campaignDetails.filter(
        cmpDetail => cmpDetail.totalCreativesCount === 1
      ),
    [creativeCampaignDetail]
  )

  const optionDisable = React.useCallback(
    (row: NormalizedIOCampaignList) => {
      const isInitSelected = initCampaignId.includes(Number(row.value))
      const isDeletedOrRejectedOrExpiredCreative =
        row.status === 'rejected' ||
        row.status === 'deleted' ||
        row?.status === 'expired'

      const isAdvanceModelingApplied =
        (advanceModeledCampaign?.filter(cmp => cmp.id === Number(row.value))
          ?.length || 0) > 0
      const isOnlyAttacedCreativeNonDraft =
        (campaignWithOnlyOneCreative?.filter(
          cmp => cmp.id === Number(row.value)
        )?.length || 0) > 0 && row?.status !== 'draft'
      return (
        isInitSelected &&
        (isDeletedOrRejectedOrExpiredCreative ||
          isAdvanceModelingApplied ||
          isOnlyAttacedCreativeNonDraft)
      )
    },
    [initCampaignId, advanceModeledCampaign, campaignWithOnlyOneCreative]
  )

  const getOptionTooltip = React.useCallback(
    (row: NormalizedIOCampaignList) => {
      const isInitSelected = initCampaignId.includes(Number(row.value))
      const currentlySelected = campaignsInfo
        .map(cmp => cmp.id)
        .includes(Number(row.value))
      const isDeletedOrRejectedCreative =
        row.status === 'rejected' || row.status === 'deleted'
      const expiredCamapign = row?.status === 'expired'
      const deletedCampaign = row?.status === 'deleted'
      const isAdvanceModelingApplied =
        (advanceModeledCampaign?.filter(cmp => cmp.id === Number(row.value))
          ?.length || 0) > 0
      const isOnlyAttacedCreativeNonDraft =
        (campaignWithOnlyOneCreative?.filter(
          cmp => cmp.id === Number(row.value)
        )?.length || 0) > 0 && row?.status !== 'draft'

      if (isSingleCreativeSelected === false) {
        return ''
      }

      // For all the Below conditions Button is disabled
      if (isDeletedOrRejectedCreative) {
        return 'Creatives with Deleted or Rejected status cannot be removed.'
      }
      if (expiredCamapign || deletedCampaign) {
        return 'Creatives can’t be removed from expired or deleted campaign.'
      }
      if (isOnlyAttacedCreativeNonDraft) {
        return 'Selected creative can’t be removed as it is the only one in the campaign'
      }
      if (isAdvanceModelingApplied) {
        return 'Creatives with Advanced Modeling applied cannot be removed.'
      }

      // For this condition button is enable message in just a warning
      if (
        isInitSelected &&
        currentlySelected &&
        (row?.status?.toLowerCase() === 'running' ||
          row?.status?.toLowerCase() === 'paused')
      ) {
        return (
          <Box sx={{ display: 'flex', gap: 8 }}>
            <SemanticError color="warning" fontSize={24} />
            <Typography>
              Removing a creative from running or paused campaign may impact its
              performance.
            </Typography>
          </Box>
        )
      }

      return ''
    },
    [
      advanceModeledCampaign,
      campaignsInfo,
      campaignWithOnlyOneCreative,
      initCampaignId,
      isSingleCreativeSelected,
    ]
  )

  const actions = {
    removeRow: ({ rowId }: { rowId: number }) => {
      setCampaignsInfo(prev => prev?.filter(grp => grp.id !== rowId))

      apiRef.current?.removeSelection(String(rowId))
    },
  }

  const onAction = (params: GridActionCellParams) => {
    actions[params.actionName as keyof typeof actions](params.metaData)
  }

  return (
    <DialogContent
      dividers
      style={{ minHeight: '400px', height: 100 }}
      sx={{ display: 'flex', flexDirection: 'column', pb: 24 }}
    >
      <Container
        sx={{
          height: 100,
          display: 'flex',
          flexDirection: 'column',
          p: 0,
          pb: 24,
        }}
      >
        <Row sx={{ mb: 16 }}>
          <Col xs={12}>{headingText}</Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SelectIOCampaignList
              ref={apiRef}
              multiple={true}
              initialData={initialNormalizedData}
              useCase="basic"
              enabled
              getOptionDisabled={optionDisable}
              getOptionTooltip={getOptionTooltip}
              slotProps={{
                PaperProps: {
                  style: {
                    maxWidth: 500,
                  },
                },
              }}
              params={{
                owIds: [owId],
                status: ['running', 'pending', 'paused', 'draft'],
                campaignTypeIds: [
                  CAMPAIGN_TYPE_BY_NAME.ADVANCED,
                  CAMPAIGN_TYPE_BY_NAME.PG,
                ],
                ...(creativeTypeId
                  ? {
                      creativeTypeIds: [creativeTypeId, EMPTY_CREATIVE_TYPE_ID],
                    }
                  : {}),
              }}
              onChange={setCampaignsInfoWrapper}
              TextFieldProps={{
                label: 'Campaigns',
                disabled:
                  isRejectedCreativeSelected || isDeletedCreativeSelected,
                variant: 'outlinedDash',
                style: {
                  width: 245,
                },
              }}
            />
          </Col>
        </Row>
        <Row sx={{ flexGrow: 1 }}>
          <Col xs={12}>
            {campaignsInfo.length ? (
              <Paper
                elevation={2}
                sx={{ mt: 24, height: 100, borderRadius: 4 }}
              >
                <Box
                  sx={{ height: 100 }}
                  style={{
                    minHeight: 96,
                  }}
                >
                  <DataGrid
                    apiRef={tableApiRef}
                    onAction={onAction}
                    data={campaignsInfo}
                    columns={colDef}
                    rowCount={campaignsInfo?.length}
                    componentsProps={{
                      // @ts-ignore
                      errorOverlay: {
                        // @ts-ignore
                        subText:
                          'Oops, something went wrong. Please try again after some time.',
                        illustration: (
                          <Warning
                            sx={{
                              textColor: 'primary-500',
                              width: 100,
                              height: 'auto',
                              mb: 40,
                            }}
                          />
                        ),
                      },
                    }}
                    meta={{
                      campaignWithOnlyOneCreative,
                      isRejectedCreativeSelected,
                      isDeletedCreativeSelected,
                      advanceModeledCampaign,
                      isSingleCreativeSelected,
                      initCampaignId,
                    }}
                    hideHeader
                    hideFooter
                    rowHeight={40}
                    checkboxSelection={false}
                    overscrollBehaviorX="contain"
                    showColumnRightBorder
                    showCellRightBorder
                    disableSelectAll
                    disableRowSelectionOnClick
                    state={{ sorting }}
                    initialState={{
                      columnVisibility: {
                        included: false,
                      },
                    }}
                    getSortedRowModel={getSortedRowModel()}
                    onSortingChange={value => {
                      setSortinig(value)
                    }}
                    classes={{
                      root: sx({
                        borderRadius: 4,
                        border: 0,
                      }),
                    }}
                  />
                </Box>
              </Paper>
            ) : null}
          </Col>
        </Row>
      </Container>
    </DialogContent>
  )
}
